import React, { Component } from 'react';
import { App, SmesshyAppShape } from '../App'
import IconButton, {CloseButton, IconBookmark, IconBookmarked } from './iconButton';
import { Footer } from '../smesshy';
import { Profile, User } from 'oidc-client';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState, SmesshyNavigateOptions } from '../smesshyCommon';
import PaintingFrame from './paintingFrame';
import { ArtistProfile, PaintingInfo } from '../storageManager';
import InfiniteScroll from "react-infinite-scroll-component";
import { prependListener } from 'process';
import Smessage from './smessage';
import { SmesshySubButton } from './smesshyButton';
import GamePage from './gamePage';
import { StreakViewer } from './followingPicker';



export interface PaintingPairState {
}
export interface PaintingPairProps {
    AppObject: App;
    AppShape: SmesshyAppShape | undefined;
    PaintingInfo1?: PaintingInfo;
    PaintingInfo2?: PaintingInfo;
    UserId: string | null;
    SaveEdit: boolean;
    ShowChallenge: boolean;
    ShowLunchbox: boolean;
    SelectionMode: boolean;
    Callback?: string;
    NoArtist: boolean;
    CurrentSelection?: string;
}

export class PaintingPair extends Component<PaintingPairProps, PaintingPairState> {

    constructor(props: PaintingPairProps) {
        super(props);

        this.state = {
        };
    }

    render() {

        return (<div className='h-items width-100p h-small-gap h-padding-small v-padding-small'>
                    <div style={{width:'50%'}}>
                        {this.props.PaintingInfo1 === undefined ? <></> :
                        <PaintingFrame AppObject={this.props.AppObject} AppShape={this.props.AppShape!}
                                                PaintingInfo = {this.props.PaintingInfo1!}
                                                UserId={this.props.UserId}
                                                SaveEdit = {this.props.SaveEdit}
                                                ShowChallenge = {this.props.ShowChallenge}
                                                ShowLunchbox = {this.props.ShowLunchbox}
                                                VoteMode = {false}
                                                SelectionMode = {this.props.SelectionMode}
                                                Callback = {this.props.Callback}
                                                NoArtist = {this.props.NoArtist}
                                                CurrentSelection = {this.props.CurrentSelection}
                                                />}
                    </div>
                    <div style={{width:'50%'}}>
                        {this.props.PaintingInfo2 === undefined ? <></> :
                         <PaintingFrame AppObject={this.props.AppObject} 
                                                AppShape={this.props.AppShape}
                                                PaintingInfo = {this.props.PaintingInfo2!}
                                                UserId={this.props.UserId}
                                                SaveEdit = {this.props.SaveEdit}
                                                ShowChallenge = {this.props.ShowChallenge}
                                                ShowLunchbox = {this.props.ShowLunchbox}
                                                VoteMode = {false}
                                                SelectionMode = {this.props.SelectionMode}
                                                Callback = {this.props.Callback}
                                                NoArtist = {this.props.NoArtist}
                                                CurrentSelection = {this.props.CurrentSelection}
                                                />}
                    </div>
            </div>
        );
    }
}



export interface GalleryPageState extends SmesshyCommonState {
    userId: string | null;
    nextStart: number;
    atEnd: boolean;
    saveEdit: boolean;
    showChallenge: boolean;
    showLunchbox: boolean;
    paintingInfos: Array<PaintingInfo>;
    follows: boolean;
    artistProfile: ArtistProfile | undefined;
    showingBookmarked: boolean;
}
export interface GalleryPageProps extends SmesshyCommonProps {
    Title: string;
    Scope: string;
    Filter: string;
    FetchCount: number;
    SelectionMode: boolean;
    ArtistMode: boolean;
    Callback?: string;
    CurrentSelection?: string;
    RequireAuth: boolean;
    ShowBookmarkFilter?: boolean;
}

class GalleryPage extends SmesshyCommon(Component<GalleryPageProps, GalleryPageState>) {

    scrollRef: React.RefObject<HTMLDivElement>;

    constructor(props: GalleryPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.scrollRef = React.createRef();
        this.initState(props);
    }

    initState(props: GalleryPageProps) {
        let saveEdit = false;
        let showChallenge = true;
        let showLunchbox = false;

        let requireAccount = false;
   
        switch(props.Scope) {
            case 'my':
                requireAccount = true;
                saveEdit = true;
                showLunchbox = true;
                break;
            case 'following':
                requireAccount = true;
                break;
            case 'global':
                if (props.Filter === 'recent') {
                    showChallenge = false;
                }
                break;
            case 'others':
                requireAccount = true;
                break;
        }

        let reqNav : SmesshyNavigateOptions | undefined = undefined;
        if (requireAccount) {
            reqNav = this.requireSetup(()=>{}, true);
        } 

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            userId: null,
            nextStart: 0,
            atEnd: false,
            saveEdit: saveEdit,
            showChallenge: showChallenge,
            showLunchbox: showLunchbox,
            paintingInfos: new Array<PaintingInfo>(),
            follows: false,
            artistProfile: undefined,
            showingBookmarked: false,
            navigateTo: reqNav
        };

    }

    componentDidMount() {
        STrace.addStep('gallery', 'didMound', '');
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('gallery', 'populateAuth', authenticated.toString());
        if (authenticated) {
            this.setState({authenticated:true, userId: await this.getUserId()});
            
            if (this.props.ArtistMode === true) {
                STrace.addStep('gallery', 'getUserFollowingAsync', 'approved');
                const follows = await this.storageManager!.getUserFollowingAsync('approved', false);
                this.setState({follows:follows.has(this.props.Filter)});

                const controlThis = this;
                STrace.addStep('gallery', 'getArtistProfilesAsync', this.props.Filter);
                await this.storageManager!.getArtistProfilesAsync([this.props.Filter], 'full', (profs: Array<ArtistProfile>)=>{
                    if (profs.length > 0) {
                        controlThis.setState({artistProfile: profs[0]});
                    }
                });

            }
            this.fetchMorePaintings(true,this.state.atEnd, this.state.nextStart, this.state.paintingInfos, this.state.showingBookmarked);
        } else {
            this.setState({authenticated:false});
            if (this.state.navigateTo === undefined) {
                this.fetchMorePaintings(false, this.state.atEnd, this.state.nextStart, this.state.paintingInfos, this.state.showingBookmarked); 
            }
        }
    }


    componentDidUpdate(prevProps: Readonly<GalleryPageProps>, prevState: Readonly<GalleryPageState>): void {
        if (prevProps.Filter !== this.props.Filter || prevProps.Scope != this.props.Scope) {
            this.initState(this.props);
        }
    }

    fetchMorePaintings(authenticated: boolean, atEnd: boolean, nextStart: number, paintingInfos: Array<PaintingInfo>, showingBookmarked: boolean) {
        if (atEnd) {
          return;
        }

        if (this.props.RequireAuth && !authenticated) {
            return;
        }
        
        let app = this.props.AppObject;
        let controlThis = this;
        this.pushWaitingFrame(this);
        this.executeAsync(async ()=> {

            const acceptPins = (pins: Array<PaintingInfo>)=>{
                controlThis.setState({
                    paintingInfos: paintingInfos.concat(pins),
                    nextStart: nextStart + pins.length, 
                    atEnd:pins.length < controlThis.props.FetchCount
                });

                for (let pin of pins) {
                    controlThis._app!.clearNotifications('painting', pin.paintingId!);
                }
            };

            let token = await controlThis.getAccessToken();
            if (token === undefined) {
                token = 'none'
            }           

            if (controlThis.props.Scope === 'my') {
                STrace.addStep('gallery', 'getUserPaintingsWithPreviews', 'my');
                let filter = controlThis.props.Filter;
                if (showingBookmarked) {
                    filter = 'favorite';
                }
                controlThis.storageManager!.getUserPaintingsWithPreviews(token, 
                    nextStart, nextStart + controlThis.props.FetchCount-1, filter, acceptPins); 
                    
            } else if (controlThis.props.Scope === 'following') {
                if (controlThis.props.Filter === 'lunchbox') {
                    STrace.addStep('gallery', 'getCurrentLunchboxPaintingsWithPreviews', 'following lunchbox');
                    controlThis.storageManager!.getCurrentLunchboxPaintingsWithPreviews(token, 
                        nextStart, nextStart + controlThis.props.FetchCount-1, acceptPins);
                } else {
                    STrace.addStep('gallery', 'getFollowingPaintingsWithPreviews', 'following');
                    controlThis.storageManager!.getFollowingPaintingsWithPreviews(token, 
                        nextStart, nextStart + controlThis.props.FetchCount-1, controlThis.props.Filter, acceptPins);
                }
            } else if (controlThis.props.Scope === 'global') {
                STrace.addStep('gallery', 'getGlobalPaintingsWithPreviews', 'global');
                controlThis.storageManager!.getGlobalPaintingsWithPreviews(token,
                    nextStart, nextStart + controlThis.props.FetchCount-1, controlThis.props.Filter, acceptPins);
            } else if (controlThis.props.Scope === 'others') {
                STrace.addStep('gallery', 'getOthersPaintingsWithPreviews', 'others');
                controlThis.storageManager!.getOthersPaintingsWithPreviews(token, 
                    nextStart, nextStart + controlThis.props.FetchCount-1, controlThis.props.Filter, acceptPins);
            }
            controlThis.popWaitingFrame();
        });

      }
    

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`gallery, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const onUnFollow = async (): Promise<boolean> => {
            STrace.addStep('gallery', 'deleteUserFollowingAsync-pending', controlThis.props.Filter);
            controlThis.pushWaitingFrame(controlThis);
            await controlThis.storageManager!.deleteUserFollowingAsync(controlThis.props.Filter, 'approved');
            controlThis.setState({follows:false});
            controlThis.popWaitingFrame();
            return true;
        }
        const onFollow = async (): Promise<boolean> => {
            let reqNav = await controlThis.requireSetupAsync(async ()=>{
                STrace.addStep('gallery', 'postUserFollowingAsync', controlThis.props.Filter);
                controlThis.pushWaitingFrame(controlThis);
                await controlThis.storageManager!.postUserFollowingAsync(controlThis.props.Filter);
                controlThis.setState({follows:true});
                controlThis.popWaitingFrame();
               });

            if (reqNav !== undefined) {
                controlThis.setState({navigateTo:reqNav});
            }
            return true;
        }

        const pairs = new Array<[PaintingInfo| undefined, PaintingInfo| undefined]>();
        let lastPair: [PaintingInfo | undefined, PaintingInfo | undefined] = [undefined, undefined];
        for (const pin of this.state.paintingInfos) {
            if (lastPair[0] === undefined) {
                pairs.push(lastPair);
                lastPair[0] = pin;
            } else {
                lastPair[1] = pin;
                lastPair = [undefined, undefined];
            }
        }
        let titleExtra = `${this.props.Scope} ${this.props.Filter}`;
        if (this.props.Scope === 'others') {
            titleExtra = 'Artist Page';
        }
        let bookmarkFilterButton = <></>;

        if (this.props.ShowBookmarkFilter) {
            bookmarkFilterButton = <IconButton AppObject={this._app!} 
                Tag='bookmarks'
                IconRef={this.state.showingBookmarked ? IconBookmarked(undefined) : IconBookmark(undefined)}
                PressTickInterval={0}
                OnPressStart={()=>{
                }}
                OnPressTick={()=>{
                }}
                OnPressFinish={async (): Promise<boolean>=>{
                    let paintingInfos = new Array<PaintingInfo>();
                    controlThis.setState({showingBookmarked:!controlThis.state.showingBookmarked, nextStart:0, paintingInfos:paintingInfos, atEnd:false});
                    controlThis.fetchMorePaintings(controlThis.state.authenticated, false, 0, paintingInfos, !controlThis.state.showingBookmarked);
                    return true;
                }}
                StartPushedState={true}
                Haptic = {this.doButtonHaptic()}
                Height={this._app!.GetScaledPxHeight(31)}
                Size={'large'}
            />

        }

        let headerSection = <div className='h-items h-large-gap'>
            <div className='text-bold h-padding-medium'>{this.props.Title}</div>
            {bookmarkFilterButton}
        </div>

        if (this.props.ArtistMode) {
            let followButton = <></>;
            if (this.props.Filter !== this.state.userId) {
                if (this.state.follows) {
                    followButton = <SmesshySubButton AppObject={this._app!}
                        Display={<>Stop Following</>}
                        Haptic = {this.doButtonHaptic()}
                        StartPushedState = {false}
                        OnPressFinish={async (): Promise<boolean> => {return await onUnFollow()}}
                    />;
                } else { 
                    followButton = <SmesshySubButton AppObject={this._app!}
                        Display={<>Follow</>}
                        Haptic = {this.doButtonHaptic()}
                        StartPushedState = {false}
                        OnPressFinish={async (): Promise<boolean> => {return await onFollow()}}
                    />;
                }
            }
            let paintingsDisp = '-';
            let offensiveDisp = '-';
            let suspiciousDisp = '-';
            let challengesDisp = '-';
            let rankDisp = '-';
            let avgScoreDisp = '-';
            let scoreGivenDisp = '-';
            let scoreRecievedDisp = '-';
            let followersDisp = '-';
            let followingDisp = '-';
            let mavenDisp = '-';

            if (this.state.artistProfile !== undefined && this.state.artistProfile.artistStats !== undefined) {
                paintingsDisp = this.state.artistProfile.artistStats!.totalPaintings.toString();
                offensiveDisp = this.state.artistProfile.artistStats!.totalOffend.toString();
                suspiciousDisp = this.state.artistProfile.artistStats!.totalSuspect.toString();
                challengesDisp = this.state.artistProfile.artistStats!.competitionEntries.toString();
                rankDisp = this.state.artistProfile.artistStats!.competitionHighestRank.toString();
                let compPoints = this.state.artistProfile.artistStats!.competitionPointsReceived;
                let compVotes = this.state.artistProfile.artistStats!.competitionVotesReceived;
                if (compVotes > 0) {
                    avgScoreDisp = (compPoints / compVotes).toFixed(1);
                }
                scoreGivenDisp  = this.state.artistProfile.artistStats!.competitionVotes.toString();
                scoreRecievedDisp = compVotes.toString();
                followersDisp = this.state.artistProfile.artistStats!.totalFollowers.toString();
                followingDisp = this.state.artistProfile.artistStats!.totalFollowing.toString();
                mavenDisp = this.state.artistProfile.artistStats!.mavenScore.toFixed(3);
            }

            headerSection = 
            <div style={{display:'grid', columnGap:'4px', gridTemplateRows:'auto auto auto auto auto auto auto auto auto ', gridTemplateColumns:'max-content max-content max-content minmax(0, 1fr) auto'}}>
                <div className='text-bold text-large h-padding-medium v-padding-medium' style={{gridRow:1, gridColumnStart:1, gridColumnEnd: 6}}>
                    {this.props.Title}
                </div>
                <div style={{gridRowStart:2, gridRowEnd:5, gridColumn:9}}>
                    {followButton}
                </div>
                <div className='text-small' style={{gridRow:2, gridColumn:1, paddingLeft:0}}>
                    Paintings:
                </div>
                <div className='text-small' style={{gridRow:2, gridColumn:2, paddingLeft:0}}>
                    {paintingsDisp}
                </div>
                <div className='text-small' style={{gridRow:3, gridColumn:1, paddingLeft:0}}>
                    Offensive:
                </div>
                <div className='text-small' style={{gridRow:3, gridColumn:2, paddingLeft:0}}>
                    {offensiveDisp}
                </div>
                <div className='text-small' style={{gridRow:3, gridColumn:3, paddingLeft:0}}>
                    Suspicious:
                </div>
                <div className='text-small' style={{gridRow:3, gridColumn:4, paddingLeft:0}}>
                    {suspiciousDisp}
                </div>
                <div className='text-small' style={{gridRow:4, gridColumn:1, paddingLeft:0}}>
                    Challenges:
                </div>
                <div className='text-small' style={{gridRow:4, gridColumn:2, paddingLeft:0}}>
                    {challengesDisp}
                </div>
                <div className='text-small' style={{gridRow:5, gridColumn:1, paddingLeft:0}}>
                    Best Rank:
                </div>
                <div className='text-small' style={{gridRow:5, gridColumn:2, paddingLeft:0}}>
                    {rankDisp}
                </div>
                <div className='text-small' style={{gridRow:5, gridColumn:3, paddingLeft:0}}>
                    Avg Score:
                </div>
                <div className='text-small' style={{gridRow:5, gridColumn:4, paddingLeft:0}}>
                    {avgScoreDisp}
                </div>
                <div className='text-small' style={{gridRow:6, gridColumn:1, paddingLeft:0}}>
                    Scores Given:
                </div>
                <div className='text-small' style={{gridRow:6, gridColumn:2, paddingLeft:0}}>
                    {scoreGivenDisp}
                </div>
                <div className='text-small' style={{gridRow:6, gridColumn:3, paddingLeft:0}}>
                    Received:
                </div>
                <div className='text-small' style={{gridRow:6, gridColumn:4, paddingLeft:0}}>
                    {scoreRecievedDisp}
                </div>
                <div className='text-small' style={{gridRow:7, gridColumn:1, paddingLeft:0}}>
                    Followers:
                </div>
                <div className='text-small' style={{gridRow:7, gridColumn:2, paddingLeft:0}}>
                    {followersDisp}
                </div>
                <div className='text-small' style={{gridRow:7, gridColumn:3, paddingLeft:0}}>
                    Following:
                </div>
                <div className='text-small' style={{gridRow:7, gridColumn:4, paddingLeft:0}}>
                    {followingDisp}
                </div>
                <div className='text-small' style={{gridRow:8, gridColumn:1, paddingLeft:0}}>
                    Maven Score:
                </div>
                <div className='text-small' style={{gridRow:8, gridColumn:2, paddingLeft:0}}>
                    {mavenDisp}
                </div>

                <div className='text-bold h-padding-medium v-padding-large' style={{gridRow:9, gridColumnStart:1, gridColumnEnd: 6}}>
                    Recent paintings:
                </div>

            </div>

            
        } 

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={this.props.RequireAuth}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>{`Smesshy - ${titleExtra}`}</span>}
                CloseNav = {-1}
                Body={
                    <div className='game-page-mid smesshy-main text-white text-medium v-small-gap v-padding-small' >
                    {headerSection}
                    <div id='gallery-scroll-host' className='width-100p height-100p v-scroll-container' ref={ this.scrollRef }>
                        <InfiniteScroll
                            dataLength={this.state.paintingInfos.length}
                            next={()=>controlThis.fetchMorePaintings(controlThis.state.authenticated, controlThis.state.atEnd, controlThis.state.nextStart, controlThis.state.paintingInfos, controlThis.state.showingBookmarked)}
                            hasMore={!this.state.atEnd}
                            loader={<div className='text-bold'>There's more...</div>}
                            scrollableTarget='gallery-scroll-host'

                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                <b>{`(no more)`}</b>
                                </p>
                            }
            >
                            {pairs.map((pair, index) => (
                                <PaintingPair
                                    key={index}
                                    AppObject={this.props.AppObject}
                                    AppShape={this.props.AppShape}
                                    PaintingInfo1={pair[0]}
                                    PaintingInfo2={pair[1]}
                                    UserId={this.state.userId}
                                    SaveEdit = {this.state.saveEdit}
                                    ShowChallenge = {this.state.showChallenge}
                                    ShowLunchbox = {this.state.showLunchbox}
                                    SelectionMode = {this.props.SelectionMode}
                                    Callback = {this.props.Callback}
                                    NoArtist = {this.props.ArtistMode}
                                    CurrentSelection = {this.props.CurrentSelection}
                                
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
       
                </div>
                }
            />, this.state);
    }
}

export default GalleryPage;