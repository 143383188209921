import React, { Children, Component } from 'react';
import { App } from '../App'
import {CloseButton } from './iconButton';
import { Footer } from '../smesshy';
import { Profile, User } from 'oidc-client';
import Smessage from './smessage';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';
import { ArtistProfile, ChallengeResult, ChallengeState, PaintingInfo, PaintingLocate, VoteRoundStatus } from '../storageManager';
import PaintingFrame from './paintingFrame';
import { SmesshyMainButton, SmesshySubButton } from './smesshyButton';
import CountDownText from './countDownText';
import { FollowingTile } from './followingPage';
import { createSearchParams } from 'react-router-dom';


export interface ResultPageState extends SmesshyCommonState {
    challengeState: ChallengeState;
    challengeStateNext: ChallengeState | undefined;
    challengeStatePrev: ChallengeState | undefined;
    resultInfos: Array<PaintingInfo> | undefined;
    luckyVoterProfile: ArtistProfile | undefined;
    challengeTitle: string;
}
export interface ResultPageProps  extends SmesshyCommonProps {
    ChallengeId: string | undefined;
}

class ResultPage extends SmesshyCommon(Component<ResultPageProps, ResultPageState>) {

    constructor(props: ResultPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            challengeState: {
                challengeId: -1,
                state: 'none',
                prizePool: 0,
                title: '',
                voteRoundCurrent: 0,
                voteRoundsTotal: 0,
                timeLeft: 100*60*60,
                roundTimeLeft: 100*60*60,
                stampAllowed: false,
            },
            challengeStateNext: undefined,
            challengeStatePrev: undefined,
            resultInfos: undefined,
            luckyVoterProfile: undefined,
            challengeTitle: 'Why are the internets slow?',
        };
    }


    componentDidMount() {
        STrace.addStep('result', 'didMound', '');
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('result', 'populateAuth', authenticated.toString());

        await this.moveToResults(this.props.ChallengeId!);
    }

    componentDidUpdate(prevProps: Readonly<ResultPageProps>, prevState: Readonly<ResultPageState>): void {

    }

    async moveToResults(challengeId: string) {

        STrace.addStep('result', 'getChallengeStateAsync', challengeId);
        let requestedChallengeState = await this.storageManager!.getChallengeStateAsync(challengeId);
        let prevChallengeState: ChallengeState | undefined = undefined;
        let nextChallengeState: ChallengeState | undefined = undefined;
        let challengeTitle = 'Why are the internets slow?';
        let fetchedResultInfos : Array<PaintingInfo> | undefined = undefined;
        let fetchedLuckyVoterProfile: ArtistProfile | undefined = undefined;

        if (requestedChallengeState === undefined) {
        }
        else {

            let chId = requestedChallengeState!.challengeId;
            let chIdNext = chId + 1;
            let chIdPrev = chId - 1;
            
            if (requestedChallengeState.state !== 'finished'){
                chIdNext = -1;
                chId = chIdPrev;
                requestedChallengeState = undefined;
                chIdPrev = chId - 1;
            }
            if (requestedChallengeState === undefined) {
                STrace.addStep('result', 'getChallengeStateAsync', chId.toString());
                requestedChallengeState = await this.storageManager!.getChallengeStateAsync(chId.toString());
            }
            if (chIdNext >= 8) {
                STrace.addStep('result', 'getChallengeStateAsync', chIdNext.toString());
                nextChallengeState = await this.storageManager!.getChallengeStateAsync(chIdNext.toString());
            }
            if (chIdPrev >= 8) {
                STrace.addStep('result', 'getChallengeStateAsync', chIdPrev.toString());
                prevChallengeState = await this.storageManager!.getChallengeStateAsync(chIdPrev.toString());
            }
           
        }

        if (requestedChallengeState !== undefined) {
            challengeTitle = requestedChallengeState.title;
            STrace.addStep('result', 'getChallengeResultAsync', 'last');
            let lastResults = await this.storageManager!.getChallengeResultAsync(requestedChallengeState.challengeId.toString());  
            if (lastResults !== undefined && lastResults !== null) {
                this.setState({challengeTitle: lastResults.title});
                if (lastResults.place1 !== undefined && lastResults.place1 !== null) {
                    // get the artist profile for the lucky voter, if there is one
                    if (lastResults.voterSelected !== undefined && lastResults.voterSelected !== null) {
                        STrace.addStep('result', 'getArtistProfilesAsync', lastResults.voterSelected);
                        await this.storageManager!.getArtistProfilesAsync([lastResults.voterSelected], 'basic', (profiles: Array<ArtistProfile>) => {
                            if (profiles.length > 0) {
                                fetchedLuckyVoterProfile = profiles[0];
                                this.setState({luckyVoterProfile: fetchedLuckyVoterProfile});
                            }
                        });
                    }
                    
                    const resultImages = new Array<PaintingLocate>();
                    resultImages.push(lastResults.place1);
                    if (lastResults.place2 !== undefined && lastResults.place2 !== null) {
                        resultImages.push(lastResults.place2);
                    }
                    if (lastResults.place3 !== undefined && lastResults.place3 !== null) {
                        resultImages.push(lastResults.place3);
                    }                
                    STrace.addStep('result', 'getGlobalPaintingsInfosAsync', '');
                    const resultInfos = await this.storageManager!.getGlobalPaintingsInfosAsync(resultImages!);
                    if (resultInfos !== undefined) {
                        let token =  (await this.getAccessToken())!;
                        if (token === undefined) {
                            token = 'none'
                        }           
                        STrace.addStep('result', 'getPaintingPreviews', '');
                        let controlThis = this; 
                        this.storageManager!.getPaintingPreviews(token, resultInfos, (pins=>{
                            if (pins.length > 0) {
                                fetchedResultInfos = pins;
                                controlThis.setState({ resultInfos: pins});
                            }
                        }))
                    }
                }
            }
        }
        this.setState({ resultInfos: fetchedResultInfos, challengeTitle: challengeTitle, luckyVoterProfile: fetchedLuckyVoterProfile, challengeState: requestedChallengeState!, challengeStateNext: nextChallengeState, challengeStatePrev: prevChallengeState});
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`vote, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const onLuckyNavigate = async (): Promise<boolean> => {
            const otherId = controlThis.state.luckyVoterProfile!.artistId!;
            STrace.addStep('result', 'lucky nav', otherId);
            const otherName = controlThis.state.luckyVoterProfile!.artistName!;
            const params = createSearchParams({title: otherName, scope: 'others', filter:otherId});
            controlThis.setState({navigateTo: {To:`/other-gallery?${params.toString()}`}});
            return true;
        }

        let viewChoices = <></>;
        var titleLine = <></>;

        let prevButton = <></>;
        let nextButton = <></>;
        
        if (this.state.showWaitSpin) {
            titleLine = <span>Checking...</span>;
        } else {
            titleLine = <div className='v-items v-gap-medium '>
                <span>Check out the winners for:</span>
                <span> {`"${this.state.challengeTitle}"`}</span>
            </div>;

            if (this.state.challengeStatePrev !== undefined) {
                prevButton = <SmesshySubButton AppObject={this._app!}
                                key={'reject'}
                                Display={<>{`<<< ${this.state.challengeStatePrev.title}`}</>}
                                Haptic = {this.doButtonHaptic()}
                                StartPushedState = {false}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    controlThis.moveToResults(this.state.challengeStatePrev!.challengeId.toString());
                                    return true;
                                }}
                            />;
            }

            if (this.state.challengeStateNext !== undefined) {
                nextButton = <SmesshySubButton AppObject={this._app!}
                                key={'accept'}
                                Display={<>{`${this.state.challengeStateNext.title} >>>`}</>}
                                Haptic = {this.doButtonHaptic()}
                                StartPushedState = {false}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    controlThis.moveToResults(this.state.challengeStateNext!.challengeId.toString());
                                    return true;
                                }}
                            />;
            }


            if (this.state.resultInfos !== undefined) {
                viewChoices =
                <div className='v-scroll-container'>
                    <div className='v-scroll-region v-medium-gap'>
                        <div className='h-items each-space-between'>
                            <span style={{width:this._app!.GetScaledPxWidthVw(110)}} className='text-center self-cross-center text-large'>Grand Champion:</span>
                            <div style={{width:this._app!.GetScaledPxWidthVw(240)}}>
                                <PaintingFrame 
                                    AppObject={this.props.AppObject} 
                                    AppShape={this.props.AppShape}
                                    PaintingInfo = {this.state.resultInfos![0]}
                                    UserId={''}
                                    SaveEdit = {false}
                                    ShowChallenge = {true}
                                    ShowLunchbox = {false}
                                    VoteMode = {false}
                                    SelectionMode = {false}
                                    NoArtist = {false}
                                    CurrentSelection = {undefined}
                                    />
                            </div>
                        </div>
                        {this.state.resultInfos.length > 1 ? 
                            <div className='h-items each-space-between'>
                                <span style={{width:this._app!.GetScaledPxWidthVw(110)}} className='text-center self-cross-center text-large'>First Runner Up:</span>
                                <div style={{width:this._app!.GetScaledPxWidthVw(240)}}>
                                <PaintingFrame 
                                    AppObject={this.props.AppObject} 
                                    AppShape={this.props.AppShape}
                                    PaintingInfo = {this.state.resultInfos![1]}
                                    UserId={''}
                                    SaveEdit = {false}
                                    ShowChallenge = {true}
                                    ShowLunchbox = {false}
                                    VoteMode = {false}
                                    SelectionMode = {false}
                                    NoArtist = {false}
                                    CurrentSelection = {undefined}
                                    />
                            </div>
                        </div>: <></>}
                        {this.state.resultInfos.length > 2 ? 
                            <div className='h-items each-space-between'>
                                <span style={{width:this._app!.GetScaledPxWidthVw(110)}} className='text-center self-cross-center text-large'>Second Runner Up:</span>
                                <div style={{width:this._app!.GetScaledPxWidthVw(240)}}>
                                <PaintingFrame 
                                    AppObject={this.props.AppObject} 
                                    AppShape={this.props.AppShape}
                                    PaintingInfo = {this.state.resultInfos![2]}
                                    UserId={''}
                                    SaveEdit = {false}
                                    ShowChallenge = {true}
                                    ShowLunchbox = {false}
                                    VoteMode = {false}
                                    SelectionMode = {false}
                                    NoArtist = {false}
                                    CurrentSelection = {undefined}
                                    />
                            </div>
                        </div>: <></>}
                        {this.state.luckyVoterProfile !== undefined ? 
                            <div className='h-items each-space-between'>
                                <span style={{width:this._app!.GetScaledPxWidthVw(150)}} className='text-center self-cross-center text-large'>The Lucky Voter:</span>
                                <div style={{width:this._app!.GetScaledPxWidthVw(200)}}>
                                    <FollowingTile
                                            AppObject={this.props.AppObject}
                                            AppShape={this.props.AppShape}
                                            ArtistProfile={this.state.luckyVoterProfile}
                                            ShowTrash={false}
                                            ShowPlus={false}
                                            ShowPending={false}
                                            AllowVScroll={true}
                                            OnTileClick={async (top: number, artistId: string, artistName: string) => {return await onLuckyNavigate();}}
                                            OnTrashClick={async (top:number, artistId:string, artistName:string)=>{return true}}
                                            OnPlusClick={async (top:number, artistId:string, artistName:string)=>{return true}}
                                        />
                                </div>
                            </div>: <></>}
                    </div>
                </div>

            } else {
                if (this.state.challengeState.state === 'finished') {
                    viewChoices = <span>Sad to say, but nobody participated in that challenge.<br/>Maybe there was a flood, an earthquake, locusts?</span>;
                } else {
                    viewChoices = <span>Nobody knows</span>;
                }
            }
        }

        return (this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={this._inPWA}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>Smesshy - Challenge</span>}
                CloseNav = '/'
                Body={
                    <div className='game-page-mid smesshy-main text-white text-medium' >
                        <div className='v-items v-medium-gap v-padding-small h-padding-small height-100p width-100p'>
                            <div className='h-items each-space-between'>
                                {prevButton}
                                {nextButton}
                            </div>
                            <div className='text-bold text-large'>
                                {titleLine}
                            </div>
                            <div className='game-page-mid'>
                                {viewChoices}
                            </div>


                        </div>
                        
                    </div>
                }
            />, this.state));
    }
}

export default ResultPage;