import './App.css';

export class EnvironmentAndInstallManager
{
    installed: string;
    deferredInstallPrompt: Event | undefined;
    deviceName: string;
    browserName: string;
    inPWA: boolean;
    canVibrate: boolean;
    afterInstall: ()=>void;
    afterSwitch: ()=>void;

    constructor() {
        this.installed = 'unknown';
        this.deferredInstallPrompt = undefined;
        this.deviceName = 'unknown';
        this.browserName = 'unknown';
        this.inPWA = false;
        this.canVibrate = false;
        this.afterInstall = ()=>{};
        this.afterSwitch = ()=>{};


        window.addEventListener('beforeinstallprompt', (e:Event) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            this.deferredInstallPrompt = e;
        });

        window.addEventListener('appinstalled', async (e) => {
            this.installed = 'true';
            this.afterInstall();
        });

        // listen for a change in the display mode that indicates a switch to the app
        window.addEventListener('DOMContentLoaded', () => {
            window.matchMedia('(display-mode: standalone)')
              .addEventListener('change', (evt) => {
                if (evt.matches) {
                    this.installed = 'true';
                    this.inPWA = true;
                    this.afterSwitch();
                }
              });
          });
    }
    public isInPWA() :boolean {
        this.inPWA = false;
        // For iOS
        if ((window.navigator as any).standalone || (window.navigator as any).fullscreen) {
            this.inPWA = true;
            return true;
        }
     
        // For Android
        if (window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches) {
            this.inPWA = true;
            return true;
        }
     
        // If neither is true, it's not installed
        return false
     }
     
    lastInstalledStatus = 'unknown';
    public async checkAppInstalledState() : Promise<string> {
        try {
            if ('getInstalledRelatedApps' in window.navigator) {
                const relatedApps = await (navigator as any)['getInstalledRelatedApps']();
                if (relatedApps.find((app:any)=>true) !== undefined){
                    this.lastInstalledStatus = 'true';
                } else {
                    this.lastInstalledStatus = 'false';
                }
            } else {
                this.lastInstalledStatus = 'unknown';
            }
            return this.lastInstalledStatus;
        } catch (e) {
            return 'unknown';
        }
    }

    public setupComplete() : boolean {
        return localStorage.getItem('smesshy-app-setup-complete') === 'true';
    }

    public setupNotifications() : boolean {
        return localStorage.getItem('smesshy-app-setup-notifications') === 'true';
    }

    public finishNotificationsSetup() : void {
        localStorage.setItem('smesshy-app-setup-notifications', 'true');
    }

    public setupCookies() : boolean {
        return localStorage.getItem('smesshy-app-setup-cookies') === 'true';
    }

    public finishCookiesSetup() : void {
        localStorage.setItem('smesshy-app-setup-cookies', 'true');
    }

    public finishSetup() : void {
        localStorage.setItem('smesshy-app-setup-complete', 'true');
    }

    public getDeferredInstallPrompt() : Event | undefined {
        return this.deferredInstallPrompt;
    }

    public getDeviceName() :string {
        const ua = navigator.userAgent;
    
        if (/android/i.test(ua)) {
            return "Android";
        }
    
        if (/iPad|iPhone|iPod/.test(ua) && !(window as any).MSStream) {
            return "iOS";
        }

        if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && (/MacIntel/.test(ua) || /Macintosh/.test(ua))) {
            return "iOS";
        }
    
        return "Other";
    }
    
    public getBrowserName() :string {
        const ua = navigator.userAgent;
        if (ua.indexOf("Chrome") > -1) {
            if (ua.indexOf("OP") > -1) {
                return "Opera";
            }    
            if (ua.indexOf("Edge") > -1) {
                return "Edge";
            }    
            if (ua.indexOf("Edg/") > -1) {
                return "Edge";
            }    
        
            return "Chrome";
        }    
        if (ua.indexOf("Firefox") > -1) {
            return "Firefox";
        }    
        if (ua.indexOf("Safari") > -1) {
            if (ua.indexOf("CriOS") > -1) {
                return "Chrome";
            }    
            return "Safari";
        }    
        return "Unknown";
     }

     public async collectEnvironmentState() : Promise<boolean> {
        this.installed = await this.checkAppInstalledState();
        this.deviceName = this.getDeviceName();
        this.browserName = this.getBrowserName();
        this.inPWA = this.isInPWA();
        this.canVibrate = this.getVibrateSupport();
        return true;
    }


     getVibrateSupport(): boolean {
        return ('vibrate' in navigator && typeof(navigator['vibrate']) === 'function' && navigator['vibrate'] !== undefined && navigator['vibrate'] !== null);
     }
}

const Env = new EnvironmentAndInstallManager();
export default Env;
