import tinycolor from 'tinycolor2';
import {colorMixKM} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {FingerTip, FlowCell, FlowCellMove} from './fingertip';


export enum CellKind {
    background = 0,
    corona = 1,
    meniscus = 2,
    smash = 3,
    dry = 5,
    knife = 6,
    scrape = 16,
    hidden = 32
}

export interface DragStep {
    radius: number;
    smashRadius: number;
    location: Point2D;
    octantMove: number;
}

// the overall effect on page cells resulting from placing or dragging a finger tip on it
export class TipEffect {
    rectTL: Point2D = new Point2D(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
    rectBR: Point2D = new Point2D(-Number.MAX_SAFE_INTEGER, -Number.MAX_SAFE_INTEGER);
    cells: Array<number>;
    steps: Array<DragStep>;
    constructor() {
        this.steps = new Array<DragStep>();
        this.cells = new Array<number>();
    }

    public isEmpty() : boolean {
        return this.cells.length === 0;
    }
}

class PageTipPlacement {
    page: Page;
    tip: FingerTip;
    pageCenter: Point2D;
    pageTipCenter: Point2D;
    isKnife: boolean;
    isBlow: boolean;
    isSmudge: boolean;
    pageBuffer: Uint8Array;
    setWetDry: boolean;
    constructor(page: Page, tip: FingerTip, tipPageCenter: Point2D, pageBuffer: Uint8Array, setWetDry: boolean, tipKind: string) {
        this.page = page;
        this.tip = tip;
        this.pageCenter = new Point2D(page.cellsWidth / 2, page.cellsHeight / 2);
        this.pageTipCenter = tipPageCenter;
        this.isKnife = tipKind === 'knife';
        this.isBlow = tipKind === 'blow';
        this.isSmudge = tipKind === 'smudge';
        this.pageBuffer = pageBuffer;
        this.setWetDry = setWetDry;
    }
}

type CrcOff = (CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D);

// ######                       
// #     #   ##    ####  ###### 
// #     #  #  #  #    # #      
// ######  #    # #      #####  
// #       ###### #  ### #      
// #       #    # #    # #      
// #       #    #  ####  ###### 
export class Page {

    static renderScale = 1;

    static paintDepthScale = 4;
    static maxCoronaDepth = Math.round(256 * Page.paintDepthScale);
    static backgroundImplicitDepth = 1.5;
    static pixToScaleUnits(cPix: number):number {
        return Math.round(cPix );
    }
    static scaleUnitsToPix(u: number):number {
        return u * this.renderScale;
    }

    public static offDryRed = 0;
    public static offDryGreen = 1;
    public static offDryBlue = 2;
    public static offWetRed = 3;
    public static offWetGreen = 4;
    public static offWetBlue = 5;
    public static offDepthHigh = 6;
    public static offDepthLow = 7;
    public static offVisRed = 8;
    public static offVisGreen = 9;
    public static offVisBlue = 10;

    cellsWidth: number;
    cellsHeight: number;
    cellsTotal: number;

    cellData: Uint8Array // dried, wet, depth, visible
    cellDataBlade: Uint8Array
    cellDryApplied: Uint16Array | undefined;

    wetCanvasOffsets: Map<number, number>;
    wetCanvasStage: number;
    wetCanvasStageMax: number;
    wetCanvasStageOffsets: Array<Set<number>>;

    rnd: SeededRandom
    blendDried: boolean = true;

    centralPull: ((d:number)=>number) | undefined;
    tipPlacement: PageTipPlacement | undefined;

    crcVisible: CanvasRenderingContext2D;
    clientRec: DOMRect;

    public static getOffscreenCrc(width: number, height: number) : CrcOff {
        if ((typeof OffscreenCanvas) !== 'undefined' && OffscreenCanvas !== undefined && OffscreenCanvas !== null) {
            let cvs = new OffscreenCanvas(width, height);
            return cvs.getContext("2d")!;
        } else {
            let cvs = document.createElement('canvas');
            cvs.width = width;
            cvs.height = height;
            return cvs.getContext("2d")!;
        }
    }

    constructor(clientRec:DOMRect, cx: number, cy: number, crcVisible: CanvasRenderingContext2D,  onClear: undefined | ((crc: CanvasRenderingContext2D)=>void), centralPull: ((d:number)=>number) | undefined, rnd: SeededRandom, blendDried: boolean){
        this.clientRec = clientRec;
        this.cellsWidth = cx;
        this.cellsHeight = cy;
        this.cellsTotal = this.cellsWidth * this.cellsHeight;
        this.cellData = new Uint8Array(this.cellsTotal * (3 + 3 + 2 + 3));
        this.cellDataBlade = new Uint8Array(3 * FingerTip.cellsWidth * (3 + 3 + 2 + 3));
        this.rnd = rnd;
        this.centralPull = centralPull;
        this.wetCanvasOffsets = new Map<number, number>();
        this.wetCanvasStage = 0;
        this.wetCanvasStageMax = 7;
        this.wetCanvasStageOffsets = new Array<Set<number>>(this.wetCanvasStageMax);
        for (let i = 0; i < this.wetCanvasStageMax; i++) {
            this.wetCanvasStageOffsets[i] = new Set<number>();
        }
        this.blendDried = blendDried;

        this.crcVisible = crcVisible;
        this.clear(onClear, centralPull, rnd, blendDried);

    }

    public clear(onClear: undefined | ((crc: CanvasRenderingContext2D)=>void), centralPull: ((d:number)=>number) | undefined, rnd: SeededRandom, blendDried: boolean) {
        let width = this.crcVisible.canvas.width;
        let height = this.crcVisible.canvas.height;

        this.crcVisible.fillStyle = 'white';
        this.crcVisible.fillRect(0, 0, width, height);
        
        // dried, wet, depth, visible
        let clearData = [255,255,255,1,1,1,0,0,255,255,255];
        for (let c = 0; c < this.cellsTotal; c++) {
            let off = c * 11;
            this.cellData.set(clearData, off);
        }
        
        if (onClear !== undefined) {
            onClear(this.crcVisible);
        }
    }
    
    public offsetOfCoordinates(pt: Point2D): number {
        if (pt.x < 0 || pt.y < 0 || pt.x > this.cellsWidth-1 || pt.y > this.cellsHeight-1) {
            return -1;
        }
        return this.cellsWidth * pt.y + pt.x;
    }
    public coordinatesOfOffset(offset: number): Point2D {
        let x = offset % this.cellsWidth;
        let y = Math.floor(offset / this.cellsWidth);
        return new Point2D(x, y);
    }

    public wetColorAtOffset(offset: number) : tinycolor.ColorFormats.RGB {
        let rOff = offset * 11 + 3;
        // dried, wet, depth, visible
        let r = this.cellData[rOff];
        let g = this.cellData[rOff + 1];
        let b = this.cellData[rOff + 2];

        return {r: r, g: g, b: b};
    }
    public visibleColorAtOffset(offset: number) : tinycolor.ColorFormats.RGB {
        let rOff = offset * 11 + 8;
        // dried, wet, depth, visible
        let r = this.cellData[rOff];
        let g = this.cellData[rOff + 1];
        let b = this.cellData[rOff + 2];
        return {r: r, g: g, b: b};
    }

    public getWetDepthAtOffset(c: number) : number {
        let off = c * 11;
        let d = (this.cellData[off + 6] << 8) | this.cellData[off + 7];
        return d;
    }

    public setWetDepthAtOffset(c: number, d: number) {
        let off = c * 11;
        this.cellData[off + 6] = d >> 8;
        this.cellData[off + 7] = d & 0xff;
    }


    public tipCoordinatesToPageCoordinates(ptTip: Point2D, ptCenter: Point2D) : Point2D{
        return new Point2D(ptTip.x + ptCenter.x - FingerTip.cellsWidth/2, ptTip.y + ptCenter.y - FingerTip.cellsWidth/2);
    }
   
    collectTipCellData(tipCoordinatesToBufferOffset: (ptTip: Point2D, adj: number)=>number) {
        let pageLoc = this.tipPlacement!.pageTipCenter;
        let tip = this.tipPlacement!.tip;
        let pageMinX = pageLoc.x + (tip.minX - FingerTip.cellsWidth/2);
        let pageMaxX = pageLoc.x + (tip.maxX - FingerTip.cellsWidth/2);
        let pageMinY = pageLoc.y + (tip.minY - FingerTip.cellsWidth/2);
        let pageMaxY = pageLoc.y + (tip.maxY - FingerTip.cellsWidth/2);

        pageMinX = Math.max(0, pageMinX);
        pageMinY = Math.max(0, pageMinY);
        pageMaxX = Math.min(this.cellsWidth-1, pageMaxX);
        pageMaxY = Math.min(this.cellsHeight-1, pageMaxY);

        let tipMinX = FingerTip.cellsWidth/2 - (pageLoc.x - pageMinX);
        let tipMinY = FingerTip.cellsWidth/2 - (pageLoc.y - pageMinY);
        let tipMaxX = FingerTip.cellsWidth/2 + (pageMaxX - pageLoc.x);
        let tipMaxY = FingerTip.cellsWidth/2 + (pageMaxY - pageLoc.y);
        for (let y = 0; y < tip.cellDataRows!.length; y++) {
            let row = tip.cellDataRows![y];

            row.lengthModified = 0;
            row.printRelativeModifiedStart = -1;
            if (row.row >= tipMinY && row.row <= tipMaxY) {
                let ptTip = new Point2D(row.firstCell, row.row);
                let adj = 0;
                if (ptTip.x < tipMinX) {
                    adj = (tipMinX - ptTip.x);
                    ptTip.x = tipMinX;
                }
                row.pageRelativeStart = tipCoordinatesToBufferOffset(ptTip, adj);

                let rowMinX = Math.max(tipMinX, row.firstCell);
                let rowMaxX = Math.min(tipMaxX, row.firstCell + (row.length / 11) - 1);
                let copyLength = (rowMaxX - rowMinX + 1) * 11;
                let copyTo = row.printRelativeStart + (rowMinX - row.firstCell) * 11;
                let copyFrom = row.pageRelativeStart + (rowMinX - row.firstCell) * 11;

                if (copyLength > 0) {
                    let v = new Uint8Array(this.tipPlacement!.pageBuffer.buffer, copyFrom, copyLength);
                    tip.allCellData!.set(v, copyTo);
                }
            }
        }

        // get the state of the page before the drag put into every source or dest cell in the print
        for (const fCell of tip.allFlowCells) {
            fCell.inBounds = fCell.ptOnPrint.x >= tipMinX && fCell.ptOnPrint.x <= tipMaxX && fCell.ptOnPrint.y >= tipMinY && fCell.ptOnPrint.y <= tipMaxY;
                // and the offset into storage arrays
            if (fCell.inBounds) {
                fCell.stagedDepth = fCell.finalDepth = (fCell.dvTipCells[Page.offDepthHigh]<<8) | fCell.dvTipCells[Page.offDepthLow];
                if (fCell.finalDepth <= 0) {
                    fCell.dvTipCells[Page.offWetRed] = fCell.dvTipCells[Page.offWetGreen] = fCell.dvTipCells[Page.offWetBlue] = 1;
                }  
            } else {
                fCell.dvTipCells[Page.offDryRed] = fCell.dvTipCells[Page.offDryGreen] = fCell.dvTipCells[Page.offDryBlue] = 1;
                fCell.dvTipCells[Page.offWetRed] = fCell.dvTipCells[Page.offWetGreen] = fCell.dvTipCells[Page.offWetBlue] = 1;
                fCell.dvTipCells[Page.offDepthHigh] = fCell.dvTipCells[Page.offDepthLow] = 0;
            }
    
            if (fCell.move1 !== undefined) {
                fCell.move1.removedDepth = 0;
            }
            if (fCell.move2 !== undefined) {
                fCell.move2.removedDepth = 0;
            }
            if (fCell.move3 !== undefined) {
                fCell.move3.removedDepth = 0;
            }
            if (fCell.move4 !== undefined) {
                fCell.move4.removedDepth = 0;
            }
            if (fCell.move5 !== undefined) {
                fCell.move5.removedDepth = 0;
            }
    
            fCell.modified = false;

        }
    }

    writeTipCellData(kindCells: Set<number>) {
        let maxDepth = 0xffff;
        let tip = this.tipPlacement!.tip;
        // send the final depth and color to the page and cell kind map
        let setWetDry = this.tipPlacement!.setWetDry
        for (const fCell of tip.allFlowCells) {
            if (fCell.modified && fCell.inBounds && fCell.finalKind !== CellKind.background) {
                if (fCell.finalDepth > maxDepth) {
                    fCell.finalDepth = maxDepth;
                }
                fCell.dvTipCells[Page.offDepthHigh] = fCell.finalDepth >> 8
                fCell.dvTipCells[Page.offDepthLow] = fCell.finalDepth & 0xff;
                let row = tip.cellDataRows![fCell.ptOnPrint.y - tip.minY];
                let copyTo = row.printRelativeStart + (fCell.ptOnPrint.x - row.firstCell) * 11;
                if (row.printRelativeModifiedStart === -1) {
                    row.printRelativeModifiedStart = copyTo;
                    row.lengthModified = 11;
                } else {
                    if (copyTo < row.printRelativeModifiedStart) {
                        row.lengthModified += row.printRelativeModifiedStart - copyTo;
                        row.printRelativeModifiedStart = copyTo;
                    } else {
                        row.lengthModified = Math.max(row.lengthModified, copyTo + 11 - row.printRelativeModifiedStart);
                    }
                }
                let pageOffset = (row.pageRelativeStart + (copyTo - row.printRelativeStart))/11;
                if (setWetDry) {
                    if (fCell.finalDepth === 0) {
                        this.makeCanvasOffsetDry(pageOffset);
                    } else {
                        this.makeCanvasOffsetWet(pageOffset);
                    }
                }
                this.setCellVisibleColor(tip, fCell);

                // this is the set of cells modified (by kind) in this 'session'
                kindCells.add(pageOffset);
            }
        }
        for(const row of tip.cellDataRows!) {
            if (row.lengthModified > 0) {
                let v = new Uint8Array(tip.allCellData!.buffer, row.printRelativeModifiedStart, row.lengthModified);
                this.tipPlacement!.pageBuffer.set(v, row.pageRelativeStart + (row.printRelativeModifiedStart - row.printRelativeStart));
            }
        }
    }

    static colorIsSmudge(buf: Uint8Array, off: number) {
        return buf[off] === 1 && buf[off + 1] === 1 && buf[off + 2] === 1;
    }
    static colorIsBlow(color: tinycolor.ColorFormats.RGB) {
        return color.r === 254 && color.g === 254 && color.b === 254;
    }
    static colorIsKnife(color: tinycolor.ColorFormats.RGB) {
        return color.r === 1 && color.g === 2 && color.b === 3;
    }

    private setCellVisibleColor(tip: FingerTip, fCell: FlowCell) {
        if (fCell.finalDepth > 0 && !Page.colorIsSmudge(fCell.dvTipCells, Page.offWetRed)) {
            if (this.blendDried && fCell.finalDepth <= FingerTip.preferredMeniscusScrapeDepth) {
                // scale between depth and 4* depth so thicker paint blocks out effect of blending much more
                let scaledDepth = fCell.finalDepth;
                if (scaledDepth > FingerTip.preferredSmashScrapeDepth) {
                    scaledDepth = 4 * FingerTip.preferredMeniscusScrapeDepth * ((scaledDepth-FingerTip.preferredSmashScrapeDepth)/(FingerTip.preferredMeniscusScrapeDepth - FingerTip.preferredSmashScrapeDepth));
                }
                let depthRat = Page.backgroundImplicitDepth / (scaledDepth + Page.backgroundImplicitDepth);
                // visible = mix of wet and dried
                colorMixKM(fCell.dvTipCells, Page.offWetRed, fCell.dvTipCells, Page.offDryRed, depthRat, fCell.dvTipCells, Page.offVisRed);
            } else {
                // visible = wet
                fCell.dvTipCells[Page.offVisRed] = fCell.dvTipCells[Page.offWetRed];
                fCell.dvTipCells[Page.offVisGreen] = fCell.dvTipCells[Page.offWetGreen];
                fCell.dvTipCells[Page.offVisBlue] = fCell.dvTipCells[Page.offWetBlue];
            }
        } else {
            // visible = dried
            fCell.dvTipCells[Page.offVisRed] = fCell.dvTipCells[Page.offDryRed];
            fCell.dvTipCells[Page.offVisGreen] = fCell.dvTipCells[Page.offDryGreen];
            fCell.dvTipCells[Page.offVisBlue] = fCell.dvTipCells[Page.offDryBlue];
        }

    }


    private stageFlow(from: FlowCell, to: FlowCellMove, totalMove: number, prevError: number) : number {
        let newError = prevError;
        if (from.inBounds && to.fCellDest.inBounds) {
            let fracMove = (totalMove * to.fraction) + prevError;
            let intMove = prevError === 0 ? Math.floor(fracMove) : Math.round(fracMove);
            newError = (fracMove - intMove);
            if (intMove > 0) {
                if (this.centralPull !== undefined) {
                    // moving away from the center?
                    // turn tip space into page space
                    
                    let ptOnPageSrc = this.tipCoordinatesToPageCoordinates(from.ptOnPrint, this.tipPlacement!.pageTipCenter);
                    let ptOnPageDst = this.tipCoordinatesToPageCoordinates(to.fCellDest.ptOnPrint, this.tipPlacement!.pageTipCenter);

                    const dSrc = ptOnPageSrc.distanceTo(this.tipPlacement!.pageCenter);
                    const dDst = ptOnPageDst.distanceTo(this.tipPlacement!.pageCenter);
                    if (dDst > dSrc) {
                        const contrib = Math.min(1.0, (dDst - dSrc)); // approx the vector in a line to the center
                        const scale = Math.min(1, Math.max(0, this.centralPull(dDst)));
                        intMove -= (Math.floor(intMove * contrib * scale));
                    }
                }

                from.stagedDepth -= intMove;
                to.fCellDest.stagedDepth += intMove;
                to.removedDepth = intMove;
            }
        }
        return newError;
    }

    private commitFlow(from: FlowCell, moveTo: FlowCellMove) {

        let move = moveTo.removedDepth;
        let to = moveTo.fCellDest;
        if (move > 0) {
            let toIsSmudge = Page.colorIsSmudge(to.dvTipCells, Page.offWetRed);
            let fromIsSmudge = Page.colorIsSmudge(from.dvTipCells, Page.offWetRed);
            let fromIsBlow = this.tipPlacement!.isBlow;

            if (fromIsBlow) {
                // dry = wet unless wet is background
                if (!toIsSmudge) {
                    // dry moves from page to air, so alter the from dry
                    from.dvTipCells[Page.offDryRed] = from.dvTipCells[Page.offWetRed];
                    from.dvTipCells[Page.offDryGreen] = from.dvTipCells[Page.offWetGreen];
                    from.dvTipCells[Page.offDryBlue] = from.dvTipCells[Page.offWetBlue];
                }
            } else if (fromIsSmudge) {
                // smearing, so leave wet in dest alone 
                // solvent would wake up dry but ...

            } else if (toIsSmudge || to.finalDepth === 0) {
                // transfer the wet
                to.dvTipCells[Page.offWetRed] = from.dvTipCells[Page.offWetRed];
                to.dvTipCells[Page.offWetGreen] = from.dvTipCells[Page.offWetGreen];
                to.dvTipCells[Page.offWetBlue] = from.dvTipCells[Page.offWetBlue];
            } else if (to.dvTipCells[Page.offWetRed] === from.dvTipCells[Page.offWetRed] &&
                    to.dvTipCells[Page.offWetGreen] === from.dvTipCells[Page.offWetGreen] &&
                    to.dvTipCells[Page.offWetBlue] === from.dvTipCells[Page.offWetBlue]) {
                    // no mix, same color
            }
            else {
                // the weight is about the paint volume. tip mixing onto page
                let wTip = moveTo.removedDepth / (moveTo.removedDepth + to.finalDepth);
                // to wet = mix of from wet and to wet
                colorMixKM(to.dvTipCells, Page.offWetRed, from.dvTipCells, Page.offWetRed, wTip, to.dvTipCells, Page.offWetRed);
            }

            to.modified = true;
            from.modified = true;

            to.finalDepth += move;
            from.finalDepth -= move;

            if (to.finalDepth === 0) {
                console.log('zero depth');
            }
        }

        moveTo.removedDepth = 0;
    }

    static ColorToTipKind(color: tinycolor.ColorFormats.RGB) : string {
        if (Page.colorIsBlow(color)) {
            return 'blow';
        }
        if (Page.colorIsKnife(color)) {
            return 'knife';
        }
        if (color.r * color.g * color.b === 1) {
            return 'smudge';
        }
        return 'paint';
    }

    public applyTip(tip: FingerTip, center: Point2D, paintColor: string, paintRadius: number) : TipEffect {

        const colorRGB = new tinycolor(paintColor).toRgb();
        let tipKind = Page.ColorToTipKind(colorRGB);
        this.tipPlacement = new PageTipPlacement(this, tip, center, this.cellData, true, tipKind);
        
        // effects from this one movement step
        const result :TipEffect = new TipEffect();
        result.steps.push({radius: tip.radius, smashRadius: tip.smashRadius, location: this.tipPlacement.pageTipCenter, octantMove: 4});

        const kindCells = new Set<number>();
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
                let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, this.tipPlacement!.pageTipCenter);
                return (this.offsetOfCoordinates(ptPage) - adj) * 11;
            });
        // get the new start volume and apply it to all cells in the minimum circle
        const fillRad = Math.floor((FingerTip.minFingerRadius / 5.0) * 3.0);
        let paintVol = FingerTip.newPaintVolume(paintRadius, colorRGB.r * colorRGB.g * colorRGB.b === 1);
        let minCells = Math.PI * fillRad * fillRad;
        FingerTip.doFilledCircle(fillRad, 0, (i: number, pt: Point2D)=>{
            // to make things jagged a bit
            const glop = (this.rnd.nextFloat() + .5)  * paintVol / minCells;

            // info on that spot on the finger
            let fCellTip = tip.getSingleChangeFlowCell(pt, glop, colorRGB);
            // now they are one
            this.stageFlow(fCellTip, fCellTip.move1, glop, 0);
            this.commitFlow(fCellTip, fCellTip.move1);
        })
        this.writeTipCellData(kindCells);


        // run the flow
        this.runCentralFlowPattern(tip, this.tipPlacement.pageTipCenter, kindCells, tipKind);

        // convert the modified page cells into drawing effects
        this.cellsToEffects(kindCells, result);

        return result;
    }

    public blowTip(tip: FingerTip, center: Point2D, dragDryAmount: number) {
        
        this.tipPlacement = new PageTipPlacement(this, tip, center, this.cellData, true, 'blow');
        const pageLoc = this.tipPlacement.pageTipCenter;
        this.cellDryApplied = new Uint16Array(this.cellsTotal);
        
        // effects from this dry step is a list of the x,y, amount of paint to dry
        //const effectPoints = new Array<Point2D>();

        // drying is moving paint from the page to the 'air' aka nowhere
        let colorRGB = {r: 254, g: 254, b: 254};
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, pageLoc);
            return (this.offsetOfCoordinates(ptPage) - adj) * 11;
        });
        let dryCells = new Set<number>();
        FingerTip.doFilledCircle(tip.radius, 0, (i: number, pt: Point2D)=>{

            let fCellAir = tip.getSingleChangeFlowCell(pt, 0, colorRGB);
            let fCellPage = fCellAir.move1.fCellDest;
            if (fCellPage.inBounds) {
                let ptPage = this.tipCoordinatesToPageCoordinates(pt, pageLoc);
                let pageOffset = this.offsetOfCoordinates(ptPage);
                dryCells.add(pageOffset);

                let pageDepth = fCellPage.finalDepth;
                // now they are one
                let localDryAmount = (dragDryAmount > pageDepth) ? pageDepth : dragDryAmount;
                if (localDryAmount > 0) {

                    let saveMove = fCellPage.move1;
                    fCellPage.move1 = {fCellDest: fCellAir, fraction: 1} as FlowCellMove;
                    this.stageFlow(fCellPage, fCellPage.move1, localDryAmount, 0);
                    this.commitFlow(fCellPage, fCellPage.move1);
                    fCellPage.move1 = saveMove;
                }

            }
        })
        this.writeTipCellData(dryCells);
        dryCells.forEach(c=> 
            this.cellDryApplied![c] += dragDryAmount
        );
    }
    public clearAppliedDry() {
        if (this.cellDryApplied !== undefined) {

        }
        this.cellDryApplied = undefined;
    }

    public clearKnifeBladeCells() {
        // no background color mixing
        let clearData = [1,1,1,1,1,1,0,0,1,1,1];
        for (let c = 0; c < FingerTip.cellsWidth * 3; c++) {
            let off = c * 11;
            this.cellDataBlade.set(clearData, off);
        }
    }

    public dragTip(ptStart: Point2D, ptEnd: Point2D, radiusStart: number, radiusEnd: number, octantLast: number, paintRadius:number, tipKind: string) : TipEffect {
        const result :TipEffect = new TipEffect();

        const kindCells = new Set<number>();

        let stepLine = ptStart.lineTo(ptEnd);
        // for this batch, pretend like every step is oriented the same way to prevent those mid-batch jagged circles
        let primaryMoveOctant = ptEnd.moveOctantFrom(ptStart);

        result.steps.push({radius: radiusStart, smashRadius: FingerTip.smashRadiusOf(radiusStart), location: ptStart, octantMove: octantLast});

        // move the radius from start to finish size in integer jumps in the same steps as the line
        let radiusStep = (radiusEnd - radiusStart) / (stepLine.length-1);
        let radiusTrack = radiusStart;
        let radiusLast = radiusTrack;
        let ptLast = ptStart;
        for (const s of stepLine.slice(1)) {
            let octantMove = s.octantFrom(ptLast);

            // add a bit to the radius, if it changes by an integer amount, use the new value
            radiusTrack += radiusStep;
            if (Math.floor(radiusTrack) !== radiusLast) {
                radiusLast = Math.floor(radiusTrack);
            }

            let ftScrape : FingerTip
            if (tipKind === 'knife') {
                // radius communicates degree of edge too
                ftScrape = FingerTip.getKnifeShape(radiusLast, octantMove, octantLast);
                ftScrape.customizeKnifePattern(paintRadius, octantMove);
            } else {
                ftScrape = FingerTip.getScrapeShape(radiusLast, octantMove, octantLast);
            }

            result.steps.push({radius: ftScrape.radius, smashRadius: ftScrape.smashRadius, location: s, octantMove: primaryMoveOctant});

            // run the flow
            if (tipKind === 'knife') {
                this.runKnifeFlowPattern(ftScrape, ptLast, kindCells);
            } else {
                this.runDragFlowPattern(ftScrape, ptLast, kindCells, tipKind);
            }
            

            octantLast = octantMove;
            ptLast = s;
        }

        // convert the modified page cells into drawing effects
        this.cellsToEffects(kindCells, result);

        return result;
    }

    public dragBlowTip(ptStart: Point2D, ptEnd: Point2D, radiusStart: number, radiusEnd: number, dragDryAmount: number, octantLast: number) : [steps: number, lastOctant: number] {

        let stepLine = ptStart.lineTo(ptEnd);
        // for this batch, pretend like every step is oriented the same way to prevent those mid-batch jagged circles
        let primaryMoveOctant = ptEnd.moveOctantFrom(ptStart);

        let steps = 1;

        // move the radius from start to finish size in integer jumps in the same steps as the line
        let radiusStep = (radiusEnd - radiusStart) / (stepLine.length-1);
        let radiusTrack = radiusStart;
        let radiusLast = radiusTrack;
        let ptLast = ptStart;
        for (const s of stepLine.slice(1)) {
            // add a bit to the radius, if it changes by an integer amount, use the new value
            radiusTrack += radiusStep;
            if (Math.floor(radiusTrack) !== radiusLast) {
                radiusLast = Math.floor(radiusTrack);
            }

            steps ++;
            let ft = FingerTip.getCenteredShape(radiusLast);
            this.blowTip(ft, ptLast, dragDryAmount);

            ptLast = s;
        }
        return [steps, primaryMoveOctant]
    }

    public removeTip(tip: FingerTip, center: Point2D, tipKind: string) : TipEffect {
        
        const pageLoc = new Point2D(center.x, center.y);
        
        // effects from this one movement step
        const result :TipEffect = new TipEffect();
        result.steps.push({radius: tip.radius, smashRadius: tip.smashRadius, location: pageLoc, octantMove: 4});

        const kindCells = new Set<number>();

        // run the flow
        this.runCentralFlowPattern(tip, pageLoc, kindCells, tipKind, true);

        // convert the modified page cells into drawing effects
        this.cellsToEffects(kindCells, result);

        return result;
    }


    private runCentralFlowPattern(tip: FingerTip, pageLoc: Point2D, kindCells: Set<number>, tipKind: string, smashOnly: boolean = false) {
        this.tipPlacement = new PageTipPlacement(this, tip, pageLoc, this.cellData, true, tipKind);

        // get the state of the page before the drag put into every source or dest cell in the print
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, pageLoc);
            return (this.offsetOfCoordinates(ptPage) - adj) * 11;
        });

        const stageRadiate = (fCell: FlowCell, max: number, priority: number) => {
            let move = fCell.finalDepth - max;
            if (move > 0) {
                if (smashOnly && fCell.finalKind !== CellKind.smash) {
                    return;
                }
                let error = 0;
                if (fCell.move1 !== undefined && fCell.move1.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move1, move, error);
                }
                if (fCell.move2 !== undefined && fCell.move2.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move2, move, error);
                }
                if (fCell.move3 !== undefined && fCell.move3.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move3, move, error);
                }
                if (fCell.move4 !== undefined && fCell.move4.priority === priority) {
                    error = this.stageFlow(fCell, fCell.move4, move, error);
                }
                if (fCell.move5 !== undefined && fCell.move5.priority === priority) {
                    this.stageFlow(fCell, fCell.move5, move, error);
                }
            }
        }
        const commitRadiate = (fCell: FlowCell, priority: number) => {
            if (smashOnly && fCell.finalKind !== CellKind.smash) {
                fCell.finalDepth = 0;
                fCell.modified = false;
                return;
            }

            if (fCell.move1 !== undefined && fCell.move1.priority === priority) {
                this.commitFlow(fCell, fCell.move1);
            }
            if (fCell.move2 !== undefined && fCell.move2.priority === priority) {
                this.commitFlow(fCell, fCell.move2);
            }
            if (fCell.move3 !== undefined && fCell.move3.priority === priority) {
                this.commitFlow(fCell, fCell.move3);
            }
            if (fCell.move4 !== undefined && fCell.move4.priority === priority) {
                this.commitFlow(fCell, fCell.move4);
            }
            if (fCell.move5 !== undefined && fCell.move5.priority === priority) {
                this.commitFlow(fCell, fCell.move5);
            }
        }

        for (const fCellsRing of tip.fCellsCentralRings) {

            for (const fCell of fCellsRing) {
                let maxDepth = fCell.maxTouchDepth;
                if (fCell.finalKind === CellKind.smash) {
                    maxDepth = fCell.preferredScrapeDepth;
                }
                stageRadiate(fCell, maxDepth, 1);
            }
            for (const fCell of fCellsRing) {
                commitRadiate(fCell, 1);
            }
            for (const fCell of fCellsRing) {
                let maxDepth = fCell.maxTouchDepth;
                if (fCell.finalKind === CellKind.smash) {
                    maxDepth = fCell.preferredScrapeDepth;
                }
                stageRadiate(fCell, maxDepth, 2);
            }
            for (const fCell of fCellsRing) {
                commitRadiate(fCell, 2);
            }
        }

        // send the final depth and color to the page and cell kind map
        this.writeTipCellData(kindCells);
    }


    stageArcScrape(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= 0) {
                continue;
            }
            let curMax = fCell.preferredScrapeDepth;
            let moveFwd = 0;
            if (curDepth > fCell.maxKindPushDepth && fCell.finalKind === fCell.move1.fCellDest.finalKind) {
                // when build-up happens, let it ride but not too far
                // some gets left behind because of this little lie
                curDepth = fCell.maxKindPushDepth;
            }
            if (curDepth > curMax) {
                moveFwd = (curDepth - curMax);
            } else if (curDepth > fCell.minScrapeDepth) {
                moveFwd = Math.floor((curDepth - fCell.minScrapeDepth) * .25);
            } 
            if (moveFwd >= 1) {
                this.stageFlow(fCell, fCell.move1, moveFwd, 0);
            }
        }
    }
    commitArcScrape(fCells: Array<FlowCell>) : Array<FlowCell> {
        const overflow = new Array<FlowCell>();
        for (const fCell of fCells) {
            if (fCell.move1.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move1);
                if (fCell.move1.fCellDest.finalKind === CellKind.corona && fCell.move1.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move1.fCellDest);
                }
            }
        }
        return overflow;
    }

    
    stageOverflows(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= Page.maxCoronaDepth || fCell.move1 === undefined) {
                continue;
            }
            let extra = curDepth - Page.maxCoronaDepth;
            let error = 0;
            error = this.stageFlow(fCell, fCell.move4, extra, error);
            error = this.stageFlow(fCell, fCell.move5, extra, error);
            error = this.stageFlow(fCell, fCell.move1, extra, error);
        }
    }
    commitOverflows (fCells: Array<FlowCell>) : Array<FlowCell> {
        const overflow = new Array<FlowCell>();
        for (const fCell of fCells) {
            if (fCell.move1 === undefined) {
                continue;
            }
            if (fCell.move1.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move1);
                if (fCell.move1.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move1.fCellDest);
                }
            }
            if (fCell.move4.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move4);
                if (fCell.move4.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move4.fCellDest);
                }
            }
            if (fCell.move5.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move5);
                if (fCell.move5.fCellDest.finalDepth > Page.maxCoronaDepth) {
                    overflow.push(fCell.move5.fCellDest);
                }
            }

        }
        return overflow;
    }

    stageFill (fCells: Array<FlowCell>): Array<FlowCell> {
        const moves = new Array<FlowCell>();
        const considered = new Set<FlowCell>();
        for (const fCell of fCells) {
            if (fCell.move3 === undefined) {
                continue;
            }
            let curDepth = fCell.finalDepth; 
            if (curDepth === 0) {
                let fCellTrace = fCell.move3.fCellDest;
                while (fCellTrace !== undefined) {
                    if (considered.has(fCellTrace)) {
                        break;
                    }
                    if (fCellTrace.finalDepth > fCellTrace.minScrapeDepth) {
                        let move = Math.min(Math.floor(Math.max(1, Math.round(fCellTrace.finalDepth * .75))), FingerTip.maxMeniscusTouchDepth);
                        //move = Math.min(FingerTip.maxSmashScrapeDepth, move);
                        if (fCellTrace.finalDepth - move < 2*FingerTip.minSmashScrapeDepth) {
                            move = Math.max(0, fCellTrace.finalDepth - 2*FingerTip.minSmashScrapeDepth);
                        }
                        if (fCellTrace.move2 !== undefined && fCellTrace.move2.removedDepth === 0) {
                            if (move >= 1) {
                                this.stageFlow(fCellTrace, fCellTrace.move2, move, 0);
                                moves.push(fCellTrace);
                            }
                        } else {
                            break;
                        }
                    }
                    considered.add(fCellTrace);
                    fCellTrace = fCellTrace.move3?.fCellDest;
                }
            }
        }
        return moves;
    }
    commitFill(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            if (fCell.move2 !== undefined && fCell.move2.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move2);
            }
        }
    }

    stageArcBlend(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= 1) {
                continue;
            }
            if (fCell.move4.fCellDest.finalDepth > 1 && fCell.move4.fCellDest.move5 !== undefined) {
                let swap = Math.max(1, Math.floor(Math.min(curDepth*.05, fCell.move4.fCellDest.finalDepth * .05)));
                this.stageFlow(fCell, fCell.move4, swap, 0)
            }
            if (fCell.move5.fCellDest.finalDepth > 1 && fCell.move5.fCellDest.move4 !== undefined) {
                let swap = Math.max(1, Math.floor(Math.min(curDepth*.05, fCell.move5.fCellDest.finalDepth * .05)));
                this.stageFlow(fCell, fCell.move5, swap, 0)
            }
        }
    }
    commitArcBlend(fCells: Array<FlowCell>) {
        for (const fCell of fCells) {
            if (fCell.move4.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move4);
            }
            if (fCell.move5.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move5);
            }
        }
    }
    performDrag() {
        let tip = this.tipPlacement!.tip;
        let fillMoves = this.stageFill(tip.fCellsMeniscusScrape);
        this.commitFill(fillMoves);
        
        fillMoves = this.stageFill(tip.fCellsSmashScrape);
        this.commitFill(fillMoves);
        
        this.stageArcScrape(tip.fCellsMeniscusScrape);
        let overflow = this.commitArcScrape(tip.fCellsMeniscusScrape);
        while (overflow.length > 0) {
            this.stageOverflows(overflow);
            overflow = this.commitOverflows(overflow);
        }

        this.stageArcScrape(tip.fCellsSmashScrape);
        overflow = this.commitArcScrape(tip.fCellsSmashScrape);
        while (overflow.length > 0) {
            this.stageOverflows(overflow);
            overflow = this.commitOverflows(overflow);
        }

        this.stageArcBlend(tip.fCellsMeniscusBlend);
        this.commitArcBlend(tip.fCellsMeniscusBlend);
    }

    private runDragFlowPattern(tip: FingerTip, pageLoc: Point2D, kindCells: Set<number>, tipKind: string ) {
        this.tipPlacement = new PageTipPlacement(this, tip, pageLoc, this.cellData, true, tipKind);

        // get the state of the page before the drag put into every source or dest cell in the print
        const tipToPageOff = (ptTip: Point2D, adj: number)=> {
            let pageX = ptTip.x + pageLoc.x - FingerTip.cellsWidth/2;
            let pageY = ptTip.y + pageLoc.y - FingerTip.cellsWidth/2;
            let offPage = this.cellsWidth * pageY + pageX;
            return (offPage - adj) * 11;
        }
        this.collectTipCellData(tipToPageOff);
        this.performDrag();
        // put back the changes
        this.writeTipCellData(kindCells);
    }

    private runKnifeFlowPattern(tip: FingerTip, pageLoc: Point2D, kindCells: Set<number>) {
        let mainPlacement = this.tipPlacement = new PageTipPlacement(this, tip, pageLoc, this.cellData, true, 'knife');
        let bladePlacement = new PageTipPlacement(this, tip.tipKnifeBlade!, pageLoc, this.cellDataBlade, false, 'knife');
        
        // get the state of the page before the drag put into every source or dest cell in the print
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let ptPage = this.tipCoordinatesToPageCoordinates(ptTip, pageLoc);
            return (this.offsetOfCoordinates(ptPage) - adj) * 11;
        });

        this.tipPlacement = bladePlacement;
        // get the state of the blade from the cache
        this.collectTipCellData((ptTip: Point2D, adj: number)=> {
            let fCell = tip.tipKnifeBlade!.getFlowCell(ptTip.x - adj, ptTip.y, CellKind.background, CellKind.background);
            let tipStart = Math.floor((this.cellDataBlade.length - tip.tipKnifeBlade!.allCellData!.length)/2);
            return fCell.dvTipCells.byteOffset + tipStart;
        });
        this.tipPlacement = mainPlacement;

        // any cells from the 'left behind in a turn'  section just vanish
        for (const fCell of tip.fCellsKnifeBack!) {
            this.stageFlow(fCell, fCell.move1, fCell.finalDepth, 0);
            this.commitFlow(fCell, fCell.move1);
        }

        // the knife edge pulls up to the blade
        for (const fCell of tip.fCellsKnifeEdge!) {
            if (fCell.move1.fCellDest !== undefined && fCell.finalDepth > 0) {
                this.stageFlow(fCell, fCell.move1, fCell.finalDepth, 0);
                this.commitFlow(fCell, fCell.move1);
            }
        }

        // the blade smooths
        for (const fCell of tip.tipKnifeBlade!.fCellsKnifeEdge!) {

            let curDepth = fCell.finalDepth; 
            if (curDepth === 0 || fCell.move2 === undefined || fCell.move3 === undefined) {
                continue;
            }
            let prevDepth = fCell.move2.fCellDest.finalDepth;
            let nextDepth = fCell.move3.fCellDest.finalDepth;
            if (prevDepth > nextDepth) {
                if (nextDepth < curDepth) {
                    let swap = Math.max(1, Math.floor(curDepth*.05));
                    this.stageFlow(fCell, fCell.move3, swap, 0)
                }

            } else {
                if (prevDepth < curDepth) {
                    let swap = Math.max(1, Math.floor(curDepth*.05));
                    this.stageFlow(fCell, fCell.move2, swap, 0)
                }

            }
        }
        for (const fCell of tip.tipKnifeBlade!.fCellsKnifeEdge!) {
            if (fCell.move2 !== undefined && fCell.move2.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move2);
            }
            if (fCell.move3 !== undefined && fCell.move3.removedDepth > 0) {
                this.commitFlow(fCell, fCell.move3);
            }
        }

        // now the blade drop paint into the gaps
        for (const fCell of tip.tipKnifeBlade!.fCellsKnifeEdge!) {
            let curDepth = fCell.finalDepth; 
            if (curDepth <= 0 || fCell.move1 === undefined || fCell.move1.fCellDest === undefined) {
                continue;
            }
            this.stageFlow(fCell, fCell.move1, curDepth, 0)
            this.commitFlow(fCell, fCell.move1);
        }

        // send the final depth and color to the page and cell kind map
        for (const fCell of tip.allFlowCells) {
            if (fCell.finalKind !== CellKind.background && fCell.inBounds) {
                if (fCell.finalDepth === 0) {
                    // scrape over only dried background will fade it
                    if (fCell.modified === false && !(fCell.dvTipCells[Page.offDryRed] === 255 && fCell.dvTipCells[Page.offDryGreen] === 255 && fCell.dvTipCells[Page.offDryBlue] === 255)) {
                        let rd = fCell.dvTipCells[Page.offDryRed];
                        let gd = fCell.dvTipCells[Page.offDryGreen];
                        let bd = fCell.dvTipCells[Page.offDryBlue];
                        let ad = .95;
                        rd = Math.floor(rd * ad + (1-ad) * 255);
                        gd = Math.floor(gd * ad + (1-ad) * 255);
                        bd = Math.floor(bd * ad + (1-ad) * 255);
                        if (rd*gd*bd === 1) {
                            rd=gd=bd=0;
                        }
                        
                        fCell.dvTipCells[Page.offDryRed] = rd;
                        fCell.dvTipCells[Page.offDryGreen] = gd;
                        fCell.dvTipCells[Page.offDryBlue] = bd;
                        fCell.modified = true;
                    }
                }
            }
        }
        

        this.writeTipCellData(kindCells);

        this.tipPlacement = bladePlacement;
        let unKindCells = new Set<number>();
        this.writeTipCellData(unKindCells);
        this.tipPlacement = mainPlacement;

    }

    private cellsToEffects(kindCells: Set<number>, effect: TipEffect) : void {
        // for every cell
        kindCells.forEach(c => {
            let pos = this.coordinatesOfOffset(c);
            effect.rectTL.pushTL(pos);
            effect.rectBR.pushBR(pos);

            effect.cells.push(c);
        });
    }

    public makeCanvasOffsetWet(offset: number) {
        let existingWetStage = this.wetCanvasOffsets.get(offset);
        if (existingWetStage !== undefined && existingWetStage !== this.wetCanvasStage) {
            // remove from earlier stage
            this.wetCanvasStageOffsets[existingWetStage].delete(offset);
        }
        // set into current stage
        this.wetCanvasOffsets.set(offset, this.wetCanvasStage);
        this.wetCanvasStageOffsets[this.wetCanvasStage].add(offset);
    }
    public makeCanvasOffsetDry(offset: number) {
        let existingWetStage = this.wetCanvasOffsets.get(offset);
        if (existingWetStage !== undefined && existingWetStage !== this.wetCanvasStage) {
            // remove from earlier stage
            this.wetCanvasStageOffsets[existingWetStage].delete(offset);
            this.wetCanvasOffsets.delete(offset);
        }
    }


    public dry(factor: number) {
        if (factor !== 0) {
            //let now = Date.now()
            let iWetCanvasStage = this.wetCanvasStage + 1;
            if (iWetCanvasStage >= this.wetCanvasStageMax) {
                iWetCanvasStage = 0;
            }

            let cellsTotal = this.cellsTotal;
            let wetCellsStage = this.wetCanvasStageOffsets[iWetCanvasStage];
            let wetCellsAll = this.wetCanvasOffsets;
            //let max=0;
            for(let c = 0; c < cellsTotal; c++) {
                let off = c * 11;
                let d = (this.cellData[off + Page.offDepthHigh] << 8) | this.cellData[off + Page.offDepthLow];
                //max = Math.max(max, d);
                if (d > 0) { 
                    if (wetCellsAll.has(c)) {
                        if(wetCellsStage.has(c)) {
                            this.wetCanvasOffsets.delete(c);
                            this.cellData[off + Page.offDryRed] = this.cellData[off + Page.offVisRed];
                            this.cellData[off + Page.offDryGreen] = this.cellData[off + Page.offVisGreen];
                            this.cellData[off + Page.offDryBlue] = this.cellData[off + Page.offVisBlue];
                        } else {
                            continue;
                        }
                    }
                    if (d > 10*Page.maxCoronaDepth) {
                        d = 10*Page.maxCoronaDepth;
                    }
                    let reduce = Math.max(1, Math.floor(d * factor));
                    d-=reduce;
                    if (d<=0) {
                        d=0;
                     }
                    this.cellData[off + Page.offDepthHigh] = d >> 8;
                    this.cellData[off + Page.offDepthLow ] = d & 0xff;
                }
            }

            this.wetCanvasStage = iWetCanvasStage;
            this.wetCanvasStageOffsets[iWetCanvasStage] = new Set<number>();
            //console.log('dry time', Date.now() - now);
        }
    }

}

