import tinycolor from 'tinycolor2';
import {FingerTip} from './fingertip';
import { Buffer } from 'buffer';
import { Point2D } from './utils';


export enum AnimationActionKind {
    start = 1,
    drag = 2,
    dry = 3,
    color = 4,
    paintRadius = 5,
    stamp = 6,
    finish = 7
}

export interface AnimationActionStart {
    kind: AnimationActionKind;
    pt: Point2D;
    rad: number;
}
export interface AnimationActionDrag {
    kind: AnimationActionKind;
    pt: Point2D;
    rad: number;
}
export interface AnimationActionDry {
    kind: AnimationActionKind;
    count?: number;
}
export interface AnimationActionColor {
    kind: AnimationActionKind;
    color: string;
}
export interface AnimationActionPaintRadius {
    kind: AnimationActionKind;
    rad: number;
}

export interface AnimationActionStampLine{
    pt: Point2D;
    colors: Array<tinycolor.ColorFormats.RGB>;
}

export interface AnimationActionStamp {
    kind: AnimationActionKind;
    rad: number;
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    lines: Array<AnimationActionStampLine>;
}

export type AnimationAction = (AnimationActionStart | AnimationActionDrag | AnimationActionDry | AnimationActionColor | AnimationActionPaintRadius | AnimationActionStamp);

class AnimationActionRecording {
    recordedAction = new Array<AnimationAction>();

    constructor() {
    }
        
    lastSerializedAction: number = -1;
   
    hasContent() : boolean {
        return this.recordedAction.length > 0;
    }

    recordAction(newAction: AnimationAction) {
        // either record a new dry action OR update the last action (a dry) to have a count
        if (this.hasContent()) {
            if (newAction.kind === AnimationActionKind.dry) {
                const lastAction = this.recordedAction[this.recordedAction.length - 1] as AnimationActionDry;
                if (lastAction.kind === AnimationActionKind.dry) {
                    if (lastAction.count !== undefined) {
                        lastAction.count ++;
                    } else {
                        lastAction.count = 2;
                    }
                    return;
                }
            }
        } else {
            // dry with no paint means nothing
            if (newAction.kind === AnimationActionKind.dry) {
                return;
            }
        }

        this.recordedAction.push(newAction);
    }

    public chopAfter(i: number) {
        if (this.hasContent() === false) {
            return;
        }
        let recLength = this.recordedAction.length;
        i++
        if (i <= 0 || i >= recLength) {
            return;
        }
        this.recordedAction = this.recordedAction.slice(0, i);
        this.lastSerializedAction = -1;
    }

    public removeLastStroke() {

        if (this.hasContent() === false) {
            return;
        }
        let iPeek = this.recordedAction.length - 1;
        let cPop = 1;

        while (iPeek >= 0) {
            let last = this.recordedAction[iPeek];
            if (last.kind === AnimationActionKind.start || last.kind === AnimationActionKind.stamp) {
                while (cPop > 0) {
                    this.lastSerializedAction=-1;
                    this.recordedAction.pop();
                    cPop --;
                }
                return;
            }
            iPeek--;
            cPop ++;
        }
    }

    public getSerializeNeeded() : string {
        let recLength = this.recordedAction.length;
        if (recLength === 0) {
            return "skip";
        }
        // never saved before, that is new too
        if (this.lastSerializedAction === -1) {
            return 'new';
        }
        if (recLength === this.lastSerializedAction + 1) {
            return "skip";
        }
        return "new";
    }

    public stampSeen = false;

    public serialize(): string {

        this.stampSeen = false;

        if (!this.hasContent()) {
            return "";
        }
        let actions = this.recordedAction;
        let startFrom = 0;
        
        this.lastSerializedAction = startFrom;

        let off = 0;
        for (const act of actions) {
            off++;
            switch (act.kind) {
                case AnimationActionKind.color:
                    off += (1 + 1 + 1);
                    break;
                case AnimationActionKind.paintRadius:
                    off += 1;
                    break;
                case AnimationActionKind.drag:
                case AnimationActionKind.start:
                case AnimationActionKind.finish:
                    off += (2 + 2 + 1);
                    break;
                case AnimationActionKind.dry:
                    off += 2;
                    break;
                case AnimationActionKind.stamp:
                    this.stampSeen = true;
                    const stampAct = (act as AnimationActionStamp);
                    off += (1 + 2 + 2 + 2 + 2 + 2);
                    for (const line of stampAct.lines) {
                        off += (2 + 2 + 2);
                        off += (line.colors.length * (1 + 1 + 1));
                    }
                    break;
            }
        }

        const buff = new ArrayBuffer(off);
        const view = new DataView(buff, 0);
        off = 0;
        for (const act of actions) {
            view.setUint8(off, act.kind);off++;
            switch (act.kind) {
                case AnimationActionKind.color:
                    let colorCur = new tinycolor((act as AnimationActionColor).color).toRgb();
                    view.setUint8(off, colorCur.r);off++;
                    view.setUint8(off, colorCur.g);off++;
                    view.setUint8(off, colorCur.b);off++;
                    break;
                case AnimationActionKind.paintRadius:
                    let paintRadCur = (act as AnimationActionPaintRadius).rad;
                    view.setUint8(off, Math.floor(paintRadCur));off++;
                    break;
                case AnimationActionKind.drag:
                case AnimationActionKind.start:
                case AnimationActionKind.finish:
                    const startAct = (act as AnimationActionStart);
                    const x = Math.floor(startAct.pt.x);
                    const y = Math.floor(startAct.pt.y);
                    const r = Math.floor(startAct.rad);
                    view.setInt16(off, x);off+=2;
                    view.setInt16(off, y);off+=2;
                    view.setUint8(off, r);off++;
                    break;
                case AnimationActionKind.dry:
                    const dryAct = (act as AnimationActionDry);
                    let count = 1;
                    if (dryAct.count !== undefined) {
                        count = dryAct.count;
                    }
                    if (count > 64000) {
                        count = 64000;
                    }
                    view.setUint16(off, count);off+=2;
                    break;
                case AnimationActionKind.stamp:
                    const stampAct = (act as AnimationActionStamp);
                    view.setUint8(off, stampAct.rad);off+=1;
                    view.setUint16(off, stampAct.x1);off+=2;
                    view.setUint16(off, stampAct.y1);off+=2;
                    view.setUint16(off, stampAct.x2);off+=2;
                    view.setUint16(off, stampAct.y2);off+=2;
                    view.setUint16(off, stampAct.lines.length);off+=2;
                    for (const line of stampAct.lines) {
                        view.setInt16(off, line.pt.x);off+=2;
                        view.setInt16(off, line.pt.y);off+=2;
                        view.setUint16(off, line.colors.length);off+=2;
                        for (const col of line.colors) {
                            view.setUint8(off, col.r);off+=1;
                            view.setUint8(off, col.g);off+=1;
                            view.setUint8(off, col.b);off+=1;
                        }
                    }
                    break;
            }
        }
        this.lastSerializedAction = this.recordedAction.length - 1;

        const encoded = Buffer.from(buff, 0, off).toString('base64');
        return encoded;
    }
    public restore(encoded: string): void {
        this.recordedAction = new Array<AnimationAction>();
        this.lastSerializedAction = -1;
        const decoded = Buffer.from(encoded, 'base64');
        
        let prevDryAction : AnimationActionDry | undefined = undefined;
        let off = 0;
        while (off < decoded.length) {
            const kind = decoded.readUInt8(off);off++;
            switch(kind) {
                case AnimationActionKind.color:
                    let r = decoded.readUInt8(off);off++;
                    let g = decoded.readUInt8(off);off++;
                    let b = decoded.readUInt8(off);off++;
                    let colorCur: tinycolor.ColorFormats.RGB = {r:r, g:g, b:b};
                    this.recordedAction.push({kind: kind, color: new tinycolor(colorCur).toHexString()});
                    prevDryAction = undefined;
                    break;
                case AnimationActionKind.paintRadius:
                    let paintRadCur = decoded.readUInt8(off);off++;
                    this.recordedAction.push({kind: kind, rad: paintRadCur});
                    prevDryAction = undefined;
                    break;
                case AnimationActionKind.drag:
                case AnimationActionKind.start:
                case AnimationActionKind.finish:
                    const x = decoded.readInt16BE(off);off+=2;
                    const y = decoded.readInt16BE(off);off+=2;
                    const rad = decoded.readUInt8(off);off++;
                    this.recordedAction.push({kind: kind, pt: new Point2D(x,y), rad:rad});
                    prevDryAction = undefined;
                    break;
                case AnimationActionKind.dry:
                    let count = decoded.readUInt16BE(off);off+=2;
                    if (prevDryAction !== undefined) {
                        // two dry in a row means save then add while drying. 
                        // the second dry action is the first action repeated with more steps
                        prevDryAction.count = count; // so just fix the last one and skip this one
                    } else {
                        prevDryAction = {kind: kind, count: count};
                        this.recordedAction.push(prevDryAction);
                    }
                    break;
                case AnimationActionKind.stamp:
                    const lines = new Array<AnimationActionStampLine>();
                    const stampAct : AnimationActionStamp={kind:kind, lines:lines, rad:0, x1:0, x2:0, y1:0, y2:0};
                    this.recordedAction.push(stampAct);
                    stampAct.rad = decoded.readUInt8(off);off++;
                    stampAct.x1 = decoded.readUInt16BE(off);off+=2;
                    stampAct.y1 = decoded.readUInt16BE(off);off+=2;
                    stampAct.x2 = decoded.readUInt16BE(off);off+=2;
                    stampAct.y2 = decoded.readUInt16BE(off);off+=2;
                    const cLine = decoded.readUInt16BE(off);off+=2;
                    for (let iLine=0; iLine < cLine; iLine++){
                        const colors = new Array<tinycolor.ColorFormats.RGB>();
                        const line : AnimationActionStampLine = {pt:new Point2D(0,0), colors: colors};
                        line.pt.x = decoded.readInt16BE(off); off+=2;
                        line.pt.y = decoded.readInt16BE(off); off+=2;
                        const cColor = decoded.readUint16BE(off); off+=2;
                        for (let iCol = 0; iCol < cColor; iCol++) {
                            const rgb: tinycolor.ColorFormats.RGB = {r:0, g:0, b:0};
                            rgb.r = decoded.readUint8(off); off++;
                            rgb.g = decoded.readUint8(off); off++;
                            rgb.b = decoded.readUint8(off); off++;
                            colors.push(rgb);
                        }
                        lines.push(line);
                    }
                    prevDryAction = undefined;
                    break;
                default:
                    console.log('bad data');
                    return;
                }

        }
    }

    public finalDrawPropsUsed() : [string, number] {
        let finalColor = 'black';
        let finalRadius = FingerTip.standardFingerRadius;
        let colorFound: boolean = false;
        let radiusFound: boolean = false;
        if (this.recordedAction !== undefined && this.recordedAction.length > 0) {
            for (let i=this.recordedAction.length - 1; i >= 0; i--) {
                const act = this.recordedAction[i];
                if (colorFound === false && act.kind === AnimationActionKind.color) {
                    finalColor = (act as AnimationActionColor).color;
                    colorFound = true;
                }
                if (radiusFound === false && act.kind === AnimationActionKind.paintRadius) {
                    finalRadius = (act as AnimationActionPaintRadius).rad;
                    radiusFound = true;
                }
                if (colorFound === true && radiusFound === true) {
                    break;
                }
            }
        }
        return [finalColor, finalRadius];
    }
    
    countDrawingActions() {
        return this.recordedAction.filter((aa) => aa.kind === AnimationActionKind.drag || aa.kind === AnimationActionKind.start || aa.kind === AnimationActionKind.finish).length;
    }
}


export default AnimationActionRecording;