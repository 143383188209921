import { Point2D } from "./components/smash/utils";
const smesshySplash =
    [{
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 1,
        "pt": new Point2D(42,107),
        "rad": 21
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(45,105),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(46,109),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(51,109),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(56,109),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(58,105),
        "rad": 21
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(44,101),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(45,105),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(51,106),
        "rad": 21
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(51,88),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(48,93),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(46,100),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(44,107),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(42,117),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(42,124),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(49,123),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(57,113),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(64,104),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(69,94),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(73,88),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(75,82),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(68,80),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(57,81),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(49,84),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(41,86),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(41,93),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(45,94),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(47,102),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(47,109),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(49,116),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(53,119),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(60,120),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(65,120),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(73,115),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(75,108),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(70,102),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(63,102),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(57,104),
        "rad": 21
    },
    {
        "kind": 2,
        "pt": new Point2D(53,109),
        "rad": 21
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(81,122),
        "rad": 21
    },
    {
        "kind": 4,
        "color": "#438ed0"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(81,124),
        "rad": 21
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#438ed0"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#438ed0"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(41,137),
        "rad": 21
    },
    {
        "kind": 4,
        "color": "#5290cb"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#5290cb"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#5290cb"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(87,97),
        "rad": 21
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 15
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 16
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 17
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 18
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 19
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 20
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(13,22),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(23,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(26,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(21,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,4),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,2),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(11,7),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(27,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,25),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(54,25),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(56,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(50,13),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(48,8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(54,8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(60,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(67,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(78,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(85,14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(93,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(97,15),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(98,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(102,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(107,10),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(114,10),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(121,11),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(127,11),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,11),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(157,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(161,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(169,14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(190,14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(198,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(205,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(210,15),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(211,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(217,21),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(265,30),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(274,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(280,26),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(308,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(315,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(324,13),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(331,34),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,38),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,44),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,51),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,57),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(334,93),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,99),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,104),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,111),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,131),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,136),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,144),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,149),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(333,205),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,209),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,216),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(326,241),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(185,228),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(179,230),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(49,238),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(17,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,228),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(9,186),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,181),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,175),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,168),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(9,123),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(11,118),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,112),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,105),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(10,61),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,54),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(45,42),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,43),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(53,43),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(63,43),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(71,44),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(106,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(117,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(131,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(136,41),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(203,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(207,49),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(265,66),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,71),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(276,77),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(283,118),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(283,126),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(284,136),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(284,146),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(284,153),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(281,209),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,213),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(279,219),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(236,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(224,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(213,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(206,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(198,246),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(193,247),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(94,229),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(93,224),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(88,218),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(33,168),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,161),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,157),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,149),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(115,107),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(119,107),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(125,101),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(130,95),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(199,118),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(201,122),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(205,127),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(174,181),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(168,183),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(159,183),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(153,183),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(127,178),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(94,168),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(92,172),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(82,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(72,173),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(145,133),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(149,133),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(201,172),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(201,177),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(200,181),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(195,185),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(141,217),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(331,249),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(325,246),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(316,244),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(305,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(294,242),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(289,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(282,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(273,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(265,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(256,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(246,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(237,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(231,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(225,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(219,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(215,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(221,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(232,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(245,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(263,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,241),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(269,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(276,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(282,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(293,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(301,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(321,235),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(327,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(321,235),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(311,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(297,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(285,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(278,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(284,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(301,228),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(321,216),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(324,210),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(324,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(325,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(326,207),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(325,218),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(323,225),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(322,231),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(321,224),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(325,213),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(330,205),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,196),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,188),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,193),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,202),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(341,209),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(341,202),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(341,195),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(341,190),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(341,198),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,209),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,216),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,227),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(331,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(331,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,220),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,209),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,192),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,180),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,160),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,138),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,133),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,157),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,172),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,177),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,129),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,112),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,98),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,92),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,101),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,95),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,79),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,55),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,30),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(340,23),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,28),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,40),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,67),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,81),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,78),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,65),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,11),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,31),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,52),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(331,65),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,74),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,80),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,87),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,94),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(328,99),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(327,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(327,111),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(327,117),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,111),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,116),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,127),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,133),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,134),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(342,127),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(344,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(345,110),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(345,104),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(345,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(343,84),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(341,74),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,65),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,61),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,54),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,42),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,22),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,10),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,2),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,-4),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,-9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(326,-9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(313,-8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(293,0),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(279,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(263,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(257,4),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(246,1),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(234,-3),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(244,1),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(264,7),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(299,14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(261,3),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(241,-4),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(208,-15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(195,-18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,-18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,-13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(197,-9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(209,-8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(224,-5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(229,-4),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(217,12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(221,12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(226,12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(241,12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(260,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(275,18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(283,20),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(277,20),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(263,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(236,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(218,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(209,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(215,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(229,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(245,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(257,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(268,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(280,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(295,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(305,23),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,23),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(274,23),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(251,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(221,16),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(180,12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(160,12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(146,12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(138,10),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(127,8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(121,8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(117,8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(122,6),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(130,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(137,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(144,4),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(128,-1),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(108,-5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(93,-8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(72,-12),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(56,-14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(50,-14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,-14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,-9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,-4),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(21,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(25,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(30,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,6),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(54,6),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(61,6),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(53,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(51,10),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(59,14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(90,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(108,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(121,18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(127,18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(132,18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(141,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(151,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(157,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(162,18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(169,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(175,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(180,16),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(185,11),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(188,4),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,-2),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(181,-5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(177,-5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(172,-3),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(167,0),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(162,4),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(157,5),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(153,6),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(147,8),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(138,9),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(132,13),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(127,16),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(123,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(117,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(110,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(97,22),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(85,24),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(74,27),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(65,31),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(60,35),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(55,38),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,35),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(45,30),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,25),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(33,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(24,18),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,24),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,35),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(8,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(7,58),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(10,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(10,59),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,68),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(8,74),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(11,64),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,52),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(15,39),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(15,33),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(10,28),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,43),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(8,61),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(8,69),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(8,75),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,83),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(11,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(11,112),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,130),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,138),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(16,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(16,149),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,123),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,111),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,104),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,99),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,137),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,148),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,157),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,168),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,178),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,183),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,189),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,188),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,176),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(4,167),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(3,160),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(1,154),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(-1,163),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(-2,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(4,171),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,160),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,147),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,140),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,133),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,127),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,137),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,149),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(10,162),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,178),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,194),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,208),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,226),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(16,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,222),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,216),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,211),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(11,221),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(8,228),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(7,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(3,247),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(2,252),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(2,257),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(7,257),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,257),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,257),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(24,257),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,260),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,260),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(48,259),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(54,258),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(63,258),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,259),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(90,261),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(97,263),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(102,264),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(108,262),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(113,259),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(38,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(52,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(61,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(82,242),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(54,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(36,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(25,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(21,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(16,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(28,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(46,241),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(72,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(99,249),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(125,253),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(145,256),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(153,257),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(161,258),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(167,258),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(173,258),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(180,258),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(165,256),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(173,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(178,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(184,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,246),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(198,249),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(205,253),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(212,254),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(179,246),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(173,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(168,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(157,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(149,245),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(137,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(126,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(144,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(150,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(141,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(128,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(117,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(110,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(105,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(112,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(121,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(134,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(154,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(180,243),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(205,248),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(222,253),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(240,258),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(249,261),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(258,263),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(265,263),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,263),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(275,261),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(283,256),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(288,255),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(49,43),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(46,54),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(43,60),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,66),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,71),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(44,62),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(42,57),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,58),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(33,62),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(34,56),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(38,28),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(29,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(24,44),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(22,57),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(20,70),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(20,80),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(21,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(31,83),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,77),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,73),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,83),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,100),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,118),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(55,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(64,122),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(72,117),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(75,108),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,84),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(69,81),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(60,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(36,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(46,115),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(55,103),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(65,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(69,78),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(70,93),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(75,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(81,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(91,76),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(97,69),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(103,57),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(105,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(103,39),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(98,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(92,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(81,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(70,68),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(61,82),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(56,92),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(70,90),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(92,83),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(86,79),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,81),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(67,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(62,96),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(61,101),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(71,93),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(76,83),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,76),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,71),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(65,69),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(53,77),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,91),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,115),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(45,118),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(55,113),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(60,106),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(55,101),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(47,99),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,104),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(30,114),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(26,128),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(31,127),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(36,117),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,101),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(45,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,93),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,106),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(36,119),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,125),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,133),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(44,137),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(52,125),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(59,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(60,92),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(57,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(57,114),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(57,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(58,127),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(63,130),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(69,131),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,131),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(54,152),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,157),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(57,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(65,120),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,80),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(75,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,115),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(78,117),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(85,114),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(90,106),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(93,101),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(89,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(79,123),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,128),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(84,126),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(94,118),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(104,107),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(109,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(118,101),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(124,96),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(122,90),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(108,84),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(105,76),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(124,60),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(129,73),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(136,73),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(147,71),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(158,68),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(174,67),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(184,67),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,67),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(148,56),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,56),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(125,56),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(121,56),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(147,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(163,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(175,53),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(164,45),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(172,45),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,46),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(207,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(224,52),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(231,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(222,45),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(203,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(169,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(150,28),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(135,28),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(129,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(145,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(152,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(145,34),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(136,35),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(129,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(137,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(145,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(165,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(195,39),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(157,58),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(168,56),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(179,56),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(186,55),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(175,54),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(159,54),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(142,57),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,59),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(128,61),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(122,67),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(103,67),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(85,69),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(75,72),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(85,65),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(96,58),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(109,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(119,50),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(129,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(142,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(158,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(167,48),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(173,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(179,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(185,46),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(196,45),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(201,42),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(209,42),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(233,45),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(249,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(265,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(276,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(272,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(253,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(246,47),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(250,42),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(258,39),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(261,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(240,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(229,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(217,35),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(208,31),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(203,29),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(218,34),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(241,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(275,45),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(287,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(302,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(295,57),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,57),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(270,57),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(263,55),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(276,66),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(293,73),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(305,77),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(310,78),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(295,78),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(290,78),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(285,78),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(293,84),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(305,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(310,90),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(278,98),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(271,100),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(189,117),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(193,112),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(200,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(205,100),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(217,91),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(209,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(191,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(184,100),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(208,103),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(221,103),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(201,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(176,100),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(134,94),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(125,92),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(109,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(121,87),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(140,87),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(161,87),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(174,88),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(181,90),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(190,95),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(202,90),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(217,84),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(229,81),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(239,80),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(253,80),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,80),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(282,83),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(296,86),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(287,82),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,81),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(249,77),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(217,73),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(194,73),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(181,75),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(191,73),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(179,75),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(169,77),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(188,77),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(193,77),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(198,77),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(220,100),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(228,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(245,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(261,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(273,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(277,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(271,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(256,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(249,103),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(266,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(251,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(270,102),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(277,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(286,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(297,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(310,98),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(321,98),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(279,113),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(290,116),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(302,119),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(315,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(327,122),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(337,123),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(345,124),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(286,131),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(290,133),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(295,133),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(300,137),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(307,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(325,150),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(330,143),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(323,131),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(317,126),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(310,125),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(305,125),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(300,129),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(297,133),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(293,140),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(289,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(288,159),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(288,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,143),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(293,135),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(293,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(300,148),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(303,140),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(303,148),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(302,161),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(302,169),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 1,
        "pt": new Point2D(49,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(58,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(68,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(77,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(87,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(92,211),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(80,206),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(69,204),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(56,201),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(47,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(40,199),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(35,199),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(33,191),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(33,186),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(39,177),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(40,183),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(37,189),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(37,194),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(35,199),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(51,186),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(67,176),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(81,167),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(89,163),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(95,159),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(100,154),
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(73,168),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(77,164),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(77,159),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(65,168),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(59,173),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(65,160),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(69,152),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(62,163),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(56,173),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(50,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(49,186),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(62,182),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(81,172),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(101,157),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(116,141),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(126,125),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(125,135),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(122,143),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(119,150),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(117,155),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(122,144),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(125,137),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(125,133),
        "rad": 12
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(89,144),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(89,137),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(94,129),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(100,122),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(107,119),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(112,118),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(117,118),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(128,115),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(133,113),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(137,111),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(147,111),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(164,114),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(175,114),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(160,117),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(141,117),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(133,115),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(142,107),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(163,101),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(178,97),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(185,93),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(179,97),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(173,104),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(165,113),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(159,117),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(133,145),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(122,158),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(113,170),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(119,172),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(126,168),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(121,171),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(108,184),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(105,190),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(105,185),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(102,180),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(97,180),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(89,184),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(82,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(77,197),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(67,202),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(61,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(71,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(77,203),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(93,203),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(102,207),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(107,210),
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(111,207),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(129,199),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(145,195),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(175,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(184,192),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(169,187),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(138,182),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(122,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(109,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(101,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(96,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(92,188),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(94,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(107,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(125,203),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(136,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(143,207),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(148,209),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(148,213),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(137,214),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(124,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(109,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(101,213),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(103,221),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(113,222),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(129,225),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(145,225),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(161,227),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(176,227),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(182,226),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(189,227),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(199,227),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(205,227),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(215,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(233,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(247,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(253,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(259,225),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(265,221),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(269,217),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(275,215),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(280,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(282,201),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(280,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(277,213),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(275,218),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(280,203),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(283,195),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(286,183),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(287,172),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(281,185),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(280,198),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(279,209),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(286,202),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(293,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(300,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(305,169),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(307,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(305,199),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(305,210),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(309,201),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(315,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(318,172),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(318,179),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(313,196),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(310,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(313,194),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(317,179),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(321,162),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(323,153),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(319,166),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(318,172),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(322,157),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(325,142),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(325,133),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(324,139),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(323,164),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(322,169),
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(312,180),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(314,173),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(316,166),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(323,133),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(325,113),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(325,97),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(325,85),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(324,80),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(317,90),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(313,101),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(310,108),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(309,113),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(309,98),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(309,77),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(309,51),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(310,21),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(306,37),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(304,49),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(299,73),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(299,78),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(303,63),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(308,41),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(306,53),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(305,69),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(303,81),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(313,68),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(320,56),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(325,44),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(327,37),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(323,44),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(321,50),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(318,56),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(315,61),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(306,45),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(299,32),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(293,23),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(280,29),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(268,43),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(254,56),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(236,72),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(230,77),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(238,70),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(250,61),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(245,65),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(238,73),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(227,82),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(221,81),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(207,78),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(195,78),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(190,80),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(190,85),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(193,90),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(198,97),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(205,107),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(210,112),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(213,117),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(214,125),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(214,134),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(214,141),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(217,122),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(217,116),
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(189,121),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(185,126),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(179,136),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(173,144),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(169,151),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(165,162),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(166,155),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(168,143),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(169,129),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(169,124),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(162,131),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(154,143),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(147,156),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(144,161),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(149,149),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(154,138),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(149,148),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(146,154),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(147,159),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(153,161),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(161,161),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(196,159),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(224,149),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(240,133),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(250,119),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(253,107),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(249,115),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(244,125),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(239,135),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(236,143),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(236,127),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(236,108),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(230,110),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(221,121),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(213,130),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(209,137),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(205,140),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(198,141),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(192,143),
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(187,149),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(191,153),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(198,153),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(208,149),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(224,133),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(232,119),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,107),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(241,97),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(243,92),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,103),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(233,113),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(228,126),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(226,134),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(235,122),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(241,109),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(245,101),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(249,111),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(253,125),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(254,137),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(259,131),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(263,117),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(268,97),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(270,80),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(270,71),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(265,83),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(264,88),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(262,93),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(265,84),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(265,94),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(263,107),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(261,122),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(261,137),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(263,152),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(268,169),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(273,182),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(274,189),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(271,194),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(266,197),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(261,199),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(255,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(256,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(259,191),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(261,182),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(199,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(192,198),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(180,196),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(167,194),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(162,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(168,192),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(178,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(190,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(220,197),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(231,198),
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(192,196),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(204,197),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(221,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(234,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(239,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(225,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(212,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(192,199),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(186,197),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(181,197),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(174,198),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(189,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(199,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(210,209),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(221,211),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(233,213),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(239,213),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(244,209),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(252,208),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(258,208),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(264,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(267,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(268,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(268,172),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(265,166),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(261,168),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(255,176),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(249,184),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(245,189),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(242,181),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(236,189),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(233,194),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(229,207),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,192),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(244,175),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(252,154),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(254,148),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(253,155),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(252,161),
        "rad": 12
    },
    {
        "kind": 1,
        "pt": new Point2D(248,162),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(241,171),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(238,178),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(234,165),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(231,155),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(227,150),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(217,160),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(210,177),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(206,196),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(205,201),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(217,190),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(225,180),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(231,170),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(235,165),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(243,160),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(246,173),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(246,186),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(247,196),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(248,201),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(255,191),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(259,180),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(261,170),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(262,164),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(259,180),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(257,194),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(257,202),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(262,195),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(267,178),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(269,163),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(269,151),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(269,142),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(262,146),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(257,151),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(249,156),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(244,158),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(239,158),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,149),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,132),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,117),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,109),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,117),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,126),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(237,131),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(241,140),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(247,145),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(255,148),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(269,153),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(281,165),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(292,176),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(300,186),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(304,194),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(309,204),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(314,204),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(317,192),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(319,186),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(316,196),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(313,202),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(312,212),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(304,208),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(297,206),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(291,206),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(285,209),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(285,215),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(292,218),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(297,220),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(302,217),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(305,213),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(311,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(316,203),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(322,205),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(337,213),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(345,217),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(349,221),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(351,226),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(351,233),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(350,238),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(343,242),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(337,245),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(335,238),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(336,233),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(340,227),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(341,222),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(341,217),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(330,220),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(310,221),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(249,221),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(202,221),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(173,221),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(143,223),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(124,227),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(109,230),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(104,231),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(93,231),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(87,231),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(79,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(73,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(65,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(56,229),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(51,230),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(46,233),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(40,233),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(34,234),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(29,234),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(25,234),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(25,226),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(27,220),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(30,207),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(32,200),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(32,189),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(30,193),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(29,198),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(29,203),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(37,185),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(47,165),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(56,144),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(62,127),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(65,118),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(67,113),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(73,108),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(79,105),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(88,105),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(98,105),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(118,100),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(126,93),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(133,88),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(137,82),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(140,77),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(141,72),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(141,66),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(132,57),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(109,55),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(90,57),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(69,61),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(55,67),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(48,73),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(45,81),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(45,85),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(51,76),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(58,64),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(67,47),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(70,29),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(71,20),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(63,21),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(53,33),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(44,45),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(38,53),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(37,57),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(44,65),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(61,73),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(84,78),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(105,81),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(125,81),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(147,79),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(169,77),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(197,76),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(204,75),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(213,73),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(220,72),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(226,70),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(228,65),
        "rad": 12
    },
    {
        "kind": 2,
        "pt": new Point2D(226,61),
        "rad": 12
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 15
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 16
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 17
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 18
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 19
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 20
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 21
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 22
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 23
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 24
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 25
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 26
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 27
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 28
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 29
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 30
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 30
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 30
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 30
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 30
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 30
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 30
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(49,75),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(50,74),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(53,69),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(53,64),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(53,58),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(52,53),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(50,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(45,47),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(42,55),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(44,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(43,44),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,39),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(34,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(24,33),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,33),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,35),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(7,45),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,50),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(4,55),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(1,62),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(27,46),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(5,51),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(6,58),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,67),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(5,81),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(6,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(7,94),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(8,100),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(11,107),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,111),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,116),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(23,121),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(25,119),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(29,123),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,128),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(42,129),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(3,189),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,193),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(6,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(8,202),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(14,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(23,202),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(27,201),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(37,195),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,191),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(43,186),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(45,181),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(41,162),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(43,157),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(37,133),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(37,146),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(40,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,150),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,155),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,160),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,166),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,171),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(36,176),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,181),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,188),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,193),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(33,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(28,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(23,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(10,192),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(23,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(29,193),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(33,188),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,179),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,169),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,164),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,159),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(42,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(42,146),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(42,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(42,135),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,129),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,124),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,119),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(31,113),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(27,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(23,106),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(18,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,92),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,85),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(17,81),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,76),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(12,71),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,65),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,60),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,54),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(5,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(9,44),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(14,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(23,46),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(28,49),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(35,51),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,52),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(45,53),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(63,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(67,196),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(70,189),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,183),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(75,177),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(76,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,168),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(73,161),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,167),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(71,174),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(69,179),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(69,184),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(82,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(86,150),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(90,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(93,158),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(93,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(90,169),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(114,198),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(114,193),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(111,184),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(109,178),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(109,173),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(110,160),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(111,154),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(110,149),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(102,143),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(89,158),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(89,163),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(89,168),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(90,170),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(100,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(104,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(109,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(113,150),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(113,157),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(115,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(115,170),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(117,176),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(66,185),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(66,180),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(66,175),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(66,169),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(66,164),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(67,159),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(69,154),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(69,149),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(73,145),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 15
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 16
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(140,167),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(144,167),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(149,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(154,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(159,164),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(163,159),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(163,154),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(160,149),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(157,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(152,142),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(145,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(139,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(134,146),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(136,147),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(141,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(146,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(153,144),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(160,144),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(165,146),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(170,149),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(131,161),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(132,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,169),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(135,174),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(136,179),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(137,185),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(137,189),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(149,192),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(153,192),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(157,191),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(163,187),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 15
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(128, 2, 46)"
    },
    {
        "kind": 5,
        "rad": 16
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(205,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(200,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(195,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,146),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,151),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(187,157),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(186,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(191,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(197,169),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(201,171),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(207,176),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(212,178),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(179,196),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(181,200),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(186,201),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(192,201),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(197,199),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(207,180),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(205,184),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(203,189),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 15
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 16
    },
    {
        "kind": 4,
        "color": "black"
    },
    {
        "kind": 5,
        "rad": 17
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(244,144),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(233,152),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(234,156),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(234,161),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(237,165),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(241,171),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(245,173),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(253,178),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(229,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(233,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(237,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(243,201),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(248,196),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(250,191),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(239,194),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(246,205),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(250,205),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(256,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(260,199),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "white"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(278,136),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(278,140),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(278,151),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(280,156),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,161),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,169),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,174),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,179),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,184),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,190),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,195),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(283,201),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(284,205),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(285,210),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(287,185),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(290,179),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(296,175),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(301,175),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(305,177),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(310,180),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(312,185),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(313,190),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(314,197),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(312,202),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(306,178),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(309,184),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(309,189),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(312,194),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(312,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(311,204),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(281,195),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,209),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "rgb(13, 27, 68)"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(307,113),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(309,117),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(311,122),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(313,127),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(315,132),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(317,137),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(319,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(321,148),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(322,153),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(325,141),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,137),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,131),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,126),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,115),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(341,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(343,104),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(328,149),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(330,161),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,166),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,171),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,176),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,182),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,187),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,193),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(331,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(331,204),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(184,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(188,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(193,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(200,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(206,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(216,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(221,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(226,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(231,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(237,238),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(221,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(225,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(233,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(239,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(245,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(251,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(256,240),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(265,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(276,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(286,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(297,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(313,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(317,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(322,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(327,227),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,222),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(309,229),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(313,225),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(319,221),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(324,217),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(326,209),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,201),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(335,196),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(339,191),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(336,174),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,183),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,190),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,195),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,201),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(330,205),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(328,210),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(325,217),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(318,227),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(314,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(309,235),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(303,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(296,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(286,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(279,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(274,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(267,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(257,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(253,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(247,234),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(237,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(230,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(225,230),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(220,229),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(214,229),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(209,228),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(203,228),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(195,231),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(190,231),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(185,231),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(180,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(188,235),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(201,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(214,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(220,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(231,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(236,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(241,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(247,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(255,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(260,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(265,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,238),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(274,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(281,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(286,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(291,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(298,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(305,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(311,228),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(315,221),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(318,217),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(325,211),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(328,205),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(336,193),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(338,189),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(329,163),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(329,167),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(330,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,180),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,185),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(334,192),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(333,201),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(332,206),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(330,211),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(328,216),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(321,221),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(317,225),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(307,230),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(301,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(295,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(288,239),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(277,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(269,240),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(259,237),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(252,236),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(245,235),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(238,233),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(231,232),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(225,231),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(220,229),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(215,229),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(209,229),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(203,230),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 15
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 16
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(83,184),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(296,195),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(294,200),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(150,154),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(198,158),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(205,158),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(237,189),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(241,188),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(225,183),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(248,156),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(246,153),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(23,175),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(25,175),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(25,69),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(26,81),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(1, 1, 1)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(1, 1, 1)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(77,179),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(77,174),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(74,169),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(94,181),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(98,177),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(101,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(101,168),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(102,163),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(134,209),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(135,204),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(133,199),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(130,194),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(130,189),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(187,41),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(192,37),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(199,30),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(204,26),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(207,21),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(205,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(201,16),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(196,16),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(189,15),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(183,14),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(177,17),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(173,19),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(173,24),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(191,35),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(182,44),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(179,50),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(177,57),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "#1a5597"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(217,173),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(220,177),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(221,181),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(221,175),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(219,169),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(211,160),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(215,163),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(217,169),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(217,173),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(21,149),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(19,144),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(16,138),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(20,145),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(22,151),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(25,157),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(29,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(26,147),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(8,126),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(10,130),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(13,136),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(16,142),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(20,147),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(25,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(28,158),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(29,153),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(29,160),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(29,165),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(29,89),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(31,93),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(33,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(36,102),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,105),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(47,111),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(48,116),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(49,121),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(44,118),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(41,113),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(40,109),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(37,104),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(33,97),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(31,93),
        "rad": 9
    },
    {
        "kind": 2,
        "pt": new Point2D(26,88),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(21,81),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(187,184),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 1,
        "pt": new Point2D(186,185),
        "rad": 9
    },
    {
        "kind": 1,
        "pt": new Point2D(184,185),
        "rad": 9
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 9
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 10
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 11
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 12
    },
    {
        "kind": 3
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 13
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 14
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    },
    {
        "kind": 5,
        "rad": 15
    },
    {
        "kind": 4,
        "color": "rgb(252, 211, 0)"
    }]
export { smesshySplash };