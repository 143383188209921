import React, { Component, useEffect } from 'react';
import { SmesshyCommon, SmesshyCommonProps } from '../smesshyCommon';
import Toggle from 'react-toggle';

export interface TutorialStepParams {
    Key: string;
    Content: JSX.Element;
    Top: number;
    Left: number;
    Width: number;
    Height?: number;
}

export interface TutorialStepState {
    loading: boolean;

}
export interface TutorialStepProps extends SmesshyCommonProps {
    Params: TutorialStepParams;
    Scale: number;
    OnDismiss: ()=>void;
}

class TutorialStep extends SmesshyCommon(Component<TutorialStepProps, TutorialStepState>) {

    constructor(props: TutorialStepProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {

        let controlThis = this;
        this.setState({ loading:false});
        controlThis._app!.FinishTutorial(controlThis.props.Params.Key);

    }

    componentDidUpdate(prevProps: Readonly<TutorialStepProps>, prevState: Readonly<TutorialStepState>): void {
        if (this.props.Params) {

        }

    }

    componentWillUnmount() {
    }

    
    render() {
        let controlThis = this;
        let centerStyle = 'each-cross-center';
        let boxLeft = this.props.Params.Left * this.props.Scale;
        let boxWidth = this.props.Params.Width * this.props.Scale;
        let boxTop = this.props.Params.Top * this.props.Scale;
        if (boxLeft !== undefined) {
            centerStyle = '';
        }
        
        return (
            <div className='width-100p height-100p' style={{maxWidth:512, maxHeight:1024, position:'absolute', left:0, top: 0, zIndex:40, backgroundColor:'var(--smesshy-opaque)'}}
                onPointerUp={(evt)=>{
                   controlThis.props.OnDismiss();
                 }}
            >
            <div className={`v-items ${centerStyle}`} style={{position:'relative', left:boxLeft, top: boxTop, width: boxWidth, zIndex:41}}>
                <div className='smesshy-ask text-white text-medium v-items v-padding-large h-padding-large each-space-between'>
                    {this.props.Params.Content === undefined ? <></> : this.props.Params.Content}
                    
                    <div className='v-items v-large-gap'>
                        <span className='self-cross-center text-small'>(Touch anywhere to dismiss this.)</span>
                        <label className='h-items h-medium-gap each-cross-center' onPointerUp={(e)=>e.stopPropagation()}>
                                <Toggle
                                    checked={false}
                                    value='yes'
                                    onChange={(evt)=>{
                                        controlThis._app!.FinishTutorial('any');
                                        controlThis.props.OnDismiss();
                                        }} />
                                <span className='text-small'>Stop showing these tutorial steps</span>
                        </label>

                    </div>

                </div>

            </div>
            </div>
        

        );
    }
}

export const tutorialPaintingInitial: TutorialStepParams = {
    Key: 'paintingInitial',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Painting</h3>
        <p>This is where it all happens, the painting canvas!</p>
        <p>Touch the canvas to learn about finger strokes.</p>
        <p>Touch any of the buttons along the top or left side to find out what they do.</p>
    </div>
}

export const tutorialPaintingCanvasTouch: TutorialStepParams = {
    Key: 'paintingCanvas',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Finger / Canvas</h3>
        <p className='text-small'>Each time you touch the canvas, you apply a new dab of paint which spreads and mixes with paint already on the canvas. When it 'runs dry' just dab again.</p>
        <p className='text-small'>Using the very tip of your finger should make a small, short, solid streak while pressing your finger flat makes a veritable 'paint plow' leaving a wide swath of shallow streaks.</p>
        <p className='text-small'>The paint you apply will dry slowly. Partially dry paint sticks to the canvas and totally dry paint doesn't even mix or move when you smear over it.</p>
    </div>
}
export const tutorialPaintingCanvasManual: TutorialStepParams = {
    Key: 'paintingCanvas',
    Top: 40,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Finger / Canvas</h3>
        <p className='text-small'>Each time you touch the canvas, you apply a new dab of paint which spreads and mixes with paint already on the canvas. When it 'runs dry' just dab again.</p>
        <p className='text-small'>Using the very tip of your finger should make a small, short, solid streak while pressing your finger flat makes a veritable 'paint plow' leaving a wide swath of shallow streaks.</p>
        <p className='text-small'>(However, since you are using the 'manual' finger size adjustment, move that slider on the bottom of the canvas to apply different tip weights.)</p>
        <p className='text-small'>The paint you apply will dry slowly. Partially dry paint sticks to the canvas and totally dry paint doesn't even mix or move when you smear over it.</p>
    </div>
}

export const tutorialPaintingColor: TutorialStepParams = {
    Key: 'paintingColor',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Color and Amount</h3>
        <p className='text-small'>Obviously, pressing one of the color splotch buttons changes the color of paint on the tip of your finger.</p>
        <p className='text-small'>BUT ALSO, you are adjusting 'how much' paint is on your fingertip when you paint. At the very bottom of the canvas, you will see a volume meter. The color of the meter is the selected color and the 'fullness of the meter (width) shows your paint volume.</p>
        <p className='text-small'>Tapping a color button gives just a dab of paint, holding a button down until the meter is full gives a big glop.</p>
    </div>
}

export const tutorialPaintingSmudge: TutorialStepParams = {
    Key: 'paintingSmudge',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Smudge and Blend</h3>
        <p>The smudge finger lets you push, drag, smooth, and blend paint already on the canvas without adding a new color.</p>
        <p>Dry paint doesn't smudge.</p>
        <p>It works by 'painting' with clear paint. So, just like with other color selections, you set the amount of clear paint to apply by holding down this button before doing your smudge.</p>
    </div>
}
export const tutorialPaintingKnife: TutorialStepParams = {
    Key: 'paintingKnife',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Behold the Knife!</h3>
        <p className='text-small'>The scraper knife button turns your finger into a powerful and terrifying blade that can create fantastic effects or destroy your work. You decide.</p>
        <p className='text-small'>Wet paint should scrape off cleanly, revealing whatever was underneath. Dry paint? Dry paint will take some scrubbing. However, even scrubbed off paint looks interesting.</p>
        <p className='text-small'>A short tap on the knife button makes a flat clean blade while a slightly longer press makes a feather like rake. As you hold the button longer, your knife becomes a notched trowel and eventually a wide 'H' shape.</p>
    </div>
}
export const tutorialPaintingBlow: TutorialStepParams = {
    Key: 'paintingBlow',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Blow to Dry</h3>
        <p className='text-small'>Paint dries slowly but sometimes you're in a hurry or you want to create an interesting effect.</p>
        <p className='text-small'>The 'Blow on it' button lets you 'paint with dryness.' The more you 'fill up' this button by holding it down, the faster your canvas drying strokes will work.</p>
        <p className='text-small'>When the paint in a region is completely dry, it will appear lightly colored until you let up your finger.</p>
        <p className='text-small'>Partially dry paint will still smear while also leaving behind a solid color when painted over.</p>
    </div>
}
export const tutorialPaintingSample: TutorialStepParams = {
    Key: 'paintingSample',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Sample and Save a Color</h3>
        <p className='text-small'>In theory those first five starting colors are all you need: Black, White, Arylide Yellow, Perylene Red, and Phthalo Blue pigments can be combined to make almost any color. In practice, that's nutty. Once you've made that perfect shade of turquoise you don't want to keep making it.</p>
        <p >Pressing the 'Sample' button turns your finger into an eyedropper. With it you then touch your canvas on the color you love, and you will get a new splotch button to work with.</p>
    </div>
}

export const tutorialPaintingPaletteSwap: TutorialStepParams = {
    Key: 'paintingPaletteSwap',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Palette / Canvas Swap</h3>
        <p>This button will let you work on your color palette.</p>
        <p>The palette is a different canvas where you can mix together custom colors.</p>
        <p>Pressing this button again from the palette view brings you back to this canvas.</p>
    </div>
}

export const tutorialPaintingPalette: TutorialStepParams = {
    Key: 'paintingPalette',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Your Palette</h3>
        <p>This is your color mixing and experimentation palette.</p>
        <p>Use it to make new colors, use the Sample '+' button to save those great colors.</p>
        <p>Paint right over that printed text or use the little dishes at the bottom to keep things apart.</p>
        <p className='text-small'>Since the palette paint never dries natually, you can always use the knife to clear off new space when needed.</p>
    </div>
}

export const tutorialPaintingTrash: TutorialStepParams = {
    Key: 'paintingTrash',
    Top: 200,
    Left: 0,
    Width: 340,
    Content: <div className='v-items'>
        <h3>Trash</h3>
        <p>You can clear the canvas and start fresh and/or...</p>
        <p>Remove all of the custom colors you have sampled and saved.</p>
    </div>
}

export const tutorialPaintingReplay: TutorialStepParams = {
    Key: 'paintingReplay',
    Top: 160,
    Left: 0,
    Width: 340,
    Content: <div className='v-items'>
        <h3>Replay / Stop</h3>
        <p>Redraw the current painting using a magical invisible finger. This lets you spy on someone else’s painting steps too!</p>
        <p className='text-small'>Your last unsaved painting will replay if you reload Smesshy. If that is not what you want, you can press the 'stop' button.</p>
        <p className='text-small'>(Stopping a replay will throw away any steps after the stopping point.)</p>

        
    </div>
}

export const tutorialPaintingStamp: TutorialStepParams = {
    Key: 'paintingStamp',
    Top: 240,
    Left: 0,
    Width: 340,
    Content: <div className='v-items'>
        <h3>Stamp a Photo</h3>
        <p className='text-small'>Take a photo or get an image from your device and stamp it on the canvas using paint. The stamped image will be wet, smearable paint and it will blend colors with any paint already on the canvas. BEFORE you select a picture, decide just how much paint should be used to stamp.</p>
        <p className='text-small'>Once selected, you can pinch and move the photo to rotate or resize it. When you are happy with the position, touch somewhere off the photo to stamp it down.</p>
        
    </div>
}

export const tutorialPaintingSave: TutorialStepParams = {
    Key: 'paintingSave',
    Top: 240,
    Left: 0,
    Width: 340,
    Content: <div className='v-items'>
        <h3>Save Your Painting</h3>
        <span className='text-small'>
            You can save / send your painting to one of these places:
            </span>
            <ul className='text-small'>
                <li>Only you see it. Find it in your Gallery page.</li>
                <li>DM a friend (mutual follower). They find it in their Lunchbox for a short time, you get a permanent copy too.</li>
                <li>Visible to all who follow you. They see it in their Following Gallery page</li>
                <li>Public entry to the currently running challenge (if one is open now). Others will vote on it, it can show up in Trending or Fantastic Gallery.</li>
            </ul>
        
    </div>
}

export const tutorialPaintingSaveAnon: TutorialStepParams = {
    Key: 'paintingSaveAnon',
    Top: 60,
    Left: 40,
    Width: 300,
    Content: <div className='v-items'>
        <h3>Save Your Painting</h3>
        <p>You need a Smesshy account to save paintings.</p>
        <p>If you press Save again, you will be asked to sign in.</p>
        
    </div>
}

export const tutorialKeys = [
    'any',
    'paintingInitial',
    'paintingCanvas',
    'paintingCanvas',
    'paintingColor',
    'paintingSmudge',
    'paintingKnife',
    'paintingBlow',
    'paintingSample',
    'paintingPaletteSwap',
    'paintingPalette',
    'paintingTrash',
    'paintingReplay',
    'paintingStamp',
    'paintingSave',
    'paintingSaveAnon'
];

export default TutorialStep;