import React, { Component } from 'react';
import { App, SmesshyAppShape } from '../App'
import IconButton, {CloseButton, IconBookmark, IconBookmarked } from './iconButton';
import { Footer } from '../smesshy';
import { Profile, User } from 'oidc-client';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import PaintingFrame from './paintingFrame';
import { ArtistProfile, PaintingInfo } from '../storageManager';
import InfiniteScroll from "react-infinite-scroll-component";
import { prependListener } from 'process';
import Smessage from './smessage';
import { SmesshyMainButton, SmesshySubButton } from './smesshyButton';
import GamePage from './gamePage';
import { StreakViewer } from './followingPicker';
import { PaintingPair } from './galleryPage';
import { createSearchParams } from 'react-router-dom';

export interface FeedPageState extends SmesshyCommonState {
    userId: string | null;
    followingOnly: boolean;
    bookmarksOnly: boolean;
    nextStart: number;
    atEnd: boolean;
    paintingInfos: Array<PaintingInfo>;
}
export interface FeedPageProps extends SmesshyCommonProps {
    FetchCount: number;
}

class FeedPage extends SmesshyCommon(Component<FeedPageProps, FeedPageState>) {

    scrollRef: React.RefObject<HTMLDivElement>;

    constructor(props: FeedPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.scrollRef = React.createRef();
        this.initState(props);
    }

    initState(props: FeedPageProps) {
        this.state = {
            showWaitSpin: false,
            authenticated: false,
            userId: null,
            nextStart: 0,
            atEnd: false,
            paintingInfos: new Array<PaintingInfo>(),
            followingOnly: false,
            bookmarksOnly: false
        };

    }

    componentDidMount() {
        STrace.addStep('feed', 'didMound', '');
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('feed', 'populateAuth', authenticated.toString());
        if (authenticated) {
            this.setState({authenticated:true, userId: await this.getUserId()});
            this.fetchMorePaintings(true, this.state.atEnd, this.state.nextStart, this.state.paintingInfos, this.state.followingOnly, this.state.bookmarksOnly);
        } else {
            this.setState({authenticated:false});
            this.fetchMorePaintings(false, this.state.atEnd, this.state.nextStart, this.state.paintingInfos, this.state.followingOnly, this.state.bookmarksOnly);
        }
    }


    fetchMorePaintings(authenticated: boolean,  atEnd: boolean, nextStart: number, paintingInfos: Array<PaintingInfo>, followingOnly: boolean, bookmarksOnly: boolean) {
        if (atEnd) {
          return;
        }
        
        let app = this.props.AppObject;
        let controlThis = this;
        this.pushWaitingFrame(this);
        this.executeAsync(async ()=> {

            const acceptPins = (pins: Array<PaintingInfo>)=>{
                controlThis.setState({
                    paintingInfos: paintingInfos.concat(pins),
                    nextStart: nextStart + pins.length, 
                    atEnd:pins.length < controlThis.props.FetchCount
                });

                for (let pin of pins) {
                    controlThis._app!.clearNotifications('painting', pin.paintingId!);
                }
            };

            let token = await controlThis.getAccessToken();
            if (token === undefined) {
                token = 'none'
            }           

            let filter = bookmarksOnly ? 'favorite' : 'recent';
            if (followingOnly) {
                STrace.addStep('feed', 'getFollowingPaintingsWithPreviews', 'following');
                controlThis.storageManager!.getFollowingPaintingsWithPreviews(token, 
                    nextStart, nextStart + controlThis.props.FetchCount-1, filter, acceptPins);
            } else {
                STrace.addStep('feed', 'getGlobalPaintingsWithPreviews', 'global');
                controlThis.storageManager!.getGlobalPaintingsWithPreviews(token,
                    nextStart, nextStart + controlThis.props.FetchCount-1, filter, acceptPins);
            }
            controlThis.popWaitingFrame();
        });

      }
    

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`gallery, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const pairs = new Array<[PaintingInfo| undefined, PaintingInfo| undefined]>();
        let lastPair: [PaintingInfo | undefined, PaintingInfo | undefined] = [undefined, undefined];
        for (const pin of this.state.paintingInfos) {
            if (lastPair[0] === undefined) {
                pairs.push(lastPair);
                lastPair[0] = pin;
            } else {
                lastPair[1] = pin;
                lastPair = [undefined, undefined];
            }
        }

        let headerSection = <div className='h-items h-medium-gap each-space-between'>
            <div className='h-items h-medium-gap each-cross-center'>
                <div className='text-bold h-padding-medium'>{'From:'}</div>
                <div className='height-100p' style={{width: this._app!.GetScaledPxWidthVw(68)}}>
                    <SmesshySubButton AppObject={this._app!}
                        ExtraStyleClass='height-100p width-100p group-cross-center'
                        Display={<>{controlThis.state.followingOnly ? 'Those I follow' : 'Anyone'}</>}
                        Haptic = {this.doButtonHaptic()}
                        StartPushedState = {false}
                        OnPressFinish={async (): Promise<boolean> => {
                            let reqNav = controlThis.requireSetup(() => {
                                let paintingInfos = new Array<PaintingInfo>();
                                controlThis.setState({followingOnly:!controlThis.state.followingOnly, nextStart:0, paintingInfos:paintingInfos, atEnd:false});
                                controlThis.fetchMorePaintings(controlThis.state.authenticated, false, 0, paintingInfos, !controlThis.state.followingOnly, controlThis.state.bookmarksOnly);
                            });
                            if (reqNav != undefined) {
                                controlThis.setState({navigateTo: reqNav});
                            }
                            return true;
                        }}
                    />
                </div>
                <IconButton AppObject={this._app!} 
                    Tag='bookmarks'
                    IconRef={this.state.bookmarksOnly ? IconBookmarked(undefined) : IconBookmark(undefined)}
                    PressTickInterval={0}
                    OnPressStart={()=>{
                    }}
                    OnPressTick={()=>{
                    }}
                    OnPressFinish={async (): Promise<boolean>=>{
                        let reqNav = controlThis.requireSetup(() => {
                            let paintingInfos = new Array<PaintingInfo>();
                            controlThis.setState({bookmarksOnly:!controlThis.state.bookmarksOnly, nextStart:0, paintingInfos:paintingInfos, atEnd:false});
                            controlThis.fetchMorePaintings(controlThis.state.authenticated, false, 0, paintingInfos, controlThis.state.followingOnly, !controlThis.state.bookmarksOnly);
                        });
                        if (reqNav != undefined) {
                            controlThis.setState({navigateTo: reqNav});
                        }
                        return true;
                    }}
                    StartPushedState={true}
                    Haptic = {this.doButtonHaptic()}
                    Height={this._app!.GetScaledPxHeight(31)}
                    Size={'large'}
                    />
            </div>

            <SmesshyMainButton AppObject={this._app!}
                ExtraStyleClass='self-right'
                Display={
                    <div className='height-100p v-items'>
                        <span className='text-small text-boldish'>Your followers want more ...</span>
                        <div className='width-100p height-100p h-items'>
                            <div className='smesshy-paint-button width-100p height-100p'></div>
                            <span className='text-small text-boldish self-cross-end'>and post</span>
                        </div>
                    </div> }

                    Haptic={this.doButtonHaptic()}
                    StartPushedState={false}
                    OnPressFinish={async (): Promise<boolean>=>{
                        let reqNav = controlThis.requireSetup(() => {
                            controlThis.setState({navigateTo: {To:{pathname:'/painting', search:`?${createSearchParams({saveMode: 'foll'})}`}}});
                        });
                        if (reqNav != undefined) {
                            controlThis.setState({navigateTo: reqNav});
                        }
                        return true;
                    }}
                />
        </div>

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={this._inPWA}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>{`Smesshy - Feed Me!`}</span>}
                CloseNav = {'/'}
                Body={
                    <div className='game-page-mid smesshy-main text-white text-medium v-medium-gap v-padding-small' >
                    {headerSection}
                    <div id='gallery-scroll-host' className='width-100p height-100p v-scroll-container' ref={ this.scrollRef }>
                        <InfiniteScroll
                            dataLength={this.state.paintingInfos.length}
                            next={()=>controlThis.fetchMorePaintings(this.state.authenticated, this.state.atEnd, this.state.nextStart, this.state.paintingInfos, this.state.followingOnly, this.state.bookmarksOnly)}
                            hasMore={!this.state.atEnd}
                            loader={<div className='text-bold'>There's more...</div>}
                            scrollableTarget='gallery-scroll-host'

                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                <b>{`(no more)`}</b>
                                </p>
                            }
            >
                            {pairs.map((pair, index) => (
                                <PaintingPair
                                    key={index}
                                    AppObject={this.props.AppObject}
                                    AppShape={this.props.AppShape}
                                    PaintingInfo1={pair[0]}
                                    PaintingInfo2={pair[1]}
                                    UserId={this.state.userId}
                                    SaveEdit = {false}
                                    ShowChallenge = {false}
                                    ShowLunchbox = {false}
                                    SelectionMode = {false}
                                    Callback = {undefined}
                                    NoArtist = {false}
                                    CurrentSelection = {undefined}
                                
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
       
                </div>
                }
            />, this.state);
    }
}

export default FeedPage;