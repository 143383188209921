import React, { Component, useEffect } from 'react';

export interface CountDownTextState {
    SecondsRemaining: number;
}
export interface CountDownTextProps {
    SecondsInitial: number;
    OnFinish: ()=>void;
}

export class CountdownTimer {
    timerId: NodeJS.Timeout | undefined = undefined;
    timeWhenStarted: number = 0;
    remainingWhenStarted: number = 0;

    public countDownFrom(secondsRemaining: number, interval: number, onTick: (rem: number) => void) {
        this.clear();
        this.remainingWhenStarted = secondsRemaining;
        this.timeWhenStarted = Date.now();
        const localThis = this;
        this.timerId = setInterval(() => {
            let newRemaining = localThis.remaining();
            onTick(newRemaining);
        }, interval * 1000);
    }

    public remaining(): number {
        const timeNow = Date.now();
        return Math.round(this.remainingWhenStarted - ((timeNow - this.timeWhenStarted) / 1000));
    }

    public clear(): void {
        if (this.timerId !== undefined) {
            clearInterval(this.timerId);
            this.timerId = undefined;
        }


    }

    public toString(secondsLeft: number | undefined): string {
        if (secondsLeft === undefined) {
            secondsLeft = this.remaining();
        }

        let timeLeft = ` (`;
        let secLeft = secondsLeft + .5;
        let h = Math.floor(secLeft / 3600);
        secLeft -= (3600 * h);
        let m = Math.floor(secLeft / 60);
        secLeft -= (60 * m);
        let s = Math.floor(secLeft);
        let mPre = '';
        if (h !== 0) {
            timeLeft += `${h}:`;
            mPre = '0';
        }
        timeLeft += `${(mPre + m.toString()).slice(-2)}:${('0' + s.toString()).slice(-2)})`;
        return timeLeft;
    }
}

class CountDownText extends Component<CountDownTextProps, CountDownTextState> {

    constructor(props: CountDownTextProps) {
        super(props);

        this.state = {
            SecondsRemaining: this.props.SecondsInitial
        };
    }
    timer = new CountdownTimer();

    setupTimer() {
        const controlThis = this;
        if (this.props.SecondsInitial > 0) {
            this.timer.countDownFrom(this.props.SecondsInitial, 2, (remaining) => {
                if (remaining !== controlThis.state.SecondsRemaining) {
                    controlThis.setState({ SecondsRemaining: remaining })
                    if (remaining <= 0) {
                        controlThis.props.OnFinish();
                    }
                }
            });
        } else {
            this.timer.clear();
        }
        this.setState({ SecondsRemaining: this.props.SecondsInitial })

    }
    componentDidMount() {
        this.setupTimer();
    }

    componentDidUpdate(prevProps: CountDownTextProps) {
        if (this.props.SecondsInitial !== prevProps.SecondsInitial) {
            this.setupTimer();
        }
    }
    componentWillUnmount() {
        this.timer.clear();
    }

    render() {
        let timeLeft = this.timer.toString(this.state.SecondsRemaining)

        return (timeLeft);
    }
}

export default CountDownText;