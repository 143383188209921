import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import { STrace, SmesshyCommon, SmesshyCommonProps } from '../../smesshyCommon';
import React, { Component } from 'react';
import Smessage from '../smessage';
import { App } from '../../App';
import { AuthenticationResultStatus } from './AuthorizeService';

export interface LoginState {
    loading: boolean,
    message: string | undefined | null
}
export interface LoginProps extends SmesshyCommonProps {
  action: string;
}

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.

class Login extends SmesshyCommon(Component<LoginProps, LoginState>) {

    constructor(props: LoginProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            loading: true,
            message: undefined
        };
    }

    componentDidMount() {
      STrace.addStep('login', 'didMound', '');
      const action = this.props.action;
      const controlThis = this;
      this.executeAsync(async () => {
        try {
          switch (action) {
            case LoginActions.Login:
              STrace.addStep('login', 'login', '');
              await controlThis.login(controlThis.getReturnUrl());
              break;
            case LoginActions.LoginCallback:
              STrace.addStep('login', 'processLoginCallback', '');
              await controlThis.processLoginCallback();
              break;
            case LoginActions.LoginFailed:
              const params = new URLSearchParams(window.location.search);
              const error = params.get(QueryParameterNames.Message);
              throw new Error(error!);
            case LoginActions.Profile:
              STrace.addStep('login', 'redirectToProfile', '');
              controlThis.redirectToProfile();
              break;
            case LoginActions.Register:
              STrace.addStep('login', 'redirectToRegister', '');
              controlThis.redirectToRegister();
              break;
            default:
              throw new Error(`Invalid action '${action}'`);
          }
        } catch (e: any) {
          controlThis.props.AppObject.reportException(`Login action ${action}`, 'ex', '', e)
        }
      });

    }

    componentDidUpdate(prevProps: Readonly<LoginProps>, prevState: Readonly<LoginState>): void {

    }

    componentWillUnmount() {
    }


    render() {
        let controlThis = this;

        const action = this.props.action;
        let { message } = this.state;
    
        if (message === undefined || message === null) {
          switch (action) {
            case LoginActions.Login:
              message = 'Signing you in...';
              break;
            case LoginActions.LoginCallback:
              message = 'Processing login callback...';
              break;
            case LoginActions.Profile:
            case LoginActions.Register:
              return (<div></div>);
            default:
              throw new Error(`Invalid action '${action}'`);
          }
        }
        return <Smessage
                AppObject={this._app!}
                AppShape={this._appShape}
                Title='Sign In'
                Loading={true}
                CloseNav='/'
                Say={message}
              />
    }

    async login(returnUrl: string) {
      const state = { returnUrl };
      STrace.addStep('login', 'signIn', '');
      const result = await this.signIn(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          STrace.addStep('login', 'navigateToReturnUrl', '');
          await this.navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          this.setState({ message: result.message.message });
          break;
        default:
          throw new Error(`Invalid status result ${result.status}.`);
      }
    }
  
    async processLoginCallback() {
      const url = window.location.href;
      STrace.addStep('login', 'completeSignIn', '');
      const result = await this.completeSignIn(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error('Should not redirect.');
        case AuthenticationResultStatus.Success:
          STrace.addStep('login', 'navigateToReturnUrl', '');
          this.navigateToReturnUrl(this.getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          this.setState({ message: result.message });
          break;
        default:
          throw new Error(`Invalid authentication result status '${result.status}'.`);
      }
    }
  
    getReturnUrl(state?: any) : string {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
      }
      return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }
  
    redirectToRegister() {
      this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }
  
    redirectToProfile() {
      this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }
  
    redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
      const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
      // It's important that we do a replace here so that when the user hits the back arrow on the
      // browser they get sent back to where it was on the app instead of to an endpoint on this
      // component.
      window.location.replace(redirectUrl);
    }
  
    navigateToReturnUrl(returnUrl: string) {
      // It's important that we do a replace here so that we remove the callback uri with the
      // fragment containing the tokens from the browser history.
      window.location.replace(returnUrl);
    }
  
}

export default Login;






