import React, { Component, useEffect } from 'react';
import { App } from '../App'
import { Navigate } from 'react-router-dom';
import Smear from './smash/smear';
import FingerTip from './smash/fingertip';
import AnimationActionRecording from './smash/animationActions';
import { SmesshySubButton } from './smesshyButton';
import { SmesshyCommon, SmesshyCommonProps } from '../smesshyCommon';
import tinycolor from 'tinycolor2';
import { Point2D } from './smash/utils';

export interface SmesshyAskOption {
    Key: string;
    PassiveOnly?: boolean;
    Option: JSX.Element;
    OnOption: (()=>Promise<boolean>);
    Disabled?: boolean;
}
export interface SmesshyAskParams {
    Title: JSX.Element | undefined;
    Prompts?: Array<JSX.Element> | undefined;
    Options?: Array<SmesshyAskOption> | undefined;
    OnShow?: (()=>void) | undefined;
    OnCancel?: (()=>void) | undefined;
    Top?: number;
}

export interface SmesshyAskState {
    loading: boolean;

}
export interface SmesshyAskProps extends SmesshyCommonProps {
    Params: SmesshyAskParams
}

class SmesshyAsk extends SmesshyCommon(Component<SmesshyAskProps, SmesshyAskState>) {

    constructor(props: SmesshyAskProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {

        let controlThis = this;
        this.setState({ loading:false});
        if (this.props.Params.OnShow !== undefined) {
            this.props.Params.OnShow();
        }
    }

    componentDidUpdate(prevProps: Readonly<SmesshyAskProps>, prevState: Readonly<SmesshyAskState>): void {
        if (this.props.Params) {

        }

    }

    componentWillUnmount() {
    }

    
    render() {
        let controlThis = this;
        
        return (
            <div className='width-100p height-100p' style={{maxWidth:512, maxHeight:1024, position:'absolute', left:0, top: 0, zIndex:20, backgroundColor:'var(--smesshy-opaque)'}}>
            <div className='width-100p v-items each-cross-center' style={{position:'relative', left:0, top: this.props.Params.Top}}>
                <div className='smesshy-ask text-white text-medium v-items v-small-gap v-padding-large h-padding-large'>
                    <div className='width-100p h-items each-space-between each-cross-center' >
                        <div className='text-large'>{this.props.Params.Title}</div>
                        <SmesshySubButton AppObject={this._app!}
                            Display={<span>X</span>}
                            Haptic = {this.doButtonHaptic()}
                            StartPushedState = {false}
                            OnPressFinish={async (): Promise<boolean>=>{
                                if (controlThis.props.Params.OnCancel) {
                                    controlThis.props.Params.OnCancel();
                                }
                                return true;
                            }}
                        />
                    </div>
                    {this.props.Params.Prompts === undefined ? <></> : 
                        <div className='v-items smesshy-ask-inset'>
                            {this.props.Params.Prompts}
                     
                        </div>
                    }
                    {this.props.Params.Options === undefined ? <></> : 
                        <div className='h-items h-medium-gap'>
                            {this.props.Params.Options.map(o=>
                                <SmesshySubButton AppObject={this._app!}
                                    key = {o.Key}
                                    Display={o.Option}
                                    Haptic = {this.doButtonHaptic()}
                                    StartPushedState = {false}
                                    PassiveOnly = {o.PassiveOnly}
                                    OnPressFinish={async (): Promise<boolean> => {return await o.OnOption();}}
                                    Disabled = {o.Disabled}
                                />
                                )}
                        </div>
                    }
                </div>

            </div>
            </div>
        

        );
    }
}
export interface SmesshyDoneState {
    startReplay: boolean;

}
export interface SmesshyDoneProps {
    AppObject: App;
    Top: number;
    OnFinish: ()=>void;
}

export class SmesshyDone extends Component<SmesshyDoneProps, SmesshyDoneState> {

    check = 'BAEBAQUJAwABBPzTAAUJAwABAQAVADQOAQApAE4OAwABBA0bRAUJBA0bRAUJAwABAQArAEwOAwABAQAWADAOAgAbADYOAgAeADsOAgAhAEAOAgAkAEUOAgAmAEoOAgArAEYOAgAzAEEOAgA4AD8OAgA9ADsOAgBAADUOAgBDADAOAgBIAC0OAgBKACgOAgBNACMOAgBSAB8OAgBXABwOAgBcABsOAgBeABYOAgBjABMOAgBmAA4OAwABBAEBAQUJAQAcACsOAgAhAC0OAgAmADEOAgAoADYOAgAsADsOAgAuAEAOAgAzADsOAgA3ADYOAgA6ADEOAgA/AC8OAgBEAC0OAgBJACkOAgBOACUOAgBTACEOAgBZAB8OAgBeABwOAgBiABcOAgBmABIOAgBpAA0OAgBnABMOAgBhABgOAgBcABsOAgBXAB8OAgBUACQOAgBYAB8OAgBcABoOAgBgABUOAgBlABAOAgBqAAsOAgBvAAkOAwAn';
    smearRef: React.RefObject<Smear>;
    checkRecording: AnimationActionRecording; 

    constructor(props: SmesshyDoneProps) {
        super(props);

        this.checkRecording = new AnimationActionRecording();
        this.smearRef = React.createRef();
        this.state = {
            startReplay: false
        };
    }

    componentDidMount() {

        this.setState({ startReplay: true});
       
    }
    

    render() {
        let controlThis = this;

        const ReplayObserver = () => {
            useEffect(()=>{
                const smear = controlThis.smearRef.current;
                if (controlThis.state.startReplay === true && smear) {
                    // if there is no render context, that means thing are still setting up in that child component. set our state to something dumb to trigger a re render
                    if (smear.getPage() === undefined) {
                        this.setState({startReplay: false});
                    } else {
                        // replay could happen. restore the sequence
                        this.checkRecording.restore(this.check); 
                        smear!.replayRecorded(.8, this.checkRecording.recordedAction, ()=>{this.props.OnFinish()}); 
                        controlThis.setState({startReplay: false});
                    }
                }
    
            });
            return <></>;
        }

        
        return (
            <>
                <ReplayObserver/>
                <div className='width-100p v-items each-cross-center' style={{position:'relative', left:0, top: this.props.Top, zIndex:100}}>
                    <div className='smesshy-ask v-items v-padding-large h-padding-large' style={{width: 144, height:120}}>
                        <Smear 
                            ref={ this.smearRef }
                            contextKey={'check'}
                            AppObject={this.props.AppObject}
                            Color={'black'}
                            SampleMode = {false}
                            CanCacheCtx = {false}
                            TextureSmash = {false}
                            DryInterval={0}
                            DryRate={0}
                            OnColorSample={(rgb)=>{}}
                            UseMouse={false}
                            FixedSize={FingerTip.standardFingerRadius}
                            AvgLightRadius={0}
                            AvgHeavyRadius={0}
                            PaintRadius={0}
                        />
                    </div>
       
                </div>
            </>);
    }
    
}

export interface SmesshyWaitState {
    startReplay: boolean;

}
export interface SmesshyWaitProps {
    AppObject: App;
    Top: number;
}

export class SmesshyWait extends Component<SmesshyWaitProps, SmesshyWaitState> {

    swirl = 'BAEBAQUFAwABAQAUADIJAgAPAC0JAgAPACgJAgAPACMJAgAPAB0JAgAPABgJAgAQABMJAgAVABAJAgAaABIJAgAiABcJAgAoABoJAgAtAB8JAgAwACUJAgAxACwJAgAyADEJAgAtADQJAgAnADQJAgAiADQJAgAdADIJAgAZACwJAgAVACcJAgAUACMJAwCp'
    smearRef: React.RefObject<Smear>;
    checkRecording: AnimationActionRecording; 

    constructor(props: SmesshyDoneProps) {
        super(props);

        this.checkRecording = new AnimationActionRecording();
        this.smearRef = React.createRef();
        this.state = {
            startReplay: true,
        };
    }


    componentDidMount() {

        this.setState({ startReplay: true});
       
    }
    

    render() {
        let controlThis = this;
        const randoColor = ()=> {
            const r = Math.floor(Math.random() * 255);
            const g = Math.floor(Math.random() * 255);
            const b = Math.floor(Math.random() * 255);
            return new tinycolor({r,g,b}).toHexString();
        }

        const ReplayObserver = () => {
            useEffect(()=>{
                const smear = controlThis.smearRef.current;
                if (controlThis.state.startReplay === true && smear) {
                    // if there is no render context, that means thing are still setting up in that child component. set our state to something dumb to trigger a re render
                    if (smear.getPage() === undefined) {
                        this.setState({startReplay: false});
                    } else {

                        smear!.pokeColor(new Point2D(25,25), randoColor(), 4, 2 + Math.floor(Math.random() * 4));
                        smear!.pokeColor(new Point2D(25,40), randoColor(), 4, 2 + Math.floor(Math.random() * 4));
                        smear!.pokeColor(new Point2D(40,51), randoColor(), 4, 2 + Math.floor(Math.random() * 4));
                        controlThis.checkRecording.restore(controlThis.swirl); 
                        smear!.replayRecorded(1, controlThis.checkRecording.recordedAction, ()=>{
                            controlThis.setState({startReplay: true});

                        }, true); 
                        controlThis.setState({startReplay: false});
                    }
                }
    
            });
            return <></>;
        }

        
        return (
            <>
                <ReplayObserver/>
                <div className='width-100p v-items each-cross-center' style={{position:'relative', left:0, top: this.props.Top, zIndex:100}}>
                    <div className='smesshy-ask v-items v-padding-large h-padding-large' style={{width: 90, height:94}}>
                        <Smear 
                            ref={ this.smearRef }
                            contextKey={'check'}
                            AppObject={this.props.AppObject}
                            Color={'black'}
                            SampleMode = {false}
                            CanCacheCtx = {false}
                            TextureSmash = {false}
                            DryInterval={0}
                            DryRate={0}
                            OnColorSample={(rgb)=>{}}
                            UseMouse={false}
                            FixedSize={FingerTip.standardFingerRadius}
                            AvgLightRadius={0}
                            AvgHeavyRadius={0}
                            PaintRadius={0}
                        />
                    </div>
       
                </div>
            </>);
    }
    
}


export default SmesshyAsk;