import React, { Component, Profiler } from 'react';
import { App } from '../../App'
import tinycolor from 'tinycolor2';
import {FingerTip} from './fingertip';
import {CellKind, Page, TipEffect} from './page';
import smearGenerator from './smearGen';
import { colorMixKM } from './kubelkaMonk';
import { AnimationAction, AnimationActionColor, AnimationActionDrag, AnimationActionDry, AnimationActionKind, AnimationActionPaintRadius, AnimationActionStamp, AnimationActionStampLine, AnimationActionStart } from './animationActions';
import { STrace, SmesshyCommon } from '../../smesshyCommon';
import { Point2D, SeededRandom } from './utils';
import { Container } from 'reactstrap';

export interface SmearState {
    loading: boolean,

}
export interface SmearProps {
    contextKey: string;
    AppObject: App;
    Color: string;
    SampleMode: boolean;
    CanCacheCtx: boolean;
    OnClear?: (crc: CanvasRenderingContext2D)=>void;
    TextureSmash: boolean;
    CentralPull?: ((d:number)=>number);
    DryInterval: number;
    DryRate: number;
    UseMouse: boolean;
    FixedSize: number;
    AvgLightRadius: number;
    AvgHeavyRadius: number;
    PaintRadius: number;
    OnDragStart?: (()=>boolean);
    OnDragEnd?: (()=>void);
    OnRecordAnimation?: ((action:AnimationAction)=>void);
    OnColorSample: (rgb:tinycolor.ColorFormats.RGB)=>void;
}


interface FingerAction {
    isStart: boolean;
    isFinish: boolean;
    ptStart: Point2D;
    radius: number;
    paintRadius: number;
    color: string;
}

interface AntiAliasPoint {
    filled0: number;
    filled1: number;
    filled2: number;
    filled3: number;
    filled5: number;
    filled6: number;
    filled7: number;
    filled8: number;
}

export class TouchActionAnimationQueue<actionKind> {
    doProfile = false;
    takeAction: (a: actionKind)=>boolean;
       
    pendingAction = new Array<actionKind>(64);
    nextAction = -1;
    lastAction = -1;
    active = true;

    constructor(takeAction: (a: actionKind)=>boolean) {
        this.takeAction = takeAction;
    }

    // a simple wrap around fifo queue
    queueAction(fa: actionKind) {
        if (!this.active) {
            return;
        }
        //this.props.AppObject.LogLine(`q: (${ptCenter.x},${ptCenter.y})${isFirst?'* ':' '}@${radius}`);
        if (this.nextAction === -1) {
            this.nextAction = 0;
            this.lastAction = 0;
            let localThis = this;
            requestAnimationFrame(()=>{
                if (!this.active) {
                    return;
                }
                this.active = localThis.takeAction(localThis.dequeueAction(true));
            });
        } else {
            this.lastAction ++;
            if (this.lastAction === 64) {
                this.lastAction = 0;
            }
        }
        this.pendingAction[this.lastAction] = fa;
    }

    dequeueAction(requestFrame: boolean) : actionKind {
        let act = this.pendingAction[this.nextAction];
        if (this.nextAction === this.lastAction) {
            this.nextAction = this.lastAction = -1;
        } else {
            this.nextAction ++;
            if (this.nextAction === 64) {
                this.nextAction = 0;
            }
            let localThis = this;
            if (requestFrame && this.active) {
                requestAnimationFrame(()=>{
                    if (!this.active) {
                        return;
                    }
                    // if there are too many pending actions (3 would take 1/20th second in frames to process, that seems ok, but longer sees lag)
                    // then process more in this same frame
                    let pendingActions = this.lastAction - this.nextAction;
                    if (pendingActions < 0) {
                        pendingActions = this.lastAction + 1 + (64-this.nextAction);
                    }
                    if (pendingActions > 3) {
                        if (this.doProfile) {
                            console.profile();                                
                        }
                        while (pendingActions > 1) {
                            if ((this.active = localThis.takeAction(localThis.dequeueAction(false))) === false) {
                                return;
                            }
                            pendingActions--;
                        }
                        if (this.doProfile) {
                            console.profileEnd(); 
                        }
                    }
        
                    this.active = localThis.takeAction(localThis.dequeueAction(true));
                });
            }
        }
        return act;
    }
}

class Smear extends SmesshyCommon(Component<SmearProps, SmearState>) {

    canvasRef : React.RefObject<HTMLCanvasElement>;

    noMouse = false;

    // Drawing state
    dragging = false;
    replaying = false;

    public page: Page | undefined = undefined;
    public usingCachedContext: boolean = false;

    lastPos: Point2D;
    lastRadius: number;
    lastDrawOctant: number;
    lastMoveOctant: number;
    lastLastMoveOctant: number;

    replayOriginX: number = 0;
    replayOriginY: number = 0;


    rnd = new SeededRandom();

    constructor(props: SmearProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.canvasRef = React.createRef();

        this.lastPos = new Point2D(0,0);
        this.lastRadius = 0;
        this.lastDrawOctant = 4;
        this.lastMoveOctant = 4;
        this.lastLastMoveOctant = 4;

        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        STrace.addStep('smear', 'didMound', this.props.contextKey);
        this.setState({ loading:false});
    }

    
    dryInterval: NodeJS.Timer | undefined = undefined;
    replayAnimationFrame: number = -1;

    componentDidUpdate(prevProps: Readonly<SmearProps>, prevState: Readonly<SmearState>): void {
        const page = this.getPage()!;

        if (this.dryInterval === undefined && this.props.DryInterval !== 0) {
            let controlThis = this;
            this.dryInterval = setInterval(() => {
                // don't dry while dragging to prevent odd hardened bits in current drag path
                // don't dry while replaying
                if (!controlThis.dragging && !controlThis.replaying){
                    if (this.props.OnRecordAnimation !== undefined) { 
                        this.props.OnRecordAnimation({kind:AnimationActionKind.dry});
                    }
                    // dry one step
                    page.dry(controlThis.props.DryRate);

                }
            }, this.props.DryInterval);
        }
       
    }

    componentWillUnmount() {
        // stop drying
        if (this.dryInterval !== undefined) {
            clearInterval(this.dryInterval);
            this.dryInterval = undefined;
        }
        // shut down any running animation
        if (this.replayAnimationFrame !== -1 && this.replaying === true) {
            cancelAnimationFrame(this.replayAnimationFrame);
            this.replayAnimationFrame = -1;
            this.replaying = false;
        }
    }

    setReplayOrigin(x: number, y: number) {
        this.replayOriginX = x;
        this.replayOriginY = y;
    }

    getPage() : Page | undefined
    {
        if (this.canvasRef.current !== undefined && this.canvasRef.current !== null) {
            if (this.page === undefined) {
                if (this.props.CanCacheCtx === true) {
                    this.page = this.GetAppState(this.props.contextKey, undefined) as Page | undefined;
                }
                let width = this.canvasRef.current.clientWidth;
                let height = this.canvasRef.current.clientHeight;
                this.canvasRef.current.width=width;
                this.canvasRef.current.height=height;
                //let drawCtx = this.canvasRef.current.getContext('2d', {willReadFrequently:true})!;
                let drawCtx = this.canvasRef.current.getContext('2d')!;
                if (drawCtx.imageSmoothingEnabled === true) {
                    drawCtx.imageSmoothingQuality = 'high';
                }

                if (this.page === undefined) {
                    this.usingCachedContext = false;
                    this.page = new Page(this.canvasRef.current.getBoundingClientRect(), width, height, drawCtx, this.props.OnClear, this.props.CentralPull, this.rnd, this.props.TextureSmash);
                    if (this.props.CanCacheCtx === true) {
                        this.SetAppState(this.props.contextKey, this.page);
                    }
                } else {
                    // the page was saved but the canvas is new so we need to change
                    // the context in the page to the new canvas and swap over the image
                    this.usingCachedContext = true;
                    drawCtx.drawImage(this.page.crcVisible.canvas, 0, 0);
                    this.page.crcVisible = drawCtx;
                }
            }
            return this.page;
        }
        return undefined;
    }
    public boundingClientRect(): DOMRect | undefined {
        let page=this.getPage();
        if (page !== undefined) {
            return page.clientRec;
        }
        return undefined;
    }

    queue = new TouchActionAnimationQueue<FingerAction>(
        (fa: FingerAction):boolean=> {
            if (this.replaying === true) {
                // ignore touches while replaying, else messes up all ... everthing 
                return true;
            }
            //fa.ptStart = new Point2D(200, fa.ptStart.y);
            fa.ptStart = new Point2D(fa.ptStart.x, fa.ptStart.y);
            if (fa.isStart) {
                let tipKind = Page.ColorToTipKind(new tinycolor(fa.color).toRgb());

                this.startStroke(fa.ptStart, fa.color, fa.radius, fa.paintRadius, tipKind);  
                if (this.props.OnRecordAnimation !== undefined) {
                    this.props.OnRecordAnimation({kind:AnimationActionKind.start, pt:fa.ptStart, rad: fa.radius});
                }
            } if (fa.isFinish) {
                if (this.finishStroke() === true) {
                    if (this.props.OnRecordAnimation !== undefined) {
                        this.props.OnRecordAnimation({kind:AnimationActionKind.finish, pt:fa.ptStart, rad: fa.radius});
                    }
                }
            } else {
                let tipKind = Page.ColorToTipKind(new tinycolor(fa.color).toRgb());
                const saveSeed = this.rnd.seed; // save so playback is consistent
                if (this.continueStroke(fa.ptStart, fa.radius, fa.paintRadius, tipKind)===true && this.props.OnRecordAnimation !== undefined) {
                    this.props.OnRecordAnimation({kind:AnimationActionKind.drag, pt:fa.ptStart, rad: fa.radius});
                } else {
                    this.rnd.seed = saveSeed;
                }
            }
            return true;
        }
    );

    
    public clearCanvas() {

        this.stopReplay();

        // start out by cleaning the main page in the context
        const page = this.getPage()!;

        // replace the page with a white, empty one
        this.rnd = new SeededRandom(); // same sequence as original
        page.clear(this.props.OnClear, this.props.CentralPull, this.rnd, this.props.TextureSmash);

    }

    public stopReplay() : number {

        if (this.replayAnimationFrame !== -1 && this.replaying === true) {
            cancelAnimationFrame(this.replayAnimationFrame);
            this.replayAnimationFrame = -1;
            this.replaying = false;
            return this.iLastReplayAction;

        }
        return -1;

    }

    public takeSnapshot(snapWidth: number, snapHeight: number) {
        let page = this.getPage();
        if (page === undefined) {
            return undefined;
        }

        let sampX = 0;
        let sampY = 0;
        let sampWidth = page.cellsWidth;
        let sampHeight = page.cellsHeight;

        let snapRatio = snapWidth / snapHeight;
        let sampRatio = sampWidth / sampHeight;
        let hRatio = snapWidth / sampWidth;
        let vRatio = snapHeight / sampHeight;

        if (snapRatio > sampRatio) { // source is more thin, scale width using height ratio and center
            let hGap = snapWidth - (sampWidth * vRatio);
            hGap = hGap / vRatio;
            sampX = (-hGap)/2;
            sampWidth += Math.abs(hGap);
            
        } else {// source is more fat, scale height using width ratio and center
            let vGap = snapHeight - (sampHeight * hRatio);
            vGap = vGap / hRatio;
            sampY = (-vGap)/2;
            sampHeight += Math.abs(vGap);
        }

        let snapCvs = document.createElement('canvas');
        snapCvs.width = snapWidth;
        snapCvs.height = snapHeight;
        let snapCrc = snapCvs.getContext("2d")!;
        snapCrc.drawImage(page.crcVisible.canvas, sampX, sampY, sampWidth, sampHeight, 0, 0, snapWidth, snapHeight);
        let img = snapCvs.toDataURL('image/jpeg');
        return img;
    }

    iLastReplayAction = 0;

    public replayRecorded(seconds: number, recorded: Array<AnimationAction>, onFinish?: ()=>void, noClear?:boolean) {

        if (this.replaying === true) {
            return;
        }

// let debugSkipDry = 0;
// for (let i = recorded.length - 1; i >= 0; i--) {
//     let act = recorded[i];
//     if (act.kind === AnimationActionKind.dry || act.kind === AnimationActionKind.paintRadius || act.kind === AnimationActionKind.color) {
//         debugSkipDry ++;
//     } else {
//         break;
//     }
// }
// if (debugSkipDry > 0) {
//     recorded = recorded.slice(0, recorded.length - debugSkipDry);
// }
// console.profile();
        this.replaying = true;
        const controlThis = this;

        let cAct = recorded.length;

        // start out by cleaning the main page in the context
        const page = this.getPage()!;
        this.rnd = new SeededRandom(); // same sequence as original

        // replace the page with a white, empty one
        if (noClear === undefined || noClear === false) {
            page.clear(this.props.OnClear, this.props.CentralPull, this.rnd, this.props.TextureSmash);
        }

        if (cAct > 0) {
            
            this.iLastReplayAction = 0;
            let drawActs = recorded.filter((aa) => aa.kind === AnimationActionKind.drag || aa.kind === AnimationActionKind.start).length;
            let expectedFrames = seconds * 60;
            let frameSkipIncrement = 0;
            if (expectedFrames > drawActs) {
                frameSkipIncrement = (expectedFrames - drawActs) / drawActs;
            }
            let actPerFrame = Math.max(1, Math.ceil(drawActs / expectedFrames));

            let colorCur = '';
            let paintRadCur = 0;
            let frameSkipAccumulate = 0;
            let tipKindCur = 'paint';

            const animateSet = ()=> {
                if (controlThis.replayAnimationFrame === -1) {
                    return;
                }

                let skip=false;

                if (frameSkipAccumulate >= 1) {
                    frameSkipAccumulate -= 1;
                    skip = true;
                } else { 
                    frameSkipAccumulate += frameSkipIncrement;
                }

                let iFrame = 0;
                while(skip === false && controlThis.iLastReplayAction < cAct && iFrame < actPerFrame) {

                    let act = recorded![controlThis.iLastReplayAction];
                    switch(act.kind) {
                        case AnimationActionKind.color:
                            colorCur = (act as AnimationActionColor).color;
                            tipKindCur = Page.ColorToTipKind(new tinycolor(colorCur).toRgb());
                            break;
                        case AnimationActionKind.paintRadius:
                            paintRadCur = (act as AnimationActionPaintRadius).rad;
                            break;
                        case AnimationActionKind.finish:
                            controlThis.finishStroke();
                            iFrame ++;
                            break;
                        case AnimationActionKind.start:
                            const startAct = (act as AnimationActionStart);
                            let ptStart = new Point2D(startAct.pt.x + controlThis.replayOriginX, startAct.pt.y + controlThis.replayOriginY);
                            controlThis.startStroke(ptStart, colorCur, startAct.rad, paintRadCur, tipKindCur);
                            iFrame ++;
                            break;
                        case AnimationActionKind.drag:
                            const dragAct = (act as AnimationActionDrag);
                            let ptDrag = new Point2D(dragAct.pt.x + controlThis.replayOriginX, dragAct.pt.y + controlThis.replayOriginY);
                            controlThis.continueStroke(ptDrag, dragAct.rad, paintRadCur, tipKindCur);
                            iFrame ++;
                            break;
                        case AnimationActionKind.dry:
                            const dryAct = (act as AnimationActionDry);
                            let count = 1;
                            if (dryAct.count !== undefined) {
                                count = dryAct.count;
                            }
                            while(count > 0) {
                                count--;
                                page.dry(controlThis.props.DryRate);
                            }
                            break;
                        case AnimationActionKind.stamp:
                            controlThis.applyStamp(act as AnimationActionStamp, new Point2D(controlThis.replayOriginX, controlThis.replayOriginY));
                            iFrame++;
                            break;
                        }

                    controlThis.iLastReplayAction++;
                }
                if (controlThis.iLastReplayAction < cAct) {
                    controlThis.replayAnimationFrame = requestAnimationFrame(()=>{
                        animateSet();
                    })
                } else {
//console.profileEnd();
                    controlThis.replaying = false;
                    if (onFinish !== undefined) {
                        onFinish();
                    }
                }

            };

            this.replayAnimationFrame = requestAnimationFrame(()=>{
                animateSet();
            });

        } else {
            this.replaying = true;

        }

    }

    public stampImage(img: HTMLImageElement, offX: number, offY: number, width: number, height: number, scaleWidth: number, scaleHeight: number, rotation: number, depth: number) {
        let pageWidth = this.page!.cellsWidth;
        let pageHeight = this.page!.cellsHeight;
        let crcSamp = Page.getOffscreenCrc(pageWidth, pageHeight);
        let stampWidth = width*scaleWidth;
        let stampHeight = height*scaleHeight
        crcSamp.translate(offX, offY);
        crcSamp.translate(stampWidth/2, stampHeight/2);
        crcSamp.rotate(rotation);
        crcSamp.translate(-stampWidth/2, -stampHeight/2);
        crcSamp.drawImage(img, 0, 0, width, height, 0, 0, stampWidth, stampHeight);
        crcSamp.translate(-offX, -offY);

        let imdat = crcSamp.getImageData(0, 0, pageWidth, pageHeight);

        const stampLines = new Array<AnimationActionStampLine>();
        const aaStamp : AnimationActionStamp = {kind:AnimationActionKind.stamp, rad: depth, lines: stampLines, x1:1000000, x2:0, y1:1000000, y2:0};

        for(let ySamp = 0; ySamp < imdat.height; ySamp++) {
            let stampLine: AnimationActionStampLine | undefined = undefined;
            for(let xSamp = 0; xSamp < imdat.width; xSamp++) {
                let iSampBase = xSamp*4 + ySamp * imdat.width * 4;
                let r = imdat.data[iSampBase];
                let g = imdat.data[iSampBase+1];
                let b = imdat.data[iSampBase+2];
                let a = imdat.data[iSampBase+3];
                if (a===0) {
                    continue;
                }
                if (stampLine === undefined) {
                    stampLine = {pt: new Point2D(xSamp, ySamp), colors: new Array<tinycolor.ColorFormats.RGB>()};
                    stampLines.push(stampLine);
                }
                aaStamp.x1 = Math.min(aaStamp.x1, xSamp);
                aaStamp.x2 = Math.max(aaStamp.x2, xSamp);
                aaStamp.y1 = Math.min(aaStamp.y1, ySamp);
                aaStamp.y2 = Math.max(aaStamp.y2, ySamp);
                stampLine.colors.push({r:r, g:g, b:b});
            }
        };
        if (this.props.OnRecordAnimation !== undefined) {
            this.props.OnRecordAnimation(aaStamp);
        }
        this.applyStamp(aaStamp);

    }
    

    // ######                                     
    // #     # ###### #    # #####  ###### #####  
    // #     # #      ##   # #    # #      #    # 
    // ######  #####  # #  # #    # #####  #    # 
    // #   #   #      #  # # #    # #      #####  
    // #    #  #      #   ## #    # #      #   #  
    // #     # ###### #    # #####  ###### #    # 
    //doProfile = true;

    private putEffectColors(effect: TipEffect, ptImageOnPage: Point2D, id: ImageData) {
        for (const c of effect.cells) {
            let color = this.page!.visibleColorAtOffset(c);


            let ptPage = this.page!.coordinatesOfOffset(c);
            let ptImage = new Point2D(ptPage.x - ptImageOnPage.x, ptPage.y - ptImageOnPage.y)
            let offImg = 4 * ((ptImage.y * id.width) + ptImage.x);
            id.data[offImg] = color.r;
            id.data[offImg+1] = color.g;
            id.data[offImg+2] = color.b;
            id.data[offImg+3] = 255;
        }

    }
    private createAntiAliasOutline(skipMoveOct: number, effect: TipEffect, ptImageOnPage: Point2D, id: ImageData) {

        const filledPoints = new Set<number>(effect.cells);
        const page = this.page!;
        const aaPoints = new Map<number, AntiAliasPoint>();

        const markPoint = (ptPage: Point2D, dx: number, dy: number, doThis: (aap: AntiAliasPoint)=>void) => {
            let pt = new Point2D(ptPage.x + dx, ptPage.y + dy);
            let o = page.offsetOfCoordinates(pt);
            if (!filledPoints.has(o)) {
                let aap = aaPoints.get(o);
                if (aap === undefined) {
                    aap = {filled0:-1, filled1:-1, filled2:-1, filled3:-1, filled5:-1, filled6:-1, filled7:-1, filled8:-1};
                    aaPoints.set(o, aap);
                }
                doThis(aap);
            }
        }

        for (const c of effect.cells) {
            let ptPage = page.coordinatesOfOffset(c);
            markPoint(ptPage, -1, -1, (aap) => {aap.filled8 = c});
            markPoint(ptPage, 0, -1, (aap) => {aap.filled7 = c});
            markPoint(ptPage, 1, -1, (aap) => {aap.filled6 = c});
            markPoint(ptPage, -1, 0, (aap) => {aap.filled5 = c});
            markPoint(ptPage, 1, 0, (aap) => {aap.filled3 = c});
            markPoint(ptPage, -1, 1, (aap) => {aap.filled2 = c});
            markPoint(ptPage, 0, 1, (aap) => {aap.filled1 = c});
            markPoint(ptPage, 1, 1, (aap) => {aap.filled0 = c});
        }
        if (skipMoveOct !== 4) {

        }

        const shouldAA = (n1: number, n2: number, n3: number) : boolean => {
            if (n1 != -1 && n2 != -1 && n3 != -1) {
                return (page.getWetDepthAtOffset(n1) > FingerTip.maxSmashScrapeDepth ||
                    page.getWetDepthAtOffset(n2) > FingerTip.maxSmashScrapeDepth ||
                    page.getWetDepthAtOffset(n3) > FingerTip.maxSmashScrapeDepth);
            }
            return false;

        }

        aaPoints.forEach((aa, o) => {
            let useColor: number| undefined;
            if (shouldAA(aa.filled3, aa.filled0, aa.filled1)) {
                useColor = aa.filled3;
            } else if (shouldAA(aa.filled1, aa.filled2, aa.filled5)) {
                useColor = aa.filled1;
            } else if (shouldAA(aa.filled5, aa.filled8, aa.filled7)) {
                useColor = aa.filled5;
            } else if (shouldAA(aa.filled7, aa.filled6, aa.filled3)) {
                useColor = aa.filled7;
            }

            if (useColor !== undefined) {
                let ptPageSrc = page.coordinatesOfOffset(useColor);
                let ptImageSrc = new Point2D(ptPageSrc.x - ptImageOnPage.x, ptPageSrc.y - ptImageOnPage.y)
                let offImgSrc = 4 * ((ptImageSrc.y * id.width) + ptImageSrc.x);
                let ptPageAA = page.coordinatesOfOffset(o);
                let ptImageAA = new Point2D(ptPageAA.x - ptImageOnPage.x, ptPageAA.y - ptImageOnPage.y)
                let offImgAA = 4 * ((ptImageAA.y * id.width) + ptImageAA.x);

                id.data[offImgAA] = id.data[offImgSrc];
                id.data[offImgAA+1] = id.data[offImgSrc+1];
                id.data[offImgAA+2] = id.data[offImgSrc+2];
                id.data[offImgAA+3] = 128;
            }
        });

    }

    private putAllAppliedDry(id: ImageData) {
        let tot = 0;
        for (let y = 0; y < id.height; y++) {
            for (let x = 0; x < id.width; x++) {
                let offImg = 4 * ((y * id.width) + x);
                let dry = this.page!.cellDryApplied![y * id.width + x];
                if (dry > 0) {
                    let vol = this.page!.getWetDepthAtOffset(y * id.width + x);
                    let a = 255;
                    if (vol === 0) {
                        a=128;
                    } else {
                        a = 255;
                    }
                    id.data[offImg+3] = a;
                }
            }
        }
    }

    private putAllDepth(id: ImageData) {
        let tot = 0;
        for (let y = 0; y < id.height; y++) {
            for (let x = 0; x < id.width; x++) {
                let offImg = 4 * ((y * id.width) + x);
                let depth = this.page!.getWetDepthAtOffset(y * id.width + x);

                let r = 0;
                let g = 0;
                let b = 0;
                if (depth === 0) {
                    r = 255;
                    g = 255;
                    b = 255;
                } else if (depth < FingerTip.minSmashScrapeDepth) {
                    r = 200;
                    g = 0;
                    b = 0;
                } else if (depth <= FingerTip.maxSmashScrapeDepth) {
                    r = 0;
                    g = 0;
                    b = 235;
                } else if (depth < FingerTip.preferredMeniscusScrapeDepth) {
                    r = 60;
                    g = 210;
                    b = 60;
                } else {
                    if (depth > 0) {
                        depth += 70
                    }
                    depth = Math.min(Page.maxCoronaDepth, depth);
                    let depthRat = depth / Page.maxCoronaDepth;
                    let depthRatInv = 1 - depthRat;
                    r = Math.floor(depthRatInv * 255);
                    g = Math.floor(depthRatInv * 255);
                    b = Math.floor(depthRatInv * 255);
                }

                tot += depth;
                id.data[offImg] = r;
                id.data[offImg+1] =g;
                id.data[offImg+2] = b;
                id.data[offImg+3] = 255;
            }
        }
    }
    
    public pokeColor(center: Point2D, color:string, radius: number, paintRadius: number) {
        this.startStroke(center, color, radius, paintRadius, 'paint');
    }

    lastPaintRadius = 0;

    paintRadiusToDryDepth(paintRadius: number) : number {
        paintRadius = Math.max(FingerTip.minFingerRadius, Math.min(FingerTip.maxFingerRadius, paintRadius));
        if (paintRadius === FingerTip.maxFingerRadius) {
            return Page.maxCoronaDepth * 5; // nuke it
        }
        let depthRatio = (paintRadius - FingerTip.minFingerRadius) / (FingerTip.maxFingerRadius - FingerTip.minFingerRadius);
        return Math.floor((FingerTip.maxSmashTouchDepth + depthRatio * Page.maxCoronaDepth)/10);
    }

    depthRender = false;
    private startStroke(center: Point2D, color:string, radius: number, paintRadius: number, tipKind: string) {
        //console.profile();
        this.page!.clearAppliedDry();
        this.page!.clearKnifeBladeCells();
        this.lastPaintRadius = paintRadius;

        center = new Point2D(Math.floor(center.x / Page.renderScale), Math.floor(center.y / Page.renderScale));
        radius = Math.floor(radius);

        this.lastPos = center;
        this.lastRadius = radius;
        this.lastDrawOctant = 4;
        this.lastMoveOctant = 4;
        this.lastLastMoveOctant = 4;

        // if in 'pick up color mode' then
        if (this.props.SampleMode === true) {
            // sample using the finger radius and take the average
            let cFilled = 0;
            let totalRed=0;
            let totalGreen=0;
            let totalBlue=0;
            FingerTip.doFilledCircle(radius, 0, (i: number, pt: Point2D)=>{
                const ptPage = this.page!.tipCoordinatesToPageCoordinates(pt, center);
                // and the offset into storage arrays
                const pageOffset = this.page!.offsetOfCoordinates(ptPage);
                // get volume and color info from the page, take kind and max from the tip
                const color = pageOffset === -1 ? {r: 1, g: 1, b: 1} : this.page!.visibleColorAtOffset(pageOffset);
                if (color.r * color.g * color.b !== 1) {
                    cFilled ++;
                    totalRed += color.r;
                    totalGreen += color.g;
                    totalBlue += color.b;
                }
            })
            if (cFilled > 0) {
                this.props.OnColorSample({r: Math.floor(totalRed / cFilled), g: Math.floor(totalGreen / cFilled), b: Math.floor(totalBlue / cFilled)});
            }
    
            return;
        }


        if (tipKind === 'knife') {
            // knife is a special case, it just cuts through everything. this joke brought to you by copilot
            return;
        }

        let f = FingerTip.getCenteredShape(Math.round(radius));
        if (tipKind === 'blow') {
            this.page!.blowTip(f, center, this.paintRadiusToDryDepth(this.lastPaintRadius));
            var id = this.page!.crcVisible.getImageData(Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(this.page!.crcVisible.canvas.width), Page.scaleUnitsToPix(this.page!.crcVisible.canvas.height));
            if (this.depthRender) {
                this.putAllDepth(id);
            } else {
                this.putAllAppliedDry(id);
            }
            this.page!.crcVisible.putImageData(id, Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0));

        } else {
            let effect = this.page!.applyTip(f, center, color, paintRadius);

            if (effect.isEmpty()) {
                return false;
            }
            
            let idDx = effect.rectBR.x - effect.rectTL.x + 4;
            let idDy = effect.rectBR.y - effect.rectTL.y + 4;
            let ptImageOnPage = new Point2D(effect.rectTL.x - 2, effect.rectTL.y - 2);
            
            let id: ImageData
            if (this.depthRender) {
                id = this.page!.crcVisible.getImageData(Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(this.page!.crcVisible.canvas.width), Page.scaleUnitsToPix(this.page!.crcVisible.canvas.height))
            } else {
                id = this.page!.crcVisible.createImageData(Page.scaleUnitsToPix(idDx), Page.scaleUnitsToPix(idDy));
            }

            if (this.depthRender) {
                this.putAllDepth(id);
                this.page!.crcVisible.putImageData(id, Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0));
            } else {
                this.putEffectColors(effect, ptImageOnPage, id);
                this.createAntiAliasOutline(4, effect, ptImageOnPage, id);
                createImageBitmap(id).then((ibm)=>{
                    this.page!.crcVisible.drawImage(ibm, Page.scaleUnitsToPix(ptImageOnPage.x), Page.scaleUnitsToPix(ptImageOnPage.y));
                });
            }

        }

    };

    private continueStroke(center: Point2D, radius: number, paintRadius: number, tipKind: string) : boolean {

        if (this.props.SampleMode === true) {
            // just a single touch in 'pick up the color' mode is needed
            return false;
        }
        
        const page = this.getPage()!;

        let ptStart = this.lastPos;
        // don't draw if really close to last point
        if (center.taxiDistance(ptStart) > 4)
        {
            // one step of smoothing, if the direction changes wait a move to commit to it
            this.lastMoveOctant = center.moveOctantFrom(ptStart);
            if (this.lastLastMoveOctant !== 4 && this.lastLastMoveOctant !== this.lastMoveOctant) {
                this.lastLastMoveOctant = this.lastMoveOctant
                return false;
            }
            this.lastLastMoveOctant = this.lastMoveOctant;

            ptStart = new Point2D(Math.floor(ptStart.x / Page.renderScale), Math.floor(ptStart.y / Page.renderScale));
            let ptEnd = new Point2D(Math.floor(center.x / Page.renderScale), Math.floor(center.y / Page.renderScale));

            if (tipKind === 'blow') {
                let effect = this.page!.dragBlowTip(ptStart, ptEnd, this.lastRadius, radius, this.paintRadiusToDryDepth(this.lastPaintRadius), this.lastDrawOctant);

                this.lastPos = center;
                this.lastRadius = radius;
                this.lastDrawOctant = effect[1];

                var id = page!.crcVisible.getImageData(Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(page!.crcVisible.canvas.width), Page.scaleUnitsToPix(page!.crcVisible.canvas.height));
                if (this.depthRender) {
                    this.putAllDepth(id);
                } else {
                    this.putAllAppliedDry(id);
                }
                page!.crcVisible.putImageData(id, Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0));

            } else {

                let effect = this.page!.dragTip(ptStart, ptEnd, this.lastRadius, radius, this.lastDrawOctant, paintRadius, tipKind);
                if (effect.isEmpty()) {
                    return false;
                }

                // make an imagedata large enough to work in. two extra on edge for anti aliasing
                let idDx = effect.rectBR.x - effect.rectTL.x + 4;
                let idDy = effect.rectBR.y - effect.rectTL.y + 4;
                let ptImageOnPage = new Point2D(effect.rectTL.x - 2, effect.rectTL.y - 2);
                
                let id: ImageData
                if (this.depthRender) {
                    id = page.crcVisible.getImageData(Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(page.crcVisible.canvas.width), Page.scaleUnitsToPix(page.crcVisible.canvas.height))
                } else {
                    //id = page.crcVisible.getImageData(ptImageOnPage.x, ptImageOnPage.y, idDx, idDy)
                    id = page.crcVisible.createImageData(Page.scaleUnitsToPix(idDx), Page.scaleUnitsToPix(idDy));
                }
    
                if (this.depthRender) {
                    this.putAllDepth(id);
                    page.crcVisible.putImageData(id, Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0));
                    
                }
                else {
                    let firstDrawOct = effect.steps[0].octantMove;
                    this.putEffectColors(effect, ptImageOnPage, id);
                    this.createAntiAliasOutline(firstDrawOct, effect, ptImageOnPage, id);
                    createImageBitmap(id).then((ibm)=>{
                        page.crcVisible.drawImage(ibm, Page.scaleUnitsToPix(ptImageOnPage.x), Page.scaleUnitsToPix(ptImageOnPage.y));
                    });
                }
           
                this.lastPos = center;
                this.lastRadius = radius;
                this.lastDrawOctant = effect.steps[effect.steps.length-1].octantMove;
            }
            return true;
        }
        return false;

    };

    private finishStroke() : boolean {
        let result = false;
        if (this.page !== undefined) {
            let page = this.page!;
            let prevTipPlacement = this.page!.tipPlacement!;
            if (prevTipPlacement.isBlow) {
                result = true;
                page.clearAppliedDry();
                var id = page.crcVisible.getImageData(Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(page.crcVisible.canvas.width), Page.scaleUnitsToPix(page.crcVisible.canvas.height));
                for (let y = 0; y < id.height; y++) {
                    for (let x = 0; x < id.width; x++) {
                        let offImg = 4 * ((y * id.width) + x);
                        id.data[offImg+3] = 255;
                    }
                }
                page.crcVisible.putImageData(id, Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0));
            } else if (prevTipPlacement.isKnife) {
                result = true;
            } else {
                result = true;
                let center = new Point2D(Math.floor(this.lastPos.x / Page.renderScale), Math.floor(this.lastPos.y / Page.renderScale));

                let radius = this.lastRadius;
        
                let f = FingerTip.getCenteredShape(Math.round(radius));
                let effect = page.removeTip(f, center, prevTipPlacement.isSmudge ? 'smudge' : 'paint');
    
                if (effect.isEmpty()) {
                    return false;
                }
                
                let idDx = effect.rectBR.x - effect.rectTL.x + 4;
                let idDy = effect.rectBR.y - effect.rectTL.y + 4;
                let ptImageOnPage = new Point2D(effect.rectTL.x - 2, effect.rectTL.y - 2);
                
                let id: ImageData
                if (this.depthRender) {
                    id = page.crcVisible.getImageData(Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(page.crcVisible.canvas.width), Page.scaleUnitsToPix(page.crcVisible.canvas.height))
                } else {
                    id = page.crcVisible.createImageData(Page.scaleUnitsToPix(idDx), Page.scaleUnitsToPix(idDy));
                }
    
                if (this.depthRender) {
                    this.putAllDepth(id);
                    page.crcVisible.putImageData(id, Page.scaleUnitsToPix(0), Page.scaleUnitsToPix(0));
                } else {
                    this.putEffectColors(effect, ptImageOnPage, id);
                    this.createAntiAliasOutline(4, effect, ptImageOnPage, id);
                    createImageBitmap(id).then((ibm)=>{
                        page.crcVisible.drawImage(ibm, Page.scaleUnitsToPix(ptImageOnPage.x), Page.scaleUnitsToPix(ptImageOnPage.y));
                    });
                }
            }
        }
        //console.profileEnd();
        return result;

    }

    public applyStamp(aaStamp: AnimationActionStamp, ptOffset?: Point2D) {
        const page = this.getPage()!;
        if (page === undefined) {
            return;
        }

        let pageWidth = page.cellsWidth;
        let pageHeight = page.cellsHeight;

        let depth = aaStamp.rad;

        let idDx = aaStamp.x2 - aaStamp.x1 + 4;
        let idDy = aaStamp.y2 - aaStamp.y1 + 4;
        let ptImageOnPage = new Point2D(aaStamp.x1 - 2, aaStamp.y1 - 2);
        if (ptOffset !== undefined) {
            ptImageOnPage = new Point2D(ptImageOnPage.x + ptOffset.x, ptImageOnPage.y + ptOffset.y)
        }
        let id = page.crcVisible.getImageData(Page.scaleUnitsToPix(ptImageOnPage.x), Page.scaleUnitsToPix(ptImageOnPage.y), Page.scaleUnitsToPix(idDx), Page.scaleUnitsToPix(idDy))

        const pageColors = page.cellData;
        const bufStamp = new Uint8Array(3);

        for (const line of aaStamp.lines) {
            let pageX = line.pt.x;
            let pageY = line.pt.y;
            if (pageY >= pageHeight) {
                continue;
            }
            if (pageX >= pageWidth) {
                continue;
            }

            let lineWidth = line.colors.length;
            if (lineWidth + pageX > pageWidth) {
                lineWidth = pageWidth - pageX;
            }
            if (lineWidth === 0) {
                continue;
            }

            for (let iclr = 0; iclr < lineWidth; iclr++) {
                bufStamp[0] = line.colors[iclr].r;
                bufStamp[1] = line.colors[iclr].g;
                bufStamp[2] = line.colors[iclr].b;
                const ptPage = new Point2D(pageX + iclr, pageY);
                let c = page.offsetOfCoordinates(ptPage);
                let offPage = 11 * c;

                // if there is wet paint already on the page, mix the colors
                let wetDepth = c === -1 ? 0 : page.getWetDepthAtOffset(c);
                if (wetDepth > 0) {
                    // the weight is about the paint volume. stamp mixing onto page
                    let wStamp = depth / (depth + wetDepth);
                    colorMixKM(pageColors, offPage + Page.offWetRed, bufStamp, 0, wStamp, pageColors, offPage + Page.offWetRed);
                    wetDepth += depth;
                } else {
                    // no wet paint, just transfer
                    pageColors[offPage+Page.offWetRed] = bufStamp[0];
                    pageColors[offPage+Page.offWetGreen] = bufStamp[1];
                    pageColors[offPage+Page.offWetBlue] = bufStamp[2];
                    wetDepth = depth;
                }
        
                if (c !== -1) {

                    // blend with the dry paint
                    if (wetDepth <= FingerTip.preferredMeniscusScrapeDepth) {
                        // scale between depth and 4* depth so thicker paint blocks out effect of blending much more
                        let scaledDepth = wetDepth;
                        if (scaledDepth > FingerTip.preferredSmashScrapeDepth) {
                            scaledDepth = 4 * FingerTip.preferredMeniscusScrapeDepth * ((scaledDepth-FingerTip.preferredSmashScrapeDepth)/(FingerTip.preferredMeniscusScrapeDepth - FingerTip.preferredSmashScrapeDepth));
                        }
                        let depthRat = Page.backgroundImplicitDepth / (scaledDepth + Page.backgroundImplicitDepth);
                        // visible = mix of wet and dried
                        colorMixKM(pageColors, offPage + Page.offWetRed, pageColors, offPage + Page.offDryRed, depthRat, pageColors, offPage + Page.offVisRed);
                    } else {
                        // visible = wet
                        pageColors[offPage + Page.offVisRed] = pageColors[offPage + Page.offWetRed];
                        pageColors[offPage + Page.offVisGreen] = pageColors[offPage + Page.offWetGreen];
                        pageColors[offPage + Page.offVisBlue] = pageColors[offPage + Page.offWetBlue];
                    }

                    page.setWetDepthAtOffset(c, wetDepth);
                    page.makeCanvasOffsetWet(c);

                    let ptImage = new Point2D(ptPage.x - ptImageOnPage.x, ptPage.y - ptImageOnPage.y)
                    let offImg = 4 * ((ptImage.y * id.width) + ptImage.x);

                    id.data[offImg] = pageColors[offPage + Page.offVisRed];
                    id.data[offImg+1] = pageColors[offPage + Page.offVisGreen];
                    id.data[offImg+2] = pageColors[offPage + Page.offVisBlue];
                    id.data[offImg+3] = 255;

                }
            }
        }

        page.crcVisible.putImageData(id, Page.scaleUnitsToPix(ptImageOnPage.x), Page.scaleUnitsToPix(ptImageOnPage.y));
        page.dry(0);
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`smear, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        if (this.state.loading === true) {
            return <></>;
        }

        const getPointerLocation = (evt:React.PointerEvent): Point2D => {
            if (!evt.currentTarget) {
                return new Point2D(0,0);
            }
            return  new Point2D(evt.clientX - controlThis.page!.clientRec.left, evt.clientY - controlThis.page!.clientRec.top);
        };
        
        
        const pointerMove = (evt:React.PointerEvent) => {
            if (!controlThis.dragging) {
                return;
            }
            let rad = (evt.width/2 + evt.height/2) / 2;
            if (rad === 0 && controlThis.props.FixedSize === 0) {
                return
            }

            this.queue.queueAction({isStart: false, isFinish:false, ptStart: getPointerLocation(evt), radius: FingerTip.normalizeTouchRadius(rad, this.props.FixedSize!==0, this.props.FixedSize, this.props.AvgLightRadius, this.props.AvgHeavyRadius), paintRadius: this.props.PaintRadius, color:this.props.Color});
        };
        
        const pointerDown = (evt:React.PointerEvent) => {

            if (controlThis.dragging || evt.button !== 0) {
                return;
            }
            if (controlThis.props.OnDragStart !== undefined) {
                if (controlThis.props.OnDragStart() === false) {
                    return;
                }
            }

            let rad = (evt.width/2 + evt.height/2) / 2;
            if (rad === 0 && controlThis.props.FixedSize === 0) {
                return
            }

            controlThis.dragging = true;
            controlThis.canvasRef.current!.setPointerCapture(evt.pointerId);

            this.queue.queueAction({isStart: true, isFinish:false, ptStart: getPointerLocation(evt), radius: FingerTip.normalizeTouchRadius(rad, this.props.FixedSize!==0, this.props.FixedSize, this.props.AvgLightRadius, this.props.AvgHeavyRadius), paintRadius: this.props.PaintRadius, color:this.props.Color});

            evt.preventDefault();
        };
        const pointerUp = (evt:React.PointerEvent) => {

            if (!controlThis.dragging) {
                return;
            }
            if (evt.button !== 0) {
                return;
            }

            controlThis.dragging = false;
            this.queue.queueAction({isStart: false, isFinish: true, ptStart: getPointerLocation(evt), radius: 0, paintRadius: this.props.PaintRadius, color:this.props.Color});
            controlThis.canvasRef.current!.releasePointerCapture(evt.pointerId);
            if (controlThis.props.OnDragEnd !== undefined) {
                controlThis.props.OnDragEnd();
            }
            evt.preventDefault();
        };
        
        return (
            <div style={{width:'100%', height:'100%'}}>
                <canvas ref={this.canvasRef} style= {{objectFit:'none', objectPosition: '0 0', backgroundColor:'white', height:'100%', width:'100%', touchAction:'none'}}
                onPointerDown={pointerDown}
                onPointerUp={pointerUp}
                onPointerMove={pointerMove}  
                ></canvas>
            </div>

        );
    }
}

export default Smear;