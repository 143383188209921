import React, { Component, useEffect } from 'react';
import { App } from '../App'
import IconButton, {CloseButton, IconRefresh} from './iconButton';
import FingerTip from './smash/fingertip';
import Toggle from 'react-toggle'
import { Link, createSearchParams } from 'react-router-dom';
import { Footer } from '../smesshy';
import Smessage from './smessage';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState, SmesshyNavigateOptions }  from '../smesshyCommon';
import { PaintingInfo, SmesshyUserPreferences } from '../storageManager';
import { UserPreferences } from 'typescript';
import { SmesshySubButton } from './smesshyButton';

export interface GamePageState extends SmesshyCommonState {
    trigger: number,
    login: boolean,
    authFinished: boolean,
}
export interface GamePageProps extends SmesshyCommonProps {
    ShowHeader : boolean;
    ShowRefresh: boolean;
    RequireAuth: boolean;
    OnPopulateAuthenticationState?: (authenticated: boolean)=>void;
    Title?: JSX.Element;
    CloseNav?: string | number | undefined;
    ShowFooter: boolean;
    Body: JSX.Element;
}

class GamePage extends SmesshyCommon(Component<GamePageProps, GamePageState>) {

    mainRef : React.RefObject<HTMLDivElement>;

    constructor(props: GamePageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.mainRef = React.createRef();

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            doRefresh: false,
            trigger:0,
            login:false,
            authFinished: false,
        };
    }

    resizeListener :(((ev: UIEvent)=>void) | undefined) = undefined;
    componentDidMount() {
        STrace.addStep('gamePage', 'didMound', '');

        let controlThis = this;
        if (this.props.RequireAuth === true || this.props.OnPopulateAuthenticationState !== undefined) {
            this.pushWaitingFrame(this);
            this.executeAsync(async ()=> {
                STrace.addStep('gamePage', 'initAuth', '');
                await controlThis.initAuthentication(controlThis);
                controlThis.popWaitingFrame();
            });
        } 

        this.executeWhenSafe(()=>{
            this.checkVersion();
        });
    }
    componentWillUnmount(): void {
        if (this.props.RequireAuth === true || this.props.OnPopulateAuthenticationState !== undefined) {
            this.termAuthentication();
        }
    }
    componentDidUpdate(prevProps: Readonly<GamePageProps>, prevState: Readonly<GamePageState>, snapshot?: any): void {
        let controlThis = this;
        if (this.props.RequireAuth === true || this.props.OnPopulateAuthenticationState !== undefined) {
            if (!(prevProps.RequireAuth === true || prevProps.OnPopulateAuthenticationState !== undefined)) {
                this.executeAsync(async ()=> {
                    STrace.addStep('gamePage', 'initAuth', '');
                    await this.initAuthentication(controlThis);
                });
            }
        } else {
            if ((prevProps.RequireAuth === true || prevProps.OnPopulateAuthenticationState !== undefined)) {
                this.termAuthentication();
            }
        }
    }

    async onPopulateAuthenticationState(authenticated: boolean) {

        this.setState({authFinished:true});

        if (authenticated) {
            if (this.props.OnPopulateAuthenticationState !== undefined) {
                await this.props.OnPopulateAuthenticationState(authenticated);
            }
        } else {
            if (this.props.RequireAuth === true) {
                this.setState({login:true});
            } else {
                if (this.props.OnPopulateAuthenticationState !== undefined) {
                    await this.props.OnPopulateAuthenticationState(authenticated);
                }
            }
        }

    }

    render() {
        let controlThis = this;

        let login = <div className='game-page-mid smesshy-main text-white h-padding-small v-padding-small' >
            <div className='text-xlarge text-line-small text-boldish'>
                You will need to sign in or create a Smesshy account to use this page.
            </div>
            <SmesshySubButton AppObject={this._app!}
                Display={<span className='h-padding-large'>Take me to 'Settings' to 'Sign In'</span>}
                Haptic = {this.doButtonHaptic()}
                StartPushedState = {false}
                OnPressFinish={async (): Promise<boolean>=>{
                    controlThis.setState({ navigateTo: {To:{pathname:'/settings'}}});
                    return true;
                }}
            />
        </div>

        let checkingAuth = <div className='game-page-mid smesshy-main text-white h-padding-small v-padding-small' >
                                <div className='text-xlarge text-line-small text-boldish'>
                                    Looking up your account details...
                                </div>
                            </div>

        let authStateResult = <> </>;
        if (this.props.RequireAuth === true || this.props.OnPopulateAuthenticationState !== undefined) {
            if (this.state.authFinished === false) {
                authStateResult = checkingAuth;
            } else {
                if (this.state.login === true) {
                    authStateResult = login;
                } else {
                    authStateResult = this.props.Body;
                }
            }
        } else {
            authStateResult = this.props.Body;
        }

        return this.renderFramework(
            <div className='game-page-base'>
                {this.props.ShowHeader ? 
                    <div className='h-items width-100p smesshy-icon-bar' style={{padding:2}}>
                        <div className='width-100p h-padding-large text-large text-white'>
                            {this.props.Title}
                        </div>
                        <div className='h-items h-small-gap'>
                            {this.props.ShowRefresh === true ? <IconButton AppObject={this._app!} Tag='refresh' IconRef={IconRefresh(undefined)} PressTickInterval={0}
                                StartPushedState={false} Haptic = {this.doButtonHaptic()} OnPressStart={()=>{}} OnPressTick={()=>{}}
                                Height={this._app!.GetScaledPxHeight(31)}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    this.setState({doRefresh:true})
                                    return true;
                                }}/> : <></>}
                            {this.props.CloseNav === undefined ? ' ' : <CloseButton AppObject={this._app!} navTo={this.props.CloseNav}  Haptic={this.doButtonHaptic()} Height={this._app!.GetScaledPxHeight(31)}/>}
                        </div> 
                    </div> :
                    <></>
                }
                {authStateResult}
                {this.props.ShowFooter ? Footer : <></>}
            </div>, this.state);
    }
}

export default GamePage;