import React from 'react'
import { Component } from 'react';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import Smessage from '../smessage';
import { App } from '../../App';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../../smesshyCommon';
import { AuthenticationResultStatus } from './AuthorizeService';


export interface LogoutState extends SmesshyCommonState {
  message: string | undefined | null;
}
export interface LogoutProps extends SmesshyCommonProps {
  action: string;
}

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.

class Logout extends SmesshyCommon(Component<LogoutProps, LogoutState>) {

  constructor(props: LogoutProps) {
      super(props);
      this.initCommon(props.AppObject);

      this.state = {
        message: undefined,
        showWaitSpin: false,
        authenticated: false
      };
  
  }

  componentDidMount() {
    STrace.addStep('logout', 'didMound', '');
    const action = this.props.action;
    const controlThis = this;
    this.executeAsync(async () => {
      controlThis.pushWaitingFrame(controlThis);
      try {
        switch (action) {
          case LogoutActions.Logout:
            //if (!!window.history.state.usr.local) {
            STrace.addStep('logout', 'logout', '');
            await controlThis.logout(controlThis.getReturnUrl(null));
            //} else {
              // This prevents regular links to <app>/authentication/logout from triggering a logout
              //this.setState({ isReady: true, message: "The logout was not initiated from within the page." });
            //}
            break;
          case LogoutActions.LogoutCallback:
            STrace.addStep('logout', 'processLogoutCallback', '');
            await controlThis.processLogoutCallback();
            break;
          case LogoutActions.LoggedOut:
            STrace.addStep('logout', 'logged out', '');
            controlThis.setState({ message: "You successfully logged out!" });
            break;
          default:
            throw new Error(`Invalid action '${action}'`);
        }
        controlThis.popWaitingFrame();

      } catch (e: any) {
        controlThis.props.AppObject.reportException(`Login action ${action}`, 'ex', '', e)
      }

      controlThis.populateAuthenticationState(controlThis);
    });
  }

  render() {
    let controlThis = this;

    let { showWaitSpin: loading, message } = this.state;
    const action = this.props.action;

    if (loading) {
      return <div></div>
    }

    if (message === undefined || message === null) {
      switch (action) {
        case LogoutActions.Logout:
          message = 'Processing logout';
          break;
        case LogoutActions.LogoutCallback:
          message = 'Processing logout callback';
          break;
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }
    return <Smessage
            AppObject={this._app!}
            AppShape={this._appShape}
            Title='Sign Out'
            CloseNav='/'
            Loading={true}
            Say={message}
          />
  }

  async logout(returnUrl: string) {
    const state = { returnUrl };
    const isauthenticated = await this.isAuthenticated();
    if (isauthenticated) {
      const result = await this.signOut(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          STrace.addStep('logout', 'navigateToReturnUrl', '');
          await this.navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          this.setState({ message: result.message });
          break;
        default:
          throw new Error("Invalid authentication result status.");
      }
    } else {
      this.setState({ message: "You successfully logged out!" });
    }
  }

  async processLogoutCallback() {
    const url = window.location.href;
    STrace.addStep('logout', 'completeSignOut', '');
    const result = await this.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        STrace.addStep('logout', 'navigateToReturnUrl', '');
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.setState({ message: result.message });
        break;
      default:
        throw new Error("Invalid authentication result status.");
    }
  }

  async onPopulateAuthenticationState(authenticate: boolean) {
    STrace.addStep('logout', 'populateAuth', authenticate.toString());
  }

  getReturnUrl(state: any) {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
    }
    return (state && state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}${ApplicationPaths.LoggedOut}`;
  }

  navigateToReturnUrl(returnUrl: string | URL) {
    return window.location.replace(returnUrl);
  }
}

export default Logout;
