import React, { Component, useEffect } from 'react';
import { SmesshyPushy, SmesshyPushyProps, SmesshyPushyState } from '../smesshyCommon';
import { JsxElement } from 'typescript';
export interface SmesshyMainButtonState extends SmesshyPushyState{
    loading: boolean;

}
export interface SmesshyMainButtonProps extends SmesshyPushyProps{
    Display: JSX.Element
}

export class SmesshyMainButton extends SmesshyPushy(Component<SmesshyMainButtonProps, SmesshyMainButtonState>) {

    constructor(props: SmesshyMainButtonProps) {
        super(props);

        this.state = {
            loading: true,
            currentPushedState: props.StartPushedState
        };
    }

    componentDidMount() {

        let controlThis = this;
        this.setState({ loading:false});
    }

    componentDidUpdate(prevProps: Readonly<SmesshyMainButtonProps>, prevState: Readonly<SmesshyMainButtonState>): void {
        if (prevProps.StartPushedState !== this.props.StartPushedState) {
            this.setState({currentPushedState: this.props.StartPushedState})
        }
    }

    componentWillUnmount() {
        this.abandonPress();
    }


    render() {
        let controlThis = this;
        let targetClass = `${this.props.Disabled ? '' : ''}`;
        targetClass += ' text-medium text-white smesshy-main-button smesshy-main-panel h-items group-center each-cross-center large-gap';
        if (this.state.currentPushedState ) {
            targetClass += ' smesshy-main-button-pushed';
        } else {
            targetClass += ' smesshy-main-button-popped';
        }
        if (this.props.ExtraStyleClass !== undefined) {
            targetClass += ` ${this.props.ExtraStyleClass}`;
        }
        if (this.props.AllowVScroll) {
            targetClass += ' smesshy-touch-pan-y';
        }

        return (
            <div ref = {this.targetRef} 
            
                className={targetClass} 
                onClick={(e:React.MouseEvent)=> controlThis.onClick(e, this.props)}
                onContextMenu={(e:React.MouseEvent)=> controlThis.onContext(e, this.props)}
                onPointerDown={(e:React.PointerEvent) => {controlThis.startPress(e, this.props)}}
                onPointerUp={(e:React.PointerEvent) => {controlThis.finishPress(e, this.props)} }
                onPointerMove={(e:React.PointerEvent) => controlThis.pointerMove(e, this.props)}
                onPointerCancel={(e:React.PointerEvent) => controlThis.cancelPress(e, this.props)}
                >
                {this.props.Display}
            </div>);
    }
}

export interface SmesshySubButtonState extends SmesshyPushyState{
    loading: boolean;

}
export interface SmesshySubButtonProps extends SmesshyPushyProps{
    Display: JSX.Element
}

export class SmesshySubButton extends SmesshyPushy(Component<SmesshySubButtonProps, SmesshySubButtonState>) {

    constructor(props: SmesshySubButtonProps) {
        super(props);

        this.state = {
            loading: true,
            currentPushedState: props.StartPushedState
        };
    }

    componentDidMount() {

        let controlThis = this;
        this.setState({ loading:false});
    }

    componentDidUpdate(prevProps: Readonly<SmesshySubButtonProps>, prevState: Readonly<SmesshySubButtonState>): void {
        if (prevProps.StartPushedState !== this.props.StartPushedState) {
            this.setState({currentPushedState: this.props.StartPushedState})
        }
    }

    componentWillUnmount() {
        this.abandonPress();
    }

    
    render() {
        let controlThis = this;
        let targetClass = `${this.props.Disabled ? 'smesshy-sub-button-disabled' : ''}`;
        targetClass += ' text-white text-medium smesshy-sub-button';
        if (this.state.currentPushedState ) {
            targetClass += ' smesshy-sub-button-pushed';
        } else {
            targetClass += ' smesshy-sub-button-popped';
        }
        if (this.props.ExtraStyleClass !== undefined) {
            targetClass += ` ${this.props.ExtraStyleClass}`;
        }
        if (this.props.AllowVScroll) {
            targetClass += ' smesshy-touch-pan-y';
        }

        return (
            <div ref = {this.targetRef} 
                className={targetClass} 
                onClick={(e:React.MouseEvent)=> controlThis.onClick(e, this.props)}
                onContextMenu={(e:React.MouseEvent)=> controlThis.onContext(e, this.props)}
                onPointerDown={(e:React.PointerEvent) => {controlThis.startPress(e, this.props)}}
                onPointerUp={(e:React.PointerEvent) => {controlThis.finishPress(e, this.props)} }
                onPointerMove={(e:React.PointerEvent) => controlThis.pointerMove(e, this.props)}
                onPointerCancel={(e:React.PointerEvent) => controlThis.cancelPress(e, this.props)}
                >
                {this.props.Display}
            </div>);
    }
}
