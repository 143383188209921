import { Profile, User, UserManager, WebStorageStateStore } from 'oidc-client';
import { ApplicationPaths, ApplicationName, QueryParameterNames } from './ApiAuthorizationConstants';
import {App} from '../../App';
import { Navigate } from 'react-router-dom';
import { promises } from 'dns';
import { STrace } from '../../smesshyCommon';

export interface AuthorizeServiceResult {
  status?: string;
  message?: any;
  state?: any;
}

export interface UserProfileValues {
  userName?: string;
  picture?: string;
}


type AuthorizeServiceSubscription = {callback: ()=>void, subscription: number};

export class AuthorizeService {
  __instId: string
  _callbacks: Array<AuthorizeServiceSubscription>= [];
  _nextSubscriptionId = 0;
  _user: User | null = null;
  _isAuthenticated = false;
  _appObject : App;

  userManager: UserManager | undefined;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  constructor(appObject: App) {
    this.__instId = "1"
    this._isAuthenticated = false;
    this._appObject = appObject;
  }

  async isAuthenticated() {
    const user = await this.getUser();
    return !!(user?.profile);
  }

  async getUser() : Promise<User | null> {
    if (this._user && this._user.profile) {
      return this._user;
    }

    try {
      const user = await this.userManager!.getUser();
      return user;
    } catch (e: any) {
      this._appObject.reportException(`Authentication - getUser`, 'ex', '', e);
      return null;
    }
  }

  async getUserId() : Promise<string | null> {
    if (this._user && this._user.profile) {
      return this._user.profile.sub;
    }

    try {
      const user = await this.userManager!.getUser();
      if (user !== null && user !== undefined && user.profile) {
        return user.profile.sub;
      }
    } catch (e: any) {
      this._appObject.reportException(`Authentication - getUserId`, 'ex', '', e);
    }
    return null;
  }

  async getAccessToken(forceNew?: boolean) : Promise<string | undefined> {
    try {
      STrace.addStep('auth', 'ensureUserManager', '');
      await this.ensureUserManagerInitialized();

      if (this.getTokenRequestState() != 'ready') {
        return undefined;
      }
  
      this.setTokenRequestState('getuser');
  
      const user = await this.userManager!.getUser();

      if (user === null) {
        this.setTokenRequestState('ready');
        return undefined;
      }

      let expSeconds = user!.expires_in;
      if (expSeconds < 20 || forceNew) {
        const state = { returnUrl:'/' };
        this.setTokenRequestState('renew');
        STrace.addStep('auth', 'signIn', 'expired');
        const result = await this.signIn(state);

        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            this.setTokenRequestState('redirect');
            return undefined;
          case AuthenticationResultStatus.Success:
            break;
          case AuthenticationResultStatus.Fail:
            this.setTokenRequestState('ready');
            throw new Error(result.message.message);
        }
      }

      this.setTokenRequestState('ready');
      if (user === null) {
        return undefined;
      }
  
      return user.access_token;
  
    } catch (e: any) {
      this.setTokenRequestState('ready');
      this._appObject.reportException(`Authentication - getAccessToken`, 'ex', '', e);
    }
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state: any) {
    STrace.addStep('auth', 'ensureUserManager', '');
    await this.ensureUserManagerInitialized();
    
    try {
      const justSignedOut = sessionStorage.getItem('smesshy-sign-out') === 'true';
      if (justSignedOut) {
        sessionStorage.removeItem('smesshy-sign-out');
        //this._appObject.SetCookie('idsrv', null);
        
      }

      STrace.addStep('auth', 'silentSignIn', '');
      const silentUser = await this.userManager!.signinSilent(this.createArguments(undefined));
      this.updateState(silentUser);
      return this.success(state);
    } catch (silentError) {
      try {
        let args = this.createArguments(state);
        STrace.addStep('auth', 'redirectSignIn', '');
        await this.userManager!.signinRedirect(args);
        return this.redirect();
      } catch (redirectError: any) {
        console.log("Redirect authentication error: ", redirectError);
        throw new Error(`There was a redirect authentication error signing in: ${redirectError.message}`);
      }
    }
  }

  async completeSignIn(url: string) {
    try {
      STrace.addStep('auth', 'ensureUserManager', '');
      await this.ensureUserManagerInitialized();
      STrace.addStep('auth', 'signInCallback', '');
      const user = await this.userManager!.signinCallback(url);
      //this.setTokenRequestState('ready');
      this.updateState(user);
      return this.success(user && user.state);
    } catch (error: any) {
      await error;
      console.log('There was an error signing in: ', error);
      throw new Error(`There was an error signing in: ${error.message}`);
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state: any) : Promise<AuthorizeServiceResult> {
    STrace.addStep('auth', 'ensureUserManager', '');
    await this.ensureUserManagerInitialized();
    try {
      sessionStorage.setItem('smesshy-sign-out', 'true');
      STrace.addStep('auth', 'signOutRedirect', '');
      await this.userManager!.signoutRedirect(this.createArguments(state));
      return this.redirect();
    } catch (redirectSignOutError: any) {
      console.log("Redirect signout error: ", redirectSignOutError);
      throw new Error(`Redirect signout error: ${redirectSignOutError.message}`);
    }
  }

  async completeSignOut(url: string) {
    STrace.addStep('auth', 'ensureUserManager', '');
    await this.ensureUserManagerInitialized();
    try {
      STrace.addStep('auth', 'signOutCallback', '');
      const response = await this.userManager!.signoutCallback(url);
      this.updateState(null);
      return this.success(response && response.state);
    } catch (error: any) {
      console.log(`There was an error trying to log out '${error}'.`);
      throw new Error(`There was an error signing out: ${error.message}`);
    }
  }

updateState(user: User | null) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    var s = this.getTokenRequestState()

    if (s === 'ready') {
      this.notifySubscribers();
    }
  }

  subscribe(callback: ()=>void ) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this._callbacks
      .map((element:AuthorizeServiceSubscription, index:number) => element.subscription === subscriptionId ? { found: true, index } : { found: false, index:-1 })
      .filter(element => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  createArguments(state:any | undefined) : any {
    return { useReplaceToNavigate: true, data: state };
  }

  error(message: any) : AuthorizeServiceResult {
    return { status: AuthenticationResultStatus.Fail, message: message };
  }

  success(state: any | undefined) : AuthorizeServiceResult {
    return { status: AuthenticationResultStatus.Success, state: state };
  }

  redirect() : AuthorizeServiceResult {
    return { status: AuthenticationResultStatus.Redirect };
  }

  getTokenRequestState() : string {
    let state = sessionStorage.getItem('smesshy-token-request-state');
    if (state == null) {
      this.setTokenRequestState('ready');
      state = 'ready';
    }

    if (state !== 'ready') {
      let stateTimeStr = sessionStorage.getItem('smesshy-token-request-state-time')!;
      let stateTime = Number.parseInt(stateTimeStr);
      if (Date.now() - stateTime > (1000 * 20)) {
        // 20 seconds since we were ready? maybe send another request

        this.setTokenRequestState('ready');
        state = 'ready';
      }
    }

    return state;
  }

  setTokenRequestState(state: string) {
    sessionStorage.setItem('smesshy-token-request-state', state);
    sessionStorage.setItem('smesshy-token-request-state-time', Date.now().toString());
  }


  async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return;
    }

    let response: Response;
    try {
      response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl, {headers: {'smesshy-api-trace':STrace.getRecent()}});
    } catch(e: any) {
      throw new Error(`Could not contact authority '${ApplicationPaths.ApiAuthorizationClientConfigurationUrl}' it says: ${e.message}` );
    }

    if (!response.ok) {
      throw new Error(`Could not load settings for '${ApplicationName}'`);
    }

    let settings = await response.json();
    
    //settings.automaticSilentRenew = true;
    //settings.includeIdTokenInSilentRenew = true;
    const st = new WebStorageStateStore({prefix: 'smesshyAuth'});

    const reloaded = sessionStorage.getItem('smesshy-reload') === 'true';
    sessionStorage.setItem('smesshy-reload', 'true');
    if (!reloaded) {
      // navigate to page in new session. so pull the user info from the store and expire it
      const ids = await st.getAllKeys();
      for (const id of ids) {
        let strUser = await st.get(id);
        let objUser = JSON.parse(strUser);
        const profile = objUser.profile;
        if (profile && profile.auth_time) {
          objUser.expires_at = profile.auth_time;
          strUser = JSON.stringify(objUser);
          await st.set(id, strUser);
        }
      }
    }

    settings.userStore = st;
    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager!.removeUser();
      this.updateState(null);
    });
  }

}

export default AuthorizeService;

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail'
};


