import React, { Component } from 'react';
import { Footer } from '../smesshy';
import { CloseButton } from './iconButton';
import { start } from 'repl';
import { SmesshyDiagnosticLog } from '../storageManager';
import {App} from '../App';
import { SmesshyCommon, SmesshyCommonProps } from '../smesshyCommon';

export interface ImageLocateState {
    mode: string;
    img: HTMLImageElement | undefined;
    imgWidth: number;
    imgHeight: number;
    offsetX: number;
    offsetY: number;
    scaleWidth: number;
    scaleHeight: number;
    rotation: number;
    sx: number;
    sy: number;
    sw: number;
    sh: number;
    dx: number;
    dy: number;
    dw: number;
    dh: number;
}
export interface ImageLocateProps extends SmesshyCommonProps {
    Url: string;
    Bounds: DOMRect;
    OnPlacement: (img: HTMLImageElement, offX: number, offY: number, width: number, height: number, scaleWidth: number, scaleHeight: number, rotation: number)=>void;
    
}


class ImageLocate extends SmesshyCommon(Component<ImageLocateProps, ImageLocateState>) {

    diag = new SmesshyDiagnosticLog();
    pointerEventAction: string | undefined = undefined;

    startOffsetX: number = 0;    
    startOffsetY: number = 0;
    startScaleWidth: number = 0;
    startScaleHeight: number = 0;
    startRotation: number = 0;
    primPointerId: number | undefined = undefined;
    startPrimX: number = 0;
    startPrimY: number = 0;
    secPointerId: number | undefined = undefined;
    startSecX: number = 0;
    startSecY: number = 0;

    lastOffsetX : number = 0;
    lastOffsetY : number = 0;
    lastScaleWidth : number = 0;
    lastScaleHeight : number = 0;
    lastRotation : number = 0;
    lastPrimX : number = 0;
    lastPrimY : number = 0;
    lastSecX : number = 0;
    lastSecY : number = 0;



    constructor(props: ImageLocateProps) {
        super(props);

        this.state = {
            mode: 'load',
            img: undefined,
            sx: 0,
            sy: 0,
            sw: 0,
            sh: 0,
            dx: 0,
            dy: 0,
            dw: 0,
            dh: 0,
            imgWidth: 0,
            imgHeight: 0,
            offsetX: 0,
            offsetY: 0,
            scaleWidth: 0,
            scaleHeight: 0,
            rotation: 0
        };
    }

    componentDidMount(): void {
        const controlThis = this;
        let width = this.props.Bounds.width;
        let height = this.props.Bounds.height;
        let img = new Image();
        img.src = this.props.Url;
        img.onload = (ev)=>{
            let sampDx = img.width;
            let sampDy = img.height;
            let visDx = width;
            let visDy = height;
            var hRatio = visDx / sampDx;
            var vRatio = visDy / sampDy;
            var ratio  = Math.min ( hRatio, vRatio );
            if (ratio < 1){
                if (hRatio < vRatio) {
                    visDy = sampDy * ratio;
                } else {
                    visDx = sampDx * ratio;
                }
            } else {
                ratio = 1;
                visDy = sampDy
                visDx = sampDx;
            }
            ratio /= 1;
            this.lastScaleWidth = ratio;
            this.lastScaleHeight = ratio;
            this.startScaleHeight = ratio;
            this.startScaleWidth = ratio;
        
            controlThis.setState({mode: 'wait', img: img, sx:0, sy:0, sw: sampDx, sh: sampDy, dx: 0, dy: 0, dw: visDx, dh: visDy,
                                imgWidth: sampDx,
                                imgHeight: sampDy,
                                offsetX: 0,
                                offsetY: 0,
                                scaleWidth: ratio,
                                scaleHeight: ratio,
                                rotation: 0})

        }
        
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`image locate, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        const controlThis = this;

        const boundLeft = this.props.Bounds.left;
        const boundTop = this.props.Bounds.top;
        const boundWidth = this.props.Bounds.width;
        const boundHeight = this.props.Bounds.height;

        const imgLeft = this.state.offsetX + boundLeft;
        const imgTop = this.state.offsetY + boundTop;
        const imgWidth = this.state.imgWidth * this.state.scaleWidth;
        const imgHeight = this.state.imgHeight * this.state.scaleHeight;

        const handleRadius = 5;
        const rotateCharm = 20;
        const rotateCharmOff = 10;

        const handleLeft = imgLeft - handleRadius; 
        const handleWidth = imgWidth + 2 * handleRadius; 
        const handleTop = imgTop - rotateCharm - rotateCharmOff; 
        const handleHeight = imgHeight + rotateCharm + rotateCharmOff + handleRadius;

        let pointerDown = (evt: React.PointerEvent, action: string)=> {

            let pointer = evt.pointerId;

            if (this.primPointerId === undefined) {
                // single pointer down
                // probably not needed, but commit any pending changes
                controlThis.startOffsetX = controlThis.lastOffsetX;
                controlThis.startOffsetY = controlThis.lastOffsetY;
                controlThis.startScaleWidth = controlThis.lastScaleWidth;
                controlThis.startScaleHeight = controlThis.lastScaleHeight;
                controlThis.startRotation = controlThis.lastRotation;

                this.primPointerId = pointer;
                controlThis.startPrimX = controlThis.lastPrimX = evt.clientX;
                controlThis.startPrimY = controlThis.lastPrimY = evt.clientY;
                evt.currentTarget.setPointerCapture(pointer);
                //controlThis.diag.log(`down p1:${pointer} action:${action} x:${evt.clientX} y:${evt.clientY}`);
    
            } else {
                if (this.secPointerId === undefined && action === 'move') {

                    // starting two pointer scale and rotate
                    // commit any pending changes
                    controlThis.startOffsetX = controlThis.lastOffsetX;
                    controlThis.startOffsetY = controlThis.lastOffsetY;
                    controlThis.startScaleWidth = controlThis.lastScaleWidth;
                    controlThis.startScaleHeight = controlThis.lastScaleHeight;
                    controlThis.startRotation = controlThis.lastRotation;
                    // pretend like pointer1 came down also
                    controlThis.startPrimX = controlThis.lastPrimX;
                    controlThis.startPrimY = controlThis.lastPrimY;
                    // start pointer 2
                    this.secPointerId = pointer;
                    controlThis.startSecX = controlThis.lastSecX = evt.clientX;
                    controlThis.startSecY = controlThis.lastSecY = evt.clientY;
                    evt.currentTarget.setPointerCapture(pointer);
                    //controlThis.diag.log(`down p2:${pointer} action:${action} x:${evt.clientX} y:${evt.clientY}`);
                }
                else 
                {
                    //controlThis.diag.log(`down other action:${action} x:${evt.clientX} y:${evt.clientY}`);
                }
            }

            controlThis.pointerEventAction = action;
            evt.stopPropagation();
            evt.preventDefault();
          
        }


        let pointerMove = (evt: React.PointerEvent, action: string)=> {

            if (!(evt.pointerId === controlThis.primPointerId || evt.pointerId === controlThis.secPointerId)){
                return;
            }
            
            let dxPrim = 0;
            let dyPrim = 0;
            let dxSec = 0;
            let dySec = 0;

            if (controlThis.primPointerId === evt.pointerId) {
                controlThis.lastPrimX = evt.clientX;
                controlThis.lastPrimY = evt.clientY;
                dxPrim = controlThis.lastPrimX - controlThis.startPrimX;
                dyPrim = controlThis.lastPrimY - controlThis.startPrimY;
                //controlThis.diag.log(`move p1:${evt.pointerId} action:${action} x:${evt.clientX} y:${evt.clientY}`);
            } else if (controlThis.secPointerId === evt.pointerId) {
                controlThis.lastSecX = evt.clientX;
                controlThis.lastSecY = evt.clientY;
                dxSec = controlThis.lastSecX - controlThis.startSecX;
                dySec = controlThis.lastSecY - controlThis.startSecY;
                //controlThis.diag.log(`move p2:${evt.pointerId} action:${action} x:${evt.clientX} y:${evt.clientY}`);
            } else {
                return;
            }

            let dw = dxPrim;
            let dh = dyPrim;

            let setOffsetX= false;
            let setOffsetY= false;
            let setScaleX= false;
            let setScaleY= false;
            let followScaleY= false;
            let setRotation= false;
            let twoPointAdjust = false;
            
            switch (action) {
                case 'move':
                    if (controlThis.primPointerId !== undefined && controlThis.secPointerId !== undefined) {
                        twoPointAdjust = true;
                    } else {
                        setOffsetX = true;
                        setOffsetY = true;
                    }
                    break;
                case 'rotate':
                    setRotation=true;
                    break;
                case 'size-nw':
                    dh = -dh;
                    dw = -dw;
                    setScaleX=true;
                    followScaleY = true;
                    setOffsetX = true;
                    setOffsetY = true;
                    break;
                case 'size-n':
                    dh = -dh;
                    setScaleY=true;
                    setOffsetY = true;
                    break;
                case 'size-ne':
                    dh = -dh;
                    setScaleX=true;
                    followScaleY = true;
                    setOffsetY = true;
                    break;
                case 'size-w':
                    dw = -dw;
                    setScaleX=true;
                    setOffsetX = true;
                    break;
                case 'size-e':
                    setScaleX=true;
                    break;
                case 'size-sw':
                    dw = -dw;
                    setScaleX=true;
                    followScaleY = true;
                    setOffsetX = true;
                    break;
                case 'size-s':
                    setScaleY=true;
                    break;
                case 'size-se':
                    setScaleX=true;
                    followScaleY = true;
                    break;

            }

            let newRotation = controlThis.lastRotation;
            let newOffsetX = controlThis.lastOffsetX;
            let newOffsetY = controlThis.lastOffsetY;
            let newScaleWidth = controlThis.lastScaleWidth;
            let newScaleHeight = controlThis.lastScaleHeight;
            let alterScaleWidth = 1;
            let alterScaleHeight = 1;
            
            if (setOffsetX) {
                newOffsetX =  controlThis.startOffsetX + dxPrim;
            }
            if (setOffsetY) {
                newOffsetY =  controlThis.startOffsetY + dyPrim;
            }
            if (setScaleX) {
                let widthStart = controlThis.state.imgWidth * controlThis.startScaleWidth;
                let widthNow = widthStart + dw;
                if (widthStart !== widthNow && widthNow > 0) {
                    alterScaleWidth = widthNow / widthStart;
                    newScaleWidth = controlThis.startScaleWidth * alterScaleWidth;
                }
            }
            if (setScaleY) {
                let heightStart = controlThis.state.imgHeight * controlThis.startScaleHeight;
                let heightNow = heightStart + dh;
                if (heightStart !== heightNow && heightNow > 0) {
                    alterScaleHeight = heightNow / heightStart;
                    newScaleHeight = controlThis.startScaleHeight * alterScaleHeight;
                }
            }

            if (followScaleY) {
                newScaleHeight = controlThis.startScaleHeight * alterScaleWidth;
            }

            if (setRotation) {
                if (dxPrim !==0 || dyPrim !== 0) {
                    let cX = this.startOffsetX + (this.state.imgWidth * this.startScaleWidth)/2;
                    let cY = this.startOffsetY + (this.state.imgHeight * this.startScaleHeight)/2;
                    let aStart = Math.atan2(((controlThis.startPrimY!)-cY), ((controlThis.startPrimX!)-cX));
                    let aNow = Math.atan2(((controlThis.startPrimY! + dyPrim)-cY), ((controlThis.startPrimX!+dxPrim)-cX));
                    let rotationThis = aNow-aStart;
                    newRotation = controlThis.startRotation + rotationThis;
                }

            }
            if (twoPointAdjust) {

                let dxStart = controlThis.startSecX! - controlThis.startPrimX!;
                let dyStart = controlThis.startSecY! - controlThis.startPrimY!;

                let dxLast = controlThis.lastSecX! - controlThis.lastPrimX!;
                let dyLast = controlThis.lastSecY! - controlThis.lastPrimY!;

                let startD = Math.sqrt((dxStart*dxStart)+(dyStart*dyStart));
                let lastD = Math.sqrt((dxLast*dxLast)+(dyLast*dyLast));
                let alterScale = lastD / startD;
                newScaleHeight = controlThis.startScaleHeight * alterScale;
                newScaleWidth = controlThis.startScaleWidth * alterScale;
                let widthStart = controlThis.startScaleWidth * controlThis.state.imgWidth;
                let heightStart = controlThis.startScaleHeight * controlThis.state.imgHeight;
                let widthLast = newScaleWidth * controlThis.state.imgWidth;
                let heightLast = newScaleHeight * controlThis.state.imgHeight;
                let dx = (widthLast - widthStart)/2;
                let dy = (heightLast - heightStart)/2;
                newOffsetX = controlThis.startOffsetX - dx;
                newOffsetY = controlThis.startOffsetY - dy;

                let aStart = Math.atan2(dyStart, dxStart);
                let aNow = Math.atan2(dyLast, dxLast);
                let rotationThis = aNow-aStart;
                newRotation = controlThis.startRotation + rotationThis;
   
            }

            let widthNew = newScaleWidth * controlThis.state.imgWidth;
            let heighNew = newScaleHeight * controlThis.state.imgHeight;
            if (newOffsetX + handleRadius >= this.props.Bounds.width || newOffsetX + widthNew - handleRadius <= 0 || newOffsetY + handleRadius >= this.props.Bounds.height || newOffsetY + heighNew - handleRadius <= 0) {
                return;
            }

            controlThis.lastRotation = newRotation;
            controlThis.lastOffsetX = newOffsetX;
            controlThis.lastOffsetY = newOffsetY;
            controlThis.lastScaleWidth = newScaleWidth;
            controlThis.lastScaleHeight = newScaleHeight;


            //controlThis.diag.log(`two pt:${twoPointAdjust} lastOffsetX:${newOffsetX}, lastOffsetY:${newOffsetY}, lastScaleWidth:${newScaleWidth}, lastScaleHeight:${newScaleHeight}, lastRotation:${newRotation}`);

            controlThis.setState({offsetX:newOffsetX, offsetY: newOffsetY, scaleWidth: newScaleWidth, scaleHeight: newScaleHeight, rotation: newRotation});
            evt.stopPropagation();
            evt.preventDefault();
            
        }
        let pointerUp = (evt: React.PointerEvent, action: string)=> {

            if (!(evt.pointerId === controlThis.primPointerId || evt.pointerId === controlThis.secPointerId)){
                return;
            }

            if (controlThis.primPointerId === evt.pointerId) {
                evt.currentTarget.releasePointerCapture(evt.pointerId);
                if (controlThis.secPointerId !== undefined ){
                    // was two finger and first finger lifted while second still down.
                    // commit two finger 
                    controlThis.startOffsetX = controlThis.lastOffsetX;
                    controlThis.startOffsetY = controlThis.lastOffsetY;
                    controlThis.startScaleWidth = controlThis.lastScaleWidth;
                    controlThis.startScaleHeight = controlThis.lastScaleHeight;
                    controlThis.startRotation = controlThis.lastRotation;
                    // to simplify other logic, make the second finger the primary and pretend like it just landed
                    controlThis.primPointerId = controlThis.secPointerId;
                    controlThis.secPointerId = undefined;
                    controlThis.startPrimX = controlThis.lastSecX;
                    controlThis.startPrimY = controlThis.lastSecY;
    
                    //controlThis.diag.log(`up p1 swap:${evt.pointerId} to ${controlThis.primPointerId} startPrimX:${this.state.startSecX}, startPrimY:${this.state.startSecY} action:${action} x:${evt.clientX} y:${evt.clientY}`);

                } else {
                    // first finger was used alone and just lifted
                    // commit one finger changes
                    controlThis.startOffsetX = controlThis.lastOffsetX;
                    controlThis.startOffsetY = controlThis.lastOffsetY;
                    controlThis.startScaleWidth = controlThis.lastScaleWidth;
                    controlThis.startScaleHeight = controlThis.lastScaleHeight;
                    controlThis.startRotation = controlThis.lastRotation;
                    controlThis.primPointerId = undefined;
                    //controlThis.diag.log(`up p1 alone:${evt.pointerId} action:${action} x:${evt.clientX} y:${evt.clientY}`);
                }
            } else if (controlThis.secPointerId === evt.pointerId) {
                // was two finger but second finger lifted up while first still down
                evt.currentTarget.releasePointerCapture(evt.pointerId);
                controlThis.secPointerId = undefined;
                // commit two finger changes
                controlThis.startOffsetX = controlThis.lastOffsetX;
                controlThis.startOffsetY = controlThis.lastOffsetY;
                controlThis.startScaleWidth = controlThis.lastScaleWidth;
                controlThis.startScaleHeight = controlThis.lastScaleHeight;
                controlThis.startRotation = controlThis.lastRotation;
                // pointer 1 in charge. 
                // pretend like it just also came down on current location
                controlThis.startPrimX = controlThis.lastPrimX;
                controlThis.startPrimY = controlThis.lastPrimY;
                //controlThis.diag.log(`up p2 :${evt.pointerId} action:${action} x:${evt.clientX} y:${evt.clientY}`);
            }
            evt.stopPropagation();
            evt.preventDefault();
        }
        let noClick = (evt:React.MouseEvent)=> {
            evt.stopPropagation();
        }


        if (this.state.mode==='load') {
            return <></>;
        } else if (this.state.mode==='wait') {

   
            return (<div className='width-100p height-100p' style={{position:'absolute', zIndex:20}} onClick={(e)=>{
                        controlThis.props.OnPlacement(controlThis.state.img!, controlThis.state.offsetX, controlThis.state.offsetY, controlThis.state.imgWidth, controlThis.state.imgHeight, controlThis.state.scaleWidth, controlThis.state.scaleHeight, controlThis.state.rotation);
                        //controlThis.storageManager.postDiagnostic(controlThis.diag);
                    }}>
                <div style={{position:'absolute', left:boundLeft, top: boundTop, width:boundWidth, height:boundHeight, overflow:'hidden'}}>
                    <div style={{position:'absolute', left:imgLeft-boundLeft, top: imgTop-boundTop, width:imgWidth, height:imgHeight,  transform:`rotate(${this.state.rotation}rad)`}}>
                        <img draggable='false' src = {this.state.img!.src} style={{ width:imgWidth, height:imgHeight, opacity:.85, cursor:'move', touchAction:'none'}} onPointerDown={(e)=>pointerDown(e, 'move')}  onPointerMove={(e)=>pointerMove(e, 'move')}  onPointerUp={(e)=>pointerUp(e, 'move')} onClick={(e)=>noClick(e)}/>
                    </div>
                </div>
                    <div className = 'v-items' style={{position:'absolute', left:handleLeft, top: handleTop, width:handleWidth, height:handleHeight, pointerEvents:'none', transform:`rotate(${this.state.rotation}rad)`, transformOrigin:`50% ${rotateCharm + rotateCharmOff + imgHeight/2}px`}}>
                        <div className='self-cross-center' style={{width:rotateCharm, height:rotateCharm, marginBottom: rotateCharmOff, pointerEvents:'initial', cursor:'grab', touchAction:'none' }} onPointerDown={(e)=>pointerDown(e, 'rotate')}  onPointerMove={(e)=>pointerMove(e, 'rotate')}  onPointerUp={(e)=>pointerUp(e, 'rotate')} onClick={(e)=>noClick(e)}>
                            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <g transform="matrix(1, 0, 0, 1, -2.5, -2.5)">
                            <path d="M 11.597 4.995 C 8.151 4.995 5.358 7.929 5.358 11.547 C 5.358 15.166 8.151 18.099 11.597 18.099 C 15.042 18.099 17.836 15.166 17.836 11.547 C 17.836 11.219 17.831 10.896 17.787 10.579 C 17.73 10.183 17.989 9.8 18.371 9.8 C 18.679 9.8 18.95 10.024 18.996 10.344 C 19.054 10.737 19.084 11.138 19.084 11.547 C 19.084 15.89 15.732 19.409 11.597 19.409 C 7.462 19.409 4.11 15.89 4.11 11.547 C 4.11 7.205 7.462 3.685 11.597 3.685 C 13.514 3.685 15.264 4.442 16.588 5.687 L 16.588 4.777 C 16.588 4.415 16.868 4.122 17.212 4.122 C 17.556 4.122 17.836 4.415 17.836 4.777 L 17.836 7.398 C 17.836 7.759 17.556 8.053 17.212 8.053 L 14.716 8.053 C 14.372 8.053 14.092 7.759 14.092 7.398 C 14.092 7.036 14.372 6.743 14.716 6.743 L 15.839 6.743 C 14.726 5.658 13.235 4.995 11.597 4.995 Z"
                            style={{strokeMiterlimit: 4.29, strokeWidth: 1.85, paintOrder: 'stroke', stroke: 'rgb(112, 173, 71)'}}></path></g></svg>
                        </div> 
                        <div style={{marginLeft:handleRadius, marginRight:handleRadius, borderWidth:1, borderStyle:'solid', width: imgWidth, height:imgHeight}} />
                        <div className = 'v-items each-space-between' style={{position:'absolute', top: imgTop-handleTop-handleRadius, width:handleWidth, height:imgHeight+handleRadius*2}}>
                            <div className = 'h-items each-space-between'>
                                <div className='resize-handle' style={{cursor:'se-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-nw')}  onPointerMove={(e)=>pointerMove(e, 'size-nw')}  onPointerUp={(e)=>pointerUp(e, 'size-nw')} onClick={(e)=>noClick(e)}/>
                                <div className='resize-handle' style={{cursor:'ns-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-n')}  onPointerMove={(e)=>pointerMove(e, 'size-n')}  onPointerUp={(e)=>pointerUp(e, 'size-n')} onClick={(e)=>noClick(e)}/>
                                <div className='resize-handle' style={{cursor:'sw-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-ne')}  onPointerMove={(e)=>pointerMove(e, 'size-ne')}  onPointerUp={(e)=>pointerUp(e, 'size-ne')} onClick={(e)=>noClick(e)}/>
                            </div>
                            <div className = 'h-items each-space-between'>
                                <div className='resize-handle' style={{cursor:'ew-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-w')}  onPointerMove={(e)=>pointerMove(e, 'size-w')}  onPointerUp={(e)=>pointerUp(e, 'size-w')} onClick={(e)=>noClick(e)}/>
                                <div className='resize-handle' style={{cursor:'ew-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-e')}  onPointerMove={(e)=>pointerMove(e, 'size-e')}  onPointerUp={(e)=>pointerUp(e, 'size-e')} onClick={(e)=>noClick(e)}/>
                            </div>
                            <div className = 'h-items each-space-between'>
                                <div className='resize-handle' style={{cursor:'sw-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-sw')}  onPointerMove={(e)=>pointerMove(e, 'size-sw')}  onPointerUp={(e)=>pointerUp(e, 'size-sw')} onClick={(e)=>noClick(e)}/>
                                <div className='resize-handle' style={{cursor:'ns-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-s')}  onPointerMove={(e)=>pointerMove(e, 'size-s')}  onPointerUp={(e)=>pointerUp(e, 'size-s')} onClick={(e)=>noClick(e)}/>
                                <div className='resize-handle' style={{cursor:'se-resize'}} onPointerDown={(e)=>pointerDown(e, 'size-se')}  onPointerMove={(e)=>pointerMove(e, 'size-se')}  onPointerUp={(e)=>pointerUp(e, 'size-se')} onClick={(e)=>noClick(e)}/>
                            </div>
                        </div>
                    </div>
                </div>);
        } else if (this.state.mode==='change') {

        }
                   
    }
}

export default ImageLocate;
