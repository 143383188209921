import React, { Component } from 'react';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';


export interface PrivacyPageState extends SmesshyCommonState {
    content: string;
}
export interface PrivacyPageProps extends SmesshyCommonProps {
}

class PrivacyPage extends SmesshyCommon(Component<PrivacyPageProps, PrivacyPageState>) {

    constructor(props: PrivacyPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            content:'fetching it ...'
        }
    }

    componentDidMount() {
        this.pushWaitingFrame(this);
        STrace.addStep('privacy', 'didMound', '');
        let controlThis = this;
        this.executeAsync(async ()=> {
            try {
                const resp = await fetch('account/getPrivacyPolicy', {headers: {'Content-Type':'text/html', 'smesshy-api-trace':STrace.getRecent()}});
                const content = await (resp.text());
                controlThis.setState({content: content});
            } catch (reason) {
                controlThis._app!.reportException('get Privacy', 'ex', '', reason);
            }
            controlThis.popWaitingFrame();
        });

    }

    render() {
        let controlThis = this;

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={false}
                RequireAuth={false}
                Title = {<span>Smesshy - Privacy Policy</span>}
                CloseNav = {-1}
                Body={
                    <div className='game-page-mid'>
                        <div className='v-scroll-container'>
                            <div className='v-scroll-region'>
                                <div className='smesshy-main  text-white h-padding-small' dangerouslySetInnerHTML={{__html:this.state.content}}>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />, this.state);
    }
}

export default PrivacyPage;