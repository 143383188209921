import React, { Component } from 'react';
import { App } from '../App'
import IconButton, {IconBookmark, IconBookmarked, IconLike, IconLikeRed, IconFinger, IconRefFactory, IconSelected, IconSelect, IconTrash, IconOffendedFilled, IconOffended, IconSuspectYes, IconSuspect, VoteButton } from './iconButton';
import { User } from 'oidc-client';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import { MakePaintingLocate, PaintingInfo } from '../storageManager';
import { Navigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { FollowingTile } from './followingPage';


export interface PaintingFrameState extends SmesshyCommonState {
    user: User | null;
    bookmark: boolean;
    favorite: boolean;
    favoriteCount: number;
    offensive: boolean;
    offensiveCount: number;
    suspect: boolean;
    suspectCount: number;
    selected: boolean;
    expandedView: boolean;
    deleted: boolean;
}
export interface PaintingFrameProps extends SmesshyCommonProps {
    PaintingInfo: PaintingInfo;
    UserId: string | null;
    SaveEdit: boolean;
    ShowChallenge: boolean;
    ShowLunchbox: boolean;
    VoteMode: boolean;
    NoArtist: boolean;
    SelectionMode: boolean;
    Callback?: string;
    CurrentSelection?: string;
    VoteSelection?: number;   
    OnVoteSelection?: (idx: number)=>boolean;
}

class PaintingFrame extends SmesshyCommon(Component<PaintingFrameProps, PaintingFrameState>) {

    rectRef = React.createRef() as React.RefObject<HTMLDivElement>;
    constructor(props: PaintingFrameProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            user: null,
            deleted: false,
            bookmark: props.PaintingInfo.userBookmark!,
            favorite: props.PaintingInfo.userFavorite!,
            favoriteCount: (props.PaintingInfo.totalFavorites === undefined) ? 0 : props.PaintingInfo.totalFavorites!,
            offensive: props.PaintingInfo.userOffended!,
            offensiveCount: (props.PaintingInfo.totalOffended === undefined) ? 0 : props.PaintingInfo.totalOffended!,
            expandedView: false,
            suspect: props.PaintingInfo.userSuspect!,
            suspectCount: (props.PaintingInfo.totalSuspect === undefined) ? 0 : props.PaintingInfo.totalSuspect!,
            selected: props.PaintingInfo.paintingId === props.CurrentSelection,
        };
    }

    componentDidUpdate(prevProps: Readonly<PaintingFrameProps>, prevState: Readonly<PaintingFrameState>): void {

    }

    componentWillUnmount() {
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`paintingFrame, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const onOtherNavigate = async ():Promise<boolean> => {
            const otherId = controlThis.props.PaintingInfo.artistProfile.artistId!;
            STrace.addStep('frame', 'other nav', otherId);
            const otherName = controlThis.props.PaintingInfo.artistProfile.artistName!;
            const params = createSearchParams({title: otherName, scope: 'others', filter:otherId});
            controlThis.setState({navigateTo: {To:`/other-gallery?${params.toString()}`}});
            return true;
        }

        const userIsArtist = this.props.UserId !== null && this.props.UserId === this.props.PaintingInfo.artistProfile.artistId;
        const doHaptic = this.doButtonHaptic();

        const bestTop = () => {
            let top = controlThis._app!.GetScaledPxHeight(80);
            if (controlThis.rectRef.current !== undefined) {
                let maxTop = controlThis._app!.GetScaledPxHeight(window.innerHeight - 160);
                let minTop = Math.max(0, Math.min(top, maxTop));
                let r = controlThis.rectRef.current!.getBoundingClientRect();
                top = Math.min(maxTop, Math.max(controlThis._app!.GetScaledPxHeight(r.bottom - 200), minTop));
            }
            return top;
        }

        let iconsUsed = 0;
        let showLunchbox = false;
        let showLike = false;
        let showBookmark = false;
        let showOffend = false;
        let showSuspect = false;
        let showEdit = false;
        let showSelect = false;
        let showDelete = false;

        let iconSizeName = 'large';
        let iconSvgSize = 31;

        if (this.props.VoteMode) {
            showLike = true;
            showBookmark = true;
            showOffend = true;
            showSuspect = true;
            showEdit = true;
            iconSizeName = 'medium';
            iconSvgSize = 25;
        } else if (this.props.SelectionMode){
            showSelect = true;
        } else {
            showLunchbox = this.props.ShowLunchbox && this.props.PaintingInfo.lunchboxViewScore !== undefined && this.props.PaintingInfo.lunchboxViewScore > 0;
            if (showLunchbox) {
                iconsUsed+= 1.33;
            }
    
            showLike = true;
            iconsUsed ++;

            if (this.state.favoriteCount > 0) {
                iconsUsed += .33
            }
            if (this.state.favoriteCount > 9) {
                iconsUsed += .33
            }

            showBookmark = true;
            iconsUsed ++;

            if (this.state.offensiveCount > 0) {
                iconsUsed += .33
            }
            if (this.state.offensiveCount > 9) {
                iconsUsed += .33
            }

            if (this.state.offensiveCount > 0 || !userIsArtist) {
                showOffend = true;
                iconsUsed ++;
            }
            
            showDelete = userIsArtist;
            if (showDelete) {
                iconsUsed++;
            }
    
            showEdit = true;
            iconsUsed ++;

            if (iconsUsed > 4.5) {
                iconSizeName = 'medium';
                iconSvgSize = 25;
                if (iconsUsed > 5.5) {
                    iconSizeName = 'small';
                    iconSvgSize = 19;
                }
            }
        }


    

        let lunchbox = (showLunchbox) ?
            <svg viewBox='0 0 256 256' className='width-100p height-100p' xmlns='http://www.w3.org/2000/svg' style={{width:controlThis._app!.GetScaledPxWidth(iconSvgSize+6), height:controlThis._app!.GetScaledPxHeight(iconSvgSize+3)}}>
                <rect x='0' y='0' width='256' height='256' fill='rgb(240, 240, 240)' stroke='' rx='12' ry='12'></rect>
                <rect x='18' y='67' width={219 * this.props.PaintingInfo.lunchboxViewScore!} height='175' fill='var(--smesshy-green)' strokeWidth='14px' strokeLinejoin='round' rx='19.918' ry='19.918'></rect>
                <rect x='11' y='67' width='233' height='175' fill='none' stroke='rgb(255, 0, 0)' strokeWidth='14px' strokeLinejoin='round' rx='19.918' ry='19.918'></rect>
                <rect x='110' y='52' width='32' height='8' fill='rgb(188, 188, 188)' strokeLinejoin='round' rx='2.173' ry='2.173'></rect>
                <rect x='32' y='54' width='16' height='6' fill='rgb(188, 188, 188)' strokeLinejoin='round' rx='2.173' ry='2.173'></rect>
                <rect x='205' y='54' width='16' height='6' fill='rgb(188, 188, 188)' strokeLinejoin='round' rx='2.173' ry='2.173'></rect>
                <path d='M 89.713 45.155 C 84.488 45.155 80.043 43.486 78.396 41.155 L 54.713 41.155 C 51.399 41.155 48.713 38.469 48.713 35.155 C 48.713 31.841 51.399 29.155 54.713 29.155 L 206.713 29.155 C 210.027 29.155 212.713 31.841 212.713 35.155 C 212.713 38.469 210.027 41.155 206.713 41.155 L 183.03 41.155 C 181.383 43.486 176.938 45.155 171.713 45.155 C 167.373 45.155 163.57 44.003 161.463 42.277 C 159.356 44.003 155.553 45.155 151.213 45.155 C 146.873 45.155 143.07 44.003 140.963 42.277 C 138.856 44.003 135.053 45.155 130.713 45.155 C 126.373 45.155 122.57 44.003 120.463 42.277 C 118.356 44.003 114.553 45.155 110.213 45.155 C 105.873 45.155 102.07 44.003 99.963 42.277 C 97.856 44.003 94.053 45.155 89.713 45.155 Z M 53.713 35.155 C 53.713 36.812 55.497 38.155 57.697 38.155 L 73.729 38.155 C 75.929 38.155 77.713 36.812 77.713 35.155 C 77.713 33.498 75.929 32.155 73.729 32.155 L 57.697 32.155 C 55.497 32.155 53.713 33.498 53.713 35.155 Z M 184.713 35.155 C 184.713 36.812 186.497 38.155 188.697 38.155 L 204.729 38.155 C 206.929 38.155 208.713 36.812 208.713 35.155 C 208.713 33.498 206.929 32.155 204.729 32.155 L 188.697 32.155 C 186.497 32.155 184.713 33.498 184.713 35.155 Z' fill='rgb(255, 54, 54)' transform='matrix(0.992546, -0.121869, 0.121869, 1.4, 0, 0)'></path>
                <line fill='rgb(216, 216, 216)' strokeWidth='4px' stroke='rgb(121, 121, 121)' strokeLinecap='round' x1='40' y1='56' x2='72' y2='44'></line>
                <line fill='rgb(216, 216, 216)' strokeWidth='4px' stroke='rgb(121, 121, 121)' strokeLinecap='round' x1='204' y1='24' x2='212' y2='56'></line>
            </svg>
            : <></>;    
        let editButton = <IconButton AppObject={this._app!} 
            Tag='edit'
            IconRef={IconFinger(undefined)}
            PressTickInterval={0}
                OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                let reqNav = await controlThis.requireSetupAsync(async () => {
                    STrace.addStep('frame', 'playback', '');
                    controlThis.pushWaitingFrame(controlThis);
                    let token = (await controlThis.getAccessToken())!;
                    STrace.addStep('frame', 'getPaintingActions', this.props.PaintingInfo.paintingId!);
                    controlThis.storageManager!.getPaintingActions(token, this.props.PaintingInfo, (pin=>{
                        localStorage.setItem(controlThis.props.SaveEdit ? 'paintingActionsSelf' : 'paintingActionsOther', pin.imageDetail!.actions);
                        let interactionMode = controlThis.props.SaveEdit ? 'edit' : 'watch';
                        controlThis.setState({navigateTo: {To: {pathname:'/painting', search:`?${createSearchParams({mode: interactionMode})}`}}});
                    }));
                    controlThis.popWaitingFrame(); 
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={false}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            Size={iconSizeName}
            />;

        let likeButton = this.state.favorite ? <IconButton AppObject={this._app!} 
            Tag='likered'
            IconRef={IconLikeRed(undefined)}
            PressTickInterval={0}
            OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                let reqNav = await controlThis.requireSetupAsync(async () => {
                    controlThis.setState({favorite:false, favoriteCount: controlThis.state.favoriteCount - 1})
                    STrace.addStep('frame', 'deleteUserFavoritePaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                    controlThis.pushWaitingFrame(controlThis);
                    await controlThis.storageManager!.deleteUserFavoritePaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                    controlThis.popWaitingFrame();
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={true}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            Size={iconSizeName}
            /> :  <IconButton AppObject={this._app!} 
            Tag='likeopen'
            IconRef={IconLike(undefined)}
            PressTickInterval={0}
            OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                let reqNav = await controlThis.requireSetupAsync(async () => {
                    controlThis.setState({favorite:true, favoriteCount: controlThis.state.favoriteCount + 1})
                    STrace.addStep('frame', 'putUserFavoritePaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                    controlThis.pushWaitingFrame(controlThis);
                    await controlThis.storageManager!.putUserFavoritePaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                    controlThis.popWaitingFrame();
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={false}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            Size={iconSizeName}
            />;

        let bookmarkButton = this.state.bookmark ? <IconButton AppObject={this._app!} 
            Tag='booktrue'
            IconRef={IconBookmarked(undefined)}
            PressTickInterval={0}
            OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                let reqNav = await controlThis.requireSetupAsync(async () => {
                    controlThis.setState({bookmark:false})
                    STrace.addStep('frame', 'deleteUserBookmarkPaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                    controlThis.pushWaitingFrame(controlThis);
                    await controlThis.storageManager!.deleteUserBookmarkPaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                    controlThis.popWaitingFrame();
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={true}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            Size={iconSizeName}
            /> :  <IconButton AppObject={this._app!} 
            Tag='bookmarkfalse'
            IconRef={IconBookmark(undefined)}
            PressTickInterval={0}
            OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                let reqNav = await controlThis.requireSetupAsync(async () => {
                    controlThis.setState({bookmark:true})
                    STrace.addStep('frame', 'putUserBookmarkPaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                    controlThis.pushWaitingFrame(controlThis);
                    await controlThis.storageManager!.putUserBookmarkPaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                    controlThis.popWaitingFrame();
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={false}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            Size={iconSizeName}
            />;    

        let offendedButton = userIsArtist ? 
            (this.state.offensiveCount === 0 ? <></> : 
                <IconButton  AppObject={this._app!}
                    Tag='offendedyes'
                    IconRef={IconOffendedFilled(undefined)}
                    PressTickInterval={0}
                    OnPressStart={()=>{
                    }}
                    OnPressTick={()=>{
                    }}
                    OnPressFinish={async (): Promise<boolean>=>{
                        return true;
                    }}
                    StartPushedState={true}
                    Haptic = {doHaptic}
                    Height={this._app!.GetScaledPxHeight(iconSvgSize)}
                    Size={iconSizeName}
                    />
            ) :
            (this.state.offensive ? <IconButton  AppObject={this._app!}
                Tag='offendedyes'
                IconRef={IconOffendedFilled(undefined)}
                PressTickInterval={0}
                OnPressStart={()=>{
                }}
                OnPressTick={()=>{
                }}
                OnPressFinish={async (): Promise<boolean>=>{
                    let reqNav = await controlThis.requireSetupAsync(async () => {
                        controlThis.setState({offensive:false, offensiveCount: controlThis.state.offensiveCount - 1})
                        STrace.addStep('frame', 'deleteUserOffensivePaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                        controlThis.pushWaitingFrame(controlThis);
                        await controlThis.storageManager!.deleteUserOffensivePaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                        controlThis.popWaitingFrame();
                    });
                    if (reqNav != undefined) {
                        controlThis.setState({navigateTo: reqNav});
                    }
                    return true;
                }}
                StartPushedState={true}
                Haptic = {doHaptic}
                Height={this._app!.GetScaledPxHeight(iconSvgSize)}
                Size={iconSizeName}
                /> :  <IconButton  AppObject={this._app!}
                Tag='offendedno'
                IconRef={IconOffended(undefined)}
                PressTickInterval={0}
                OnPressStart={()=>{
                }}
                OnPressTick={()=>{
                }}
                OnPressFinish={async (): Promise<boolean>=>{
                    STrace.addStep('frame', 'offend ask', '');
                    let reqNav = controlThis.requireSetup(() => {
                        controlThis.setState({askQuestion:{
                            Top: bestTop(),
                            Title: <span>Offensive?</span>,
                            Prompts: [<span key={'l1'}>Report this painting as offensive or in poor taste?</span>,
                                    <span key={'l2'}>Doing so may have punitive repercussions for the artist.</span>],
                            Options: [{Key:'trashPainting', Option:<span>Yes, it IS BAD</span>, OnOption:async ():Promise<boolean>=>{
                                controlThis.setState({offensive:true, offensiveCount: controlThis.state.offensiveCount + 1, askQuestion:undefined})
                                STrace.addStep('frame', 'putUserOffensivePaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                                controlThis.pushWaitingFrame(controlThis);
                                await controlThis.storageManager!.putUserOffensivePaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                                controlThis.popWaitingFrame();
                                return true;
                            }}], OnCancel:()=>{controlThis.setState({askQuestion:undefined});}
                        }});
                    });
                    if (reqNav != undefined) {
                        controlThis.setState({navigateTo: reqNav});
                    }
                    return true;
                }}
                StartPushedState={false}
                Haptic = {doHaptic}
                Height={this._app!.GetScaledPxHeight(iconSvgSize)}
                Size={iconSizeName}
                />);

            let suspectButton =
                (this.state.suspect ? <IconButton  AppObject={this._app!}
                    Tag='suspectyes'
                    IconRef={IconSuspectYes(undefined)}
                    PressTickInterval={0}
                    OnPressStart={()=>{
                    }}
                    OnPressTick={()=>{
                    }}
                    OnPressFinish={async (): Promise<boolean>=>{
                        let reqNav = await controlThis.requireSetupAsync(async () => {
                            controlThis.setState({suspect:false})
                            STrace.addStep('frame', 'deleteUserSuspectPaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                            controlThis.pushWaitingFrame(controlThis);
                            await controlThis.storageManager!.deleteUserSuspectPaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                            controlThis.popWaitingFrame();
                        });
                        if (reqNav != undefined) {
                            controlThis.setState({navigateTo: reqNav});
                        }
                        return true;
                    }}
                    StartPushedState={true}
                    Haptic = {doHaptic}
                    Height={this._app!.GetScaledPxHeight(iconSvgSize)}
                    Size={iconSizeName}
                    /> :  <IconButton  AppObject={this._app!}
                    Tag='suspectNo'
                    IconRef={IconSuspect(undefined)}
                    PressTickInterval={0}
                    OnPressStart={()=>{
                    }}
                    OnPressTick={()=>{
                    }}
                    OnPressFinish={async (): Promise<boolean>=>{
                        STrace.addStep('frame', 'suspect ask', '');
                        let reqNav = controlThis.requireSetup(() => {
                            controlThis.setState({askQuestion:{
                                Top: bestTop(),
                                Title: <span>Suspect?</span>,
                                Prompts: [<span key={'l1'}>Report this painting as suspect, a copy or a fake?</span>,
                                        <span key={'l2'}>Doing so may have punitive repercussions for the artist.</span>],
                                Options: [{Key:'suspectPainting', Option:<span>Yes, it IS SUSPECT</span>, OnOption:async ():Promise<boolean>=>{
                                    controlThis.setState({suspect:true, askQuestion:undefined})
                                    STrace.addStep('frame', 'putUserSuspectPaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                                    controlThis.pushWaitingFrame(controlThis);
                                    await controlThis.storageManager!.putUserSuspectPaintingAsync(MakePaintingLocate(controlThis.props.PaintingInfo));
                                    controlThis.popWaitingFrame();
                                    return true;
                                }}], OnCancel:()=>{controlThis.setState({askQuestion:undefined});}
                            }});
                        });
                        if (reqNav != undefined) {
                            controlThis.setState({navigateTo: reqNav});
                        }
                        return true;
                    }}
                    StartPushedState={false}
                    Haptic = {doHaptic}
                    Height={this._app!.GetScaledPxHeight(iconSvgSize)}
                    Size={iconSizeName}
                    />);
    
        let selectButton = this.state.selected ? <IconButton  AppObject={this._app!}
            Tag='selectcheck'
            IconRef={IconSelected(undefined)}
            PressTickInterval={0}
            OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                STrace.addStep('frame', 'suspect ask', '');
                let reqNav = controlThis.requireSetup(() => {
                    STrace.addStep('frame', 'selected', 'none');
                    controlThis.setState({selected:false});
                    controlThis.setState({navigateTo: {To:`${controlThis.props.Callback}?${createSearchParams({current: 'none'})}`}});
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={true}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            /> :  <IconButton  AppObject={this._app!}
            Tag='select'
            IconRef={IconSelect(undefined)}
            PressTickInterval={0}
                OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                STrace.addStep('frame', 'suspect ask', '');
                let reqNav = controlThis.requireSetup(() => {
                    STrace.addStep('frame', 'selected', controlThis.props.PaintingInfo.paintingId!);
                    controlThis.setState({selected:true})
                    controlThis.setState({navigateTo: {To:`${controlThis.props.Callback}?${createSearchParams({current: controlThis.props.PaintingInfo.paintingId!})}`}});
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={false}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            />;

        let deleteButton = userIsArtist ? <IconButton  AppObject={this._app!}
            Tag='delete'
            IconRef={IconTrash(undefined)}
            PressTickInterval={0}
            OnPressStart={()=>{
            }}
            OnPressTick={()=>{
            }}
            OnPressFinish={async (): Promise<boolean>=>{
                STrace.addStep('frame', 'delete ask', '');
                STrace.addStep('frame', 'suspect ask', '');
                let reqNav = controlThis.requireSetup(() => {
                    controlThis.setState({askQuestion:{
                        Top: bestTop(),
                        Title: <span>Trash?</span>,
                        Prompts: [<span key={'l1'}>To be clear, delete</span>,
                                <span key={'l2'}>this fantastic work?</span>],
                        Options: [{Key:'trashPainting', Option:<span>Yes, trash it</span>, OnOption:async ():Promise<boolean>=>{
                            controlThis.setState({deleted: true, askQuestion:undefined});
                            STrace.addStep('frame', 'deleteUserPaintingAsync', controlThis.props.PaintingInfo.paintingId!);
                            controlThis.pushWaitingFrame(controlThis);
                            await controlThis.storageManager!.deleteUserPaintingAsync(controlThis.props.PaintingInfo.paintingId!);
                            controlThis.popWaitingFrame();
                            return true;
                        }}], OnCancel:()=>{controlThis.setState({askQuestion:undefined});}
                    }});
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
            StartPushedState={false}
            Haptic = {doHaptic}
            Height={this._app!.GetScaledPxHeight(iconSvgSize)}
            Size={iconSizeName}
            /> : <></>;
        
        let feedbackLine = <></>;
        let sideLine = <></>;
        if (this.props.VoteMode) {
            const isPushed = (idx: number): boolean => {
                return controlThis.props.VoteSelection === idx;
            }
            const onPress = (idx: number): boolean => {
                if (controlThis.props.OnVoteSelection !== undefined) {
                    STrace.addStep('frame', 'vote', idx.toString());
                    return controlThis.props.OnVoteSelection(idx);
                }
                return false;
            }

            feedbackLine = 
                <div className='h-items h-small-gap'>
                    {likeButton}
                    {bookmarkButton}
                    {offendedButton}
                    {suspectButton}
                    {editButton}
                </div>

            sideLine = 
            <div className='v-items each-space-around'>
                <VoteButton AppObject={this._app!}
                    Haptic = {doHaptic}
                    Tag='9'
                    Value='9'
                    StartPushedState={isPushed(9)}
                    OnPressFinish={async (): Promise<boolean>=>onPress(9)}
                />
                <VoteButton AppObject={this._app!}
                    Haptic = {doHaptic}
                    Tag='8'
                    Value='8'
                    StartPushedState={isPushed(8)}
                    OnPressFinish={async (): Promise<boolean>=>onPress(8)}
                />
                <VoteButton AppObject={this._app!}
                    Haptic = {doHaptic}
                    Tag='5'
                    Value='5'
                    StartPushedState={isPushed(5)}
                    OnPressFinish={async (): Promise<boolean>=>onPress(5)}
                />
                <VoteButton AppObject={this._app!}
                    Haptic = {doHaptic}
                    Tag='4'
                    Value='4'
                    StartPushedState={isPushed(4)}
                    OnPressFinish={async (): Promise<boolean>=>onPress(4)}
                />
                <VoteButton AppObject={this._app!}
                    Haptic = {doHaptic}
                    Tag='2'
                    Value='2'
                    StartPushedState={isPushed(2)}
                    OnPressFinish={async (): Promise<boolean>=>onPress(2)}
                />
                <VoteButton AppObject={this._app!}
                    Haptic = {doHaptic}
                    Tag='1'
                    Value='1'
                    StartPushedState={isPushed(1)}
                    OnPressFinish={async (): Promise<boolean>=>onPress(1)}
                />
                <VoteButton AppObject={this._app!}
                    Haptic = {doHaptic}
                    Tag='0'
                    Value='0'
                    StartPushedState={isPushed(0)}
                    OnPressFinish={async (): Promise<boolean>=>onPress(0)}
                />
            </div>


        } else if (this.props.SelectionMode){
            feedbackLine = 
            <div className='h-items width-100p group-center'>
                {selectButton}
            </div>
        } else {
            feedbackLine = 
            <div className='h-items width-100p each-space-between'>
                <div className='h-items h-small-gap'>
                    {lunchbox}
                    {likeButton}
                    {this.state.favoriteCount ? <div className='self-cross-center text-small'>{this.state.favoriteCount}</div>: <></>}
                    {bookmarkButton}
                    {offendedButton}
                    {this.state.offensiveCount ? <div className='self-cross-center text-small'>{this.state.offensiveCount}</div>: <></>}
                    {deleteButton}
                </div>
                {editButton}
            </div>
        }

        let artistLine = <></>;

        if (!userIsArtist && this.props.SelectionMode===false && this.props.NoArtist===false) {
            artistLine = 
            <FollowingTile
                AppObject={this.props.AppObject}
                AppShape={this.props.AppShape}
                ArtistProfile={this.props.PaintingInfo.artistProfile!}
                ShowTrash = {false}
                ShowPlus = {false}
                ShowPending = {false}
                AllowVScroll = {true}
                OnTrashClick={async (top:number, artistId:string, artistName:string): Promise<boolean>=>{return true;}}
                OnPlusClick={async (top:number, artistId:string, artistName:string): Promise<boolean>=>{return true;}}
                OnTileClick={async (top:number, artistId:string, artistName:string): Promise<boolean>=>{return await onOtherNavigate();}}
                />
        }

        let challengeLine = <></>;
        if (this.props.ShowChallenge && this.props.PaintingInfo.challengeName && this.props.SelectionMode===false) {
            let challengeResult = this.props.PaintingInfo.totalVotes === undefined || this.props.PaintingInfo.totalVotes === 0 ? <></> :
            <div className='v-items text-small h-padding-medium'>
                <span>{`votes: ${this.props.PaintingInfo.totalVotes}`}</span>
                <span>{`points: ${this.props.PaintingInfo.totalPoints}`}</span>
            </div>
    
            challengeLine =
            <div style={{display:'grid'}}>
                <div className='text-small h-padding-small' style={{gridRow:1, gridColumn:1}}>
                    Challenge:
                </div>
                <div className='text-small h-padding-small' style={{gridRow:1, gridColumn:2}}>
                    {this.props.PaintingInfo.challengeName}
                </div>
                <div className='text-small h-padding-small' style={{gridRow:2, gridColumn:1}}>
                    Rank:
                </div>
                <div className='text-small h-padding-small' style={{gridRow:2, gridColumn:2}}>
                    {this.props.PaintingInfo.finalRank === undefined || this.props.PaintingInfo.finalRank === 0 ? 'unknown' : this.props.PaintingInfo.finalRank}
                </div>
                <div className='text-small h-padding-small' style={{gridRow:3, gridColumn:1}}>
                    {`Points/Votes`}
                </div>
                <div className='text-small h-padding-small' style={{gridRow:3, gridColumn:2}}>
                    {this.props.PaintingInfo.totalVotes === undefined || this.props.PaintingInfo.totalVotes === 0 || this.props.PaintingInfo.totalPoints === undefined ? 'unknown' : 
                        `${this.props.PaintingInfo.totalPoints}/${this.props.PaintingInfo.totalVotes} = ${Math.round(this.props.PaintingInfo.totalPoints/this.props.PaintingInfo.totalVotes*10)/10}`}
                </div>
            </div>
        }

        const paintingMeta = <div className='v-items v-small-gap v-margin-small'>
            {feedbackLine}
            {challengeLine}
            {artistLine}
        </div>

        const onPaintingClick = ()=> {
            STrace.addStep('frame', 'expand', (!this.state.expandedView).toString());
            this.setState({expandedView: !this.state.expandedView});
        }

        var imgStyle = {width:'100%', height:'100%', 
            backgroundImage:`url(${this.props.PaintingInfo.imageDetail!.preview})`,
            backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' };

        const imgElement = this.props.VoteMode === false ? 
            <img src={this.props.PaintingInfo.imageDetail!.preview} draggable={false} onPointerUp={(e)=>onPaintingClick()}/> : 
            <div className='h-items width-100p height-100p h-small-gap'>
                <div style={imgStyle} onPointerUp={(e)=>onPaintingClick()}></div>  
                {sideLine}
            </div>;
        

        return this.renderFramework(
            <>
                {this.state.expandedView == false ? (
                <>
                    <div ref ={this.rectRef} className='smesshy-group v-items width-100p height-100p' style={{position:'relative', zIndex:5}}>
                        {imgElement}
                        {paintingMeta}
                        {this.state.deleted === true ? (<div className='smesshy-grey-out'/>) : (<></>)}
                    </div>
                </>
                
                ) : (
                <div onPointerUp={(e)=>onPaintingClick()} 
                        style={{maxWidth:512, maxHeight: 1024, position:'absolute', left:0, top: 0, height:'100%', width:'100%', backgroundColor:'rgba(0,0,0,.67)', zIndex: 10}}>

                    <div style={imgStyle}>
                    </div>;
                </div>
                )}
            </>, this.state);
    }
}

export default PaintingFrame;