//import * as signalR from "@microsoft/signalr"
import React, { Component, ReactElement, useEffect } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import './App.css';
import Smesshy from "./smesshy";
import { v4 as uuidv4 } from "uuid";
import PaintingPage from "./components/paintingPage";
import FingerTip from "./components/smash/fingertip";
import SettingsPage from "./components/settingsPage";
import CalibrateTouchPage from "./components/calibrateTouch";
import StatsPage from './components/statsPage';
import GetApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Route, Routes, URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import GalleryDoorsPage from './components/galleryDoorsPage';
import ErrorPage from './components/errorPage';
import AuthorizeService from './components/api-authorization/AuthorizeService';
import GalleryPage from './components/galleryPage';
import FollowingPage from './components/followingPage';
import SmesshyStorageManager from './storageManager';
import { smesshyVersion } from './version';
import Smessage from './components/smessage';
import TermsOfServicePage from './components/termsOfServicePage';
import PrivacyPage from './components/privacyPage';
import VotePage from './components/votePage';
import Env from './envInst';
import SetupPage from './components/setupPage';
import { STrace } from './smesshyCommon';
import ResultPage from './components/results';
import LunchboxPage from './components/lunchboxPage';
import FeedPage from './components/feedPage';


class modWindow {
    csweetGameId: any
}

let serviceWorkerRegistration : ServiceWorkerRegistration | undefined = undefined;
if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/sw.js')
        .then((reg) => { 
            console.log('Service Worker Registered'); 
            serviceWorkerRegistration = reg;

        });
}
interface AppRoute {
    index?: boolean;
    path?: string;
    element: ReactElement;
}

export interface SmesshyAppShape {
    appRectangle: DOMRect;
}

export interface AppState {
    loading: boolean;
    sessionId: number;
    sessionState: string;
    fromPage: string;
    shownPage: string;
    showSplash: boolean;
    appShape: SmesshyAppShape | undefined;
    trigger: number;
}

export interface AppProps {
    cookies: Cookies
    searchParams: [URLSearchParams, (nextInit: URLSearchParamsInit, navigateOptions?: {replace?: boolean | undefined;state?: any;} | undefined) => void]
}

export class App extends Component<AppProps, AppState> {

    static displayName = App.name;
    public asyncHelp = new AsyncHelper();

    public authService = new AuthorizeService(this);

    storageManager : SmesshyStorageManager;

    outerRef : React.RefObject<HTMLDivElement>;
    hostRef : React.RefObject<HTMLDivElement>;
    resizeListener :(((ev: UIEvent)=>void) | undefined) = undefined;

    waitingFrames = new Array<Component>();
    showingSpinner = false;
    spinnerTimeout: NodeJS.Timeout | undefined = undefined;
    

    constructor(props: AppProps) {
        super(props);
        const { cookies } = props;
        const { searchParams } = props;

        this.outerRef = React.createRef();
        this.hostRef = React.createRef();

        this.storageManager = new SmesshyStorageManager(this);

        let gameSession = Number.NaN;

        if ((window as object).hasOwnProperty('csweetGameId')) {
            const idSetOnWindow: any = (window as unknown as modWindow).csweetGameId;
            if (idSetOnWindow !== undefined && idSetOnWindow !== 'current_csweet_game_id') {
                gameSession = Number.parseInt(idSetOnWindow);
            }
        }

        if (Number.isNaN(gameSession)) {
            const queryParameters = new URLSearchParams(window.location.search);
            const gameSessionSent = queryParameters.get("g");
            if (gameSessionSent !== undefined && gameSessionSent !== null) {
                gameSession = Number.parseInt(gameSessionSent);
            }
        }

        this.initGameState();

        const bound = new DOMRect(0, 0, window.innerWidth, window.innerHeight);
        let appShape = this.adjustAppShape(bound, false);

        this.state = {
            loading: true,
            sessionId: gameSession,
            sessionState: 'unk',
            fromPage: 'smesshy',
            shownPage: 'smesshy',
            showSplash: true,
            appShape: appShape,
            trigger: 0
        };
    }

    componentDidMount() {
        STrace.addStep('app', 'didMound', '');

        if (this.resizeListener === undefined) {
            let controlThis = this;

            this.resizeListener = (ev: UIEvent)=> {
                controlThis.setState({trigger: controlThis.state.trigger + 1});
            };

            window.addEventListener('resize', this.resizeListener, true);
        }

        this.asyncHelp.executeAsyncLater(async ()=> {
            let env = Env;

            env.afterSwitch = ()=> {
                this.triggerRefresh();
                this.checkVersion();
            }
            await env.collectEnvironmentState();

            if (env.inPWA === true) {
                try {
                    STrace.addStep('app', 'ensureUserManager', '');
                    await this.authService.ensureUserManagerInitialized();
                } catch (reason) {
                    this.reportException('init Authentication', 'ex', '', reason);
                }
            }

            this.setState({ loading: false, shownPage: 'smesshy'});
    
        });
    }

    componentWillUnmount(): void {
        if (this.resizeListener !== undefined) {
            window.removeEventListener('resize', this.resizeListener);
        }
        if (this.spinnerTimeout !== undefined) {
            clearTimeout(this.spinnerTimeout);
        }
    }


    get SessionId(): number  {
        return this.state.sessionId;
    }
   
    
    render() {

        let env = Env;
        let inApp = env.inPWA;

        let paintMode = this.props.searchParams[0].get('mode')!;
        if (paintMode === undefined || paintMode === null && this.state.loading == false) {
            if (inApp === false) {
                paintMode = 'anon';
            }
        }
        const controlThis = this;

         const OrientObserver = () => {
            useEffect(()=>{
                if (controlThis.outerRef.current){
                    const bound = controlThis.outerRef.current.getBoundingClientRect();
                    bound.width = Math.round(bound.width);
                    bound.height = Math.round(bound.height);
                    bound.x = Math.round(bound.x);
                    bound.y = Math.round(bound.y);
                    if (bound.width < 10) { // sometimes we get an empty or near empty window mid-refreshing
                        return;
                    }
                    if (bound.height < 700 && bound.width > bound.height) {
                        this.adjustAppShape(undefined, true);
                    } else {
                        this.adjustAppShape(bound, true);
                    }
                }
            });
            return <></>;
        }


         const GetAppRoutes = (): Array<AppRoute>=> {
            return [
            {
                path: '/',
                index: true,
                element: <Smesshy
                    AppObject={this} AppShape={this.state.appShape}
                    ShowSplash={this.state.showSplash}
                />
            },
            {
                path: '/setup',
                element: <SetupPage AppObject={this} AppShape={this.state.appShape}
                    InApp = {inApp }
                />
            },
            {
                path: '/painting',
                element: <PaintingPage AppObject={this} AppShape={this.state.appShape}
                    InteractionMode = {paintMode}
                    StampsAllowed = {this.props.searchParams[0].get('stampAllowed') !== 'false' }
                    SaveMode={this.props.searchParams[0].get('saveMode')!}
                    KnownAvgLightRadius =  {this.GetAppState('avgLightRadius', FingerTip.minFingerRadius) as number}
                    KnownAvgHeavyRadius =  {this.GetAppState('avgHeavyRadius', FingerTip.maxFingerRadius) as number}
                    KnownUseMouse =  {this.GetAppState('useMouse', false) as boolean}
                    KnownShowSize =  {this.GetAppState('showSize', false) as boolean}
                />
            },
            {
                path: '/settings',
                element: <SettingsPage
                    AppObject={this} AppShape={this.state.appShape}
                    KnownHapticButton= {this.GetAppState('hapticButton', true) as boolean}
                    KnownShowSize={this.GetAppState('showSize', false) as boolean}
                    KnownUseMouse={this.GetAppState('useMouse', false) as boolean}
                    NewSelfPortraitId={this.props.searchParams[0].get('current')!}
                />
            },
            {
                path: '/calibrate',
                element: <CalibrateTouchPage
                    AppObject={this} AppShape={this.state.appShape}
                    KnownCalibrated={this.GetAppState('calibrationVersion', false) as number === App.calibrationVersion}
                />
            },
            {
                path: '/stats',
                element: <StatsPage
                    AppObject={this} AppShape={this.state.appShape}
                />
            },
            {
                path: '/vote',
                element: <VotePage
                    AppObject={this} AppShape={this.state.appShape}
                    ChallengeId= {this.props.searchParams[0]?.get('challengeId')!}
                />
            },
            {
                path: '/result',
                element: <ResultPage
                    AppObject={this} AppShape={this.state.appShape}
                    ChallengeId= {this.props.searchParams[0]?.get('challengeId')!}
                />
            },
            {
                path: '/gallery-doors',
                element: <GalleryDoorsPage
                    AppObject={this} AppShape={this.state.appShape}
                />
            },
            {
                path: '/single',
                element: <GalleryPage
                    AppObject={this} AppShape={this.state.appShape}
                    Title= {`That Painting I Mentioned`}
                    Scope= 'my'
                    Filter= {this.props.searchParams[0].get('paintingId')!}
                    FetchCount={1}
                    SelectionMode={false}
                    ArtistMode={false}
                    RequireAuth={true}
                />
            },
            {
                path: '/my-paintings',
                element: <GalleryPage
                    AppObject={this} AppShape={this.state.appShape}
                    Title= {`My Fantastic Paintings`}
                    Scope= 'my'
                    Filter= 'recent'
                    FetchCount={10}
                    SelectionMode={false}
                    ArtistMode={false}
                    RequireAuth={true}
                    ShowBookmarkFilter={true}
                />
            },

            {
                path: '/gallery-doors/gallery',
                element: <GalleryPage
                    key={'/gallery-doors/gallery'}
                    AppObject={this} AppShape={this.state.appShape}
                    Title= {this.props.searchParams[0].get('title')!}
                    Scope= {this.props.searchParams[0].get('scope')!}
                    Filter= {this.props.searchParams[0].get('filter')!}
                    RequireAuth={this.props.searchParams[0].get('auth')! === 'true'}
                    FetchCount={10}
                    SelectionMode={false}
                    ArtistMode={false}
                />
            },
            {
                path: '/feed',
                element: <FeedPage
                    key={'/feed'}
                    AppObject={this} AppShape={this.state.appShape}
                    FetchCount={10}
                />
            },
            {
                path: '/lunchbox',
                element: <LunchboxPage
                    key={'/lunchbox'}
                    AppObject={this} AppShape={this.state.appShape}
                    FetchCount={10}
                />
            },
            {
                path: '/painting-selection',
                element: <GalleryPage
                    key={'/painting-selection'}
                    AppObject={this} AppShape={this.state.appShape}
                    Title= {this.props.searchParams[0].get('title')!}
                    Scope= {this.props.searchParams[0].get('scope')!}
                    Filter= {this.props.searchParams[0].get('filter')!}
                    Callback= {this.props.searchParams[0].get('callback')!}
                    CurrentSelection= {this.props.searchParams[0].get('current')!}
                    FetchCount={10}
                    SelectionMode={true}
                    ArtistMode={false}
                    RequireAuth={true}
                />
            },
            {
                path: '/other-gallery',
                element: <GalleryPage
                    key={'/other-gallery'}
                    AppObject={this} AppShape={this.state.appShape}
                    Title= {this.props.searchParams[0].get('title')!}
                    Scope= {this.props.searchParams[0].get('scope')!}
                    Filter= {this.props.searchParams[0].get('filter')!}
                    FetchCount={10}
                    SelectionMode={false}
                    ArtistMode={true}
                    RequireAuth={this.GetInPWA()}
                />
            },
            {
                path: '/following',
                element: <FollowingPage
                    AppObject={this} AppShape={this.state.appShape}
                    FetchCount={10}
                    />
            },
            {
                path: '/termsOfServiceApp',
                element: <TermsOfServicePage
                    AppObject={this} AppShape={this.state.appShape}/>
            },
            {
                path: '/privPolApp',
                element: <PrivacyPage
                    AppObject={this} AppShape={this.state.appShape}/>
            },

            {
                path: '/error',
                element: <ErrorPage
                    AppObject={this} AppShape={this.state.appShape}
                    From = {localStorage.getItem('_lastExceptionSource')!}
                    Kind = {localStorage.getItem('_lastExceptionKind')!}
                    Code = {localStorage.getItem('_lastExceptionCode')!}
                    Message = {localStorage.getItem('_lastExceptionText')!}
                />
            }
            ];
        }

        var rectHost = this.state.appShape?.appRectangle;

        const allRoutes = <Routes>
                        {GetAppRoutes().map((route, index) => {
                            const { element, path, ...rest } = route;
                            return <Route key={index} path={path} {...rest} element={element} />;
                        })}
                        {GetApiAuthorzationRoutes(this).map((route, index) => {
                            const { element, path, ...rest } = route;
                            return <Route key={index + 100} path={path} {...rest} element={element} />;
                        })}

                        </Routes>
        const authRoutes = <Routes>
                        {GetAppRoutes().map((route, index) => {
                            const { element, path, ...rest } = route;
                            return <Route key={index} path={path} {...rest} element={<Smessage AppObject={this} AppShape={this.state.appShape} Loading={true} Title='Smesshy' Say='Getting Smesshy, one moment...'/>} />;
                        })}
                        {GetApiAuthorzationRoutes(this).map((route, index) => {
                            const { element, path, ...rest } = route;
                            return <Route key={index + 100} path={path} {...rest} element={element} />;
                        })}

                        </Routes>

        const wrongWay = <>
            <div className='width-100p height-100p' style={{
                backgroundImage:'url("/wrongway.png")',
                backgroundPosition: 'center',
                backgroundRepeat:'no-repeat',
                backgroundSize:'contain'}}>
            </div>
            {authRoutes};
        </>

        const gettingSmesshy = <div className='smesshy-app-host' style={{top:rectHost?.top, left:rectHost?.left, width: rectHost?.width, height: rectHost?.height}}>
                    {authRoutes}
                </div>;

        const smesshyMain =  <div className='smesshy-app-host' style={{top:rectHost?.top, left:rectHost?.left, width: rectHost?.width, height: rectHost?.height}}>
                    {allRoutes}
                </div>;
        
        const body = this.state.appShape === undefined ? wrongWay : this.state.loading === true ? gettingSmesshy : smesshyMain;

        return <>
            <OrientObserver/>
            <div ref={this.outerRef} className='smesshy-gutter' >
                {body}
            </div>
        </>;
    }

    playerId?: string = undefined;

    static calibrationVersion = 2;

    adjustAppShape(bound: DOMRect | undefined, updateState: boolean) : SmesshyAppShape | undefined {
        if (bound === undefined) {
            if (this.state.appShape !== undefined) {
                let result = this.state.appShape;
                this.setState({appShape: undefined});
                return result;
            }
            return undefined;
        }
        let idealAvailableWidth = 370;
        let idealAvailableHeight = 750;
        let idealAspectRatio = idealAvailableWidth / idealAvailableHeight;
        let topGutter = 2;
        let bottomGutter = 6;
        let leftGutter = 2;
        let rightGutter = 6;
        let availableWidth = bound.width - leftGutter - rightGutter;
        let availableHeight = bound.height - topGutter - bottomGutter;
        if (availableHeight > 1024)
        {
            availableHeight = 1024;
            let extraGutter = (bound.height - availableHeight) / 2;
            topGutter += extraGutter;
            bottomGutter += extraGutter;
        }
        if (availableWidth > 512)
        {
            availableWidth = 512;
            let extraGutter = (bound.width - availableWidth) / 2;
            leftGutter += extraGutter;
            rightGutter += extraGutter;
        }
        let aspectRatio = availableWidth / availableHeight;
        let usedWidth = availableWidth;
        let usedHeight = availableHeight;
        if (aspectRatio < idealAspectRatio) {
            usedHeight = availableWidth / idealAspectRatio;
        } else if (aspectRatio > idealAspectRatio) {
            usedWidth = availableHeight * idealAspectRatio;
        }
        let rectNew = new DOMRect(leftGutter + (availableWidth - usedWidth) / 2, topGutter + (availableHeight - usedHeight) / 2, usedWidth, usedHeight);
        if (this.state === undefined || this.state.appShape === undefined || 
            this.state.appShape.appRectangle.width !== rectNew.width || this.state.appShape.appRectangle.height !== rectNew.height ||
            this.state.appShape.appRectangle.x !== rectNew.x || this.state.appShape.appRectangle.y !== rectNew.y) {

            let scaledPx2Vw =  (usedWidth/idealAvailableWidth) * 100 / usedWidth;
            let scaledPx2Vh =  (usedHeight/idealAvailableHeight) * 100 / usedHeight;
            let unitWidth = (usedWidth / bound.width) * scaledPx2Vw;
            let unitHeight = (usedHeight / bound.height) * scaledPx2Vh;
           
            let r = document.querySelector(':root') as HTMLElement;
            if (r !== null) {
                r.style.setProperty('--smesshy-unit-width', unitWidth.toString());
                r.style.setProperty('--smesshy-unit-height', unitHeight.toString());
            }
            const newShape = {appRectangle: rectNew};
            if (updateState) {
                this.setState({appShape: newShape});
            }
            return newShape;
        }
        return undefined;
    }


    public GetScaledPxWidthVw(px : number) : string {
        if (this.state.appShape === undefined || this.state.appShape === null) {
            return '100px';
        }
        return `calc(var(--smesshy-unit-width) * 1vw * ${px})`
    }
    public GetScaledPxHeightVh(px : number) : string {
        if (this.state.appShape === undefined || this.state.appShape === null) {
            return '100px';
        }
        return `calc(var(--smesshy-unit-height) * 1vh * ${px})`
    }
    public GetScaledPxWidth(px : number) : number {
        if (this.state.appShape === undefined || this.state.appShape === null) {
            return 1;
        }
        return px * this.state.appShape!.appRectangle.width / 370;
    }
    public GetScaledPxHeight(px : number) : number {
        if (this.state.appShape === undefined || this.state.appShape === null) {
            return 1;
        }
        return px * this.state.appShape!.appRectangle.height / 750;
    }

    public GetAppShape() : SmesshyAppShape {
        return this.state.appShape!;
    }

    public GetInPWA() : boolean {
        return Env.inPWA;
    }

    initGameState() {
        let env = Env;
        const controlThis = this;

        const { cookies } = this.props;
        this.playerId = cookies.get('playerUniqueId');
        if (this.playerId === undefined || this.playerId === null) {
            this.playerId = uuidv4();
            this.SetCookie('playerUniqueId', this.playerId);
        }
       
        let storedCalibrated = localStorage.getItem('calibrationVersion');
        if (storedCalibrated === null) {
            // maybe the old cookies are there, swap over
            let storedCalibrated = cookies.get('calibrationVersion');
            if (storedCalibrated !== undefined && storedCalibrated !== null) {
                this.SetAppState('calibrationVersion', storedCalibrated, true);
                this.SetAppState('avgLightRadius', this.GetCookie('avgLightRadius', FingerTip.minFingerRadius.toString()), true);
                this.SetAppState('avgHeavyRadius', this.GetCookie('avgHeavyRadius', FingerTip.maxFingerRadius.toString()), true);
                this.SetAppState('useMouse', this.GetCookie('useMouse', 'false'), true);
                this.SetAppState('showSize', this.GetCookie('showSize', 'false'), true);
                this.SetAppState('hapticButton', env.getVibrateSupport() ? this.GetCookie('hapticButton', 'true') : 'false', true);

                this.SetCookie('calibrationVersion', null);
                this.SetCookie('avgLightRadius', null);
                this.SetCookie('avgHeavyRadius', null);
                this.SetCookie('useMouse', null);
                this.SetCookie('showSize', null);
                this.SetCookie('hapticButton', null);
            }
        }
        // set defaults unless already stored. either way, convert from strings
        this.SetAppState('calibrationVersion', Number.parseInt(this.GetAppState('calibrationVersion', '0', true) as string), true);
        this.SetAppState('avgLightRadius', Number.parseFloat(this.GetAppState('avgLightRadius', FingerTip.minFingerRadius.toString(), true) as string), true);
        this.SetAppState('avgHeavyRadius', Number.parseFloat(this.GetAppState('avgHeavyRadius', FingerTip.maxFingerRadius.toString(), true) as string), true);
        this.SetAppState('useMouse', this.GetAppState('useMouse', 'false', true) === 'true', true);
        this.SetAppState('showSize', this.GetAppState('showSize', 'false', true) === 'true', true);
        this.SetAppState('hapticButton', this.GetAppState('hapticButton', env.getVibrateSupport() ? 'true' : 'false', true) === 'true', true);

    }

    public SetCookie(tag: string, value: any) {
        const { cookies } = this.props;
        if (value === null) {
            cookies.remove(tag);
        } else {
            cookies.set(tag, value, { path: '/', maxAge:2*365*24*60*60 });
        }
    }
    public GetCookie(tag: string, defaultValue: string) {
        const { cookies } = this.props;
        var value = cookies.get(tag);
        if (value === undefined || value === null) {
            value = defaultValue;
        }
        return value;
    }

    _gameState = new Map<string, unknown>();
    public GetAppState(tag: string, defaultValue: unknown, checkPersist?: boolean): unknown {
        let fullTag = `${tag}`;
        if (this._gameState.has(fullTag)) {
            return this._gameState.get(fullTag);
        } else {
            if (checkPersist === true) {
                let persisted: string | null;
                if ((persisted = localStorage.getItem(tag)) !== null) {
                    defaultValue = persisted;
                }
            }
            this._gameState.set(fullTag, defaultValue);
            return defaultValue;
        }
    }
    public SetAppState(tag: string, newValue: unknown, persist?: boolean) {
        let fullTag = `${tag}`;
        this._gameState.set(fullTag, newValue);
        if (persist === true) {
            if (newValue === null) {
                localStorage.removeItem(tag);
            } else {
                localStorage.setItem(tag, (newValue as any).toString());
            }
        }
    }

    public DoShowTutorial(key: string) {
        if (this.GetAppState(`show-tutorial-${key}`, 'true', true) === 'true') {
            return this.GetAppState(`show-tutorial-any`, 'true', true) === 'true';
        }
        return false;
    }

    public FinishTutorial(key: string) {
        this.SetAppState(`show-tutorial-${key}`, 'false', true);
    }

    public ResetTutorialKeys(keys: Array<string>) {
        for (let key of keys) {
            this.SetAppState(`show-tutorial-${key}`, 'true', true);
        }
    }
    
    public triggerRefresh() {
        sessionStorage.setItem('smesshy-app-refresh', 'true');
    }

    public checkVersion() {
        let timeNow = Date.now();
        let lastVerCheck = sessionStorage.getItem('smesshy-app-version-check');
        let triggeredRefresh = sessionStorage.getItem('smesshy-app-refresh');
        if (triggeredRefresh === 'true' || lastVerCheck === null || Number.parseInt(lastVerCheck) < timeNow - (1000*60*60)) {
            sessionStorage.removeItem('smesshy-app-refresh');
            sessionStorage.setItem('smesshy-app-version-check', timeNow.toString());
            if (triggeredRefresh === 'true') {
                window.location.reload();
            } else {
                fetch('api/diagnostic/getAppVersion').then((resp)=>{
                    resp.text().then((v: string) => {
                        if (v !== smesshyVersion) {
                            window.location.reload();
                        }
                    })
                    
                }).catch(reason=>{
                    window.location.reload();
                });
            }
        }
    }

    public pushWaitingFrame(frame: Component) {
        this.waitingFrames.push(frame);
        if (this.waitingFrames.length === 1) {
            // should never happen, but just in case
            if (this.spinnerTimeout !== undefined) {
                clearTimeout(this.spinnerTimeout);
                this.spinnerTimeout = undefined;
            }
            this.showingSpinner = false;
            let controlThis = this;
            this.spinnerTimeout = setTimeout(()=> {
                controlThis.spinnerTimeout = undefined;
                controlThis.showingSpinner = true;
                controlThis.waitingFrames[0].setState({showWaitSpin: true});
            }, 250);
        }
    }
    public popWaitingFrame() {
        if (this.waitingFrames.length > 0) {
            let frame = this.waitingFrames.pop();
            if (this.waitingFrames.length === 0) {
                if (this.spinnerTimeout !== undefined) {
                    clearTimeout(this.spinnerTimeout);
                    this.spinnerTimeout = undefined;
                }
                if (this.showingSpinner === true) {
                    frame!.setState({showWaitSpin: false});
                }
                this.showingSpinner = false;
            }
        }
    }

    debugLog = '';
    public LogLine(line: string) {
        this.debugLog+=line+'\n';
    }
    public GetLog() {
        return this.debugLog;
    }

    public reportException(source: string, kind: string, code: string, exception: unknown) {
        localStorage.setItem('_lastExceptionSource', source);
        localStorage.setItem('_lastExceptionKind', kind);
        localStorage.setItem('_lastExceptionCode', code);
        localStorage.setItem('_lastExceptionText', exception === undefined ? 'undefined' : (exception as object).toString());
        this.waitingFrames = new Array<Component>();
        window.location.assign('/error');

    }


     urlBase64ToUint8Array(base64String: string) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
    
        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);
    
        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }


    public notificationsPossible() : boolean {
        // must be a service worker to talk with
        // needed to set breakpoints so don't mind this goofy code
        if (serviceWorkerRegistration !== undefined)
        if ('pushManager' in serviceWorkerRegistration)
        if (serviceWorkerRegistration['pushManager'] !== undefined)
        if ('subscribe' in serviceWorkerRegistration['pushManager'])
        if (serviceWorkerRegistration['pushManager']['subscribe'] !== undefined)
        if ('getSubscription' in serviceWorkerRegistration['pushManager'])
        if (serviceWorkerRegistration['pushManager']['getSubscription'] !== undefined)
        if ('Notification' in window)
        if (window['Notification'] !== undefined)
        if ('permission' in window['Notification'])
        if (window['Notification']['permission'] !== undefined)
        if ('requestPermission' in window['Notification'])
        if (window['Notification']['requestPermission'] !== undefined)
            return true;

        return false;
    }

    public async clearNotifications(kind: string, id?: string) {
        if (this.notificationsPossible() === false) {
            return;
        }
        const nots = await serviceWorkerRegistration!.getNotifications();
        let totalNots = nots.length;
        let clearedNots = 0;
        for (let i = 0; i < nots.length; i++) {
            let body = nots[i].body;
            switch (kind) {
                case 'all':
                    nots[i].close();
                    clearedNots++;
                    break;
                case 'lunchbox':
                    if (body.endsWith('in your lunchbox.')) {
                        nots[i].close();
                        clearedNots++;
                    }
                    break;
                case 'pokeLunchbox':
                    if (body.includes('VERY WORRIED about your lunchbox')) {
                        nots[i].close();
                        clearedNots++;
                    }
                    break;
                case 'following':
                    if (body.endsWith('follow your paintings.')) {
                        nots[i].close();
                        clearedNots++;
                    }
                    break;
                case 'painting':
                    var paintingId = '';
                    if (body.endsWith(']')) {
                        let start = body.lastIndexOf('[');
                        if (start !== -1) {
                            paintingId = body.substring(start+1, body.length-1);
                            if (paintingId === id) {
                                nots[i].close();
                                clearedNots++;
                            }
                        }
                    }
                    break;
    
            }
        }

        if ('setAppBadge' in navigator) {
            (navigator as any).setAppBadge(totalNots - clearedNots);
        }

    }

     public async getNotificationSubscription() : Promise<PushSubscription | null> {
        return await serviceWorkerRegistration!['pushManager']['getSubscription']();
     }

     public async deleteNotificationRegistration() : Promise<boolean> {
        // must be a service worker to talk with
        if (this.notificationsPossible() === false) {
            return true; // since true means it's gone, and it was never here
        }
        try {
            // existing subscription? fine else register with the worker
            let sub = await this.getNotificationSubscription();
            if (sub === null || sub === undefined) {
                return true; // since true means it's gone, and it was never here
            }
            if (await sub.unsubscribe() === true) {
                STrace.addStep('app', 'deleteNotificationSubscription', '');
                await this.storageManager.deleteNotificationSubscription(sub.endpoint);
                return true;
            }
        } catch (reason) {
        }
        return false;
     }

     public async getNotificationPermission(reAsk: boolean) : Promise<boolean> {
        let perms = window['Notification']['permission'];
        if (perms === 'default' || (perms === 'denied' && reAsk)) {
            // don't know yet, must ask
            perms = await window['Notification']['requestPermission']();
        }
        if (perms === 'denied') {
            return false; // nope
        }
        return true;
     }

     public async createNotificationRegistration() : Promise<boolean>{
        try {
            // need public key from server
            STrace.addStep('app', 'getVapidPublicKey', '');
            const respPK = await fetch('api/notification/getVapidPublicKey');
            const vapidPK = await respPK.text();
            const convertedVapidPK = this.urlBase64ToUint8Array(vapidPK);

            const newSub = await serviceWorkerRegistration!.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidPK
            });

            STrace.addStep('app', 'registerNotificationSubscription', '');
            await this.storageManager.registerNotificationSubscription(newSub);
            return true;
        } catch (reason) {
            // alert(`tell Jeff: '${reason}'`)
        }
        return false;
    }
    
}

class AsyncHelper {

    currentAsync: (()=>void) | undefined = undefined;
    pendingWork = new Array<()=>void>();
    
    constructor() {
        
    }

    public executeAsyncLater(code: ()=>void) {

        const localThis = this;
        async function wrapper() {
            localThis.currentAsync = code;
            try {
                await code();  
                localThis.currentAsync = undefined;          
            } catch(err) {
                localThis.currentAsync = undefined;          
                throw err;
            }
            while(localThis.pendingWork.length > 0) {
                const pending = localThis.pendingWork[0];
                localThis.pendingWork = localThis.pendingWork.slice(1);
                pending();
            }
        }
        if (this.currentAsync !== undefined) {
            //console.log('attempt to async / non / async in js, stashing');
            this.pendingWork.push(wrapper);
        } else {
            this.currentAsync = code;
            wrapper();
        }
    }

    public executeWhenSafe(code: ()=>void) {
        if (this.currentAsync !== undefined) {
            this.pendingWork.push(code);
        } else {
            code();
        }
    }

}

export default withCookies((props: any) => (
    <App
        {...props}
        searchParams = {useSearchParams()}
    />));
