import React, { Component } from 'react';
import IconButton, {IconClose } from './iconButton';
import { App } from '../App';

export interface SearchInputState {
    currentValue: string | undefined;
}
export interface SearchInputProps {
    AppObject: App;
    DefaultPrompt: string;
    DefaultValue: string | undefined;
    Delay: number;
    Haptic: boolean;
    Scale: number;
    OnValueChange: (newValue: string | undefined)=>void;
}

export class SearchInput extends Component<SearchInputProps, SearchInputState> {

    constructor(props: SearchInputProps) {
        super(props);

        this.state = {
            currentValue: props.DefaultValue
        };
    }

    render() {
        let controlThis = this;

        let inputRef = React.createRef() as React.RefObject<HTMLInputElement>;
        let inputTimer: NodeJS.Timeout | undefined = undefined;

        return (
            <div className='h-items h-small-gap width-100p'>
                <div className='flex-auto' style={{paddingRight:8, paddingLeft:2, paddingBottom: 8, paddingTop:2}}>
                    <input ref={inputRef} type="text" className='width-100p height-100p smesshy-input text-small text-black' 
                        defaultValue={this.state.currentValue === undefined ? this.props.DefaultPrompt : this.state.currentValue}
                        onFocus={()=>{
                            if (controlThis.state.currentValue === undefined) {
                                inputRef.current!.value = '';
                            }
                        }}
                        onChange={()=>{
                            if (inputTimer !== undefined) {
                                clearTimeout(inputTimer);
                                inputTimer = undefined;
                            } 
                            inputTimer = setTimeout(() => {
                                clearTimeout(inputTimer);
                                inputTimer = undefined;
                                if (inputRef.current) {
                                    if (inputRef.current.value === undefined || inputRef.current.value === null || inputRef.current.value === '') {
                                        controlThis.setState({currentValue: undefined});
                                        controlThis.props.OnValueChange(undefined);
                                    } else {
                                        controlThis.setState({currentValue: inputRef.current.value});
                                        controlThis.props.OnValueChange(inputRef.current.value);
                                    }
                                }
                            }, this.props.Delay);
                        }}
                    />
                </div>

                <IconButton  AppObject={this.props.AppObject!}
                    Tag='done'
                    IconRef={IconClose(undefined)}
                    PressTickInterval={0}
                    OnPressStart={()=>{
                    }}
                    OnPressTick={()=>{
                    }}
                    OnPressFinish={async (): Promise<boolean>=>{
                        if (controlThis.state.currentValue !== undefined) {
                            if (inputRef.current) {
                                inputRef.current.value = this.props.DefaultPrompt;
                                inputRef.current.blur();
                            }
                            controlThis.setState({currentValue: undefined});
                            controlThis.props.OnValueChange(undefined);
                        }
                        return true;
                    }}
                    StartPushedState={false}
                    Disabled={this.state.currentValue === undefined}
                    Haptic={this.props.Haptic}
                    Height={this.props.Scale * 31}
                />
                
            </div>);


    }
}



