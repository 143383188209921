import React, { Component } from 'react';
import { Footer } from '../smesshy';
import { CloseButton } from './iconButton';
import { SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';

export interface SmessageState extends SmesshyCommonState {
}
export interface SmessageProps extends SmesshyCommonProps {
  Title: string;
  Say: string;
  Loading: boolean;
  CloseNav?: string | undefined;
}


class Smessage extends SmesshyCommon(Component<SmessageProps, SmessageState>) {

    constructor(props: SmessageProps) {
        super(props);
        this.initCommon(props.AppObject);


        this.state = {
            authenticated: false,
            showWaitSpin: false
        };
    }

    render() {
                   
        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={false}
                RequireAuth={false}
                Title = {<span>{this.props.Title}</span>}
                CloseNav = {this.props.CloseNav}
                Body={
                    <div className='game-page-mid' >
                        <div className='v-items v-medium-gap v-padding-large h-padding-medium height-100p width-100p'>
                        {this.props.Say}
                        </div>
                    </div>
                }
            />, this.state);
    }
}

export default Smessage;
