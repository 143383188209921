const randoFacts = [
        `Banging your head against a wall for one hour burns 150 calories.`,
        `In Switzerland, it is illegal to own just one guinea pig.`,
        `The color orange was actually named after oranges.`,
        `Snakes can predict earthquakes.`,
        `Crows often hold grudges against specific people.`,
        `The oldest "your mom" joke was discovered on a 3,500-year-old Babylonian tablet.`,
        `You get Goosebumps when you're scared to make yourself look bigger.`,
        `May 29 is officially "Put a Pillow on Your Fridge Day."`,
        `Cherophobia is the irrational fear of fun or happiness.`,
        `7% of American adults believe that chocolate milk comes from brown cows.`,
        `If you lift a kangaroo's tail off the ground, it can't hop.`,
        `Bananas are curved because they grow towards the sun.`,
        `Most Korean people don't have armpit odor.`,
        `The original London Bridge is now in Arizona.`,
        `During your lifetime, you will produce enough saliva to fill 50 bathtubs.`,
        `Polar bears could eat as many as 10 penguins in a single sitting…`,
        `Car manufacturer Volkswagen makes sausages.`,
        `Movie trailers were originally shown after the movie, which is why they're called "trailers."`,
        `Hawaiian pizza is a Canadian invention.`,
        `The smallest bone in your body is in your ear.`,
        `Tennis players can be fined up to $20,000 for swearing while playing at Wimbledon.`,
        `Only 5% of the ocean has been explored.`,
        `Most people fart around 14 to 23 times a day!`,
        `There is a species of spider called the Hobo Spider.`,
        `A lion's roar can be heard from 5 miles away.`,
        `Saint Lucia is the only country in the world named after a woman.`,
        `The national animal of Scotland is a unicorn.`,
        `The United States Navy used Xbox controllers for their periscopes.`,
        `The word "burrito" means "little donkey" in Spanish.`,
        `A baby octopus is about the size of a flea when it is born.`,
        `A sheep, a duck, and a rooster were the first passengers to take a trip in a hot air balloon.`,
        `In Uganda, around 48% of the population is under 15 years of age.`,
        `On average, men get bored of a shopping trip after 26 minutes.`,
        `In the 16th Century, Turkish women could initiate divorce if their husbands didn't provide enough coffee.`,
        `After the premiere of 16 and Pregnant, teen pregnancy rates dropped.`,
        `Squirrels cause approximately 10-20% of US power outages.`,
        `Facebook, Instagram, and Twitter are all banned in China.`,
        `95% of people text things they could never say in person.`,
        `Honeybees can recognize human faces.`,
        `The Battle of Hastings didn't actually take place in Hastings.`,
        `Human blood cells have different lifespans.`,
        `A swarm of 20,000 bees followed a car for two days because their queen was stuck inside.`,
        `Eating carrots can turn your skin orange.`,
        `A crocodile can't poke its tongue out.`,
        `Sea otters hold hands when they sleep so they don't drift away from each other.`,
        `A small child could swim through the veins of a blue whale.`,
        `The word "y'all" dates back to at least 1631.`,
        `J.K. Rowling chose the unusual name "Hermione" so young girls wouldn't be teased for being nerdy.`,
        `Hewlett-Packard's (also known as HP) name was decided in a coin toss in 1939.`,
        `There are a total of 1,710 steps in the Eiffel Tower.`,
        `The names of the Pokémon Hitmonlee and Hitmonchan are based on Bruce Lee and Jackie Chan.`,
        `Pigs are considered to be the world's fifth-smartest animal.`,
        `Pirates wore earrings because they believed they improved their eyesight.`,
        `Los Angeles original name is "El Pueblo de Nuestra Senora la Reina de Los Angeles de Porciuncula."`,
        `The Twitter bird actually has a name - Larry.`,
        `Octopuses actually have six arms and two legs!`,
        `Pound cake got its name because the original recipe contained a pound of butter, sugar, flour, and eggs.`,
        `The German chocolate cake has nothing to do with Germany. A Texan invented the cake and used baking chocolate named after the creator, Sam German.`,
        `Mike Tyson once offered a zoo attendant $10,000 to let him fight a gorilla.`,
        `ABBA turned down 1 billion dollars to do a reunion tour.`,
        `Abraham Lincoln fed his cat with a gold fork.`,
        `There is a nut on a helicopter nicknamed the "Jesus Nut."`,
        `Some insects and small birds see the world in slow motion.`,
        `A goat called William Windsor served as a Lance Corporal in the British Army.`,
        `The most venomous jellyfish in the world is the Irukandji.`,
        `March 20th is known as Snowman Burning Day.`,
        `Queen Elizabeth II wasn't allowed to sit on the Iron Throne from Game of Thrones.`,
        `There is an official Wizard of New Zealand.`,
        `An apple, potato, and onion taste the same if you eat them with your nose plugged.`,
        `The longest word you're likely to encounter on an everyday basis is "uncharacteristically."`,
        `A company in Poland makes dinnerware out of wheat so you can eat your plate!`,
        `The average person walks the equivalent of five times around the world in their lifetime.`,
        `Michael Jackson offered to make a Harry Potter musical, but the offer was rejected.`,
        `The world record for stuffing drinking straws into your mouth at once is 650.`,
        `Nutella was invented shortly after WWII ended because chocolate was so expensive.`,
        `North Korea has its own space program.`,
        `According to Genesis 1:20-22, the chicken came before the egg.`,
        `Ants leave maps for other ants when they walk.`,
        `Tears contain a natural painkiller, which reduces pain and improves your mood.`,
        `Saturn is the only planet in our solar system that could float in water.`,
        `Male cats have longer tails than female cats.`,
        `Dolly Parton lost in a Dolly Parton look-alike contest.`,
        `George W. Bush was once a cheerleader.`,
        `In total, there are 205 bones in the skeleton of a horse.`,
        `Coca-Cola once bought all the website URLs that can be read as ahh, all the way up to 62 h's.`,
        `Each year there are more than 40,000 toilet-related injuries in the US.`,
        `Strawberries can be red, yellow, green, or white.`,
        `Walt Disney World is the second-largest buyer of fireworks in the US.`,
        `Four people lived in a home for six months infested with over 2,000 venomous spiders.`,
        `If you fell into a volcano, you'd float on the lava instead of melting.`,
        `The giant stone heads on Easter Island have hidden bodies!`,
        `Kleenex tissues were originally used as filters in gas masks.`,
        `In 1998, Sony accidentally sold 700,000 camcorders that could see through people's clothes.`,
        `During your lifetime, you will spend around seventy-nine days brushing your teeth.`,
        `Ronald McDonald is "Donald McDonald" in Japan because it makes pronunciation easier for the Japanese.`,
        `Three presidents, all Founding Fathers—John Adams, Thomas Jefferson, and James Monroe—died on July 4. Presidents Adams and Jefferson also died the same year, 1826; President Monroe died in 1831.`,
        `The Barbie doll's full name is Barbara Millicent Roberts, from Willows, Wisconsin. Her birthday is March 9, 1959, when she was first displayed at the New York Toy Fair.`,
        `There actually aren't "57 varieties" of Heinz ketchup, and never were. Company founder H.J. Heinz thought his product should have a number, and he liked 57.`,
        `The 10th US President, John Tyler born in 1790, has a grandson who is still alive today. President Tyler was 63 when his son Lyon Tyler was born in 1853; Lyon's son was born when he was 75. President Tyler's living grandson, Harrison Tyler is 94. Lyon's other son Lyon Jr. passed away in 2020 at the age of 95. The Tyler family still maintains the President's home, Sherwood Forest Plantation in Virginia.`,
        `The tallest man ever recorded was American giant Robert Wadlow (1918-1940), who stood 8 feet 11 inches. Wadlow's size was the result of an abnormally enlarged pituitary gland.`,
        `The oldest person ever to have lived (whose age could be authenticated), a French woman named Jeanne Louise Calment, was 122 years old when she died in 1997.`,
        `Sliced bread was first manufactured by machine and sold in the 1920s by the Chillicothe Baking Company in Missouri.`,
        `The Earl of Sandwich, John Montagu, who lived in the 1700s, reportedly invented the sandwich so he wouldn't have to leave his gambling table to eat`,
        `The first college football game was played on November 6, 1869, between Rutgers and Princeton (then known as the College of New Jersey) in New Brunswick, New Jersey. Rutgers won.`,
        `Experiments in universities have actually been carried out to figure out how many licks it takes to get to the center of a Tootsie Pop, both with machine and human lickers. The results ranged from 252 to 411.`,
        `Canada is south of Detroit.`,
        `The original name for the search engine Google was Backrub. It was renamed Google after the googol, which is the number one followed by 100 zeros.`,
        `The oldest-known living land animal is a tortoise named Jonathan, who is about to turn 190 years old. He was born in 1832 and has lived on the island of St. Helena in the Atlantic Ocean since 1882.`,
        `Bats are the only mammal that can actually fly.`,
        `Wombats are the only animal whose poop is cube-shaped. This is due to how its intestines form the feces. The animals then stack the cubes to mark their territory.`,
        `The most common wild bird in the world isn't the sparrow or blue jay—it's the red-billed quelea, which live in Africa and have an estimated population of 1.5 billion.`,
        `The heart of the blue whale, the largest animal on earth, is five feet long and weighs 400 pounds. The whale in total weighs 40,000 pounds.`,
        `Elephants can't jump.`,
        `Octopuses have three hearts.`,
        `Cows don't actually have four stomachs; they have one stomach with four compartments.`,
        `The platypus doesn't have a stomach at all: Their esophagus goes straight to their intestines.`,
        `Polar bears have black skin. And actually, their fur isn't white—it's see-through, so it appears white as it reflects light.`,
        `Tigers' skin is actually striped, just like their fur. Also, no two fur patterns are alike.`,
        `Flamingoes are only pink because of chemicals called carotenoids in the algae and fish (which also eat the algae) they eat; their feathers are grayish-white when they're born.`,
        `Mosquitoes are the deadliest animal in the world: They kill more people than any other creature, due to the diseases they carry.`,
        `Psycho was the first movie to show a toilet flushing.`,
        `One of the most famous movie lines in history was never said. We often quote, "Play it again, Sam," from Casablanca; but the real line is, "Play it, Sam. Play 'As Time Goes By.'"`,
        `The green code in The Matrix was actually created from symbols in the code designer's wife's sushi cookbook.`,
        `The wedding of Princess Diana and Prince Charles was watched by 750 million people worldwide in 1981; sadly, 2.5 billion watched her funeral in 1997.`,
        `There are no muscles in your fingers: Their function is controlled by muscles in your palms and arms.`,
        `It's impossible to hum while holding your nose.`,
        `All of an adult human's blood vessels, if laid out end to end, would be about 100,000 miles, so they could encircle the earth four times.`,
        `According to recent research, the human nose can distinguish at least a trillion different odors.`,
        `The longest fingernails ever were over 42 feet in total and belonged to American Diana Armstrong, recognized as the new record holder in March 2022. The previous record was held by American Lee Redmond, with fingernails over 28 feet in total.`,
        `The origin of the word "sinister" reflects a historical bias against left-handed people. It comes from the Latin word for "left," which was also seen to be unlucky or evil.`,
        `The word "strengths" is the longest word in the English language with only one vowel.`,
        `The original title of Jane Austen's Pride and Prejudice was First Impressions.`,
        `Mary Shelley wrote Frankenstein when she 18, during a ghost story competition while staying in Switzerland with writers Percy Shelley (her lover) and Lord Byron.`,
        `Almost all commercially grown artichokes (99.9 percent) come from California. One town in particular, Castroville, is nicknamed "the Artichoke Capital of the World."`,
        `The different colors of Froot Loops cereal all taste the same—they're not individual flavors.`,
        `What's inside a Kit Kat? Broken Kit Kats that are damaged during production—they get ground up and go between the wafers inside, along with cocoa and sugar.`,
        `Nobody will ever see this one, right?`
        ];

function PickRandomFact() : string {
    let i = Math.min(Math.floor(Math.random() * randoFacts.length), randoFacts.length-1);
    return randoFacts[i];
}
export default PickRandomFact