import { timeout } from "workbox-core/_private";
import {App} from "./App";
import { UserPreferences } from "typescript";
import ImageLocate from "./components/imageLocate";
import { STrace, SmesshyTrace } from "./smesshyCommon";


export interface SmesshyUserPreferences {
    autoApprove?: boolean | undefined;
    defaultPublic?: boolean | undefined;
    notifications?: string | undefined;
}

export interface ChallengeState {
    challengeId: number;
    state: string;
    title: string;
    prizePool: number;
    timeLeft: number;
    roundTimeLeft: number;
    voteRoundsTotal: number;
    voteRoundCurrent: number;
    stampAllowed: boolean;
}

export interface ChallengeResult {
    challengeId?: number;
    title: string;
    place1?: PaintingLocate;
    place2?: PaintingLocate;
    place3?: PaintingLocate;
    voterSelected?: string;
}

export interface VoteRoundStatus {
    voteRoundsTotal: number;
    voteRoundCurrent: number;
    userStatus: string;
    roundSecondsLeft: number;
}

export interface ArtistStats {
    asOf: string;
    level: string;
    totalPaintings: number;
    totalFavoredByFollowers: number;
    totalFavoredByOthers: number;
    totalOffend: number;
    totalOffendedBy: number;
    totalSuspect: number;
    totalSuspectOf: number;
    totalFavorites: number;
    offendedAlignment: number;
    suspectedAlignment: number;
    lunchboxDeliveriesSent: number;
    lunchboxDeliveriesSeen: number;
    lunchboxDeliveriesReceived: number;
    lunchboxDeliveriesViewed: number;
    longestLunchboxStreak: number;
    competitionEntries: number;
    competitionVotes: number;
    competitionVoteAlignment: number;
    competitionHighestRank: number;
    competitionVotesReceived: number;
    competitionPointsReceived: number;
    totalFollowers: number;
    totalFollowing: number;
    averageFollowerMavenScore: number;
    averageFollowingMavenScore: number;
    mavenScore: number;
    totalPaintingsRank: number;
    totalFavoredByFollowersRank: number;
    totalFavoredByOthersRank: number;
    totalOffendRank: number;
    totalOffendedByRank: number;
    totalSuspectRank: number;
    totalSuspectOfRank: number;
    totalFavoritesRank: number;
    offendedAlignmentRank: number;
    suspectedAlignmentRank: number;
    lunchboxDeliveriesSentRank: number;
    lunchboxDeliveriesSeenRank: number;
    lunchboxDeliveriesReceivedRank: number;
    lunchboxDeliveriesViewedRank: number;
    longestLunchboxStreakRank: number;
    competitionEntriesRank: number;
    competitionVotesRank: number;
    competitionVoteAlignmentRank: number;
    competitionHighestRankRank: number;
    competitionVotesReceivedRank: number;
    competitionPointsReceivedRank: number;
    totalFollowersRank: number;
    totalFollowingRank: number;
    averageFollowerMavenScoreRank: number;
    averageFollowingMavenScoreRank: number;
    mavenScoreRank: number;

}

export interface ArtistProfile extends SmesshyUserPreferences {
    artistId: string;
    artistName: string | undefined;
    artistImageId?: string | undefined;
    artistImageUrl: string | undefined;
    artistStats: ArtistStats | undefined;
}

export interface PaintingLocate {
    artistId: string;
    paintingId: string;
}

export interface PaintingActions {
    saveAction : string;
    drawingAction : string;
}

export interface PaintingImage {
    paintingId?: string;
    thumbnail: string;
    preview: string;
    actions: string;
}

export interface PaintingInfo {
    paintingId?: string;
    visibilityKind: string;
    visibilityId?: number;
    challengeName?: string;
    createDateTime?: string;
    imageDetail?: PaintingImage;
    userBookmark?: boolean;
    userFavorite?: boolean;
    totalFavorites?: number;
    userOffended?: boolean;
    totalOffended?: number;
    userSuspect?: boolean;
    totalSuspect?: number;
    totalVotes?: number;
    totalPoints?: number;
    finalRank?: number;
    lunchboxViewScore?: number;
    artistProfile: ArtistProfile
}

export interface LunchboxDelivery {
    deliveryId: number;
    paintingId: string;
    userDays: number;
    toUsers: Array<string>;
}

export interface LunchBoxStreakReported {
    userId: string;
    otherId: string;
    currentStreak: number;
    secondsUntilStreakEnd: number;
}

export interface UserRewardSummary {
    userId: string;
    totalRewardGranted: number;
    currentRewardBalance: number;
    pendingPayoutRequestDateTime: string;
    pendingPayoutRequestAmount: number;
    pendingPayoutRequestStatus: string;
}

export function MakePaintingLocate(info: PaintingInfo): PaintingLocate {
    return {artistId: info.artistProfile.artistId, paintingId: info.paintingId!};
}

export class SmesshyDiagnosticLog {
    lines : Array<string> = new Array<string>();
    public log(line: string) {
        this.lines.push(line);
    }
}

class TimeoutList {
    startTime: number;
    maxTime: number;
    items: Array<string>;
    containsValues: boolean;    
    knownFull: boolean;
    public constructor(duration: number) {
        this.startTime = Date.now();
        this.maxTime = this.startTime + duration;
        this.items = new Array<string>();
        this.containsValues = false;
        this.knownFull = false;
    }
    public isValid() : boolean {
        return (this.containsValues || this.knownFull) && Date.now() < this.maxTime;
    }
    public missingRange(firstOffset: number, lastOffset: number): [number, number] | undefined {
        if (this.isValid()) {
            if (firstOffset < this.items.length && lastOffset < this.items.length){
                return undefined;
            }
            if (this.knownFull) {
                if (firstOffset >= this.items.length) {
                    return undefined;
                }
                if (lastOffset >= this.items.length) {
                    return undefined;
                }
            }
            return [Math.max(firstOffset, this.items.length), lastOffset];
        }
        return [firstOffset, lastOffset];
    }
    public expire() : void {
        this.maxTime = Date.now();
    }
}

class SmesshyStorageManager {

    appObject: App;
    allTimeoutLists: Map<string, TimeoutList>;
    cachedPaintingInfos: Map<string, PaintingInfo>;
    cachedThumbnails: Map<string, string>;
    cachedPreviews: Map<string, string>;
    cachedActions: Map<string, string>;
    cachedArtistProfiles:Map<string, ArtistProfile>;

    fiveMinutes = 5 * (60 * 1000);

    constructor(appObject: App) {
        this.appObject = appObject;
        this.allTimeoutLists = appObject.GetAppState('storage-manager-all-timeout-lists', new Map<string, TimeoutList>()) as Map<string, TimeoutList>;
        this.cachedPaintingInfos = appObject.GetAppState('storage-manager-cached-painting-infos', new Map<string, PaintingInfo>()) as Map<string, PaintingInfo>;
        this.cachedThumbnails = appObject.GetAppState('storage-manager-cached-thumbnails', new Map<string, string>()) as Map<string, string>;
        this.cachedPreviews = appObject.GetAppState('storage-manager-cached-previews', new Map<string, string>()) as Map<string, string>;
        this.cachedActions = appObject.GetAppState('storage-manager-cached-actions', new Map<string, string>()) as Map<string, string>;
        this.cachedArtistProfiles = appObject.GetAppState('storage-manager-cached-artists', new Map<string, ArtistProfile>()) as Map<string, ArtistProfile>;
    }

    async makeRequestInit(tokenNeed: string, method: string, body: unknown | undefined) : Promise<RequestInit | undefined> {
        let token: string | undefined = undefined;
        if (tokenNeed !== 'none') {
            STrace.addStep('store', 'getAccessToken', '');
            token = await this.appObject.authService.getAccessToken();
        }
        switch(tokenNeed) {
            case 'none':
                break;
            case 'required':
                if (token === undefined) {
                    return undefined;
                }
                break;
            case 'optional':
                break;
        }
        let trace = STrace.getRecent();
        let headers : HeadersInit = {'Content-Type':'application/json', 'smesshy-api-trace':trace};
        if (token !== undefined) {
            headers = {'Content-Type':'application/json', 'smesshy-api-trace':trace, 'Authorization': `Bearer ${token}`};
        }
        let result : RequestInit = {method:method, headers:headers};

        if (body !== undefined && body !== null) {
            result.body = JSON.stringify(body);
        }

        return result;
    }

    private makeQueryWithArrayParams(baseQuery: string, paramName: string, paramList: Array<string>): string {

        const params = paramList.map(i=>`${paramName}=${encodeURIComponent(i!)}`).join('&');
        // request the thumbnail images for that set
        const query = `${baseQuery}?${params}`;
        return query;
    }


    public async getChallengeStateAsync(challengeId: string | undefined) : Promise<ChallengeState | undefined> {
        if (challengeId === undefined || challengeId === null) {
            challengeId = 'current';
        }
        const reqInit = await this.makeRequestInit('optional', 'GET', undefined);
        let localThis = this;
        try {
            const responseChallengeState = await fetch(`api/challenge/getChallengeState?challengeId=${encodeURIComponent(challengeId)}`, reqInit);
            if (!responseChallengeState.ok) {
                throw new Error('not ok');
            }
            const challengeState: ChallengeState = await responseChallengeState.json();
            return challengeState;
        } catch (e: any){
            localThis.appObject.reportException(`getChallengeState`, 'ex', '', e)
            return undefined;
        }
    }

    public async getChallengeResultAsync(challengeId: string | undefined) : Promise<ChallengeResult | undefined> {
        if (challengeId === undefined || challengeId === null) {
            challengeId = 'current';
        }
        const reqInit = await this.makeRequestInit('optional', 'GET', undefined);
        let localThis = this;
        try {
            const responseChallengeResult = await fetch(`api/challenge/getChallengeResult?challengeId=${encodeURIComponent(challengeId)}`, reqInit);
            if (!responseChallengeResult.ok) {
                throw new Error('not ok');
            }
            const challengeResult: ChallengeResult = await responseChallengeResult.json();
            return challengeResult;
        } catch (e: any){
            localThis.appObject.reportException(`getChallengeResult`, 'ex', '', e)
            return undefined;
        }
    }

    public async getChallengeUserVoteStatusAsync(challengeId: string | undefined) : Promise<VoteRoundStatus | undefined> {
        if (challengeId === undefined || challengeId === null) {
            challengeId = 'current';
        }

        const reqInit = await this.makeRequestInit('required', 'GET', undefined);
        let localThis = this;
        try {
            const responseVoteStatus = await fetch(`api/challenge/getChallengeUserVoteStatus?challengeId=${encodeURIComponent(challengeId)}`, reqInit);
            if (!responseVoteStatus.ok) {
                throw new Error('not ok');
            }
            const voteStatus: VoteRoundStatus = await responseVoteStatus.json();
            return voteStatus;
        } catch (e: any){
            localThis.appObject.reportException(`getChallengeUserVoteStatus`, 'ex', '', e)
            return undefined;
        }
    }

    public async putChallengeUserVoteStatusAsync(challengeId: string | undefined) {
        if (challengeId === undefined || challengeId === null) {
            challengeId = 'current';
        }

        const reqInit = await this.makeRequestInit('required', 'PUT', undefined);
        let localThis = this;
        try {
            const responseVoteStatus = await fetch(`api/challenge/putChallengeUserVoteStatus?challengeId=${encodeURIComponent(challengeId)}`, reqInit);
            if (!responseVoteStatus.ok) {
                throw new Error('not ok');
            }
        } catch (e: any){
            localThis.appObject.reportException(`putChallengeUserVoteStatus`, 'ex', '', e)
        }
    }
    // public async putAdvanceAsync() {
    //     const reqInit = await this.makeRequestInit('required', 'PUT', undefined);
    //     let localThis = this;
    //     try {
    //         const responseVoteStatus = await fetch(`api/challenge/putAdvance`, reqInit);
    //         if (!responseVoteStatus.ok) {
    //             throw new Error('not ok');
    //         }
    //     } catch (e: any){
    //         localThis.appObject.reportException(`putAdvance`, e)
    //     }
    // }

    public async getChallengeVoteImagesAsync(challengeId: string | undefined) : Promise<Array<PaintingLocate> | undefined> {
        if (challengeId === undefined || challengeId === null) {
            challengeId = 'current';
        }
        const reqInit = await this.makeRequestInit('required', 'GET', undefined);
        let localThis = this;
        try {
            const responseVoteImages = await fetch(`api/challenge/getChallengeVoteImages?challengeId=${encodeURIComponent(challengeId)}`, reqInit);
            if (!responseVoteImages.ok) {
                throw new Error('not ok');
            }
            const voteImages: Array<PaintingLocate> = await responseVoteImages.json();
            return voteImages;
        } catch (e: any){
            localThis.appObject.reportException(`getChallengeVoteImages`, 'ex', '', e)
            return undefined;
        }
    }
    public async postVoteScoresAsync(challengeId: string | undefined, scores: Array<number>) : Promise<string> {
        if (challengeId === undefined || challengeId === null) {
            challengeId = 'current';
        }
        let localThis = this;
        const reqInit = await this.makeRequestInit('required', 'POST', scores);
        if (reqInit) {
            try {
                const responseCurrentPut = await fetch(`api/challenge/postChallengeVoteScores?challengeId=${encodeURIComponent(challengeId)}`, reqInit);
                if (!responseCurrentPut.ok) {
                    throw new Error('not ok');
                }
                const result = await responseCurrentPut.text();
                return result;
            } catch (e: any){
                localThis.appObject.reportException(`putChallengeVoteScores`, 'ex', '', e)
            }
        }
        return 'noauth'
    }


    public async putUserPaintingAsync(visibilityKind: string, thumb: string, preview: string, actions: string) : Promise<PaintingInfo> {
        let localThis = this;

        const paintingImg : PaintingImage = {
            thumbnail: thumb,
            preview: preview,
            actions: actions
        };
        const paintingInfo : PaintingInfo = {
            visibilityKind: visibilityKind,
            imageDetail: paintingImg,
            artistProfile: undefined as any as ArtistProfile
        }
        
        const reqInit = await this.makeRequestInit('required', 'POST', paintingInfo);

        if (reqInit) {
            try {
                const responseCurrentPut = await fetch(`api/userPaintings`, reqInit);
                if (!responseCurrentPut.ok) {
                    throw new Error('not ok');
                }
                const newPInfo = await responseCurrentPut.json() as PaintingInfo;
                paintingInfo.paintingId=newPInfo.paintingId;
                paintingInfo.createDateTime=newPInfo.createDateTime;
                paintingInfo.artistProfile=newPInfo.artistProfile;
                paintingInfo.challengeName=newPInfo.challengeName;
                paintingInfo.visibilityKind=newPInfo.visibilityKind;
                paintingInfo.visibilityId=newPInfo.visibilityId;
                const tl = localThis.getTimeoutList('paintingId', 'user created', 'recent');

                if (tl.isValid()) {
                    tl.containsValues = false;
                    let newList = localThis.getTimeoutList('paintingId', 'user created', 'recent');
                    newList.containsValues = true;
                    newList.knownFull = tl.knownFull;
                    newList.items.push(paintingInfo.paintingId!);
                    newList.items.push(...tl.items)
                    localThis.cachedPaintingInfos.set(paintingInfo.paintingId!, paintingInfo);
                    localThis.cachedThumbnails.set(paintingInfo.paintingId!, thumb);
                    localThis.cachedPreviews.set(paintingInfo.paintingId!, preview);
                    localThis.cachedActions.set(paintingInfo.paintingId!, actions);
                }
                return paintingInfo;
            } catch (e: any){
                localThis.appObject.reportException(`PaintingPage upload challenge`, 'ex', '', e)
            }
        }
        return undefined as any as PaintingInfo;
    }

    public async deleteUserPaintingAsync(paintingId: string) : Promise<void> {
        let localThis = this;
       
        const reqInit = await this.makeRequestInit('required', 'DELETE', paintingId);

        if (reqInit) {
            try {
                const responseCurrentPut = await fetch(`api/userPaintings`, reqInit);
                if (!responseCurrentPut.ok) {
                    throw new Error('not ok');
                }
                const tl = localThis.getTimeoutList('paintingId', 'user created', 'recent');
                tl.expire();

            } catch (e: any){
                localThis.appObject.reportException(`deleteUserPaintingAsync`, 'ex', '', e)
            }
        }
    }

    public async getUserInChallengeAsync() : Promise<boolean> {
        let localThis = this;
       
        const reqInit = await this.makeRequestInit('required', 'GET', undefined);

        if (reqInit) {
            try {
                const responseInChallenge = await fetch(`api/userPaintings/getInChallenge`, reqInit);
                if (!responseInChallenge.ok) {
                    throw new Error('not ok');
                }
                const inIt = await responseInChallenge.text();
                return inIt === "true";

            } catch (e: any){
                localThis.appObject.reportException(`getInChallenge`, 'ex', '', e)
            }
        }
        return false;
    }
    public async putUserBookmarkPaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'POST', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/postBookmark`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userBookmark = true;
                }

            } catch (e: any){
                localThis.appObject.reportException(`putUserBookmarkPainting`, 'ex', '', e)
            }
        }
    }
    public async deleteUserBookmarkPaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'DELETE', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/deleteBookmark`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userBookmark = false;
                }

            } catch (e: any){
                localThis.appObject.reportException(`deleteUserBookmarkPainting`, 'ex', '', e)
            }
        }
    }

    public async putUserFavoritePaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'POST', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/postFavorite`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userFavorite = true;
                    if (pin.totalFavorites !== undefined && pin.totalFavorites !== null) {
                        pin.totalFavorites ++;
                    } else {
                        pin.totalFavorites = 1;
                    }
                }

            } catch (e: any){
                localThis.appObject.reportException(`putUserFavoritePainting`, 'ex', '', e)
            }
        }
    }
    public async deleteUserFavoritePaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'DELETE', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/deleteFavorite`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userFavorite = false;
                    if (pin.totalFavorites !== undefined && pin.totalFavorites !== null) {
                        pin.totalFavorites --;
                    } else {
                        pin.totalFavorites = 0;
                    }
                }

            } catch (e: any){
                localThis.appObject.reportException(`deleteUserFavoritePainting`, 'ex', '', e)
            }
        }
    }
    public async putUserOffensivePaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'POST', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/postOffensive`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userOffended = true;
                    if (pin.totalOffended !== undefined && pin.totalOffended !== null) {
                        pin.totalOffended ++;
                    } else {
                        pin.totalOffended = 1;
                    }
                }

            } catch (e: any){
                localThis.appObject.reportException(`putUserOffensivePainting`, 'ex', '', e)
            }
        }
    }
    public async deleteUserOffensivePaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'DELETE', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/deleteOffensive`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userOffended = false;
                    if (pin.totalOffended !== undefined && pin.totalOffended !== null) {
                        pin.totalOffended --;
                    } else {
                        pin.totalOffended = 0;
                    }
                }

            } catch (e: any){
                localThis.appObject.reportException(`deleteUserOffensivePainting`, 'ex', '', e)
            }
        }
    }
    public async putUserSuspectPaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'POST', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/postSuspect`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userSuspect = true;
                }

            } catch (e: any){
                localThis.appObject.reportException(`putUserSuspectPainting`, 'ex', '', e)
            }
        }
    }
    public async deleteUserSuspectPaintingAsync(locate: PaintingLocate) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'DELETE', locate);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responseFavPut = await fetch(`api/assesment/deleteSuspect`, reqInit);
                if (!responseFavPut.ok) {
                    throw new Error('not ok');
                }
                if (this.cachedPaintingInfos.has(locate.paintingId)) {
                    const pin = this.cachedPaintingInfos.get(locate.paintingId)!;
                    pin.userSuspect = false;
                }

            } catch (e: any){
                localThis.appObject.reportException(`deleteUserSuspectPainting`, 'ex', '', e)
            }
        }
    }

    public getPaintingThumbs(token: string, pins: Array<PaintingInfo>, onGet: (pins: Array<PaintingInfo>)=>void) {
        let localThis = this;

        const missingThumbs = new Array<PaintingInfo>();
        for (const pin of pins) {
            if (pin.imageDetail === undefined) {
                pin.imageDetail = {paintingId: pin.paintingId, thumbnail: '', preview: '', actions:''};
            }
            if (pin.imageDetail.thumbnail === undefined || pin.imageDetail.thumbnail === null || pin.imageDetail.thumbnail === '') {
                if (this.cachedThumbnails.has(pin.paintingId!)) {
                    pin.imageDetail.thumbnail = this.cachedThumbnails.get(pin.paintingId!)!;
                } else {
                    missingThumbs.push(pin);
                }
            }
        }

        if (missingThumbs.length === 0) {
            onGet(pins);
            return;
        }

        this.fetchThumbs(token, missingThumbs)
        .then(respThum => {
            if (!respThum.ok) {
                localThis.appObject.reportException('fetchThumbs', 'resp', respThum.status.toString(), respThum.statusText);
            } else {
                respThum.json()
                    .then((thumbs: Array<string>)=>{
                        localThis.addThumbs(missingThumbs, thumbs);
                        onGet(pins);
                    })
                    .catch((reason) => {localThis.appObject.reportException('fetchThumbs.json', 'ex', '', reason); } );
            }
        })
        .catch((reason) => {localThis.appObject.reportException('fetchThumbs', 'ex', '', reason); } );
    }

    public getPaintingPreviews(token: string, pins: Array<PaintingInfo>, onGet: (pins: Array<PaintingInfo>)=>void) {
        let localThis = this;

        const missingPreviews = new Array<PaintingInfo>();
        for (const pin of pins) {
            if (pin.imageDetail === undefined) {
                pin.imageDetail = {paintingId: pin.paintingId, thumbnail: '', preview: '', actions:''};
            }
            if (pin.imageDetail.preview === undefined || pin.imageDetail.preview === null || pin.imageDetail.preview === '') {
                if (this.cachedPreviews.has(pin.paintingId!)) {
                    pin.imageDetail.preview = this.cachedPreviews.get(pin.paintingId!)!;
                } else {
                    missingPreviews.push(pin);
                }
            }
        }

        if (missingPreviews.length === 0) {
            onGet(pins);
            return;
        }

        this.fetchPreviews(token, missingPreviews)
        .then(respPrev => {
            if (!respPrev.ok) {
                localThis.appObject.reportException('fetchPreviews', 'resp', respPrev.status.toString(), respPrev.statusText);
            } else {
                respPrev.json()
                    .then((prevs: Array<string>)=>{
                        localThis.addPreviews(missingPreviews, prevs);
                        onGet(pins);
                    })
                    .catch((reason) => {localThis.appObject.reportException('fetchPreviews.json', 'ex', '', reason); } );
            }
        })
        .catch((reason) => {localThis.appObject.reportException('fetchPreviews', 'ex', '', reason); } );
    }

    public getPaintingActions(token: string, pin: PaintingInfo, onGet: (pins: PaintingInfo)=>void) {
        let localThis = this;

        if (pin.imageDetail === undefined) {
            pin.imageDetail = {paintingId: pin.paintingId, thumbnail: '', preview: '', actions:''};
        }
        if (pin.imageDetail.actions === undefined || pin.imageDetail.actions === null || pin.imageDetail.actions === '') {
            if (this.cachedActions.has(pin.paintingId!)) {
                pin.imageDetail.actions = this.cachedActions.get(pin.paintingId!)!;
            } 
        }

        if (!(pin.imageDetail.actions === undefined || pin.imageDetail.actions === null || pin.imageDetail.actions === '')) {
            onGet(pin);
            return;
        }

        this.fetchActions(token, pin)
        .then(respActions => {
            if (!respActions.ok) {
                localThis.appObject.reportException('fetchActions', 'resp', respActions.status.toString(), respActions.statusText);
            } else {
                respActions.json()
                    .then((actions: Array<string>)=>{
                        pin.imageDetail!.actions = actions[0];
                        localThis.cachedActions.set(pin.paintingId!, actions[0])                        
                        onGet(pin);
                    })
                    .catch((reason) => {localThis.appObject.reportException('fetchActions.json', 'ex', '', reason); } );
            }
        })
        .catch((reason) => {localThis.appObject.reportException('fetchActions', 'ex', '', reason); } );
    }

    public getUserPaintingsWithThumbs(token:string, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'user created', kind);
        this.getPaintingsWithThumbs(token, tl, SmesshyStorageManager.fetchUserPaintings, firstOffset, lastOffset, kind, onGet);
    }

    public getFollowingPaintingsWithThumbs(token:string, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'following', kind);
        this.getPaintingsWithThumbs(token, tl, SmesshyStorageManager.fetchFollowingPaintings, firstOffset, lastOffset, kind, onGet);
    }

    public getGlobalPaintingsWithThumbs(token:string, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'global', kind);
        this.getPaintingsWithThumbs(token, tl, SmesshyStorageManager.fetchGlobalPaintings, firstOffset, lastOffset, kind, onGet);
    }

    public getUserPaintingsWithPreviews(token:string, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'user created', kind);
        this.getPaintingsWithPreviews(token, tl, SmesshyStorageManager.fetchUserPaintings, firstOffset, lastOffset, kind, onGet);
    }

    public getFollowingPaintingsWithPreviews(token:string, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'following', kind);
        this.getPaintingsWithPreviews(token, tl, SmesshyStorageManager.fetchFollowingPaintings, firstOffset, lastOffset, kind, onGet);
    }

    public getGlobalPaintingsWithPreviews(token:string, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'global', kind);
        this.getPaintingsWithPreviews(token, tl, SmesshyStorageManager.fetchGlobalPaintings, firstOffset, lastOffset, kind, onGet);
    }

    public getOthersPaintingsWithPreviews(token:string, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'other', kind);
        this.getPaintingsWithPreviews(token, tl, SmesshyStorageManager.fetchOthersPaintings, firstOffset, lastOffset, kind, onGet);
    }

    public getCurrentLunchboxPaintingsWithPreviews(token:string, firstOffset:number, lastOffset:number, onGet: (pins: Array<PaintingInfo>)=>void) {
        const tl = this.getTimeoutList('paintingId', 'following', 'lunchbox');
        this.getPaintingsWithPreviews(token, tl, SmesshyStorageManager.fetchLunchboxPaintings, firstOffset, lastOffset, 'lunchbox', onGet);
    }

    public async getGlobalPaintingsInfosAsync(locates: Array<PaintingLocate>): Promise<Array<PaintingInfo> | undefined> {
        
        const artistIdParams = locates.map(i=>`artistIds=${encodeURIComponent(i.artistId!)}`).join('&');
        const paintingIdParams = locates.map(i=>`paintingIds=${encodeURIComponent(i.paintingId!)}`).join('&');
        const query = `api/globalPaintings/getInfos?${artistIdParams}&${paintingIdParams}`;

        const reqInit = await this.makeRequestInit('optional', 'GET', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('getGlobalPaintingsInfosAsync', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    const list = await resp.json() as Array<PaintingInfo>;
                    if (list !== undefined && list !== null && list.length > 0) {
                        return list;
                    }
                }
            } catch (e: any){
                this.appObject.reportException(`getGlobalPaintingsInfosAsync`, 'ex', '', e)
            }
        }
        return undefined;
    }

    public async getUserFollowingAsync(status: string, mutual: boolean): Promise<Set<string>> {
        const tl = this.getTimeoutList('userId', `following${mutual?'-m':''}`, status);
        if (!tl.knownFull) {
            const query = mutual !== true ? `api/userFollowings/getFollowing?status=${status}` : `api/userFollowings/getMutualFollowing`;
            //console.log(query);
            const reqInit = await this.makeRequestInit('required', 'GET', undefined);
            if (reqInit !== undefined) {
                try {
                    const resp = await fetch(query, reqInit);
                    if (!resp.ok) {
                        this.appObject.reportException('getUserFollowing', 'resp', resp.status.toString(), resp.statusText);
                    } else {
                        const list = await resp.json() as Array<string>;
                        if (list !== undefined && list !== null && list.length > 0) {
                            tl.containsValues=true;
                            tl.items.push(...list);
                        }
                        tl.knownFull = true;
                    }
                } catch (e: any){
                    this.appObject.reportException(`getUserFollowing`, 'ex', '', e)
                }
            }
        }
        return new Set<string>(tl.items)

    }
    public async postUserFollowingAsync(followId: string): Promise<boolean> {

        //await this.getUserFollowingAsync('pending', false);
        const tl = this.getTimeoutList('userId', 'following', 'pending');

        const query = `api/userFollowings/postFollowing`;
        //console.log(query);
        
        const reqInit = await this.makeRequestInit('required', 'POST', followId);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('postUserFollowing', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    tl.items.push(followId);
                    tl.containsValues=true;
                }
            } catch (e: any){
                this.appObject.reportException(`postUserFollowing`, 'ex', '', e)
            }
        }

        return true;
    }
    public async deleteUserFollowingAsync(followId: string, status: string): Promise<boolean> {

        //await this.getUserFollowingAsync(status, false);
        const tl = this.getTimeoutList('userId', 'following', status);

        const query = `api/userFollowings/deleteFollowing`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'DELETE', followId);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('deleteUserFollowingAsync', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    let idx = tl.items.indexOf(followId);
                    if (idx >= 0) {
                        tl.items.splice(idx, 1);
                    }
                }
            } catch (e: any){
                this.appObject.reportException(`deleteUserFollowingAsync`, 'ex', '', e)
            }
        }

        return true;
    }
    public async deleteUserFollowerAsync(followId: string, status: string): Promise<boolean> {

        //await this.getUserFollowingAsync(status, false);
        const tl = this.getTimeoutList('userId', 'followers', status);

        const query = `api/userFollowings/deleteFollower`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'DELETE', followId);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('deleteUserFollower', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    let idx = tl.items.indexOf(followId);
                    if (idx >= 0) {
                        tl.items.splice(idx, 1);
                    }
                }
            } catch (e: any){
                this.appObject.reportException(`deleteUserFollower`, 'ex', '', e)
            }
        }

        return true;
    }

    public async approveUserFollowerAsync(followId: string): Promise<boolean> {

        STrace.addStep('store', 'getUserFollowersAsync', 'pending');
        await this.getUserFollowersAsync(0, 1000, 'pending');
        const tl = this.getTimeoutList('userId', 'followers', 'pending');

        const query = `api/userFollowings/putApproveFollower`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'PUT', followId);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('approveUserFollowerAsync', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    let idx = tl.items.indexOf(followId);
                    if (idx >= 0) {
                        tl.items.splice(idx, 1);
                    }
                    const tlApp = this.getTimeoutList('userId', 'followers', 'approved');
                    if (tlApp.isValid()) {
                        tlApp.items.push(followId);
                        tlApp.containsValues = true;
                    }

                }
            } catch (e: any){
                this.appObject.reportException(`approveUserFollowerAsync`, 'ex', '', e)
            }
        }

        return true;
    }

    public async getUserFollowersAsync(firstOffset:number, lastOffset:number, status: string): Promise<Array<string>> {
        const tl = this.getTimeoutList('userId', 'followers', status);

        let missing = tl.missingRange(firstOffset, lastOffset);
        if (missing === undefined) {
            return tl.items.slice(firstOffset, lastOffset+1);
        }

        const query = `api/userFollowings/getFollowers?&first=${missing[0]}&last=${missing[1]}&status=${status}`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'GET', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('getUserFollowersAsync', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    const list = await resp.json() as Array<string>;
                    if (list !== undefined && list !== null && list.length > 0) {
                        tl.containsValues=true;
                        tl.items.push(...list);
                        tl.knownFull = list.length < (missing[1]-missing[0] + 1);
                    } else {
                        tl.knownFull = true;
                    }
                }
            } catch (e: any){
                this.appObject.reportException(`getUserFollowersAsync`, 'ex', '', e)
            }
        }
        
        return tl.items.slice(firstOffset, lastOffset+1);

    }

    public async searchArtistProfilesAsync(searchQuery: string, firstOffset:number, lastOffset:number, cancelSignal: AbortSignal, resolve: (profs: Array<ArtistProfile>)=>void) {
        let localThis = this;
        const profsResult = new Array<ArtistProfile>();
        const reqInit = await this.makeRequestInit('required', 'GET', undefined);

        if (reqInit === undefined) {
            resolve(profsResult);
            return;
        }

        // request the thumbnail images for that set
        const query = `api/userProfile/searchArtistProfiles?query=${encodeURIComponent(searchQuery)}&first=${firstOffset}&last=${lastOffset}`;
        //console.log(query);
        reqInit.signal = cancelSignal;
        try {
            let resp = await fetch(query, reqInit);
            if (!resp.ok) {
                localThis.appObject.reportException(`searchArtistProfiles`, 'resp', resp.status.toString(), resp.statusText);
            } else {
                resp.json()
                    .then((profs: Array<ArtistProfile>) => {
                        for (const prof of profs) {
                            profsResult.push(prof);
                            localThis.cachedArtistProfiles.set(prof.artistId, prof);
                        }
                        resolve(profsResult);
                    })
                    .catch((reason) => {localThis.appObject.reportException(`searchArtistProfiles`, 'ex', '', reason); } );
            }
        }
        catch(reason) {
            if ((reason as any).code === 20) {
                // cancel
                return;
            }
            localThis.appObject.reportException(`searchArtistProfiles`, 'ex', '', reason)
        }
    }
    
    public async getArtistProfilesAsync(ids: Array<string>, statsLevel: string, resolve: (profs: Array<ArtistProfile>)=>void) {
        let localThis = this;

        const missingArtists = new Array<string>();
        const profsResult = new Array<ArtistProfile>();
        for (const id of ids) {
            if (!this.cachedArtistProfiles.has(id)) {
                missingArtists.push(id);
            } else {
                profsResult.push(this.cachedArtistProfiles.get(id)!);
            }
        }
        if (missingArtists.length === 0) {
            resolve(profsResult);
            return;
        }

        const idParams = missingArtists.map(i=>`userIds=${encodeURIComponent(i!)}`).join('&');
        // request the thumbnail images for that set
        const query = `api/userProfile/getArtistProfiles?${idParams}&statsLevel=${statsLevel}`;
        //console.log(query);

        const reqInit = await this.makeRequestInit('optional', 'GET', undefined);
        try {
            let resp = await fetch(query, reqInit);
            if (!resp.ok) {
                localThis.appObject.reportException(`getArtistProfiles`, 'resp', resp.status.toString(), resp.statusText);
            } else {
                resp.json()
                    .then((profs: Array<ArtistProfile>) => {
                        for (const prof of profs) {
                            profsResult.push(prof);
                            localThis.cachedArtistProfiles.set(prof.artistId, prof);
                        }
                        resolve(profsResult);
                    })
                    .catch((reason) => {localThis.appObject.reportException(`getArtistProfiles`, 'ex', '', reason); } );
            }
        } catch (reason) {
            localThis.appObject.reportException(`getArtistProfiles`, 'ex', '', reason);
        }
    }

    public async getUserArtistProfileAsync() : Promise<ArtistProfile | undefined> {
        let localThis = this;

        // request the thumbnail images for that set
        const query = `api/userProfile/getUserArtistProfile`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'GET', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('getUserArtistProfile', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    const prof = await resp.json() as ArtistProfile;
                    if (prof !== null && prof !== undefined) {
                        localThis.cachedArtistProfiles.set(prof.artistId, prof);
                        return prof;
                    }
                }
            } catch (e: any){
                this.appObject.reportException(`getUserArtistProfile`, 'ex', '', e)
            }
        }
        return undefined;
    }
    
    public async deleteUserAccountAsync(confirm: string) : Promise<boolean> {
        let localThis = this;

        const query = `account/deleteAccount`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'DELETE', confirm);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responsePost = await fetch(query, reqInit);
                if (!responsePost.ok) {
                    this.appObject.reportException('deleteAccount', 'resp', responsePost.status.toString(), responsePost.statusText);
                }
                return (await responsePost.json()) as boolean;
            } catch (e: any){
                localThis.appObject.reportException(`deleteAccount`, 'ex', '', e)
            }
        }
        return false;
    }

    public async getUserStatsAsync(level: string, entries: number) : Promise<Array<ArtistStats> | undefined> {
        let localThis = this;

        // request the thumbnail images for that set
        const query = `api/statistics/getUserStats?level=${encodeURIComponent(level)}&entries=${encodeURIComponent(entries)}`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'GET', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('getUserStatsAsync', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    const stats = await resp.json() as Array<ArtistStats>;
                    if (stats !== null && stats !== undefined) {
                        return stats;
                    }
                }
            } catch (e: any){
                this.appObject.reportException(`getUserPaintingsAsync`, 'ex', '', e)
            }
        }
        return undefined;
    }

    public async getUserRewardSummaryAsync() : Promise<UserRewardSummary | undefined> {
        let localThis = this;

        // request the thumbnail images for that set
        const query = `api/reward/getUserRewardSummary`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'GET', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('getUserRewardSummary', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    const rewardSummary = await resp.json() as UserRewardSummary;
                    return rewardSummary;
                }
            } catch (e: any){
                this.appObject.reportException(`getUserRewardSummary`, 'ex', '', e)
            }
        }
        return undefined;
    }

    public async requestRewardPayoutAsync() : Promise<void> {
        let localThis = this;

        const query = `api/reward/postUserRewardPayoutRequest`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'POST', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('postUserRewardPayoutRequest', 'resp', resp.status.toString(), resp.statusText);
                } 
            } catch (e: any){
                this.appObject.reportException(`postUserRewardPayoutRequest`, 'ex', '', e)
            }
        }
        return undefined;
    }
    public async requestRewardCancelAsync() : Promise<void> {
        let localThis = this;

        const query = `api/reward/postUserRewardCancelRequest`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'POST', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('postUserRewardCancelRequest', 'resp', resp.status.toString(), resp.statusText);
                } 
            } catch (e: any){
                this.appObject.reportException(`postUserRewardCancelRequest`, 'ex', '', e)
            }
        }
        return undefined;
    }
    public async requestRewardRepeatAsync() : Promise<void> {
        let localThis = this;

        const query = `api/reward/postUserRewardRepeatRequest`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'POST', undefined);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('postUserRewardRepeatRequest', 'resp', resp.status.toString(), resp.statusText);
                } 
            } catch (e: any){
                this.appObject.reportException(`postUserRewardRepeatRequest`, 'ex', '', e)
            }
        }
        return undefined;
    }
    public async testRewardValidateAmountAsync(userReportedAmount: number) : Promise<void> {
        let localThis = this;

        const query = `api/reward/postUserTestRewardValidateAmount`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'POST', userReportedAmount);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('postUserTestRewardValidateAmount', 'resp', resp.status.toString(), resp.statusText);
                } 
            } catch (e: any){
                this.appObject.reportException(`postUserTestRewardValidateAmount`, 'ex', '', e)
            }
        }
        return undefined;
    }


    public async putUserPortraitAsync(portraitId: string) : Promise<void> {

        let localThis = this;

        const query = `api/userProfile/putUserPortrait`;
        //console.log(query);
        const reqInit = await this.makeRequestInit('required', 'PUT', portraitId);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('putUserPortrait', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    return;
                }
            } catch (e: any){
                this.appObject.reportException(`putUserPortrait`, 'ex', '', e)
            }
        }

    }

    public async putUserPreferencesAsync(prefs: SmesshyUserPreferences) : Promise<void> {

        let localThis = this;

        const query = `api/userProfile/putUserPreferences`;
        //console.log(query);
       
        const reqInit = await this.makeRequestInit('required', 'PUT', prefs);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('putUserPreferences', 'resp', resp.status.toString(), resp.statusText);
                } else {
                    return;
                }
            } catch (e: any){
                this.appObject.reportException(`putUserPreferences`, 'ex', '', e)
            }
        }

    }

    public async postLunchboxDeliveryAsync(delivery: LunchboxDelivery) : Promise<void> {
        const query = `api/lunchBox/postDelivery`;

        const reqInit = await this.makeRequestInit('required', 'POST', delivery);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('postLunchboxDeliveryAsync', 'resp', resp.status.toString(), resp.statusText);
                } 
            } catch (e: any){
                this.appObject.reportException(`postLunchboxDeliveryAsync`, 'ex', '', e)
            }
        }

    }

    public async getLunchboxStreakInfoAsync(otherIds: Array<string>): Promise<Array<LunchBoxStreakReported> | undefined> {
        let localThis = this;

        const idParams = otherIds.map(i=>`otherIds=${encodeURIComponent(i!)}`).join('&');
        const query = `api/lunchBox/getStreakInfo?${idParams}`;
        //console.log(query);

        const reqInit = await this.makeRequestInit('required', 'GET', undefined);
        try {
            let resp = await fetch(query, reqInit);
            if (!resp.ok) {
                localThis.appObject.reportException(`getLunchboxStreakInfoAsync`, 'resp', resp.status.toString(), resp.statusText);
            } else {
                return await resp.json() as Array<LunchBoxStreakReported>;
            }
        } catch (reason) {
            localThis.appObject.reportException(`getLunchboxStreakInfoAsync`, 'ex', '', reason);
        }
        return undefined
    }

    public async postPokeLunchboxAsync(otherId: string) : Promise<void> {
        const query = `api/lunchBox/postPoke`;

        const reqInit = await this.makeRequestInit('required', 'POST', otherId);
        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('postPokeLunchboxAsync', 'resp', resp.status.toString(), resp.statusText);
                } 
            } catch (e: any){
                this.appObject.reportException(`postPokeLunchboxAsync`, 'ex', '', e)
            }
        }

    }

    private getPaintingsWithThumbs(token: string, tl: TimeoutList, fetchFunc: (token: string, firstOffset:number, lastOffset:number, kind: string)=>Promise<Response>, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        let localThis = this;

        let missing = tl.missingRange(firstOffset, lastOffset);
        if (missing === undefined) {
            const pins = this.getCachedPaintingInfos(tl.items, firstOffset, lastOffset);
            this.getPaintingThumbs(token, pins, onGet);
            return;
        }

        fetchFunc(token, missing[0], missing[1], kind)
            .then(resp=>{
                if (!resp.ok) {
                    localThis.appObject.reportException(`${fetchFunc.name}`, 'resp', resp.status.toString(), resp.statusText);
                } else {
                    resp.json()
                        .then((pinsAdd: Array<PaintingInfo>) => {
                            lastOffset = localThis.addPaintingInfos(tl, lastOffset, pinsAdd);
                            const pins = this.getCachedPaintingInfos(tl.items, firstOffset, lastOffset);
                            localThis.getPaintingThumbs(token, pins, onGet);
                        })
                        .catch((reason) => {localThis.appObject.reportException(`${fetchFunc.name}.json`, 'ex', '', reason); } );
                }
            })
            .catch((reason) => {localThis.appObject.reportException(`${fetchFunc.name}`, 'ex', '', reason) } );
    }
    private getPaintingsWithPreviews(token: string, tl: TimeoutList, fetchFunc: (token: string, firstOffset:number, lastOffset:number, kind: string)=>Promise<Response>, firstOffset:number, lastOffset:number, kind: string, onGet: (pins: Array<PaintingInfo>)=>void) {
        let localThis = this;

        let missing = tl.missingRange(firstOffset, lastOffset);
        if (missing === undefined) {
            const pins = this.getCachedPaintingInfos(tl.items, firstOffset, lastOffset);
            this.getPaintingPreviews(token, pins, onGet);
            return;
        }

        fetchFunc(token, missing[0], missing[1], kind)
            .then(resp=>{
                if (!resp.ok) {
                    localThis.appObject.reportException(`${fetchFunc.name}`, 'resp', resp.status.toString(), resp.statusText);
                } else {
                    resp.json()
                        .then((pinsAdd: Array<PaintingInfo>) => {
                            lastOffset = localThis.addPaintingInfos(tl, lastOffset, pinsAdd);
                            const pins = this.getCachedPaintingInfos(tl.items, firstOffset, lastOffset);
                            localThis.getPaintingPreviews(token, pins, onGet);
                        })
                        .catch((reason) => {localThis.appObject.reportException(`${fetchFunc.name}.json`, 'ex', '', reason); } );
                }
            })
            .catch((reason) => {localThis.appObject.reportException(`${fetchFunc.name}`, 'ex', '', reason) } );
    }

    private getTimeoutList(thing:string, scope: string, kind: string) : TimeoutList{
        const key = `${thing}-${scope}-${kind}`;
        if (this.allTimeoutLists.has(key)) {
            const tl = this.allTimeoutLists.get(key);
            if (tl!.isValid()) {
                return tl!;
            }
        }
        const tl = new TimeoutList(this.fiveMinutes);
        this.allTimeoutLists.set(key, tl);
        return tl;
    }

    private getCachedPaintingInfos(pids: Array<string>, firstOffset: number, lastOffset: number) : Array<PaintingInfo> {
        const result = new Array<PaintingInfo>();
        for (let i = firstOffset; i <= lastOffset; i++) {
            if (i < pids.length) {
                result.push(this.cachedPaintingInfos.get(pids[i])!);
            }
        }
        return result;
    }

    private addPaintingInfos(tl: TimeoutList, lastOffset: number, pins: Array<PaintingInfo>) : number {
        tl.containsValues = true;
        for (const pin of pins) {
            tl.items.push(pin.paintingId!)
            this.cachedPaintingInfos.set(pin.paintingId!, pin);
        }
        if (tl.items.length <= lastOffset) {
            tl.knownFull = true;
            return tl.items.length - 1;
        }
        return lastOffset;
    }


    private static fetchUserPaintings(token: string, firstOffset:number, lastOffset:number, kind: string) : Promise<Response> {

        if (token === undefined || token === null) {
            throw new Error('dont');
        }

        let filter : string;
        let order : string;
        switch (kind) {
            case 'recent':
                filter = 'none'
                order = 'recent'
                break;
            case 'favorite':
                filter = 'self favorite'
                order = 'recent'
                break;
            case 'popular':
                filter = 'other favorite'
                order = 'other favorite'
                break;
            default:
                // must be an id?
                order = 'recent'
                filter = kind;
                break;

        }
        const query = `api/userPaintings/getOrderedFiltered?order=${order!}&filter=${filter!}&first=${firstOffset}&last=${lastOffset}`;
        //console.log(query);
        const reInit : RequestInit = {headers:{'smesshy-api-trace':STrace.getRecent(), 'Authorization': `Bearer ${token}`}};
        
        //console.log('token', token);
        return fetch(query, reInit);
    }

    private static fetchFollowingPaintings(token: string, firstOffset:number, lastOffset:number, kind: string) : Promise<Response> {

        if (token === undefined || token === null) {
            throw new Error('dont');
        }

        let filter : string;
        let order : string;
        switch (kind) {
            case 'recent':
                filter = 'none'
                order = 'recent'
                break;
            case 'favorite':
                filter = 'self favorite'
                order = 'recent'
                break;
            case 'popular':
                filter = 'other favorite'
                order = 'other favorite'
                break;

        }
        const query = `api/userFollowings/getOrderedFilteredPaintings?order=${order!}&filter=${filter!}&first=${firstOffset}&last=${lastOffset}`;
        //console.log(query);
        const reInit : RequestInit = {headers:{'smesshy-api-trace':STrace.getRecent(), 'Authorization': `Bearer ${token}`}};

        //console.log('token', token);
        return fetch(query, reInit);
    }

    private static fetchGlobalPaintings(token: string, firstOffset:number, lastOffset:number, kind: string) : Promise<Response> {

        let filter : string;
        let order : string;
        switch (kind) {
            case 'recent':
                filter = 'trending'
                order = 'other favorite'
                break;
            case 'favorite':
                filter = 'self favorite'
                order = 'recent'
                break;
            case 'popular':
                filter = 'challenge votes'
                order = 'challenge score'
                break;

        }
        const query = `api/globalPaintings/getOrderedFiltered?order=${order!}&filter=${filter!}&first=${firstOffset}&last=${lastOffset}`;
        //console.log(query);

        //console.log('token', token);
        const sTrace = STrace.getRecent()
        if (token === 'none') {
            return fetch(query, {headers:{'smesshy-api-trace':sTrace}});
        } else {
            const reInit : RequestInit = {headers:{'smesshy-api-trace':sTrace, 'Authorization': `Bearer ${token}`}};
            return fetch(query, reInit);
        }
    }

    private static fetchOthersPaintings(token: string, firstOffset:number, lastOffset:number, kind: string) : Promise<Response> {

        if (token === undefined || token === null) {
            throw new Error('dont');
        }

        const query = `api/othersVisible/getRecentPaintings?otherId=${kind!}&first=${firstOffset}&last=${lastOffset}`;
        //console.log(query);
        const reInit : RequestInit = {headers:{'smesshy-api-trace':STrace.getRecent(), 'Authorization': `Bearer ${token}`}};

        //console.log('token', token);
        return fetch(query, reInit);
    }
    private static fetchLunchboxPaintings(token: string, firstOffset:number, lastOffset:number, kind: string) : Promise<Response> {

        if (token === undefined || token === null) {
            throw new Error('dont');
        }

        const query = `api/lunchBox/getCurrentPaintings?&first=${firstOffset}&last=${lastOffset}`;
        //console.log(query);
        const reInit : RequestInit = {headers:{'smesshy-api-trace':STrace.getRecent(), 'Authorization': `Bearer ${token}`}};

        //console.log('token', token);
        return fetch(query, reInit);
    }

    private async fetchThumbs(token: string, pis: Array<PaintingInfo>) :  Promise<Response> {
        // yank out just the id numbers and concat into a long set of urls params
        const imageIds = pis.map(i=>i.paintingId!);
        // request the thumbnail images for that set
        const query = this.makeQueryWithArrayParams('api/paintingImages/getThumbnails', 'paintingIds', imageIds);
        //console.log(query);
        const sTrace = STrace.getRecent()
        if (token === 'none') {
            return fetch(query, {headers:{'smesshy-api-trace':sTrace}});
        } else {
            const reInit : RequestInit = {headers:{'smesshy-api-trace':sTrace, 'Authorization': `Bearer ${token}`}};
            return fetch(query, reInit);
        }
    }

    private addThumbs(pis: Array<PaintingInfo>, thumbs: Array<string>) : void {
        for(let iThumb=0; iThumb < thumbs.length; iThumb++) {
            let info = pis[iThumb];
            if (info.imageDetail === undefined) {
                info.imageDetail = {paintingId:info.paintingId, thumbnail:'', preview: '', actions: ''};
            }
            info.imageDetail.thumbnail = thumbs[iThumb];
            this.cachedThumbnails.set(info.paintingId!, info.imageDetail.thumbnail)
        }
    }

    private async fetchPreviews(token: string, pis: Array<PaintingInfo>) :  Promise<Response> {
        // yank out just the id numbers and concat into a long set of urls params
        const imageIds = pis.map(i=>i.paintingId!);
        const query = this.makeQueryWithArrayParams('api/paintingImages/getPreviews', 'paintingIds', imageIds);

        //console.log(query);
        const sTrace = STrace.getRecent()
        if (token === 'none') {
            return fetch(query, {headers:{'smesshy-api-trace':sTrace}});
        } else {
            const reInit : RequestInit = {headers:{'smesshy-api-trace':sTrace, 'Authorization': `Bearer ${token}`}};
            return fetch(query, reInit);
        }
    }

    private addPreviews(pis: Array<PaintingInfo>, previews: Array<string>) : void {
        for(let iPrev=0; iPrev < previews.length; iPrev++) {
            let info = pis[iPrev];
            if (info.imageDetail === undefined) {
                info.imageDetail = {paintingId:info.paintingId, thumbnail:'', preview: '', actions: ''};
            }
            info.imageDetail.preview = previews[iPrev];
            this.cachedPreviews.set(info.paintingId!, info.imageDetail.preview)
        }
    }

    private async fetchActions(token: string, pin: PaintingInfo) :  Promise<Response> {
        // request the thumbnail images for that set
        const query = this.makeQueryWithArrayParams('api/paintingImages/getActions', 'paintingIds', [pin.paintingId!]);
        //console.log(query);
        const sTrace = STrace.getRecent()
        if (token === 'none') {
            return fetch(query, {headers:{'smesshy-api-trace':sTrace}});
        } else {
            const reInit : RequestInit = {headers:{'smesshy-api-trace':sTrace, 'Authorization': `Bearer ${token}`}};
            return fetch(query, reInit);
        }
    }

    public async registerNotificationSubscription(newSub: PushSubscription) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'POST', newSub.toJSON());
        if (reqInit) {
            //console.log('token', token);
            try {
                const responsePost = await fetch(`api/notification/postSubscription`, reqInit);
                if (!responsePost.ok) {
                    throw new Error('not ok');
                }

            } catch (e: any){
                localThis.appObject.reportException(`postSubscription`, 'ex', '', e)
            }
        }


    }
    public async deleteNotificationSubscription(subId: string) {
        let localThis = this;

        const reqInit = await this.makeRequestInit('required', 'DELETE', subId);
        if (reqInit) {
            //console.log('token', token);
            try {
                const responsePost = await fetch(`api/notification/deleteSubscription`, reqInit);
                if (!responsePost.ok) {
                    throw new Error('not ok');
                }

            } catch (e: any){
                localThis.appObject.reportException(`deleteSubscription`, 'ex', '', e)
            }
        }


    }
    
    public async testAuthFail() {
        let localThis = this;
        const query = `api/lunchBox/postPoke`;

        const reqInit = await this.makeRequestInit('required', 'POST', "baadfood");
        //(reqInit as any).headers.Authorization = (reqInit as any).headers.Authorization + 'x';

        if (reqInit !== undefined) {
            try {
                const resp = await fetch(query, reqInit);
                if (!resp.ok) {
                    this.appObject.reportException('testAuthFail', 'resp', resp.status.toString(), resp.statusText);
                } 
            } catch (e: any){
                this.appObject.reportException(`testAuthFail`, 'ex', '', e)
            }
        }
    }


    public postDiagnostic(diag: SmesshyDiagnosticLog) {
        let localThis = this;

        this.appObject.asyncHelp.executeAsyncLater(async ()=>{
            const token = await localThis.appObject.authService.getAccessToken();
            if (token) {
                //console.log('token', token);
                try {
                    const responseFavPut = await fetch(`api/diagnostic/postLog`, 
                            {method:'POST', headers:{'Content-Type':'application/json', 'Authorization': `Bearer ${token}`},
                            body:JSON.stringify(diag)});
                    if (!responseFavPut.ok) {
                        throw new Error('not ok');
                    }

                } catch (e: any){
                    localThis.appObject.reportException(`postDiagnostic`, 'ex', '', e)
                }
            }
        });
    }




}


export default SmesshyStorageManager;