import { Component } from 'react';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import { ArtistProfile, LunchBoxStreakReported } from '../storageManager';
import InfiniteScroll from "react-infinite-scroll-component";
import { FollowingTile } from './followingPage';
import { SmesshySubButton } from './smesshyButton';



export interface FollowingListerState extends SmesshyCommonState {
    followingArtists: Array<[ArtistProfile, boolean]>;
    streakInfo: Map<string, LunchBoxStreakReported>;
}
export interface FollowingListerProps extends SmesshyCommonProps {
    Mutual: boolean;
    ShowCheckbox: boolean;
    ShowStreak: boolean;
    StreaksOnly: boolean;
    OnSelectChange?: (selected: Array<string>)=>Promise<boolean>;
    OnSelect?: (selectedId: string)=>Promise<boolean>;
}

class FollowingLister extends SmesshyCommon(Component<FollowingListerProps, FollowingListerState>) {

    followingRequestNextStart: number;
    followingRequestAtEnd: boolean;
    followingArtistsNextStart: number;
    followingArtistsAtEnd: boolean;

    constructor(props: FollowingListerProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.followingRequestNextStart =  0;
        this.followingRequestAtEnd =  false;
        this.followingArtistsNextStart =  0;
        this.followingArtistsAtEnd =  false;

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            followingArtists: new Array<[ArtistProfile, boolean]>(),
            streakInfo: new Map<string, LunchBoxStreakReported>()
        };

    }
    
    componentDidMount() {
        STrace.addStep('followingLister', 'didMound', '');
        let controlThis = this;
        this.executeAsync(async ()=> {
            controlThis.pushWaitingFrame(this);
            STrace.addStep('followingPicker', 'initAuth', '');
            await controlThis.initAuthentication(controlThis);
            controlThis.popWaitingFrame();
        });
        
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('followingLister', 'populateAuth', authenticated.toString());
        this.pushWaitingFrame(this);

        if (authenticated) {
            let token = await this.getAccessToken();
            if (token !== undefined) {
                await this.fetchAllFollowingAsync();
            }
            
        }
        this.popWaitingFrame();
    }

    componentWillUnmount() {
        this.termAuthentication();
    }


   async fetchAllFollowingAsync() : Promise<boolean> {

        STrace.addStep('followingPicker', 'fetchAllFollowingAsync', '');
        if (this.followingArtistsAtEnd) {
            return false;
        }

        this.pushWaitingFrame(this);
        let token = await this.getAccessToken();
        if (token === undefined) {
            this.popWaitingFrame();
            return false;
        }  
        
        const following = new Array<[ArtistProfile, boolean]>();
        STrace.addStep('followingPicker', 'getUserFollowingAsync', 'approved');
        const more = await this.storageManager!.getUserFollowingAsync('approved', this.props.Mutual);
        const morelist  = new Array<string>(); more.forEach(i=>morelist.push(i));

        if (this.props.ShowStreak) {
            STrace.addStep('followingPicker', 'getLunchboxStreakInfoAsync', '');
            const streaks = await this.storageManager!.getLunchboxStreakInfoAsync(morelist);
            if (streaks !== undefined) {
                for (const streak of streaks) {
                    this.state.streakInfo.set(streak.otherId, streak);
                }
            }
        }
        const controlThis = this;
        STrace.addStep('followingPicker', 'getArtistProfilesAsync', '');
        await this.storageManager!.getArtistProfilesAsync(morelist, 'basic', (profs: Array<ArtistProfile>)=>{
            for (const prof of profs) {
                if (controlThis.props.StreaksOnly) {
                    if (controlThis.state.streakInfo.has(prof.artistId)) {
                        following.push([prof, false]);
                    }
                } else {
                    following.push([prof, false]);
                }
            }
            controlThis.setState({followingArtists: following});
            controlThis.followingArtistsAtEnd = true;
        });
        this.popWaitingFrame();

        return true
    }

    fetchMoreFollowing() {
        let controlThis = this;
        this.pushWaitingFrame(this);
        this.executeAsync(async ()=> {
            await controlThis.fetchAllFollowingAsync();
            this.popWaitingFrame();
        });
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`followingPicker, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const onSelectClick = async (otherId: string) : Promise<boolean> => {
            if (controlThis.props.OnSelect !== undefined) {
                return await controlThis.props.OnSelect(otherId);
            }
            if (controlThis.props.ShowCheckbox !== true || controlThis.props.OnSelectChange === undefined) {
                return true;
            }
            STrace.addStep('followingLister', 'other nav', otherId);
            const newSelected = new Array<[ArtistProfile, boolean]>();
            const selectedIds = new Array<string>();
            for (const pair of controlThis.state.followingArtists) {
                let pairPush = pair;
                if (pair[0].artistId === otherId) {
                    pairPush = [pair[0], !pair[1]];
                }
                newSelected.push(pairPush);
                if (pairPush[1] === true) {
                    selectedIds.push(pairPush[0].artistId);
                }
            }
            await controlThis.props.OnSelectChange!(selectedIds);
            controlThis.setState({followingArtists: newSelected});
            return true;
        }

        const followingList = <div id='following-scroll-host' className='width-100p height-100p v-scroll-container'>
                            <InfiniteScroll
                                dataLength={this.state.followingArtists.length}
                                next={()=>controlThis.fetchMoreFollowing()}
                                hasMore={!this.followingArtistsAtEnd}
                                loader={<div className='text-bold'>Getting them...</div>}
                                scrollableTarget='following-scroll-host'

                                endMessage={<></>}
                                >
                                {this.state.followingArtists.map((pair, index) => (
                                    <FollowingTile
                                        key={index}
                                        AppObject={this.props.AppObject}
                                        AppShape={this._appShape!}
                                        ArtistProfile={pair[0]}
                                        ShowTrash = {false}
                                        ShowPending = {false}
                                        ShowPlus = {false}
                                        ShowCheck = {this.props.ShowCheckbox}
                                        Checked = {pair[1] === true}
                                        StreakInfo = {this.state.streakInfo.get(pair[0].artistId)}
                                        AllowVScroll = {true}
                                        OnTileClick={async (top:number, artistId:string, artistName:string)=>{return await onSelectClick(artistId)}}
                                        OnTrashClick={async (top:number, artistId:string, artistName:string)=>{return true;}}
                                        OnPlusClick={async (top:number, artistId:string, artistName:string)=>{return true;}}
                                        OnCheckClick={async (top:number, artistId:string, artistName:string)=>{return await onSelectClick(artistId)}}
                                        />
                                ))}
                            </InfiniteScroll>
                        </div>;


        return this.renderFramework(followingList, this.state);
    }
}



export interface LuchboxPickerState extends SmesshyCommonState {
    selectedFollowers: Array<string>;

}
export interface LuchboxPickerProps extends SmesshyCommonProps {
    Bounds: DOMRect;
    OnAccept: (selectedFollowers: Array<string>)=>Promise<boolean>;
    OnReject: ()=>void;
}


export class LuchboxPicker extends SmesshyCommon(Component<LuchboxPickerProps, LuchboxPickerState>) {

    constructor(props: LuchboxPickerProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            selectedFollowers: new Array<string>()
        };
    }

    componentDidMount(): void {
        const controlThis = this;
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`lunchboxPicker, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        const controlThis = this;

        const boundLeft = this.props.Bounds.left;
        const boundTop = this.props.Bounds.top;
        const boundWidth = this.props.Bounds.width;
        const boundHeight = this.props.Bounds.height;
  
        return this.renderFramework(
                <div className='width-100p height-100p' style={{position:'relative', zIndex:20, backgroundColor:'var(--smesshy-opaque)'}}>
                    <div style={{position:'relative', left:boundLeft, top: boundTop, width:boundWidth, height:boundHeight}}>
                        <div className='smesshy-ask text-white text-medium v-items v-medium-gap v-padding-medium h-padding-large width-100p height-100p'>
                            <div className='width-100p h-items each-space-between each-cross-center' >
                                <div className='text-large'>{`Who should get this one?`}</div>
                                <SmesshySubButton AppObject={this._app!}
                                    key={'reject'}
                                    Display={<>X</>}
                                    Haptic = {this.doButtonHaptic()}
                                    StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=>{
                                        STrace.addStep('lunchboxPicker', 'reject', '');
                                        controlThis.props.OnReject();
                                        return true;
                                    }}
                                />
                            </div>
                            <SmesshySubButton AppObject={this._app!}
                                    key={'accept'}
                                    Display={<>Send it!</>}
                                    Haptic = {this.doButtonHaptic()}
                                    StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=>{
                                        STrace.addStep('lunchboxPicker', 'accept', '');
                                        return await controlThis.props.OnAccept(this.state.selectedFollowers);
                                    }}
                                    Disabled={this.state.selectedFollowers.length === 0}/>
                            <div className='game-page-mid'>
                                <FollowingLister 
                                    AppObject={this.props.AppObject} 
                                    AppShape={this._appShape!}
                                    Mutual={true}
                                    ShowCheckbox={true}
                                    ShowStreak={true}
                                    StreaksOnly={false}
                                    OnSelectChange={async (selected: Array<string>) : Promise<boolean>=>{
                                        STrace.addStep('lunchboxPicker', 'list changed', '');
                                        controlThis.setState({selectedFollowers: selected});
                                        return true;
                                    }}
                                    />
                            </div>
                        </div>
                    </div>
                </div>, this.state);
                   
    }
}

export interface StreakViewerState extends SmesshyCommonState {

}
export interface StreakViewerProps extends SmesshyCommonProps {
    Bounds: DOMRect;
    OnReject: ()=>void;
}


export class StreakViewer extends SmesshyCommon(Component<StreakViewerProps, StreakViewerState>) {

    constructor(props: StreakViewerProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false
        };
    }

    componentDidMount(): void {
        STrace.addStep('streakViewer', 'didMound', '');
        const controlThis = this;
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`streakViewer, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        const controlThis = this;

        const boundLeft = this.props.Bounds.left;
        const boundTop = this.props.Bounds.top;
        const boundWidth = this.props.Bounds.width;
        const boundHeight = this.props.Bounds.height;
  
        return this.renderFramework(
                <div className='width-100p height-100p' style={{position:'relative', zIndex:20, backgroundColor:'var(--smesshy-opaque)'}}>
                    <div style={{position:'relative', left:boundLeft, top: boundTop, width:boundWidth, height:boundHeight}}>
                        <div className='smesshy-ask text-white text-medium v-items v-medium-gap v-padding-medium h-padding-large width-100p height-100p'>
                            <div className='width-100p h-items each-space-between each-cross-center' >
                                <div className='text-large'>{`Your current lunchbox streaks:`}</div>
                                <SmesshySubButton AppObject={this._app!}
                                    key={'reject'}
                                    Display={<>X</>}
                                    Haptic = {this.doButtonHaptic()}
                                    StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=>{
                                        STrace.addStep('streakViewer', 'reject', '');
                                        controlThis.props.OnReject();
                                        return true;
                                    }}
                                />
                            </div>
                            <div className='game-page-mid'>
                                <FollowingLister 
                                    AppObject={this.props.AppObject} 
                                    AppShape={this._appShape!}
                                    Mutual={true}
                                    ShowCheckbox={false}
                                    ShowStreak={true}
                                    StreaksOnly={true}
                                    OnSelect={async (selectedId: string) : Promise<boolean>=>{
                                        controlThis.setState({askQuestion:{
                                            Top: controlThis._app!.GetScaledPxHeight(164),
                                            Title: <span>Poke</span>,
                                            Prompts: [<span key={'l1'}>Shall I remind this individual, vis-à-vis your currently endangered lunchbox streak, as to both the importance of and benefits gained from maintaining personal relationships even in small ways, such as by regularly placing a friendly little drawing in a pal's lunchbox or by, in the reciprocal case where a painting has been carefully crafted and gifted to them by someone such as yourself, merely looking at it?</span>],
                                            Options: [{Key:'canvas', Option:<span>Yup</span>, OnOption:async ():Promise<boolean>=>{
                                                                    STrace.addStep('followingPicker', 'postPokeLunchboxAsync', selectedId);
                                                                    controlThis.pushWaitingFrame(controlThis);
                                                                    controlThis.storageManager!.postPokeLunchboxAsync(selectedId);
                                                                    controlThis.setState({askQuestion:undefined})
                                                                    controlThis.popWaitingFrame();
                                                                    return true;
                                                                }}],
                                            OnCancel:()=>{controlThis.setState({askQuestion:undefined});}                                        
                                            }});
                                        return true;
                                    }}

                                    />
                            </div>
                        </div>
                    </div>
                </div>, this.state);
                   
    }
}

export default FollowingLister;