import React, { Component } from 'react';
import Login from './Login'
import Logout from './Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import { JsxElement } from 'typescript';
import { App } from '../../App';

const GetApiAuthorizationRoutes = (app: App) =>
{
  return [
    {
      path: ApplicationPaths.Login,
      element: loginAction(app, LoginActions.Login)
    },
    {
      path: ApplicationPaths.LoginFailed,
      element: loginAction(app, LoginActions.LoginFailed)
    },
    {
      path: ApplicationPaths.LoginCallback,
      element: loginAction(app, LoginActions.LoginCallback)
    },
    {
      path: ApplicationPaths.Profile,
      element: loginAction(app, LoginActions.Profile)
    },
    {
      path: ApplicationPaths.Register,
      element: loginAction(app, LoginActions.Register)
    },
    {
      path: ApplicationPaths.LogOut,
      element: logoutAction(app, LogoutActions.Logout)
    },
    {
      path: ApplicationPaths.LogOutCallback,
      element: logoutAction(app, LogoutActions.LogoutCallback)
    },
    {
      path: ApplicationPaths.LoggedOut,
      element: logoutAction(app, LogoutActions.LoggedOut)
    }
  ];
}

function loginAction(app: App, name: string):JSX.Element {
  return (<Login AppObject={app} AppShape={app.GetAppShape()} action={name}></Login>);
}

function logoutAction(app: App, name: string):JSX.Element  {
  return (<Logout AppObject={app} AppShape={app.GetAppShape()} action={name}></Logout>);
}

export default GetApiAuthorizationRoutes;
