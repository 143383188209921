import React, { Component } from 'react';
import { App } from '../App'
import {TouchActionAnimationQueue} from './smash/smear';
import {CloseButton } from './iconButton';
import FingerTip from './smash/fingertip';
import { Navigate } from 'react-router-dom';
import { Footer } from '../smesshy';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';
import { Point2D } from './smash/utils';


class fakeFinger {
    radiusMin: number;
    radiusMax: number;
    radiusLast: number;
    constructor(radiusMin: number, radiusMax: number) {
        this.radiusMin = radiusMin;
        this.radiusMax = radiusMax;
        this.radiusLast = radiusMin + (radiusMax - radiusMin) / 2;
    }
    public nextRadius() {
        let spin = Math.random();
        if (spin < .4) {
            return this.radiusLast;
        } else if (spin < .7) {
            if (this.radiusLast <= this.radiusMin) {
                return this.radiusLast;
            }
            this.radiusLast --;
            return this.radiusLast;
        } else {
            if (this.radiusLast >= this.radiusMax) {
                return this.radiusLast;
            }
            this.radiusLast ++;
            return this.radiusLast;
        }
    
    }

}


export interface CalibrateSpotState {

}
export interface CalibrateSpotProps {
    OnMouseUse: ()=>void;
    OnTouchUse: ()=>void;
    OnRecordedChange: (recordedValue: number)=>void;
    Expected: string;
    KnownMinRadius: number;
    KnownMaxRadius: number;
    Diameter: number;
    Border: number;
    Locked: boolean;
    LastShownValue: number;
}


export class CalibrateSpot extends Component<CalibrateSpotProps, CalibrateSpotState> {

    trackRef: React.RefObject<HTMLDivElement>;

    constructor(props: CalibrateSpotProps) {
        super(props);

        let fakeMin = 0;
        let fakeMax = 0;
        if (props.Expected === 'light') {
            fakeMin = FingerTip.minFingerRadius * 2
            fakeMax = Math.floor(FingerTip.minFingerRadius * 5);
        } else {
            fakeMax = FingerTip.maxFingerRadius * 2
            fakeMin = Math.floor(FingerTip.maxFingerRadius);
        }
        this.trackRef = React.createRef();
        this.fakefinger = new fakeFinger(fakeMin, fakeMax);
    }
    fakefinger: fakeFinger;

    componentDidMount(): void {
        const controlThis = this;
    }
    capturedPointer = -1;
    
    render() {
        const controlThis = this;

        const pointerDown = (evt: React.PointerEvent) => {
            if (controlThis.capturedPointer === -1) {
                if (controlThis.props.Locked) {
                    return;
                }
                controlThis.capturedPointer = evt.pointerId;
                const track=this.trackRef.current!;
                track.setPointerCapture(evt.pointerId);
                let newDiameter = evt.width;
                //newDiameter = controlThis.fakefinger.nextRadius();
                controlThis.props.OnRecordedChange(newDiameter);
            }

        };
        const pointerMove = (evt: React.PointerEvent) => {
            if (controlThis.capturedPointer === evt.pointerId) {

                if (controlThis.props.Locked) {
                    return;
                }

                let width = evt.width;
                //width = controlThis.fakefinger.nextRadius();

                controlThis.props.OnRecordedChange(width);
                
            }

        };
        const pointerUp = (evt: React.PointerEvent) => {
            if (controlThis.capturedPointer === evt.pointerId) {
                const track=this.trackRef.current!;
                controlThis.capturedPointer = -1;
                track.releasePointerCapture(evt.pointerId);
            }
        };
        
        
        const mouseDown = (evt: React.MouseEvent) => {
            controlThis.props.OnMouseUse();
        };
        
        const mouseClick = (evt: React.MouseEvent) => {
            controlThis.props.OnMouseUse();
        };

        const touchStart = (evt:React.TouchEvent) => {
            controlThis.props.OnTouchUse();
        };
        const touchMove = (evt:React.TouchEvent) => {
            try {
                evt.preventDefault();
            } catch (e) {
            }
            
        };
        const touchEnd = (evt:React.TouchEvent) => {
            
        };

        let minWidth = this.props.KnownMinRadius;
        let maxWidth = this.props.KnownMaxRadius;

        // diameterRecorded is ratio of recorded between min and max, then turn into display width
        let recDiameter = 0;

        let maxDiameter: number;
        let targetDiameter: number;
        let targetStart: number;
        let maxBorder = 'none'
        let targetBorder = 'none'
        let recordedValue = this.props.LastShownValue;
        if (this.props.Expected === 'light') {
            maxDiameter = this.props.Diameter + this.props.Border;
            targetDiameter = this.props.Diameter;

            if (recordedValue !== 0) {
                recDiameter = 0;
                if (minWidth !== maxWidth) {
                    recDiameter = (recordedValue - minWidth) / (maxWidth - minWidth);
                }
                recDiameter = Math.floor(this.props.Diameter + recDiameter * this.props.Border);
            }

            targetBorder = '1px solid black';
            targetStart = this.props.Border/2;
        } else {
            maxDiameter = this.props.Diameter;
            targetDiameter = this.props.Diameter;

            if (recordedValue !== 0) {
                recDiameter = 1;
                if (minWidth !== maxWidth) {
                    recDiameter = (recordedValue - minWidth) / (maxWidth - minWidth);
                }
                const variance = Math.min(this.props.Border, this.props.Diameter);
                recDiameter = Math.floor((this.props.Diameter - variance) + (recDiameter * variance));
            }

            targetStart = 0;
            maxBorder = '1px solid black';

        }

        let recStart = (maxDiameter - recDiameter) / 2;
        if (maxBorder !== 'none' && recDiameter > 1) {
            recDiameter -= 2; // stupid border
        }

        return (<div ref={this.trackRef} style={{position:'relative', width:maxDiameter, height:maxDiameter, borderRadius: maxDiameter/2, border: maxBorder }}
                                onMouseDown={mouseDown} onClick={mouseClick} 
                                onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd}
                                onPointerDown={pointerDown} onPointerUp={pointerUp} onPointerMove={pointerMove}>
                    <div style={{position:'absolute', left:recStart, top:recStart, width:recDiameter, height:recDiameter, borderRadius: recDiameter/2, backgroundColor:this.props.Locked ? 'var(--smesshy-opaque)' : 'var(--smesshy-green)', pointerEvents:'none'}}></div>
                        <div style={{position:'absolute', left:targetStart, top:targetStart, width:targetDiameter, height:targetDiameter, borderRadius: targetDiameter/2, border: targetBorder,
                                                backgroundColor: this.props.Locked ? 'var(--smesshy-blue)' : 'var(--smesshy-opaque)', pointerEvents:'none'}}
                    />   

                </div>);
    }
}

export interface CalibrateTouchPageState extends SmesshyCommonState {
    doImplicitClose: boolean,
    lightCalibrated: boolean,
    bestLightDiam: number;
    minLightDiam: number;
    maxLightDiam: number;
    lastLightDiam: number;
    lightRecorded: Array<number>;
    heavyCalibrated: boolean,
    bestHeavyDiam: number;
    minHeavyDiam: number;
    maxHeavyDiam: number;
    lastHeavyDiam: number;
    heavyRecorded: Array<number>;
    sawMouseClick: boolean;
    sawTouch: boolean;
}
export interface CalibrateTouchPageProps extends SmesshyCommonProps {
    KnownCalibrated: boolean;
}

class CalibrateTouchPage extends SmesshyCommon(Component<CalibrateTouchPageProps, CalibrateTouchPageState>) {

    listRef : React.RefObject<HTMLTextAreaElement>;

    constructor(props: CalibrateTouchPageProps) {
        super(props);
        this.initCommon(props.AppObject);
        this.listRef = React.createRef();


        this.state = {
            showWaitSpin: false,
            authenticated: false,
            doImplicitClose: false,
            sawMouseClick: false,
            sawTouch: false,
            lightCalibrated: false,
            bestLightDiam: 0,
            minLightDiam: 0,
            maxLightDiam: 0,
            lastLightDiam: 0,
            lightRecorded: new Array<number>(0,0,0,0),
            heavyCalibrated: false,
            bestHeavyDiam: 0,
            minHeavyDiam: 0,
            maxHeavyDiam: 0,
            lastHeavyDiam: 0,
            heavyRecorded: new Array<number>(0,0,0,0),
        };
    }

    prevDefault = (ev:TouchEvent)=>{
        if (ev.touches.length === 1) {
            ev.preventDefault();
        }
    };

    componentDidMount() {
        STrace.addStep('calibrate', 'didMound', '');

        window.addEventListener('touchstart', this.prevDefault, { passive: false })

        // if the calibration is being repeated, then just keep going.
        // however, on the first time we see this page, set the app settings so it looks like we calibrated
        // and want to set the size manually. 
        // if calibration works, then the settings will change. If the user closes the page, then the manual size will be used.
        if ((this.GetAppState('calibrationVersion', false) as number) < App.calibrationVersion) {
            this.SetAppState('useMouse', false, true);
            this.SetAppState('showSize', true, true);
            this.SetAppState('avgLightRadius', 0, true);
            this.SetAppState('avgHeavyRadius', 0, true);
            this.SetAppState('calibrationVersion', App.calibrationVersion, true);
        }

    }
    componentWillUnmount() {
        window.removeEventListener('touchstart', this.prevDefault);
    }

    reset() {
        this.setState({doImplicitClose:false, sawMouseClick:false, sawTouch:false,  
            lightCalibrated:false, bestLightDiam:0, minLightDiam:0, maxLightDiam:0, lastLightDiam:0, lightRecorded:[0,0,0,0], 
            heavyCalibrated:false, bestHeavyDiam:0, minHeavyDiam:0, maxHeavyDiam:0, lastHeavyDiam:0, heavyRecorded:[0,0,0,0], 
        });
    }

    localPressWeight = FingerTip.minFingerRadius;

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`calibrate, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        if (this.state.doImplicitClose === true) {
            return <Navigate to={-1 as any}/>
        }

        const sawMouse = () => {
            if (controlThis.state.sawTouch === true) {
                return;
            }
            controlThis.setState({sawMouseClick: true,
                askQuestion:{
                    Top: 64,
                    Title: <span>A Mouse!</span>,
                    Prompts: [<span key={'l1'}>Using a mouse is fine. You'll just need to set the 'finger size' by using the <b>slider</b> at the bottom of the painting canvas.</span>,
                    <img className='self-cross-center v-padding-medium' key={'l2'} src='finger-manual.png'></img>],
                    Options: [{Key:'ok', Option:<span>{ controlThis.props.KnownCalibrated ? `Understood` : `Ok, let me paint now!`}</span>, OnOption:async ():Promise<boolean>=>{
                                                STrace.addStep('calibrate', 'mouse ok', '');
                                                controlThis.SetAppState('useMouse', true, true);
                                                controlThis.SetAppState('showSize', true, true);
                                                controlThis.SetAppState('avgLightRadius', controlThis.state.bestLightDiam/2, true);
                                                controlThis.SetAppState('avgHeavyRadius', controlThis.state.bestHeavyDiam/2, true);
                                                controlThis.SetAppState('calibrationVersion', App.calibrationVersion, true);

                                                controlThis.setState({askQuestion:undefined, doImplicitClose:true});
                                                return true;
                                        }}, 
                            ],
                    OnCancel:()=>{
                        STrace.addStep('calibrate', 'mouse cancel', '');
                        controlThis.setState({askQuestion:undefined});  controlThis.reset();}
                    }}
                );
        };
        const sawTouch = () => {
            controlThis.setState({sawTouch: true});
        };
        const recordValue = (recordedValue: number, index: number, type: string) => {
            if (recordedValue <= 0){
                return;
            }
            
            let lastLight: number;
            let lastHeavy: number;
            let min: number;
            let max: number;
            let recordedValues: Array<number>;
            if (type === 'light') {
                recordedValues = controlThis.state.lightRecorded;
                min = controlThis.state.minLightDiam;
                max = controlThis.state.maxLightDiam;
                lastLight = recordedValue;
                lastHeavy = controlThis.state.lastHeavyDiam;
            } else {
                recordedValues = controlThis.state.heavyRecorded;
                min = controlThis.state.minHeavyDiam;
                max = controlThis.state.maxHeavyDiam;
                if (recordedValue < recordedValues[index]) {
                    // this captures the heaviest press in this spot
                    recordedValue = recordedValues[index];
                }
                lastLight = controlThis.state.lastLightDiam;
                lastHeavy = recordedValue;
            }

            recordedValues[index] = recordedValue;
            let sum = 0;
            let cnt = 0;

            let localMin = Number.MAX_VALUE;
            for (let i=0; i<recordedValues.length; i++) {
                if (recordedValues[i] === 0) {
                    continue;
                }
                if (min === 0 || recordedValues[i] < min) {
                    min = recordedValues[i];
                }
                if (max === 0 || recordedValues[i] > max) {
                    max = recordedValues[i];
                }
                localMin = Math.min(localMin, recordedValues[i]);
                sum += recordedValues[i];
                cnt ++;
            }
            let avg: number;
            if (cnt === 0) {
                avg = 0;
            } else {
                avg = sum / cnt;
            }

            // done is when all values filled in 
            // always remove the min. better for both light and heavy
            let lightCalibrated = controlThis.state.lightCalibrated;
            let heavyCalibrated = controlThis.state.heavyCalibrated;
            let bestLightDiam = controlThis.state.bestLightDiam;
            let bestHeavyDiam = controlThis.state.bestHeavyDiam;

            if (cnt === recordedValues.length) {
                sum -= localMin;
                cnt --;
                avg = sum / cnt;

                if (type === 'light') {
                    lightCalibrated = true;
                    controlThis.setState({lightCalibrated: true});
                } else {
                    heavyCalibrated = true;
                    controlThis.setState({heavyCalibrated: true});
                }
            }

            min = Math.floor(min * 10) / 10;
            max = Math.floor(max * 10) / 10;
            avg = Math.floor(avg * 10) / 10;
            lastHeavy = Math.floor(lastHeavy * 10) / 10;
            lastLight = Math.floor(lastLight * 10) / 10;

            if (type === 'light') {
                bestLightDiam = avg;
                controlThis.setState({minLightDiam: min, maxLightDiam: max, bestLightDiam: avg, lastLightDiam: lastLight});
            } else {
                controlThis.setState({minHeavyDiam: min, maxHeavyDiam: max, bestHeavyDiam: avg, lastHeavyDiam: lastHeavy});
                bestHeavyDiam = avg;
            }

            if (lightCalibrated && heavyCalibrated) {

                if (bestLightDiam === 0 || bestHeavyDiam === 0 || bestLightDiam === bestHeavyDiam || Math.abs(bestLightDiam - bestHeavyDiam) < bestHeavyDiam * .2) {
                    controlThis.setState({
                        askQuestion:{
                            Top: 64,
                            Title: <span>Those looked the same</span>,
                            Prompts: [<span key={'l1'}>The light and heavy touches looked the same. This can happen with some older phones, or some 'special' fingers. You'll need to set the 'finger size' by using the <b>slider</b> at the bottom of the painting canvas.</span>,
                            <img className='self-cross-center v-padding-medium' key={'l2'} src='finger-manual.png'></img>],
                            Options: [{Key:'ok', Option:<span>{ controlThis.props.KnownCalibrated ? `Understood` : `Ok, let me paint now!`}</span>, OnOption:async ():Promise<boolean>=>{
                                                        STrace.addStep('calibrate', 'too close ok', Math.abs(bestLightDiam - bestHeavyDiam).toString());
                                                        controlThis.SetAppState('useMouse', false, true);
                                                        controlThis.SetAppState('showSize', true, true);
                                                        controlThis.SetAppState('avgLightRadius', 0, true);
                                                        controlThis.SetAppState('avgHeavyRadius', 0, true);
                                                        controlThis.SetAppState('calibrationVersion', App.calibrationVersion, true);

                                                        controlThis.setState({askQuestion:undefined, doImplicitClose:true});
                                                        return true;
                                                }}, 
                                    {Key:'retry', Option:<span>{ `No wait, I want to try again`}</span>, OnOption:async ():Promise<boolean>=>{    
                                        STrace.addStep('calibrate', 'too close retry', Math.abs(bestLightDiam - bestHeavyDiam).toString());
                                        controlThis.setState({askQuestion:undefined}); controlThis.reset();
                                        return true;
                                        }},                                                
                                    ],
                            OnCancel:()=>{
                                STrace.addStep('calibrate', 'too close cancel', Math.abs(bestLightDiam - bestHeavyDiam).toString());
                                controlThis.setState({askQuestion:undefined}); controlThis.reset();}
                            }}
                        );
    
                } else {
                    controlThis.setState({
                        askQuestion:{
                            Top: 64,
                            Title: <span>Measurement Done</span>,
                            Prompts: [<span key={'l1'}>Success!, your finger measurements will be used when painting.</span>],
                            Options: [{Key:'ok', Option:<span>{ controlThis.props.KnownCalibrated ? `Understood` : `Ok, let me paint now!`}</span>, OnOption:async ():Promise<boolean>=>{
                                                        STrace.addStep('calibrate', 'good ok', Math.abs(bestLightDiam - bestHeavyDiam).toString());
                                                        controlThis.setState({askQuestion:undefined, doImplicitClose:true});
                                                        controlThis.SetAppState('useMouse', false, true);
                                                        controlThis.SetAppState('showSize', false, true);
                                                        controlThis.SetAppState('avgLightRadius', bestLightDiam/2, true);
                                                        controlThis.SetAppState('avgHeavyRadius', bestHeavyDiam/2, true);
                                                        controlThis.SetAppState('calibrationVersion', App.calibrationVersion, true);
                                                        return true;

                                                }}, 
                                    {Key:'retry', Option:<span>{ `No wait, I want to try again`}</span>, OnOption:async ():Promise<boolean>=>{    
                                        STrace.addStep('calibrate', 'good retry', Math.abs(bestLightDiam - bestHeavyDiam).toString());
                                        controlThis.setState({askQuestion:undefined}); controlThis.reset();
                                        return true;
                                        }},                                                
                                    ],
                            OnCancel:()=>{
                                STrace.addStep('calibrate', 'good cancel', Math.abs(bestLightDiam - bestHeavyDiam).toString());
                                controlThis.setState({askQuestion:undefined}); controlThis.reset();}
                            }}
                        );
                }
            }
        };
               
        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={false}
                RequireAuth={false}
                Title = {<span>Smesshy Fingertip Measurement</span>}
                CloseNav = {-1}
                Body={
                    <div className='game-page-mid each-space-between  text-medium text-black' >
                        <div className='v-items h-padding-medium v-padding-medium'>
                            <div>Before finger painting with Smesshy, let's test out your 'brush'.</div>
                            <div>All these phones, computers, fingers and people... There's so many kinds! </div>
                        </div>

                        <div className='v-items'>
                            <div className='h-items'>
                                <div className='self-cross-center h-padding-large'>
                                    <img src='finger-light.svg' width={this._app!.GetScaledPxWidth(70)}/>

                                </div>
                                <div className='v-items each-space-around'>
                                    <div className='h-items h-large-gap h-padding-large'>
                                        <CalibrateSpot
                                            Expected='light'
                                            KnownMinRadius={this.state.minLightDiam}
                                            KnownMaxRadius={this.state.maxLightDiam}
                                            Diameter={this._app!.GetScaledPxWidth(FingerTip.minFingerRadius*2)}
                                            Border={this._app!.GetScaledPxWidth(30)}
                                            Locked={this.state.lightCalibrated}
                                            LastShownValue={this.state.lightRecorded[0]}
                                            OnMouseUse={()=>{
                                                sawMouse() }
                                            }
                                            OnTouchUse={()=>{
                                                sawTouch() }
                                            }

                                            OnRecordedChange={(recordedValue: number)=>{
                                                recordValue(recordedValue, 0, 'light');
                                            }}
                                        />
                                        <CalibrateSpot
                                            Expected='light'
                                            KnownMinRadius={this.state.minLightDiam}
                                            KnownMaxRadius={this.state.maxLightDiam}
                                            Diameter={this._app!.GetScaledPxWidth(FingerTip.minFingerRadius*2)}
                                            Border={this._app!.GetScaledPxWidth(30)}
                                            Locked={this.state.lightCalibrated}
                                            LastShownValue={this.state.lightRecorded[1]}
                                            OnMouseUse={()=>{
                                                sawMouse() }
                                            }
                                            OnTouchUse={()=>{
                                                sawTouch() }
                                            }

                                            OnRecordedChange={(recordedValue: number)=>{
                                                recordValue(recordedValue, 1, 'light');
                                            }}
                                        />
                                    </div>
                                    <div className='h-items h-large-gap h-padding-large'>
                                        <CalibrateSpot
                                            Expected='light'
                                            KnownMinRadius={this.state.minLightDiam}
                                            KnownMaxRadius={this.state.maxLightDiam}
                                            Diameter={this._app!.GetScaledPxWidth(FingerTip.minFingerRadius*2)}
                                            Border={this._app!.GetScaledPxWidth(30)}
                                            Locked={this.state.lightCalibrated}
                                            LastShownValue={this.state.lightRecorded[2]}
                                            OnMouseUse={()=>{
                                                sawMouse() }
                                            }
                                            OnTouchUse={()=>{
                                                sawTouch() }
                                            }

                                            OnRecordedChange={(recordedValue: number)=>{
                                                recordValue(recordedValue, 2, 'light');
                                            }}
                                        />
                                        <CalibrateSpot
                                            Expected='light'
                                            KnownMinRadius={this.state.minLightDiam}
                                            KnownMaxRadius={this.state.maxLightDiam}
                                            Diameter={this._app!.GetScaledPxWidth(FingerTip.minFingerRadius*2)}
                                            Border={this._app!.GetScaledPxWidth(30)}
                                            Locked={this.state.lightCalibrated}
                                            LastShownValue={this.state.lightRecorded[3]}
                                            OnMouseUse={()=>{
                                                sawMouse() }
                                            }
                                            OnTouchUse={()=>{
                                                sawTouch() }
                                            }

                                            OnRecordedChange={(recordedValue: number)=>{
                                                recordValue(recordedValue, 3, 'light');
                                            }}
                                        />
                                    </div>
                                </div>
                                   
                                <div className='self-cross-center'>
                                    <span>Using your </span> 
                                    <span className='text-xlarge text-bold text-green'>lightest touch</span>
                                    <span>, just your fingertip, </span>
                                    <span  className='text-bold text-green'>fill in</span>
                                    <span> (only the insides of) those small circles.</span>
                                   
                                </div>

                            </div>
                            <div style={{backgroundColor:'var(--smesshy-green)', height:2}}></div>
                            <div className='h-items'>
                                <div className='h-padding-medium'>
                                    <span>Using a </span> 
                                    <span className='text-xlarge text-bold text-gray'>heavy flat touch</span>
                                    <span>, smash it down, </span>
                                    <span className='text-bold text-green'>fill in</span>
                                    <span> (out to the edges) the insides of these larger circles.</span>

                                </div>
                                <div className='h-padding-large self-cross-center'>
                                    <img src='finger-smash.svg' width={this._app!.GetScaledPxWidth(70)}/>
                                </div>

                            </div>
                            <div className='h-items width-100p each-space-around v-padding-large'>
                                <CalibrateSpot
                                    Expected='heavy'
                                    KnownMinRadius={this.state.minHeavyDiam}
                                    KnownMaxRadius={this.state.maxHeavyDiam}
                                    Diameter={this._app!.GetScaledPxWidth(FingerTip.maxFingerRadius*2)}
                                    Border={this._app!.GetScaledPxWidth(this._app!.GetScaledPxWidth(30))}
                                    Locked={this.state.heavyCalibrated}
                                    LastShownValue={this.state.heavyRecorded[0]}
                                    OnMouseUse={()=>{
                                        sawMouse() }
                                    }
                                    OnTouchUse={()=>{
                                        sawTouch() }
                                    }

                                    OnRecordedChange={(recordedValue: number)=>{
                                        recordValue(recordedValue, 0, 'heavy');
                                    }}
                                />
                                <CalibrateSpot
                                    Expected='heavy'
                                    KnownMinRadius={this.state.minHeavyDiam}
                                    KnownMaxRadius={this.state.maxHeavyDiam}
                                    Diameter={this._app!.GetScaledPxWidth(FingerTip.maxFingerRadius*2)}
                                    Border={this._app!.GetScaledPxWidth(30)}
                                    Locked={this.state.heavyCalibrated}
                                    LastShownValue={this.state.heavyRecorded[1]}
                                    OnMouseUse={()=>{
                                        sawMouse() }
                                    }
                                    OnTouchUse={()=>{
                                        sawTouch() }
                                    }

                                    OnRecordedChange={(recordedValue: number)=>{
                                        recordValue(recordedValue, 1, 'heavy');
                                    }}
                                />
                                <CalibrateSpot
                                    Expected='heavy'
                                    KnownMinRadius={this.state.minHeavyDiam}
                                    KnownMaxRadius={this.state.maxHeavyDiam}
                                    Diameter={this._app!.GetScaledPxWidth(FingerTip.maxFingerRadius*2)}
                                    Border={this._app!.GetScaledPxWidth(30)}
                                    Locked={this.state.heavyCalibrated}
                                    LastShownValue={this.state.heavyRecorded[2]}
                                    OnMouseUse={()=>{
                                        sawMouse() }
                                    }
                                    OnTouchUse={()=>{
                                        sawTouch() }
                                    }

                                    OnRecordedChange={(recordedValue: number)=>{
                                        recordValue(recordedValue, 2, 'heavy');
                                    }}
                                />

                                <CalibrateSpot
                                    Expected='heavy'
                                    KnownMinRadius={this.state.minHeavyDiam}
                                    KnownMaxRadius={this.state.maxHeavyDiam}
                                    Diameter={this._app!.GetScaledPxWidth(FingerTip.maxFingerRadius*2)}
                                    Border={this._app!.GetScaledPxWidth(30)}
                                    Locked={this.state.heavyCalibrated}
                                    LastShownValue={this.state.heavyRecorded[3]}
                                    OnMouseUse={()=>{
                                        sawMouse() }
                                    }
                                    OnTouchUse={()=>{
                                        sawTouch() }
                                    }

                                    OnRecordedChange={(recordedValue: number)=>{
                                        recordValue(recordedValue, 3, 'heavy');
                                    }}
                                />
                            </div>
                        </div>
                        <div className='v-items h-padding-medium'>
                            <div className='h-items h-large-gap'>
                                <div className={`border-thin text-small h-padding-small v-padding-small ${this.state.sawMouseClick ? 'smesshy-vote-button-pushed' : ''}`}>
                                    Mouse Click
                                </div>
                                <div className={`border-thin text-small h-padding-small v-padding-small ${this.state.sawTouch ? 'smesshy-vote-button-pushed' : ''}`}>
                                    Finger Touch
                                </div>
                            </div>
                            <div className='h-items h-medium-gap'>
                                <div className='text-small'>
                                    Light Touch
                                </div>
                                <div className='text-small'>
                                    Last: 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.lastLightDiam} 
                                </div>
                                <div className='text-small'>
                                    Min: 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.minLightDiam} 
                                </div>
                                <div className='text-small'>
                                    Max: 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.maxLightDiam} 
                                </div>
                                <div className='text-small'>
                                    Best 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.bestLightDiam} 
                                </div>

                            </div>
                            <div className='h-items h-medium-gap'>
                                <div className='text-small'>
                                    Heavy Touch
                                </div>
                                <div className='text-small'>
                                    Last: 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.lastHeavyDiam} 
                                </div>
                                <div className='text-small'>
                                    Min: 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.minHeavyDiam} 
                                </div>
                                <div className='text-small'>
                                    Max: 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.maxHeavyDiam} 
                                </div>
                                <div className='text-small'>
                                    Best 
                                </div>
                                <div className='text-small text-green'>
                                    {this.state.bestHeavyDiam} 
                                </div>
                            </div>
                        </div>
                </div>
                }
            />, this.state);            
    }
}


export default CalibrateTouchPage;