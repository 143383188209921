import React, { Component } from 'react';
import { App } from '../App'
import {CloseButton } from './iconButton';
import { Footer } from '../smesshy';
import { Profile, User } from 'oidc-client';
import Smessage from './smessage';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';
import { ArtistProfile, ChallengeResult, ChallengeState, PaintingInfo, PaintingLocate, VoteRoundStatus } from '../storageManager';
import PaintingFrame from './paintingFrame';
import { SmesshyMainButton, SmesshySubButton } from './smesshyButton';
import CountDownText from './countDownText';
import { FollowingTile } from './followingPage';
import { createSearchParams } from 'react-router-dom';


export interface VotePageState extends SmesshyCommonState {
    challengeState: ChallengeState;
    voteStatus: VoteRoundStatus | undefined;
    voteInfos: Array<PaintingInfo> | undefined;
    expired: boolean;
    currentSelections: Map<number, number>;
    challengeTitle: string;
}
export interface VotePageProps  extends SmesshyCommonProps {
    ChallengeId: string | undefined;
}

class VotePage extends SmesshyCommon(Component<VotePageProps, VotePageState>) {

    constructor(props: VotePageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            challengeState: {
                challengeId: -1,
                state: 'none',
                prizePool: 0,
                title: '',
                voteRoundCurrent: 0,
                voteRoundsTotal: 0,
                timeLeft: 100*60*60,
                roundTimeLeft: 100*60*60,
                stampAllowed: false,
            },
            voteStatus: undefined,
            expired: false,
            voteInfos: undefined,
            currentSelections: new Map<number, number>(),
            challengeTitle: 'Loading...',
        };
    }

    lastAuthCheck: boolean | undefined = undefined;
    lastChallengeCheck: ChallengeState | undefined = undefined;

    componentDidMount() {
        STrace.addStep('vote', 'didMound', '');
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('vote', 'populateAuth', authenticated.toString());

        this.lastAuthCheck = authenticated;
        STrace.addStep('vote', 'getChallengeStateAsync', '');
        this.lastChallengeCheck = await this.storageManager!.getChallengeStateAsync(this.props.ChallengeId);
        if (this.lastChallengeCheck !== undefined) {
            this.setState({challengeState:this.lastChallengeCheck});
        }
        await this.checkFetchChallengeVotes();
    }

    componentDidUpdate(prevProps: Readonly<VotePageProps>, prevState: Readonly<VotePageState>): void {

    }

    async checkFetchChallengeVotes() : Promise<boolean> {
        let controlThis = this;
        let challengeTitle = this.state.challengeState.title;

        if (this.lastAuthCheck === undefined || this.lastChallengeCheck === undefined) {
            
            return false; // not init yet
        }
        this.pushWaitingFrame(this);
        if (this.lastAuthCheck && (this.lastChallengeCheck.state === 'vote' || this.lastChallengeCheck.state === 'finished')) {
            STrace.addStep('vote', 'getChallengeUserVoteStatusAsync', 'check');
            const voteStatus = (await this.storageManager!.getChallengeUserVoteStatusAsync(this.props.ChallengeId))!;
            STrace.addStep('vote', 'got status', voteStatus.userStatus);
            this.setState({voteStatus: voteStatus});
            if (voteStatus.userStatus === 'allowed') {
                STrace.addStep('vote', 'getChallengeVoteImagesAsync', '');
                let voteImages = await this.storageManager!.getChallengeVoteImagesAsync(this.props.ChallengeId);
                if (voteImages?.length !== 0) {
                    STrace.addStep('vote', 'getGlobalPaintingsInfosAsync', '');
                    const voteInfs = await this.storageManager!.getGlobalPaintingsInfosAsync(voteImages!);
                    if (voteInfs !== undefined) {
                        const token =  (await controlThis.getAccessToken())!;
                        STrace.addStep('vote', 'getPaintingPreviews', '');
                        this.pushWaitingFrame(this);
                        this.storageManager!.getPaintingPreviews(token, voteInfs, (pins=>{
                            if (pins.length > 0) {
                                controlThis.setState({ voteInfos: pins, expired: false, currentSelections: new Map<number, number>()});
                            }
                            controlThis.popWaitingFrame();
                        }))
                    }
                }
            } 
        }
        this.popWaitingFrame();
        return true;
    }

    async evaluateAd(): Promise<boolean> {
        STrace.addStep('vote', 'putChallengeUserVoteStatusAsync', '');
        this.pushWaitingFrame(this);
        await this.storageManager!.putChallengeUserVoteStatusAsync(this.props.ChallengeId);
        await this.checkFetchChallengeVotes();
        this.popWaitingFrame();
        return true;
    }


    async sendVote() : Promise<boolean>{
        const votes = new Array<number>();
        let sel = this.state.currentSelections;
        votes.push(sel.get(1)!);
        if (sel.size > 1) {
            votes.push(sel.get(2)!);
        }
        if (sel.size > 2) {
            votes.push(sel.get(3)!);
        }
        if (sel.size > 3) {
            votes.push(sel.get(4)!);
        }
        STrace.addStep('vote', 'postVoteScoresAsync', '');
        this.pushWaitingFrame(this);
        let result = await this.storageManager!.postVoteScoresAsync(this.props.ChallengeId, votes);
        if (result === 'expired') {
            this.setState({expired: true, voteInfos: undefined, askQuestion: undefined});

        } else if (result === 'registered') {
            STrace.addStep('vote', 'getChallengeUserVoteStatusAsync', 'voted');
            const voteStatus = (await this.storageManager!.getChallengeUserVoteStatusAsync(this.props.ChallengeId))!;
            STrace.addStep('vote', 'got status post vote', voteStatus === undefined ? 'undefined' : voteStatus.userStatus);
            
            this.setState({overlayTool: this.smesshyDone(100), voteInfos: undefined, voteStatus: voteStatus, askQuestion: undefined});

        }
        this.popWaitingFrame();
        return true;
    
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`vote, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const getVoteSelection = (option: number): number | undefined => {
            if (controlThis.state.currentSelections.has(option)) {
                return controlThis.state.currentSelections.get(option);
            }
        };
        const voteSelected = (option: number, idx: number): boolean => {
            let sel = controlThis.state.currentSelections;
            // push same number again or a new number?
            if (sel.has(option) && sel.get(option) === idx) {
                sel.delete(option);
            } else {
                sel.set(option, idx);
            }
            // other option with this number? pop up
            sel.forEach((v, k) => {
                if (k !== option) {
                    if (v === idx) {
                        sel.delete(k);
                    }
                }
            });
                

            let canVote = sel.size === controlThis.state.voteInfos?.length;
            controlThis.setState({currentSelections: sel, askQuestion: canVote === false? undefined : 
                {
                    Top: 164,
                    Title: <span>Send Your Vote</span>,
                    Prompts: [<span key={'l1'}>Happy with those choices?</span>],
                    Options: [{Key:'vote', Option:<span>Send My Vote!</span>, OnOption: async ():Promise<boolean>=>{return await controlThis.sendVote()}}],
                    OnCancel:()=>{
                        STrace.addStep('vote', 'send reject', '');
                        controlThis.setState({askQuestion:undefined});}
                    }});
            return true;
        };

        let viewChoices = <></>;
        var titleLine = <></>;
        
        if (this.state.showWaitSpin) {
            titleLine = <span>Checking...</span>;
        }
        else if (this.state.expired) {

            titleLine = <span>Dang, you waited too long, someone else got that vote</span>;

            viewChoices = <SmesshyMainButton AppObject={this._app!}
                            Display={
                                <>
                                <span>Push this giant button to get a fresh set</span>
                                </>}
                                Haptic={this.doButtonHaptic()}
                                StartPushedState={false}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    STrace.addStep('vote', 'get ad', '');
                                    return await controlThis.checkFetchChallengeVotes()
                                }}
                        />


        } else if (this.state.challengeState.state === 'vote' || this.state.challengeState.state === 'finished') {
            if (this.state.voteInfos !== undefined && this.state.voteInfos!.length > 0) {

                titleLine = <div className='v-items v-medium-gap'>
                                <span>Award points to each painting for:</span>
                                <span> {`"${this.state.challengeState.title}"`}</span>
                            </div>;

                let vote1 = <PaintingFrame 
                                AppObject={this.props.AppObject} 
                                AppShape={this.props.AppShape}
                                PaintingInfo = {this.state.voteInfos![0]}
                                UserId={''}
                                SaveEdit = {false}
                                ShowChallenge = {false}
                                ShowLunchbox = {false}
                                VoteMode = {true}
                                SelectionMode = {false}
                                NoArtist = {true}
                                CurrentSelection = {''}
                                VoteSelection={getVoteSelection(1)}
                                OnVoteSelection={(idx:number)=>{return voteSelected(1, idx)}}
                                />;
    
                let vote2 = <></>;
                if (this.state.voteInfos!.length > 1) {
                    vote2 = <PaintingFrame 
                        AppObject={this.props.AppObject} 
                        AppShape={this.props.AppShape}
                        PaintingInfo = {this.state.voteInfos![1]}
                        UserId={''}
                        SaveEdit = {false}
                        ShowChallenge = {false}
                        ShowLunchbox = {false}
                        VoteMode = {true}
                        SelectionMode = {false}
                        NoArtist = {true}
                        CurrentSelection = {''}
                        VoteSelection={getVoteSelection(2)}
                        OnVoteSelection={(idx:number)=>{return voteSelected(2, idx)}}
                        />;
                }
    
                let vote3 = <></>;
                if (this.state.voteInfos!.length > 2) {
                    vote3 = <PaintingFrame 
                        AppObject={this.props.AppObject} 
                        AppShape={this.props.AppShape}
                        PaintingInfo = {this.state.voteInfos![2]}
                        UserId={''}
                        SaveEdit = {false}
                        ShowChallenge = {false}
                        ShowLunchbox = {false}
                        VoteMode = {true}
                        SelectionMode = {false}
                        NoArtist = {true}
                        CurrentSelection = {''}
                        VoteSelection={getVoteSelection(3)}
                        OnVoteSelection={(idx:number)=>{return voteSelected(3, idx)}}
                        />
                }
    
                let vote4 = <></>;
                if (this.state.voteInfos!.length > 3) {
                    vote4 = <PaintingFrame 
                        AppObject={this.props.AppObject} 
                        AppShape={this.props.AppShape}
                        PaintingInfo = {this.state.voteInfos![3]}
                        UserId={''}
                        SaveEdit = {false}
                        ShowChallenge = {false}
                        ShowLunchbox = {false}
                        VoteMode = {true}
                        SelectionMode = {false}
                        NoArtist = {true}
                        CurrentSelection = {''}
                        VoteSelection={getVoteSelection(4)}
                        OnVoteSelection={(idx:number)=>{return voteSelected(4, idx)}}
                        />
                }
    
                viewChoices = <div className='v-items width-100p height-100p'>
                    <div className='h-items' style={{height:'50%'}}>
                        <div style={{display:'flex', width:'50%'}}>
                            {vote1}
                        </div>
                        <div style={{display:'flex',width:'50%'}}>
                            {vote2}
                        </div>
    
                    </div>
                    <div className='h-items' style={{height:'50%'}}>
                        <div style={{display:'flex',width:'50%'}}>
                            {vote3}
                        </div>
                        <div style={{display:'flex',width:'50%'}}>
                            {vote4}
                        </div>
                    </div>
                </div>
            } else {

                if (this.state.voteStatus !== undefined){
                    let whatsNext = <span>The next round is in:</span>

                    if (this.state.voteStatus.voteRoundCurrent + 1 === this.state.voteStatus.voteRoundsTotal) {
                        whatsNext = <span>The vote ends in:</span>;
                    } 
                    if (this.state.voteStatus.userStatus === 'locked' || this.state.voteStatus.userStatus === 'allowed') {
                        titleLine =  <div className='v-items'>
                                        <span>You've already voted this round.</span>
                                        <div>
                                            {whatsNext}
                                            <CountDownText SecondsInitial={this.state.voteStatus.roundSecondsLeft} OnFinish={()=>{controlThis.setState({expired:true});}}/>
                                        </div>
                                        <span>Wait around I guess, or...</span>
                                    </div>

                        viewChoices = <SmesshyMainButton AppObject={this._app!}
                                        Display={
                                            <div className='v-items'>
                                                <span>Push this GIANT button to:</span>
                                                <ul>
                                                <li><span>Evaluate an advertisement</span></li>
                                                <li><span>Get a fresh set of votes right now.</span></li>
                                                </ul>
                                            </div>}
                                            Haptic={this.doButtonHaptic()}
                                            StartPushedState={false}
                                            OnPressFinish={async ()=>{return await controlThis.evaluateAd();}}
                                        />
                    }
                    else {
                        titleLine =  <div className='v-items'>
                        <span>You've used all of this round's allowed votes.</span>
                        <div>
                            {whatsNext}
                            <CountDownText SecondsInitial={this.state.voteStatus.roundSecondsLeft} OnFinish={()=>{controlThis.setState({expired:true});}}/>
                        </div>
                        <span>Come back later.</span>
                    </div>


                    }

                }
            }
        }

        return (this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={true}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>Smesshy - Challenge</span>}
                CloseNav = '/'
                Body={
                    <div className='game-page-mid smesshy-main text-white text-medium' >
                        <div className='v-items v-medium-gap v-padding-small h-padding-small height-100p width-100p'>
                            <div className='text-bold text-large'>
                                {titleLine}
                            </div>
                            <div className='game-page-mid'>
                                {viewChoices}
                            </div>


                        </div>
                        
                    </div>
                }
            />, this.state));
    }
}

export default VotePage;