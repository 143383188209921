import React, { Component } from 'react';
import { App } from '../App'
import {CloseButton } from './iconButton';
import { Footer } from '../smesshy';
import authService from './api-authorization/AuthorizeService'
import { Profile } from 'oidc-client';
import { SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';
import { SmesshyMainButton } from './smesshyButton';


export interface ErrorPageState extends SmesshyCommonState{
}
export interface ErrorPageProps extends SmesshyCommonProps {
    From: string;
    Message: string;
    Kind: string;
    Code: string;
}

class ErrorPage extends SmesshyCommon(Component<ErrorPageProps, ErrorPageState>) {

    constructor(props: ErrorPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false

        };
    }

    render() {
        let controlThis = this;

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={false}
                RequireAuth={false}
                Title = {<span>Smesshy - Disaster</span>}
                CloseNav = {'/'}
                Body={
                    <div className='game-page-mid' >
                    <div className ='v-items v-medium-gap v-padding-large h-padding-medium height-100p width-100p'>
                        <div className='text-xlarge text-line-small text-boldish'>
                            Something unexpected happened
                        </div>
                        <div className='h-line'>
                        </div>
                        <div className='text-xlarge text-line-small'>
                            Smesshy.com {this.props.Kind === 'ex' ? `says: 'what we have here, is a failure to communicate'`: 'responded with an error'}
                        </div>
                        <div className='text-xlarge text-line-small'>
                            {this.props.Code === '' ? '': `Code: ${this.props.Code}`}
                        </div>
                        <div className='text-xlarge text-line-small'>
                            When you said: {this.props.From}
                        </div>
                        <div className='text-xlarge text-line-small'>
                            It said: {this.props.Message}
                        </div>
                        <div className='h-line'>
                        </div>
                        <SmesshyMainButton AppObject={this._app!}
                                    Display={
                                        <>
                                            <div className='text-xlarge text-line-small text-boldish'>
                                            Go back and refresh
                                            </div>
                                        </>}

                                        Haptic={this.doButtonHaptic()}
                                        StartPushedState={false}
                                        ExtraStyleClass='each-space-around'
                                        OnPressFinish={async (): Promise<boolean>=>{
                                            controlThis.triggerRefresh();
                                            controlThis.setState({navigateTo: {To:-1}});
                                            return true;
                                        }}
                                />

                        

                    </div>
                </div>
                }
            />, this.state            
        );
    }
}

export default ErrorPage;