import React, { Component, useEffect } from 'react';
import { User } from "oidc-client";
import AuthorizeService, { AuthorizeServiceResult } from "./components/api-authorization/AuthorizeService";
import { ApplicationPaths, QueryParameterNames } from "./components/api-authorization/ApiAuthorizationConstants";
import { Navigate, Path } from "react-router-dom";
import SmesshyStorageManager from "./storageManager";
import { App, SmesshyAppShape } from "./App";
import SmesshyAsk, { SmesshyAskParams, SmesshyDone, SmesshyWait } from './components/smesshyAsk';
import TutorialStep, { TutorialStepParams } from './components/tutorialStep';
import Env from './envInst';
import { Buffer } from 'buffer';
import { JsxElement } from 'typescript';

export interface SmesshyCommonProps {
    AppObject: App;
    AppShape: SmesshyAppShape | undefined;
}

export interface SmesshyNavigateOptions {
    To: string | number | Partial<Path>;
    Replace?: boolean;
    State?: any;
}
export interface SmesshyToolOptions {
    Element: JSX.Element;
    OnCancel?: (()=>void);
}

export interface SmesshyCommonState {
    showWaitSpin: boolean,
    doRefresh?: boolean,
    authenticated: boolean,
    askQuestion?: SmesshyAskParams | undefined;
    navigateTo?: SmesshyNavigateOptions | undefined;
    overlayTool?: SmesshyToolOptions | undefined;
    tutorialStep?: TutorialStepParams;
}

type Constructor<T> = new (...args: any[]) => T;
export function SmesshyCommon<T extends Constructor<{}>>(SuperClass: T) {
  return class extends SuperClass {
    _subscription: number = -1;
    _app : App | undefined = undefined;
    _appShape : SmesshyAppShape | undefined = undefined;
    _inPWA : boolean = false;
    storageManager: SmesshyStorageManager | undefined = undefined;
    constructor(...args: any[]) {
        super();
    }
    initCommon(app: App) {
        this._app = app;
        this._appShape = app.GetAppShape();
        this._inPWA = app.GetInPWA();
        this.storageManager = this._app.storageManager;
    }


    public executeAsync(code: ()=>void) {
        this._app!.asyncHelp.executeAsyncLater(code);
    }
    public executeWhenSafe(code: ()=>void) {
        this._app!.asyncHelp.executeWhenSafe(code);
    }

    public GetAppState(tag: string, defaultValue: unknown): unknown {
        return this._app!.GetAppState(tag, defaultValue);
    }
    public SetAppState(tag: string, newValue: unknown, cookie?: boolean) {
        return this._app!.SetAppState(tag, newValue, cookie);
    }
    public pushWaitingFrame(frame: Component<SmesshyCommonProps, SmesshyCommonState>) {
        this._app!.pushWaitingFrame(frame);
    }
    public popWaitingFrame() {
        this._app!.popWaitingFrame();
    }

    public doButtonHaptic(): boolean {
        let env = Env;
        if (env.getVibrateSupport()) {
            return this.GetAppState('hapticButton', true) as boolean;
        }
        return false;
    }


    subscribe(onChange: ()=>void) {
      this._subscription = (this as any).props.AppObject.authService.subscribe(onChange);
    }
    unsubscribe() {
      (this as any).props.AppObject.authService.unsubscribe(this._subscription);
    }

    async isAuthenticated() {
      return await (this as any).props.AppObject.authService.isAuthenticated();
    } 

    async getAccessToken(forceNew?: boolean) : Promise<string | undefined> {
      return await (this as any).props.AppObject.authService.getAccessToken(forceNew);
    }
    async getUser() : Promise<User | null> {
      return await (this as any).props.AppObject.authService.getUser();
    }
    async getUserId() : Promise<string | null> {
      return await (this as any).props.AppObject.authService.getUserId();
    }
    async signIn(state:any) : Promise<AuthorizeServiceResult> {
      return await (this as any).props.AppObject.authService.signIn(state);
    }
    async completeSignIn(state:any) : Promise<AuthorizeServiceResult> {
      return await (this as any).props.AppObject.authService.completeSignIn(state);
    }
    async signOut(state:any) : Promise<AuthorizeServiceResult> {
      return await (this as any).props.AppObject.authService.signOut(state);
    }
    async completeSignOut(state:any) : Promise<AuthorizeServiceResult> {
      return await (this as any).props.AppObject.authService.completeSignOut(state);
    }

    requireSetup(thenAction : ()=>void, replace: boolean=false) : SmesshyNavigateOptions | undefined {
        let env = Env;
        if (this._inPWA) {
            if (env.setupComplete()) {
                thenAction();
                return undefined;
            }
        } 
        return {To:'/setup', Replace:replace};
    }
    async requireSetupAsync(thenAction : ()=>Promise<void>) : Promise<SmesshyNavigateOptions | undefined> {
        let env = Env;
        if (this._inPWA) {
            if (env.setupComplete()) {
                await thenAction();
                return undefined;
            }
        } 
        return {To:'/setup'};
    }

    async initAuthentication(comp: Component<SmesshyCommonProps, SmesshyCommonState> ) {
        let controlThis = this;
        try {
            STrace.addStep('common', 'ensureUserManager', '');
            this._app!.authService.ensureUserManagerInitialized();
            this.subscribe(() => this.authenticationChanged(comp));
    
            let token = await controlThis.getAccessToken();
            await controlThis.populateAuthenticationState(comp);
        } catch (reason) {
            this._app!.reportException('init Authentication', 'ex', '', reason);
        }
    }

    termAuthentication() {
        this.unsubscribe();
    }

    async authenticationChanged(comp: Component<SmesshyCommonProps, SmesshyCommonState>) {
        await this.populateAuthenticationState(comp);
    }

    async populateAuthenticationState(comp: Component<SmesshyCommonProps, SmesshyCommonState>) {
        try {
            const authenticated = await this.isAuthenticated();
            comp.setState({ authenticated: authenticated });
            if ((comp as any).onPopulateAuthenticationState !== undefined) {
                await (comp as any).onPopulateAuthenticationState(authenticated);
            }
    
        } catch (e: any) {
            this._app!.reportException(`check authentication`, 'ex', '', e)
        }
    }
    
    triggerRefresh() {
        this._app?.triggerRefresh();
    }

    checkVersion() {
        this._app!.checkVersion();
    }

    smesshyDone(top:number): SmesshyToolOptions {
        return {
            Element: <SmesshyDone AppObject={this._app!} Top={top} OnFinish={()=>{
                (this as unknown as Component<SmesshyCommonProps, SmesshyCommonState>).setState({overlayTool: undefined}); }}/>,
            OnCancel: ()=>(this as unknown as Component<SmesshyCommonProps, SmesshyCommonState>).setState({overlayTool: undefined})
        }
    }


    renderFramework(inner: JSX.Element, state: SmesshyCommonState) : JSX.Element {
        let controlThis = this;

        let renderInstallPrompt = <></>;


        let nav =<></>;
        let ask =<></>;
        let tool =<></>;
        let tutorial =<></>;

        if (state.doRefresh === true) {
            window.location.reload();
            return <></>;
        }

        if (state.navigateTo !== undefined) {
            nav = <Navigate to={state.navigateTo.To as any} replace={state.navigateTo.Replace} state={state.navigateTo.State}/>
        }

        if (this._app?.GetAppShape() !== undefined) {

            if (state.askQuestion !== undefined) {
                ask = <SmesshyAsk
                        AppObject={this._app!}
                        AppShape={this._appShape}
                        Params={state.askQuestion}
                        />;
            }

            if (state.overlayTool !== undefined) {
                tool = <div className='width-100p height-100p' style={{maxHeight:1024, maxWidth:512, position:'absolute', left:0, top: 0}} 
                    onClick={(e)=>{
                        if (state.overlayTool?.OnCancel !== undefined) {
                            state.overlayTool.OnCancel();
                        }
                    }}>
                        {state.overlayTool.Element}
                    </div>
            }

            if (state.tutorialStep !== undefined) {
                tutorial = <TutorialStep
                            AppObject={this._app!}
                            AppShape={this._appShape}
                            Params={state.tutorialStep}
                            Scale={this._app!.GetScaledPxWidth(1)}
                            OnDismiss={()=>(controlThis as any).setState({tutorialStep: undefined})}
                            />;
            }

            if (state.showWaitSpin === true) {
                tool = <div className='width-100p height-100p' style={{maxHeight:1024, maxWidth:512, position:'absolute', left:0, top: 0}}>
                    <SmesshyWait AppObject={this._app!} Top={100}/>
                </div>
            }
        }


        return (
            <div className='v-items width-100p height-100p'>
                <>
                    {inner}
                    {ask}
                    {tool}
                    {tutorial}
                    {nav}
                </>
            </div>);
       
    }


    getAuthenticationNavigate(path: string, action:string) : SmesshyNavigateOptions {
      var link = document.createElement("a");
      link.href = path;
      const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
      const redirectUrl = `${action === 'logout' ? ApplicationPaths.LogOut : (action === 'login' ? ApplicationPaths.Login : '/')}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
      return {Replace:true, To:redirectUrl};
    }
  };
}

export interface SmesshyPushyProps {
    AppObject: App;
    OnPressStart?: ()=>void;
    OnPressTick?: ()=>void | undefined;
    OnPressFinish?: ()=>Promise<boolean>;
    OnClick?: ()=>void;
    StartPushedState: boolean;
    PressTickInterval?: number;
    Haptic: boolean;
    Disabled?: boolean;
    Size?: string;
    AllowVScroll?: boolean;
    ExtraStyleClass?: string;
    PassiveOnly?: boolean;
}

export interface SmesshyPushyState {
    currentPushedState: boolean;
}


export function SmesshyPushy<T extends Constructor<{}>>(SuperClass: T) {
  return class extends SuperClass {

    capturedPointer = -1;
    targetRef : React.RefObject<HTMLDivElement>;
    targetRect : DOMRect | undefined;
    lastOnTarget: boolean = true;
    finishing: boolean = false;
    pressTickInterval: NodeJS.Timer | undefined = undefined;
    
    constructor(...args: any[]) {
        super();
        this.targetRef = React.createRef();

    }

    onClick(e:React.MouseEvent, props: SmesshyPushyProps){
        if (props.PassiveOnly) return;
        //console.log('click');
        e.preventDefault();
        e.stopPropagation();
    }
    onclick(e:MouseEvent, props: SmesshyPushyProps){
        if (props.PassiveOnly) return;
        e.preventDefault();
        e.stopPropagation();
        if (props.OnClick !== undefined && this.lastOnTarget) {
             props.OnClick();
        }
    }
    onContext(e:React.MouseEvent, props: SmesshyPushyProps){
        e.preventDefault();
        e.stopPropagation();
    }


    startPress(e:React.PointerEvent, props: SmesshyPushyProps) {
        // very special case where a control must get the real onclick event when it happend from the browser and not the react synthetic one
        // register an onClick is how to indicate this. the real onclick starts out set to the noop function
        if (this.targetRef.current !== null && props.OnClick !== undefined) {
            this.targetRef.current.onclick = (e:MouseEvent) => this.onclick(e, props);
        }
        if (props.PassiveOnly) return;

        if (!this.finishing && !props.Disabled && this.capturedPointer === -1)
        {
            //console.log('start');
            this.abandonPress();
            this.capturedPointer = e.pointerId;

            e.preventDefault(); 
            e.stopPropagation();
            e.currentTarget.setPointerCapture(e.pointerId);

            this.targetRect = this.targetRef.current!.getBoundingClientRect();
            this.pointerMove(e, props);

            if (props.Haptic) {
                try {
                    navigator.vibrate(2);
                } catch {

                }
            }

            if (props.OnPressStart !== undefined) {
                props.OnPressStart();
            }
            let controlThis = this;
            if (props.PressTickInterval !== undefined && props.PressTickInterval > 0) {
                this.pressTickInterval = setInterval(() => {
                    if (this.lastOnTarget) {
                        controlThis.continuePress(props);                
                    }
                }, props.PressTickInterval);
            }
        }
    }

    continuePress(props: SmesshyPushyProps) {
        if (!this.finishing && props.OnPressTick !== undefined) {
            props.OnPressTick();
        }
    }

    abandonPress() {
        if (this.capturedPointer !== -1) {
            this.capturedPointer = -1;
        }
        if (this.pressTickInterval !== undefined) {
            clearInterval(this.pressTickInterval);
            this.pressTickInterval = undefined;
        }
    }

    finishPress(e:React.PointerEvent, props: SmesshyPushyProps) {
        if (props.PassiveOnly) return;

        if (!this.finishing && !props.Disabled && e.pointerId === this.capturedPointer) {
            //console.log('finish');
            e.preventDefault(); 
            e.stopPropagation();

            e.currentTarget.releasePointerCapture(this.capturedPointer);
            this.abandonPress();
            if (this.lastOnTarget) {
                if (props.Haptic) {
                    try {
                        navigator.vibrate(2);
                    } catch {

                    }
                }
                if (props.OnPressFinish !== undefined) {
                    this.finishing = true;
                    props.AppObject.asyncHelp.executeAsyncLater(async ()=>{
                        await props.OnPressFinish!();
                        this.finishing = false;
                    });

                }
        
            }
            (this as unknown as Component<SmesshyPushyProps, SmesshyPushyState>).setState({currentPushedState: props.StartPushedState});
        }
    }

    cancelPress(e:React.PointerEvent, props: SmesshyPushyProps) {
        if (props.PassiveOnly) return;

        if (!props.Disabled && e.pointerId === this.capturedPointer) {
            //console.log('cancel');

            e.currentTarget.releasePointerCapture(this.capturedPointer);
            this.abandonPress();
            (this as unknown as Component<SmesshyPushyProps, SmesshyPushyState>).setState({currentPushedState: props.StartPushedState});
        }
    }
    
    
    pointerOnTarget(e:React.PointerEvent, props: SmesshyPushyProps){
        if (e.pointerId === this.capturedPointer && !props.Disabled ) {
            const x = e.clientX;
            const y = e.clientY;
            const rect = this.targetRect!
            const onButton = !(x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom)
            return onButton;
        }
        return false;

    }

    pointerMove(e:React.PointerEvent, props: SmesshyPushyProps) {
        if (props.PassiveOnly) return;

        if (e.pointerId === this.capturedPointer && !props.Disabled ) {
            //console.log('move');

            this.lastOnTarget = this.pointerOnTarget(e, props); 
            (this as unknown as Component<SmesshyPushyProps, SmesshyPushyState>).setState({currentPushedState: this.lastOnTarget ? !props.StartPushedState : props.StartPushedState});
        }
    }

  };
 
}

export class SmesshyTrace {
    recentSteps: string[] = [];
    constructor() {

    }
    public addStep(page: string, action: string, data: string) {
        this.recentSteps.push(`${Date.now()}^${page}^${action}^${data}`);
    }
    public getRecent() : string {
        if (this.recentSteps.length === 0) {
            return '';
        }
        const recent = this.recentSteps.join(';');
        this.clearRecent();
        return Buffer.from(recent).toString('base64');
    }
    public clearRecent() {
        this.recentSteps = [];

    }

}
export const STrace = new SmesshyTrace();

