import React, { Component } from 'react';
import { App, SmesshyAppShape } from '../App'
import IconButton, {CloseButton, IconBookmark, IconBookmarked } from './iconButton';
import { Footer } from '../smesshy';
import { Profile, User } from 'oidc-client';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import PaintingFrame from './paintingFrame';
import { ArtistProfile, PaintingInfo } from '../storageManager';
import InfiniteScroll from "react-infinite-scroll-component";
import { prependListener } from 'process';
import Smessage from './smessage';
import { SmesshyMainButton, SmesshySubButton } from './smesshyButton';
import GamePage from './gamePage';
import { StreakViewer } from './followingPicker';
import { PaintingPair } from './galleryPage';
import { createSearchParams } from 'react-router-dom';


export interface LunchboxPageState extends SmesshyCommonState {
    userId: string | null;
    followingOnly: boolean;
    bookmarksOnly: boolean;
    nextStart: number;
    atEnd: boolean;
    paintingInfos: Array<PaintingInfo>;
}
export interface LunchboxPageProps extends SmesshyCommonProps {
    FetchCount: number;
}

class LunchboxPage extends SmesshyCommon(Component<LunchboxPageProps, LunchboxPageState>) {

    scrollRef: React.RefObject<HTMLDivElement>;

    constructor(props: LunchboxPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.scrollRef = React.createRef();
        this.initState(props);
    }

    initState(props: LunchboxPageProps) {

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            userId: null,
            nextStart: 0,
            atEnd: false,
            paintingInfos: new Array<PaintingInfo>(),
            followingOnly: false,
            bookmarksOnly: false,
        };

    }

    componentDidMount() {
        STrace.addStep('lunchbox', 'didMound', '');
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('lunchbox', 'populateAuth', authenticated.toString());
        if (authenticated) {
            this.setState({authenticated:true, userId: await this.getUserId()});
            this.fetchMorePaintings(true, this.state.atEnd, this.state.nextStart, this.state.paintingInfos, this.state.followingOnly, this.state.bookmarksOnly);
            // clear any notifications about the lunchbox
            this._app!.clearNotifications('lunchbox');
        } else {
            this.setState({authenticated:false});
        }
    }


    fetchMorePaintings(authenticated: boolean,  atEnd: boolean, nextStart: number, paintingInfos: Array<PaintingInfo>, followingOnly: boolean, bookmarksOnly: boolean) {
        if (atEnd) {
          return;
        }
        
        let app = this.props.AppObject;
        let controlThis = this;
        this.pushWaitingFrame(this);
        this.executeAsync(async ()=> {

            const acceptPins = (pins: Array<PaintingInfo>)=>{
                controlThis.setState({
                    paintingInfos: paintingInfos.concat(pins),
                    nextStart: nextStart + pins.length, 
                    atEnd:pins.length < controlThis.props.FetchCount
                });
            };

            let token = await controlThis.getAccessToken();
            if (token !== undefined) {
                STrace.addStep('lunchbox', 'getCurrentLunchboxPaintingsWithPreviews', 'following lunchbox');
                controlThis.storageManager!.getCurrentLunchboxPaintingsWithPreviews(token, 
                    controlThis.state.nextStart, controlThis.state.nextStart + controlThis.props.FetchCount-1, acceptPins);

                controlThis.popWaitingFrame();
            }
        });

      }
    

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`gallery, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const pairs = new Array<[PaintingInfo| undefined, PaintingInfo| undefined]>();
        let lastPair: [PaintingInfo | undefined, PaintingInfo | undefined] = [undefined, undefined];
        for (const pin of this.state.paintingInfos) {
            if (lastPair[0] === undefined) {
                pairs.push(lastPair);
                lastPair[0] = pin;
            } else {
                lastPair[1] = pin;
                lastPair = [undefined, undefined];
            }
        }

        const onStreakLook = async (): Promise<boolean> => {
            const smearRect = controlThis.scrollRef.current!.getBoundingClientRect();
            const dlgWidth = controlThis._app!.GetScaledPxWidth(280);
            const dlgHeight = controlThis._app!.GetScaledPxWidth(600);
            const dlgTop = controlThis._app!.GetScaledPxWidth(70);
            const dlgLeft = controlThis._app!.GetScaledPxWidth(40);
            const dlgRect = new DOMRect(dlgLeft, dlgTop, dlgWidth, dlgHeight);

            controlThis.setState({askQuestion: undefined, overlayTool: {
                Element: <StreakViewer
                    AppObject={controlThis.props.AppObject}
                    AppShape={this._appShape!}
                    Bounds={dlgRect}
                    OnReject={()=>{
                        controlThis.setState({overlayTool: undefined});
                    }}

                    />
            }});
            return true;
        }

        let myPaintingsButton = <SmesshyMainButton AppObject={this._app!}
            Display={ <div style={{height: this._app!.GetScaledPxHeightVh(86)}}>
                <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
                    <rect width="180" height="300" rx="6" ry="6" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'rgb(255, 255, 255)'}} x="2" y="2"></rect>
                    <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 165 20 L 165 190"></path>
                    <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 20 234 L 160 234"></path>
                    <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px'}} d="M 0 220 L 180 220"></path>
                    <g transform="matrix(.8, 1, -1.5, 1, -4, -145)" style={{transformOrigin: '98px 258px'}}>
                        <rect x="28.846" y="242.647" width="125.724" height="32.517" style={{fill: 'rgb(187, 187, 195)'}}></rect>
                        <text style={{fill: 'rgb(51, 51, 51)', fontFamily: 'Arial, sans-serif', fontSize: '24px', fontWeight: '700', whiteSpace: 'pre'}} x="37.276" y="266.132">Just Mine</text>
                        <path d="M 29.011 243.322 C 27.011 243.322 21.313 241.437 19.428 243.322 C 18.868 243.882 20.949 246.654 21.17 247.097 C 23.305 251.366 22.529 257.748 25.817 261.036 C 27.383 262.602 24.915 268.266 26.398 269.748 C 26.51 269.861 29.301 274.394 29.301 274.394 C 29.301 274.394 32.135 269.818 32.496 269.457 C 33.282 268.672 32.786 265.039 32.786 263.65 C 32.786 261.065 31.625 256.538 31.625 252.905 C 31.625 251.711 31.661 249.456 31.044 248.839 C 30.051 247.846 31.62 244.774 29.592 244.774" style={{fill: 'rgb(187, 187, 195)'}}></path>
                    </g>
                </svg>
            </div> }
            Haptic={this.doButtonHaptic()}
            StartPushedState={false}
            OnPressFinish={async (): Promise<boolean>=>{
                let reqNav = controlThis.requireSetup(() => {
                    STrace.addStep('lunchbox', 'nav my', '');
                    controlThis.setState({navigateTo:{To:{pathname:'/my-paintings', search:`?${createSearchParams({title: 'My recent paintings:', scope: 'my', filter:'recent', auth:'true'})}`} }})
                });
                if (reqNav != undefined) {
                    controlThis.setState({navigateTo: reqNav});
                }
                return true;
            }}
        />;
        


        let headerSection = <div className='h-items h-medium-gap each-space-around'>
            <div className='height-100p' style={{width: this._app!.GetScaledPxWidthVw(100)}}>
                <SmesshySubButton AppObject={this._app!}
                    ExtraStyleClass='height-100p width-100p group-cross-center'
                    Display={<>How are my daily streaks?</>}
                    Haptic = {this.doButtonHaptic()}
                    StartPushedState = {false}
                    OnPressFinish={async (): Promise<boolean> => {
                        let reqNav = await controlThis.requireSetupAsync(async () => {
                            await onStreakLook()
                        });
                        if (reqNav != undefined) {
                            controlThis.setState({navigateTo: reqNav});
                        }
                        return true;
        
                    }}
                /> 
            </div>
            <SmesshyMainButton AppObject={this._app!}
                ExtraStyleClass='self-right'
                Display={
                    <div className='height-100p v-items'>
                        <div className='width-100p height-100p h-items each-cross-center'>
                            <div className='smesshy-paint-button width-100p height-100p'></div>
                            <div className='v-items'>
                                <span className='text-small text-boldish self-cross-end'>and</span>
                                <span className='text-small text-boldish self-cross-end'>hide</span>
                            </div>
                        </div>
                        <span className='text-small text-boldish'>it in a pal's lunchbox</span>

                    </div> }

                    Haptic={this.doButtonHaptic()}
                    StartPushedState={false}
                    OnPressFinish={async (): Promise<boolean>=>{
                        let reqNav = controlThis.requireSetup(() => {
                            controlThis.setState({navigateTo: {To:{pathname:'/painting', search:`?${createSearchParams({ saveMode: 'lunc'})}`}}});
                        });
                        if (reqNav != undefined) {
                            controlThis.setState({navigateTo: reqNav});
                        }
                        return true;
                    }}
                />
            <div className='height-100p' style={{width: this._app!.GetScaledPxWidthVw(68)}}>
                {myPaintingsButton}
            </div>
        </div>

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={this._inPWA}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>{`Smesshy - My Lunchbox`}</span>}
                CloseNav = {'/'}
                Body={
                    <div className='game-page-mid smesshy-main text-white text-medium v-medium-gap v-padding-small' >
                    {headerSection}
                    <div className='text-bold h-padding-medium'>Paintings your pals have hidden recently:</div>

                    <div id='gallery-scroll-host' className='width-100p height-100p v-scroll-container' ref={ this.scrollRef }>
                        <InfiniteScroll
                            dataLength={this.state.paintingInfos.length}
                            next={()=>controlThis.fetchMorePaintings(this.state.authenticated, this.state.atEnd, this.state.nextStart, this.state.paintingInfos, this.state.followingOnly, this.state.bookmarksOnly)}
                            hasMore={!this.state.atEnd}
                            loader={<div className='text-bold'>There's more...</div>}
                            scrollableTarget='gallery-scroll-host'

                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                <b>{`(no more)`}</b>
                                </p>
                            }
            >
                            {pairs.map((pair, index) => (
                                <PaintingPair
                                    key={index}
                                    AppObject={this.props.AppObject}
                                    AppShape={this.props.AppShape}
                                    PaintingInfo1={pair[0]}
                                    PaintingInfo2={pair[1]}
                                    UserId={this.state.userId}
                                    SaveEdit = {false}
                                    ShowChallenge = {false}
                                    ShowLunchbox = {false}
                                    SelectionMode = {false}
                                    Callback = {undefined}
                                    NoArtist = {false}
                                    CurrentSelection = {undefined}
                                
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
       
                </div>
                }
            />, this.state);
    }
}

export default LunchboxPage;