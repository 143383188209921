import React, { Component } from 'react';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';


export interface TermsOfServicePageState extends SmesshyCommonState {
    content: string;
}
export interface TermsOfServicePageProps extends SmesshyCommonProps {
}

class TermsOfServicePage extends SmesshyCommon(Component<TermsOfServicePageProps, TermsOfServicePageState>) {

    constructor(props: TermsOfServicePageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            content:'fetching it ...'
        }
    }

    componentDidMount() {
        STrace.addStep('terms', 'didMound', '');
        let controlThis = this;
        this.pushWaitingFrame(this);
        this.executeAsync(async ()=> {
            try {
                const resp = await fetch('account/getTermsOfService', {headers: {'Content-Type':'text/html', 'smesshy-api-trace':STrace.getRecent()}});
                const content = await (resp.text());
                controlThis.setState({content: content});
            } catch (reason) {
                controlThis._app!.reportException('get termsOfService', 'ex', '', reason);
            }
            controlThis.popWaitingFrame();

        });

    }

    render() {
        let controlThis = this;

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={false}
                RequireAuth={false}
                Title = {<span>Smesshy - Terms Of Service</span>}
                CloseNav = {-1}
                Body={
                    <div className='game-page-mid'>
                        <div className='v-scroll-container'>
                            <div className='v-scroll-region'>
                                <div className='smesshy-main h-padding-small text-white' dangerouslySetInnerHTML={{__html:this.state.content}}>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />, this.state);
    }
}

export default TermsOfServicePage;