import React, { Component } from 'react';
import {SvgDown, SvgUp } from './iconButton';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import GamePage from './gamePage';
import { ArtistStats } from '../storageManager';


export interface StatsPageState extends SmesshyCommonState {
    stats: Array<ArtistStats> | undefined;
}
export interface StatsPageProps  extends SmesshyCommonProps {
}

class StatsPage extends SmesshyCommon(Component<StatsPageProps, StatsPageState>) {

    constructor(props: StatsPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            stats: undefined
        };
    }

    componentDidMount() {
        STrace.addStep('stats', 'didMound', '');
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('setup', 'populateAuth', authenticated.toString());

        if (authenticated) {
            STrace.addStep('settings', 'getUserStatsAsync', 'full');
            const userStats = await this.storageManager!.getUserStatsAsync('full', 2);
            this.setState({stats: userStats});
        }
    }


    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`stats, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const onLabelPress = (row: number, label: string) => {
            let explanation1 : string | undefined = undefined;
            let explanation2 : string | undefined = undefined;
            let explanation3 : string | undefined = undefined;

            switch (row) {
                case 2:
                    explanation1 = 'The number of paintings you have saved and not deleted.';
                    break;
                case 3:
                    explanation1 = `'Favored' means pushing the 'heart' button on a painting.`;
                    break;
                case 4:
                    explanation1 = `The number of times you have favored a painting, even one of your own, which is like ... 'whatever?!?'`;
                    break;
                case 5:
                    explanation1 = `The number of times a follower of yours has favored one of your paintings.`;
                    break;
                case 6:
                    explanation1 = `The number of times a non-follower has favored one of your paintings.`;
                    break;
                case 7:
                    explanation1 = `'Offensive' means someone has pushed the 'yuck face' button on a painting.`;
                    break;
                case 8:
                    explanation1 = `The number of times you have found a painting offensive AND were brave enough to report it.`;
                    break;
                case 9:
                    explanation1 = `The average alignment (with the beliefs and bravery of others) for the paintings you have found offensive.`;
                    explanation2 = `If only you report a painting, you get a 0. If others report it, you get a 1`;
                    break;
                case 10:
                    explanation1 = `The number of times you have painted something that someone else found offensive and they were brave enough to report it.`;
                    break;
                case 11:
                    explanation1 = `'Suspicious' means someone has pushed the 'question mark, exclamation point, question mark' button on a painting indicating they think it could be fake or copied.`;
                    break;
                case 12:
                    explanation1 = `The number of times you have found a painting suspicious AND were brave enough to report it.`;
                    break;
                case 13:
                    explanation1 = `The average alignment (with the beliefs and bravery of others) for the paintings you have found suspicious.`; 
                    explanation2 = `If only you report a painting, you get a 0. If others report it, you get a 1`;
                    break;
                case 14:
                    explanation1 = `The number of times you have painted something that someone else found suspicious and they were brave enough to report it.`;
                    break;
                case 15:
                    explanation1 = `Lunchbox is a feature where you can send a painting to one or more mutual followers. This is a direct message that only they can see.`;
                    break;
                case 16:
                    explanation1 = `The number of paintings you have hid in someone's lunchbox.`;
                    break;
                case 17:
                    explanation1 = `The number of paintings where you hid it AND the receiver looked upon it in delight.`;
                    break;
                case 18:
                    explanation1 = `The number of paintings you have received in your lunchbox.`;
                    break;
                case 19:
                    explanation1 = `The number of paintings you looked at in your lunchbox.`;
                    break;
                case 20:
                    explanation1 = `The longest streak of days where you have sent a painting to someone's lunchbox, they have looked at it, they have sent a painting to your lunchbox, and you have looked at it.`;
                    break;
                case 21:
                    explanation1 = `Challenges are the public competitions where people submit paintings and vote on them.`;
                    break;
                case 22:
                    explanation1 = `The number of paintings you have submitted to a challenge.`;
                    break;
                case 23:
                    explanation1 = `The highest final position one of your entries has achieved in a challenge.`;
                    break;
                case 24:
                    explanation1 = `The total number of points you have received from other people in challenges.`;
                    break;
                case 25:
                    explanation1 = `The total number of votes you have received from other people in challenges.`;
                    break;
                case 26:
                    explanation1 = `The total number of points you have given to other people in challenges.`;
                    break;
                case 27:
                    explanation1 = `The average alignment between the scores you give to each painting and the average of scores from all other voters for that same painting.`;
                    explanation2 = `For instance, you score a painting as 5 and the group average is 6.5 then you get a (1-(6.5-5)/9) alignment.`;
                    explanation3 = `(that's .83).`;
                    break;
                case 28:
                    explanation1 = `Followers are people who have chosen to see your paintings on their 'Following' refrigerator doors.`;
                    break;
                case 29:
                    explanation1 = `The number of people who have chosen to follow your work and bask in the glow of your majestic presence.`;
                    break;
                case 30:
                    explanation1 = `The number of people you have chosen to follow.`;
                    break;
                case 31:
                    explanation1 = `A 'Maven' is an influential connoisseur or expert in a field.`;
                    explanation2 = `So, to be called a 'Smesshy maven'? Special.`;
                    break;
                case 32:
                    explanation1 = `Your Maven rating combines your expertise (number of challenges entered and how well you rank in them), your connoisseur skill (how well can you predict the highest challenge scores and how often do you vote), and your influence level (number of followers, follows and their overall ratings too).`;
                    break;
                case 33:
                    explanation1 = `The average Maven score of your followers.`;
                    break;
                case 34:
                    explanation1 = `The average Maven score of those you follow.`;
                    break;
            }

            if (explanation1 !== undefined) {
                let prompts = new Array<JSX.Element>();
                prompts.push(<span key={'l1'}>{explanation1}</span>);
                if (explanation2 !== undefined) {
                    prompts.push(<span key={'l2'} className='text-small'>{explanation2}</span>);
                }
                if (explanation3 !== undefined) {
                    prompts.push(<span key={'l3'} className='text-small'>{explanation3}</span>);
                }

                controlThis.setState({askQuestion:{
                    Top: controlThis._app!.GetScaledPxHeight(120),
                    Title: <span>Since you asked...</span>,
                    Prompts: [...prompts],
                    Options: [{Key:'okGreat', Option:<span>OK</span>, OnOption:async ():Promise<boolean>=>{
                        controlThis.setState({askQuestion:undefined});
                        return true;
                    }}], OnCancel:()=>{
                        controlThis.setState({askQuestion:undefined});
                    }
                }});
            }
        }

        let statsNew = this.state.stats !== undefined && this.state.stats.length > 0 ? this.state.stats[0] : undefined;
        let statsPrev = this.state.stats !== undefined && this.state.stats.length > 1 ? this.state.stats[1] : undefined;

        let kpiHeight = `${this._app!.GetScaledPxHeight(20)}px`;
        const addRow = (row: number, label: string, member: string) => {
            let prevOne = statsPrev !== undefined ? Math.round((statsPrev as any)[member] as number * 1000)/1000 : ' - ';
            let newOne = statsNew !== undefined ? Math.round((statsNew as any)[member] as number * 1000)/1000 : ' - ';

            let kpi = <></>;
            if (typeof prevOne === 'number' && typeof newOne === 'number') {
                kpi = newOne > prevOne ? SvgUp(kpiHeight) : newOne < prevOne ? SvgDown(kpiHeight) : <></>;
            }
            let rank = ' - ';
            if (statsNew !== undefined) {
                let rankNum =  Math.round((statsNew as any)[member+'Rank'] as number * 100);
                if (rankNum === 0) {
                    rank = '<1%';
                } else if (rankNum === 100) {
                    rank = ' - ';
                } else {
                    rank = `${rankNum}%`;
                }
            }

            return <>
                    <div className='text-small h-padding-small border-thin' style={{gridRow:row, gridColumn:1}} onPointerDown={(e)=>onLabelPress(row, label)}>
                        {label}
                    </div>
                    <div className='h-padding-small self-center self-cross-center' style={{gridRow:row, gridColumn:2}}>
                        {prevOne}
                    </div>
                    <div className=' h-padding-small self-cross-center' style={{gridRow:row, gridColumn:3}}>
                        {kpi}
                    </div>
                    <div className='h-padding-small self-center self-cross-center' style={{gridRow:row, gridColumn:4}}>
                        {newOne}
                    </div>
                    <div className='text-small h-padding-small self-cross-center' style={{gridRow:row, gridColumn:5}}>
                        {rank}
                    </div>

                </>
        }
        const addLine = (row: number, title: string) => {
            return <>
                    <div className = 'text-large' style={{gridRow:row, gridColumn:1}} onPointerDown={(e)=>onLabelPress(row, title)}>
                        {title}
                    </div>
                    <div style={{gridRow:row, gridColumn:2}}>
                        &nbsp;
                    </div>
                    <div style={{gridRow:row, gridColumn:3}}>
                        &nbsp;
                    </div>
                    <div style={{gridRow:row, gridColumn:4}}>
                        &nbsp;
                    </div>
                    <div style={{gridRow:row, gridColumn:5}}>
                        &nbsp;
                    </div>

                </>
        }

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={this._inPWA}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>Smesshy - Statistics</span>}
                CloseNav = '/'
                Body={
                    <div className='game-page-mid text-medium text-black v-scroll-region' >
                        <div className='v-items v-medium-gap v-padding-large h-padding-medium height-100p width-100p v-scroll-container'>
                            <div style={{display:'grid'}}>
                                <div className='text-large h-padding-small' style={{gridRow:1, gridColumn:1}}>
                                    
                                </div>
                                <div className='text-large h-padding-small' style={{gridRow:1, gridColumn:2}}>
                                    Previous
                                </div>
                                <div className='text-large h-padding-small' style={{gridRow:1, gridColumn:3}}>
                                    
                                </div>
                                <div className='text-large h-padding-small' style={{gridRow:1, gridColumn:4}}>
                                    Latest
                                </div>
                                <div className='text-large h-padding-small' style={{gridRow:1, gridColumn:5}}>
                                    Top %
                                </div>

                                {addRow(2, 'Paintings Saved', 'totalPaintings')}
                                {addLine(3, 'Favored:')}
                                {addRow(4, 'You Think', 'totalFavorites')}
                                {addRow(5, `You Made (Followers)`, 'totalFavoredByFollowers')}
                                {addRow(6, `You Made (Strangers)`, 'totalFavoredByOthers')}
                                {addLine(7, 'Offensive:')}
                                {addRow(8, 'You Think', 'totalOffendedBy')}
                                {addRow(9, 'Think Alignment', 'offendedAlignment')}
                                {addRow(10, 'You Made', 'totalOffend')}
                                {addLine(11, 'Suspicious:')}
                                {addRow(12, 'You Think', 'totalSuspectOf')}
                                {addRow(13, 'Think Alignment', 'suspectedAlignment')}
                                {addRow(14, 'You Made', 'totalSuspect')}
                                {addLine(15, 'Lunchbox:')}
                                {addRow(16, 'Deliveries Made', 'lunchboxDeliveriesSent')}
                                {addRow(17, 'Deliveries Seen', 'lunchboxDeliveriesSeen')}
                                {addRow(18, 'Receptions', 'lunchboxDeliveriesReceived')}
                                {addRow(19, 'Receptions Viewed', 'lunchboxDeliveriesViewed')}
                                {addRow(20, 'Longest Streak', 'longestLunchboxStreak')}
                                {addLine(21, 'Challenges:')}
                                {addRow(22, 'Your Entries', 'competitionEntries')}
                                {addRow(23, 'Highest Rank', 'competitionHighestRank')}
                                {addRow(24, 'Points Received', 'competitionPointsReceived')}
                                {addRow(25, 'Votes Received', 'competitionVotesReceived')}
                                {addRow(26, 'Points You Awarded', 'competitionVotes')}
                                {addRow(27, 'Points Alignment', 'competitionVoteAlignment')}
                                {addLine(28, 'Followers:')}
                                {addRow(29, 'You Follow', 'totalFollowers')}
                                {addRow(30, 'Follow You', 'totalFollowing')}
                                {addLine(31, 'Maven*:')}
                                {addRow(32, 'Your Rating', 'mavenScore')}
                                {addRow(33, 'Follower Rating', 'averageFollowerMavenScore')}
                                {addRow(34, 'Following Rating', 'averageFollowingMavenScore')}
                            </div>

                        </div>
                        
                    </div>
                }
            />, this.state);
    }
}

export default StatsPage;