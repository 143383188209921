import React, { Component } from 'react';
import { App } from '../App'
import {CloseButton } from './iconButton';
import { Footer } from '../smesshy';
import { Profile, User } from 'oidc-client';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState, SmesshyNavigateOptions, SmesshyPushy, SmesshyPushyProps, SmesshyPushyState } from '../smesshyCommon';
import { Link, Navigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { PaintingInfo } from '../storageManager';
import Smessage from './smessage';
import { SmesshySubButton } from './smesshyButton';
import GamePage from './gamePage';



interface FridgeState extends SmesshyPushyState{

}
interface FridgeProps extends SmesshyPushyProps{
    Infos: Array<PaintingInfo>;
    GetImage: (imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined)=>JSX.Element;
}

class Fridge extends SmesshyPushy(Component<FridgeProps, FridgeState>) {

    constructor(props: FridgeProps) {
        super(props);

        this.state = {
            currentPushedState: false
        };
    }


    componentDidUpdate(prevProps: Readonly<FridgeProps>, prevState: Readonly<FridgeState>): void {
        if (prevProps.StartPushedState !== this.props.StartPushedState) {
            this.setState({currentPushedState: this.props.StartPushedState})
        }
    }

    componentWillUnmount() {
        this.abandonPress();
    }

    render() {

        let controlThis = this;

        const thumbIfExists = (infos: Array<PaintingInfo>, idx: number) : string | undefined => {
            if (infos === undefined || infos.length <= idx || infos[idx].imageDetail === undefined) {
                return undefined;
            }
            return (infos[idx].imageDetail!.thumbnail);
        }

        return (
        <div ref = {this.targetRef} className='height-100p width-100p'
            onClick={(e:React.MouseEvent)=> controlThis.onClick(e, this.props)}
            onContextMenu={(e:React.MouseEvent)=> controlThis.onContext(e, this.props)}
            onPointerDown={(e:React.PointerEvent) => {controlThis.startPress(e, this.props)}}
            onPointerUp={(e:React.PointerEvent) => {controlThis.finishPress(e, this.props)} }
            onPointerMove={(e:React.PointerEvent) => controlThis.pointerMove(e, this.props)}
            onPointerCancel={(e:React.PointerEvent) => controlThis.cancelPress(e, this.props)}
            >
            {this.props.GetImage(thumbIfExists(this.props.Infos, 0), thumbIfExists(this.props.Infos, 1), thumbIfExists(this.props.Infos, 2))}
        </div>);
    }
}


export interface GalleryDoorsPageState extends SmesshyCommonState {
    myRecInfos: Array<PaintingInfo>;
    myFavInfos: Array<PaintingInfo>;
    myPopInfos: Array<PaintingInfo>;
    folRecInfos: Array<PaintingInfo>;
    folFavInfos: Array<PaintingInfo>;
    folPopInfos: Array<PaintingInfo>;
    globTrendInfos: Array<PaintingInfo>;
    globFavInfos: Array<PaintingInfo>;
    globPopInfos: Array<PaintingInfo>;

}
export interface GalleryDoorsPageProps extends SmesshyCommonProps {
}

class GalleryDoorsPage extends SmesshyCommon(Component<GalleryDoorsPageProps, GalleryDoorsPageState>) {

    constructor(props: GalleryDoorsPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            myRecInfos: new Array<PaintingInfo>(),
            myFavInfos: new Array<PaintingInfo>(),
            myPopInfos: new Array<PaintingInfo>(),
            folRecInfos: new Array<PaintingInfo>(),
            folFavInfos: new Array<PaintingInfo>(),
            folPopInfos: new Array<PaintingInfo>(),
            globTrendInfos: new Array<PaintingInfo>(),
            globFavInfos: new Array<PaintingInfo>(),
            globPopInfos: new Array<PaintingInfo>(),
        };
    }

    componentDidMount() {
        STrace.addStep('galleryDoors', 'didMound', '');
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('galleryDoors', 'populateAuth', authenticated.toString());

        if (authenticated) {
            const sm=this.storageManager!;
            let token = (await this.getAccessToken())!;

            STrace.addStep('galleryDoors', 'getUserPaintingsWithThumbs', 'recent');
            sm.getUserPaintingsWithThumbs(token, 0, 2, 'recent', pins=>this.setState({myRecInfos:pins}));
            STrace.addStep('galleryDoors', 'getUserPaintingsWithThumbs', 'favorite');
            sm.getUserPaintingsWithThumbs(token, 0, 2, 'favorite', pins=>this.setState({myFavInfos:pins}));
            STrace.addStep('galleryDoors', 'getUserPaintingsWithThumbs', 'popular');
            sm.getUserPaintingsWithThumbs(token, 0, 2, 'popular', pins=>this.setState({myPopInfos:pins}));

            STrace.addStep('galleryDoors', 'getFollowingPaintingsWithThumbs', 'recent');
            sm.getFollowingPaintingsWithThumbs(token, 0, 2, 'recent', pins=>this.setState({folRecInfos:pins}));
            STrace.addStep('galleryDoors', 'getFollowingPaintingsWithThumbs', 'favorite');
            sm.getFollowingPaintingsWithThumbs(token, 0, 2, 'favorite', pins=>this.setState({folFavInfos:pins}));
            STrace.addStep('galleryDoors', 'getFollowingPaintingsWithThumbs', 'popular');
            sm.getFollowingPaintingsWithThumbs(token, 0, 2, 'popular', pins=>this.setState({folPopInfos:pins}));

            STrace.addStep('galleryDoors', 'getGlobalPaintingsWithThumbs-auth', 'recent');
            sm.getGlobalPaintingsWithThumbs(token, 0, 2, 'recent', pins=>this.setState({globTrendInfos:pins}));
            STrace.addStep('galleryDoors', 'getGlobalPaintingsWithThumbs-auth', 'favorite');
            sm.getGlobalPaintingsWithThumbs(token, 0, 2, 'favorite', pins=>this.setState({globFavInfos:pins}));
            STrace.addStep('galleryDoors', 'getGlobalPaintingsWithThumbs-auth', 'popular');
            sm.getGlobalPaintingsWithThumbs(token, 0, 2, 'popular', pins=>this.setState({globPopInfos:pins}));

        } else {
            const sm=this.storageManager!;
            let token = 'none';

            STrace.addStep('galleryDoors', 'getGlobalPaintingsWithThumbs-anon', 'recent');
            sm.getGlobalPaintingsWithThumbs(token, 0, 2, 'recent', pins=>this.setState({globTrendInfos:pins}));
            STrace.addStep('galleryDoors', 'getGlobalPaintingsWithThumbs-anon', 'favorite');
            sm.getGlobalPaintingsWithThumbs(token, 0, 2, 'favorite', pins=>this.setState({globFavInfos:pins}));
            STrace.addStep('galleryDoors', 'getGlobalPaintingsWithThumbs-anon', 'popular');
            sm.getGlobalPaintingsWithThumbs(token, 0, 2, 'popular', pins=>this.setState({globPopInfos:pins}));
        }
       
    }


    componentDidUpdate(prevProps: Readonly<GalleryDoorsPageProps>, prevState: Readonly<GalleryDoorsPageState>): void {

    }

    getGalRefMyRec(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="180" y2="0" id="gradient-0" gradientTransform="">
                <stop offset="0" style={{stopColor: 'rgb(160, 160, 170)'}}></stop>
                <stop offset="0.5" style={{stopColor: 'rgb(220, 220, 230)'}}></stop>
                <stop offset="1" style={{stopColor: 'rgb(180, 180, 190)'}}></stop>
                </linearGradient>
            </defs>
            <rect width="180" height="300" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'url(#gradient-0)'}} x="2" y="2"></rect>
            <rect width="180" height="90" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'url(#gradient-0)'}} y="2" x="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 15 20 L 15 75"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 15 115 L 15 205"></path>
            <ellipse style={{stroke: 'rgb(0, 0, 0)'}} cx="20" cy="304" rx="3" ry="3"></ellipse>
            <ellipse style={{stroke: 'rgb(0, 0, 0)'}} cx="160" cy="304" rx="3" ry="3"></ellipse>
            <g>
                <text style={{fill: 'rgb(131, 49, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="32" y="34">R</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="34" y="32">R</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, -0.610506, 3.052527)">
                <text style={{fill: 'rgb(2, 69, 128)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="54" y="34">E</text>
                <text style={{fill: 'rgb(4, 135, 253)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="56" y="32">E</text>
            </g>
            <g transform="matrix(0.990268, -0.139173, 0.139173, 0.990268, -0.610506, 0.610507)" style={{transformOrigin: '82.346px 21.8583px'}}>
                <text style={{fill: 'rgb(131, 131, 3)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="72" y="34">C</text>
                <text style={{fill: 'rgb(255, 254, 6)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="74" y="32">C</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, 0.915758, 2.747274)">
                <text style={{fill: 'rgb(2, 71, 131)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="92" y="34">E</text>
                <text style={{fill: 'rgb(4, 135, 253)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="94" y="32">E</text>
            </g>
            <g>
                <text style={{fill: 'rgb(122, 45, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="110" y="34">N</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="112" y="32">N</text>
            </g>
            <g style={{transformOrigin: '143.547px 21.8583px'}} transform="matrix(0.994522, 0.104528, -0.104528, 0.994522, 0, 0)">
                <text style={{fill: 'rgb(2, 82, 26)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} y="34" x="134">T</text>
                <text style={{fill: 'rgb(4, 158, 49)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="136" y="32">T</text>
            </g>
            {imgSrc1 === undefined ? <></> : <image x="35" y="110" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="114" y="127" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="64" y="188" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.981627, 0.190809, -0.190809, 0.981627, -0.000021, -0.000042)" href={imgSrc3}></image>}
            </svg>);
    }


    getGalRefMyFav(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs></defs>
            <rect width="180" height="300" rx="6" ry="6" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'rgb(255, 255, 255)'}} x="2" y="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 165 20 L 165 190"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 20 234 L 160 234"></path>
            {imgSrc1 === undefined ? <></> : <image x="35" y="110" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.990268, 0.139173, -0.139173, 0.990268, -15.28465, -88.007278)" href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="97" y="22" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="64" y="188" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.866025, -0.500001, 0.500001, 0.866025, 0.000221, -83.198737)" href={imgSrc3}></image>}
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px'}} d="M 0 220 L 180 220"></path>
            <g transform="matrix(0.994522, 0.104528, -0.104528, 0.994522, -4.332682, 8.149307)" style={{transformOrigin: '98.5136px 258.007px'}}>
                <rect x="28.846" y="242.647" width="145.724" height="32.517" style={{fill: 'rgb(187, 187, 195)'}}></rect>
                <text style={{fill: 'rgb(51, 51, 51)', fontFamily: 'Arial, sans-serif', fontSize: '24px', fontWeight: '700', whiteSpace: 'pre'}} x="37.276" y="266.132">Bookmarks</text>
                <path style={{fill: 'rgb(187, 187, 195)'}} d="M 174.209 243.031 C 175.274 244.63 175.113 248.582 176.242 249.711 C 177.403 250.872 175.951 254.647 177.113 255.809 C 177.669 256.365 175.712 260.797 177.113 262.198 C 177.469 262.554 176.242 263.878 176.242 264.521 C 176.242 266.976 176.286 269.211 177.694 270.619 C 177.728 270.653 176.091 276.566 173.919 274.394 C 170.564 271.039 173.383 260.546 171.305 256.39 C 170.686 255.152 170.717 253.784 171.305 253.195 C 172.277 252.223 170.896 245.935 172.467 245.935"></path>
                <path d="M 29.011 243.322 C 27.011 243.322 21.313 241.437 19.428 243.322 C 18.868 243.882 20.949 246.654 21.17 247.097 C 23.305 251.366 22.529 257.748 25.817 261.036 C 27.383 262.602 24.915 268.266 26.398 269.748 C 26.51 269.861 29.301 274.394 29.301 274.394 C 29.301 274.394 32.135 269.818 32.496 269.457 C 33.282 268.672 32.786 265.039 32.786 263.65 C 32.786 261.065 31.625 256.538 31.625 252.905 C 31.625 251.711 31.661 249.456 31.044 248.839 C 30.051 247.846 31.62 244.774 29.592 244.774" style={{fill: 'rgb(187, 187, 195)'}}></path>
            </g>
            </svg>);
    }

    getGalRefMyPop(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs></defs>
            <rect width="180" height="300" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'rgb(212, 199, 180)'}} x="2" y="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px'}} d="M 0 110L 180 110"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px'}} d="M 8 304 L 174 304"></path>
            {imgSrc1 === undefined ? <></> : <image x="13" y="120" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="105" y="127" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="64" y="188" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.438371, -0.898794, 0.898794, 0.438371, 4.898968, -0.000028)" href={imgSrc3}></image>}
            <rect x="166" y="58" width="8" height="40" rx="4" ry="4" style={{stroke: 'rgb(0, 0, 0)', fill: 'rgb(229, 229, 229)', paintOrder: 'fill', strokeWidth: '2px'}} transform=""></rect>
            <rect x="166" y="122.214" width="8" height="60" rx="8" ry="4" style={{stroke: 'rgb(0, 0, 0)', fill: 'rgb(229, 229, 229)', paintOrder: 'fill', strokeWidth: '2px'}}></rect>
            <rect x="26.196" y="15.007" width="130.218" height="42.904" style={{stroke: 'rgb(255, 255, 255)', strokeWidth: '2px'}}></rect>
            <text style={{fill: 'rgb(255, 255, 255)', fontFamily: 'Arial, sans-serif', fontSize: '32px', whiteSpace: 'pre'}} x="34.475" y="44.363">Popular
            </text>
            </svg>);
    }


    getGalRefFolRec(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="180" y2="0" id="gradient-0" gradientTransform="">
                <stop offset="0" style={{stopColor: 'rgb(160, 160, 170)'}}></stop>
                <stop offset="0.5" style={{stopColor: 'rgb(220, 220, 230)'}}></stop>
                <stop offset="1" style={{stopColor: 'rgb(180, 180, 190)'}}></stop>
                </linearGradient>
            </defs>
            <rect width="180" height="300" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'url(#gradient-0)'}} x="2" y="2"></rect>
            <rect width="180" height="90" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'url(#gradient-0)'}} y="2" x="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 15 20 L 15 75"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 15 115 L 15 205"></path>
            <ellipse style={{stroke: 'rgb(0, 0, 0)'}} cx="20" cy="304" rx="3" ry="3"></ellipse>
            <ellipse style={{stroke: 'rgb(0, 0, 0)'}} cx="160" cy="304" rx="3" ry="3"></ellipse>
            <g>
                <text style={{fill: 'rgb(131, 49, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="32" y="34">R</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="34" y="32">R</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, -0.610506, 3.052527)">
                <text style={{fill: 'rgb(2, 69, 128)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="54" y="34">E</text>
                <text style={{fill: 'rgb(4, 135, 253)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="56" y="32">E</text>
            </g>
            <g transform="matrix(0.990268, -0.139173, 0.139173, 0.990268, -0.305253, 9.462836)" style={{transformOrigin: '82.346px 21.8583px'}}>
                <text style={{fill: 'rgb(131, 131, 3)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="72" y="34">C</text>
                <text style={{fill: 'rgb(255, 254, 6)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="74" y="32">C</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, 1.221011, 17.704657)">
                <text style={{fill: 'rgb(2, 71, 131)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="92" y="34">E</text>
                <text style={{fill: 'rgb(4, 135, 253)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="94" y="32">E</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, 4.273538, 25.64123)">
                <text style={{fill: 'rgb(122, 45, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="110" y="34">N</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="112" y="32">N</text>
            </g>
            <g style={{transformOrigin: '143.547px 21.8583px'}} transform="matrix(0.994522, 0.104528, -0.104528, 0.994522, 5.494549, 32.662041)">
                <text style={{fill: 'rgb(2, 82, 26)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} y="34" x="134">T</text>
                <text style={{fill: 'rgb(4, 158, 49)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="136" y="32">T</text>
            </g>
            {imgSrc1 === undefined ? <></> : <image x="35" y="110" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.990268, 0.139173, -0.139173, 0.990268, 0.000013, -0.000015)" href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="106" y="109" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="64" y="188" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.743145, -0.669131, 0.669131, 0.743145, 0.000137, -0.000021)" href={imgSrc3}></image>}
            </svg>);
    }

    getGalRefFolFav(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs></defs>
            <rect width="180" height="300" rx="6" ry="6" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'rgb(255, 255, 255)'}} x="2" y="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 165 20 L 165 190"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 20 234 L 160 234"></path>
            {imgSrc1 === undefined ? <></> : <image x="35" y="110" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(1, 0, 0, 1, -15.285, -88.007)" href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="97" y="22" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.994522, -0.104528, 0.104528, 0.994522, -0.000033, 6.339297)" href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="64" y="188" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.994522, 0.104527, -0.104527, 0.994522, -7.910607, -83.198829)" href={imgSrc3}></image>}
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px'}} d="M 0 220 L 180 220"></path>
            <g transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -4.332691, 8.149307)" style={{transformOrigin: '98.5136px 258.007px'}}>
                <rect x="28.846" y="242.647" width="145.724" height="32.517" style={{fill: 'rgb(187, 187, 195)'}}></rect>
                <text style={{fill: 'rgb(51, 51, 51)', fontFamily: 'Arial, sans-serif', fontSize: '26px', fontWeight: '700', whiteSpace: 'pre'}} x="37.276" y="266.132">Bookmarks</text>
                <path style={{fill: 'rgb(187, 187, 195)'}} d="M 174.209 243.031 C 175.274 244.63 175.113 248.582 176.242 249.711 C 177.403 250.872 175.951 254.647 177.113 255.809 C 177.669 256.365 175.712 260.797 177.113 262.198 C 177.469 262.554 176.242 263.878 176.242 264.521 C 176.242 266.976 176.286 269.211 177.694 270.619 C 177.728 270.653 176.091 276.566 173.919 274.394 C 170.564 271.039 173.383 260.546 171.305 256.39 C 170.686 255.152 170.717 253.784 171.305 253.195 C 172.277 252.223 170.896 245.935 172.467 245.935"></path>
                <path d="M 29.011 243.322 C 27.011 243.322 21.313 241.437 19.428 243.322 C 18.868 243.882 20.949 246.654 21.17 247.097 C 23.305 251.366 22.529 257.748 25.817 261.036 C 27.383 262.602 24.915 268.266 26.398 269.748 C 26.51 269.861 29.301 274.394 29.301 274.394 C 29.301 274.394 32.135 269.818 32.496 269.457 C 33.282 268.672 32.786 265.039 32.786 263.65 C 32.786 261.065 31.625 256.538 31.625 252.905 C 31.625 251.711 31.661 249.456 31.044 248.839 C 30.051 247.846 31.62 244.774 29.592 244.774" style={{fill: 'rgb(187, 187, 195)'}}></path>
            </g>
            </svg>);
    }

    getGalRefFolPop(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs></defs>
            <rect width="180" height="300" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'rgb(212, 199, 180)'}} x="2" y="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px'}} d="M 0 110L 180 110"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px'}} d="M 8 304 L 174 304"></path>
            {imgSrc1 === undefined ? <></> : <image x="13" y="120" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="64" y="151" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="119" y="190" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} href={imgSrc3}></image>}
            <rect x="166" y="58" width="8" height="40" rx="4" ry="4" style={{stroke: 'rgb(0, 0, 0)', fill: 'rgb(229, 229, 229)', paintOrder: 'fill', strokeWidth: '2px'}} transform=""></rect>
            <rect x="166" y="122.214" width="8" height="60" rx="8" ry="4" style={{stroke: 'rgb(0, 0, 0)', fill: 'rgb(229, 229, 229)', paintOrder: 'fill', strokeWidth: '2px'}}></rect>
            <rect x="26.196" y="15.007" width="130.218" height="42.904" style={{stroke: 'rgb(255, 255, 255)', strokeWidth: '2px'}}></rect>
            <text style={{fill: 'rgb(255, 255, 255)', fontFamily: 'Arial, sans-serif', fontSize: '32px', whiteSpace: 'pre'}} x="34.475" y="44.363">Popular
            </text>
            </svg>);
    }

    getGalRefGlobRec(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="180" y2="0" id="gradient-0" gradientTransform="">
                <stop offset="0" style={{stopColor: 'rgb(160, 160, 170)'}}></stop>
                <stop offset="0.5" style={{stopColor: 'rgb(220, 220, 230)'}}></stop>
                <stop offset="1" style={{stopColor: 'rgb(180, 180, 190)'}}></stop>
                </linearGradient>
            </defs>
            <rect width="180" height="300" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'url(#gradient-0)'}} x="2" y="2"></rect>
            <rect width="180" height="90" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'url(#gradient-0)'}} y="2" x="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 15 20 L 15 75"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 15 115 L 15 205"></path>
            <ellipse style={{stroke: 'rgb(0, 0, 0)'}} cx="20" cy="304" rx="3" ry="3"></ellipse>
            <ellipse style={{stroke: 'rgb(0, 0, 0)'}} cx="160" cy="304" rx="3" ry="3"></ellipse>
            <g style={{transformOrigin: '143.547px 21.8583px'}} transform="matrix(0.994522, 0.104528, -0.104528, 0.994522, -107.448959, 9.462834)">
                <text style={{fill: 'rgb(2, 82, 26)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} y="34" x="134">T</text>
                <text style={{fill: 'rgb(4, 158, 49)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="136" y="32">T</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, 13.736372, 11.599603)">
                <text style={{fill: 'rgb(131, 49, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="32" y="34">R</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="34" y="32">R</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, 13.736372, 8.241823)">
                <text style={{fill: 'rgb(2, 69, 128)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="54" y="34">E</text>
                <text style={{fill: 'rgb(4, 135, 253)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="56" y="32">E</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, -21.978197, 13.736374)">
                <text style={{fill: 'rgb(122, 45, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="110" y="34">N</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="112" y="32">N</text>
            </g>
            <g transform="matrix(0.990268, -0.139173, 0.139173, 0.990268, 38.767097, 7.936574)" style={{transformOrigin: '82.346px 21.8583px'}}>
                <text style={{fill: 'rgb(122, 45, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="72" y="34">D</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="74" y="32">D</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, 41.209118, 9.462832)">
                <text style={{fill: 'rgb(2, 71, 131)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="92" y="34">I</text>
                <text style={{fill: 'rgb(4, 135, 253)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="94" y="32">I</text>
            </g>
            <g transform="matrix(1, 0, 0, 1, 37.250278, 18.842093)">
                <text style={{fill: 'rgb(122, 45, 1)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="110" y="34">N</text>
                <text style={{fill: 'rgb(254, 94, 2)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="112" y="32">N</text>
            </g>
            <g style={{transformOrigin: '143.547px 21.858px'}} transform="matrix(0.994522, 0.104528, -0.104528, 0.994522, 23.120941, 44.17807)">
                <text style={{fill: 'rgb(2, 82, 26)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} y="34" x="134">G</text>
                <text style={{fill: 'rgb(4, 158, 49)', fontFamily: 'Tahoma', fontSize: '28px', fontWeight: '700', whiteSpace: 'pre'}} x="136" y="32">G</text>
            </g>
            {imgSrc1 === undefined ? <></> : <image x="35" y="110" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.994522, -0.104529, 0.104529, 0.994522, 0.000092, 8.339311)" href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="74" y="175" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="64" y="188" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(0.99863, -0.052336, 0.052336, 0.99863, 50.582547, -67.760102)" href={imgSrc3}></image>}
            </svg>);
    }

    getGalRefGlobFav(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined){
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs></defs>
            <rect width="180" height="300" rx="6" ry="6" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'rgb(255, 255, 255)'}} x="2" y="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 165 20 L 165 190"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px', strokeLinecap: 'round'}} d="M 20 234 L 160 234"></path>
            {imgSrc1 === undefined ? <></> : <image x="35" y="110" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(1, 0, 0, 1, -15.285, -88.007)" href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="97" y="22" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(1, 0, 0, 1, 0, 6.339)" href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="64" y="188" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} transform="matrix(1, -0.000001, 0.000001, 1, -16.999951, -83.198789)" href={imgSrc3}></image>}
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px'}} d="M 0 220 L 180 220"></path>
            <g transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -4.332691, 8.149307)" style={{transformOrigin: '98.5136px 258.007px'}}>
                <rect x="28.846" y="242.647" width="145.724" height="32.517" style={{fill: 'rgb(187, 187, 195)'}}></rect>
                <text style={{fill: 'rgb(51, 51, 51)', fontFamily: 'Arial, sans-serif', fontSize: '26px', fontWeight: '700', whiteSpace: 'pre'}} x="29.431" y="266.232">Bookmarks</text>
                <path style={{fill: 'rgb(187, 187, 195)'}} d="M 174.209 243.031 C 175.274 244.63 175.113 248.582 176.242 249.711 C 177.403 250.872 175.951 254.647 177.113 255.809 C 177.669 256.365 175.712 260.797 177.113 262.198 C 177.469 262.554 176.242 263.878 176.242 264.521 C 176.242 266.976 176.286 269.211 177.694 270.619 C 177.728 270.653 176.091 276.566 173.919 274.394 C 170.564 271.039 173.383 260.546 171.305 256.39 C 170.686 255.152 170.717 253.784 171.305 253.195 C 172.277 252.223 170.896 245.935 172.467 245.935"></path>
                <path d="M 29.011 243.322 C 27.011 243.322 21.313 241.437 19.428 243.322 C 18.868 243.882 20.949 246.654 21.17 247.097 C 23.305 251.366 22.529 257.748 25.817 261.036 C 27.383 262.602 24.915 268.266 26.398 269.748 C 26.51 269.861 29.301 274.394 29.301 274.394 C 29.301 274.394 32.135 269.818 32.496 269.457 C 33.282 268.672 32.786 265.039 32.786 263.65 C 32.786 261.065 31.625 256.538 31.625 252.905 C 31.625 251.711 31.661 249.456 31.044 248.839 C 30.051 247.846 31.62 244.774 29.592 244.774" style={{fill: 'rgb(187, 187, 195)'}}></path>
            </g>
            </svg>);
    }

    getGalRefGlobPop(imgSrc1: string|undefined, imgSrc2: string|undefined, imgSrc3: string|undefined) {
        return (
            <svg viewBox="0 0 184 308" xmlns="http://www.w3.org/2000/svg" style={{height:'100%', width: '100%'}}>
            <defs></defs>
            <rect width="180" height="300" style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px', strokeLinejoin: 'round', paintOrder: 'fill', fill: 'rgb(212, 199, 180)'}} x="2" y="2"></rect>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '4px'}} d="M 0 110L 180 110"></path>
            <path style={{stroke: 'rgb(0, 0, 0)', strokeWidth: '6px'}} d="M 8 304 L 174 304"></path>
            {imgSrc1 === undefined ? <></> : <image x="13" y="120" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc1}></image>}
            {imgSrc2 === undefined ? <></> : <image x="64" y="151" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px'}} href={imgSrc2}></image>}
            {imgSrc3 === undefined ? <></> : <image x="119" y="190" width="50" height="100" style={{fill: 'transparent', strokeWidth: '0px', transformBox: 'fill-box', transformOrigin: '50% 50%'}} href={imgSrc3}></image>}
            <rect x="166" y="58" width="8" height="40" rx="4" ry="4" style={{stroke: 'rgb(0, 0, 0)', fill: 'rgb(229, 229, 229)', paintOrder: 'fill', strokeWidth: '2px'}} transform=""></rect>
            <rect x="166" y="122.214" width="8" height="60" rx="8" ry="4" style={{stroke: 'rgb(0, 0, 0)', fill: 'rgb(229, 229, 229)', paintOrder: 'fill', strokeWidth: '2px'}}></rect>
            <rect x="20.196" y="15.007" width="144.218" height="42.904" style={{stroke: 'rgb(255, 255, 255)', strokeWidth: '2px'}}></rect>
            <text style={{fill: 'rgb(255, 255, 255)', fontFamily: 'Arial, sans-serif', fontSize: '32px', whiteSpace: 'pre'}} x="28.475" y="44.363">Fantastic
            </text>
            </svg>);
    }


    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`gallery doors, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={false}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>Smesshy - Gallery</span>}
                CloseNav = {'/'}
                Body={
                    <div className='game-page-mid smesshy-main text-white v-small-gap v-padding-small' >
                    <div className='gallery-doors-grid'>
                        <div className='text-center text-xlarge text-line-small text-boldish' style={{gridColumnStart:1, gridColumnEnd:4, gridRow:1 }}>
                            My Masterpieces
                        </div>
                        <div style={{gridColumn:1, gridRow:2, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefMyRec} Infos={this.state.myRecInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=> {
                                        STrace.addStep('gallery doors', 'pick door', 'my recent');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'My recent paintings:', scope: 'my', filter:'recent', auth:'true'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                        <div style={{gridColumn:2, gridRow:2, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefMyFav} Infos={this.state.myFavInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=> {
                                        STrace.addStep('gallery doors', 'pick door', 'my favorite');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'My paintings that I like:', scope: 'my', filter:'favorite', auth:'true'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                        <div style={{gridColumn:3, gridRow:2, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefMyPop} Infos={this.state.myPopInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=> {
                                        STrace.addStep('gallery doors', 'pick door', 'my popular');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'My paintings that others like:', scope: 'my', filter:'popular', auth:'true'})}`} }})
                                        return true;
                                    }}/>
                        </div>

                        <div className='h-items width-100p h-large-gap group-center' style={{gridColumnStart:1, gridColumnEnd:4, gridRow:3}}>
                            <div className='text-xlarge text-line-small text-boldish'>
                                Following
                            </div>
                            <SmesshySubButton AppObject={this._app!}
                                Display={<div> <span className='text-large text-line-none text-boldish'>Whom</span> <span className='text-xlarge text-line-none text-boldish'>...</span></div>}
                                Haptic={this.doButtonHaptic()}
                                StartPushedState={false}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    STrace.addStep('gallery doors', 'following', '');
                                    controlThis.setState({navigateTo:{To:'/following'}});
                                    return true;
                                }}

                            />
                        </div>
                        <div style={{gridColumn:1, gridRow:4, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefFolRec} Infos={this.state.folRecInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=> {
                                        STrace.addStep('gallery doors', 'pick door', 'follow recent');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'Recent paintings from those I follow:', scope: 'following', filter:'recent', auth:'true'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                        <div style={{gridColumn:2, gridRow:4, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefFolFav} Infos={this.state.folFavInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=> {
                                        STrace.addStep('gallery doors', 'pick door', 'follow favorite');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'My favorite paintings from those I follow:', scope: 'following', filter:'favorite', auth:'true'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                        <div style={{gridColumn:3, gridRow:4, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefFolPop} Infos={this.state.folPopInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=> {
                                        STrace.addStep('gallery doors', 'pick door', 'follow popular');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'Popular paintings from those I follow:', scope: 'following', filter:'popular', auth:'true'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                        <div className='text-center text-xlarge text-line-small text-boldish' style={{gridColumnStart:1, gridColumnEnd:4, gridRow:5}}>
                            From Everyone
                        </div>
                        <div style={{gridColumn:1, gridRow:6, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefGlobRec} Infos={this.state.globTrendInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=> {
                                        STrace.addStep('gallery doors', 'pick door', 'global recent');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'Trending paintings from everyone:', scope: 'global', filter:'recent', auth:'false'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                        <div style={{gridColumn:2, gridRow:6, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefGlobFav} Infos={this.state.globFavInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=>{
                                        STrace.addStep('gallery doors', 'pick door', 'global favorite');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'Paintings I like from everyone:', scope: 'global', filter:'favorite', auth:'true'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                        <div style={{gridColumn:3, gridRow:6, margin:1}}>
                            <Fridge AppObject={this._app!} GetImage={this.getGalRefGlobPop} Infos={this.state.globPopInfos} Haptic = {this.doButtonHaptic()} StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=>{
                                        STrace.addStep('gallery doors', 'pick door', 'global popular');
                                        controlThis.setState({navigateTo:{To:{pathname:'./gallery', search:`?${createSearchParams({title: 'All time best from everyone:', scope: 'global', filter:'popular', auth:'false'})}`} }})
                                        return true;
                                    }}/>
                        </div>
                    </div>
                </div>
                }
            />,            
            this.state);
    }
}

export default GalleryDoorsPage;