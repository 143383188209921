import React, { Component, useEffect } from 'react';
import { App } from '../App'
import {CloseButton, IconClose, IconSelect, IconSelected } from './iconButton';
import { Footer } from '../smesshy';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import { ArtistProfile, ArtistStats, LunchBoxStreakReported } from '../storageManager';
import InfiniteScroll from "react-infinite-scroll-component";
import Smessage from './smessage';
import IconButton, {IconRefFactory, IconTrash, IconPlus } from './iconButton';
import { Navigate, createSearchParams } from 'react-router-dom';
import { SearchInput } from './searchInput';
import { SmesshyMainButton } from './smesshyButton';
import GamePage from './gamePage';


export interface FollowingTileState extends SmesshyCommonState {

}
export interface FollowingTileProps extends SmesshyCommonProps{
    ArtistProfile: ArtistProfile;
    ShowTrash: boolean;
    ShowPending: boolean;
    ShowPlus: boolean;
    ShowCheck?: boolean;
    Checked?: boolean;
    AllowVScroll: boolean;
    StreakInfo?: LunchBoxStreakReported;
    OnTileClick: (top: number, artistsId:string, artistsName:string)=>Promise<boolean>;
    OnTrashClick: (top: number, artistsId:string, artistsName:string)=>Promise<boolean>;
    OnPlusClick: (top: number, artistsId:string, artistsName:string)=>Promise<boolean>;
    OnCheckClick?: (top: number, artistsId:string, artistsName:string)=>Promise<boolean>;
}

export class FollowingTile extends SmesshyCommon(Component<FollowingTileProps, FollowingTileState>) {

    rectRef = React.createRef() as React.RefObject<HTMLDivElement>;

    constructor(props: FollowingTileProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
        };
    }

    render() {
        let controlThis = this;

        const bestTop = () => {
            let top = controlThis._app!.GetScaledPxHeight(80);
            if (controlThis.rectRef.current !== undefined) {
                let maxTop = controlThis._app!.GetScaledPxHeight(window.innerHeight - 160);
                let minTop = Math.max(0, Math.min(top, maxTop));
                let r = controlThis.rectRef.current!.getBoundingClientRect();
                top = Math.min(maxTop, Math.max(r.top, minTop));
            }
            return top;
        }


        let artistImage = <></>;
        let artistPortrait = this.props.ArtistProfile !== undefined && this.props.ArtistProfile.artistImageUrl !== undefined && 
                            this.props.ArtistProfile.artistImageUrl !== null && this.props.ArtistProfile.artistImageUrl !== '' ? this.props.ArtistProfile.artistImageUrl : undefined;
        if (artistPortrait !== undefined) {
            artistImage = <img src={artistPortrait} style={{width:this._app!.GetScaledPxWidthVw(32)}} draggable={false}/>
        } else {
            artistImage = <img src='unk-artist.png' style={{width:this._app!.GetScaledPxWidthVw(32)}} draggable={false}/>
        }

        const plusButton = !this.props.ShowPlus ? <></> :  
            <IconButton AppObject={this._app!} 
                Tag='plus'
                IconRef={IconPlus(undefined)}
                PressTickInterval={0}
                OnPressStart={()=>{
                }}
                OnPressTick={()=>{
                }}
                OnPressFinish={async (): Promise<boolean>=>{
                    return controlThis.props.OnPlusClick(bestTop(), this.props.ArtistProfile.artistId, this.props.ArtistProfile.artistName!);
                }}
                StartPushedState={false}
                Haptic={controlThis.doButtonHaptic()}
                Height={this._app!.GetScaledPxHeight(31)}

            />;


        const pendingButton = !this.props.ShowPending ? <></> :  
        <div className='text-bold text-xlarge'>(...)</div>;

        const trashButton = !this.props.ShowTrash ? <></> : 
            <IconButton AppObject={this._app!} 
                Tag='trash'
                IconRef={IconTrash(undefined)}
                PressTickInterval={0}
                OnPressStart={()=>{
                }}
                OnPressTick={()=>{
                }}
                OnPressFinish={async (): Promise<boolean>=>{
                    return controlThis.props.OnTrashClick(bestTop(), this.props.ArtistProfile.artistId, this.props.ArtistProfile.artistName!);
                }}
                StartPushedState={false}
                Haptic={controlThis.doButtonHaptic()
                }
                Height={this._app!.GetScaledPxHeight(31)}
            />;
        const checkButton = !(this.props.ShowCheck === true) ? <></> : 
            <IconButton AppObject={this._app!}
                Tag='check'
                IconRef={this.props.Checked ? IconSelected(undefined): IconSelect(undefined)}
                PressTickInterval={0}
                OnPressStart={()=>{
                }}
                OnPressTick={()=>{
                }}
                OnPressFinish={async (): Promise<boolean>=>{
                    return controlThis.props.OnCheckClick!(bestTop(), this.props.ArtistProfile.artistId, this.props.ArtistProfile.artistName!);
                }}
                StartPushedState={false}
                Haptic={controlThis.doButtonHaptic()}
                Height={this._app!.GetScaledPxHeight(31)}
            />;

        let streakDisplay = <></>;
        let statsColumn = 2;
        if (this.props.StreakInfo !== undefined) {
            statsColumn = 3;
            const secondsLeft = this.props.StreakInfo.secondsUntilStreakEnd;
            const currentStreak = this.props.StreakInfo.currentStreak;
            let streakColor = 'rgb(188, 188, 188)';
            if (secondsLeft > 0) {
                if (secondsLeft < 60 * 60 * 2) {
                    streakColor = 'var(--smesshy-red)';
                } else if (secondsLeft < 60 * 60 * 8) {
                    streakColor = 'var(--smesshy-orange)';
                } else if (secondsLeft < 60 * 60 * 18) {
                    streakColor = 'var(--smesshy-yellow)';
                } else {
                    streakColor = 'var(--smesshy-green)';
                }
            }
            streakDisplay = <svg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg' className='mini-lunchbox'>
                <rect x='0' y='0' width='256' height='256' fill='rgb(240, 240, 240)' stroke='' rx='12' ry='12'></rect>
                <rect x='18' y='67' width={219} height='175' fill={streakColor} strokeWidth='14px' strokeLinejoin='round' rx='19.918' ry='19.918'></rect>
                <rect x='11' y='67' width='233' height='175' fill='none' stroke='rgb(255, 0, 0)' strokeWidth='14px' strokeLinejoin='round' rx='19.918' ry='19.918'></rect>
                <rect x='110' y='52' width='32' height='8' fill='rgb(188, 188, 188)' strokeLinejoin='round' rx='2.173' ry='2.173'></rect>
                <rect x='32' y='54' width='16' height='6' fill='rgb(188, 188, 188)' strokeLinejoin='round' rx='2.173' ry='2.173'></rect>
                <rect x='205' y='54' width='16' height='6' fill='rgb(188, 188, 188)' strokeLinejoin='round' rx='2.173' ry='2.173'></rect>
                <path d='M 89.713 45.155 C 84.488 45.155 80.043 43.486 78.396 41.155 L 54.713 41.155 C 51.399 41.155 48.713 38.469 48.713 35.155 C 48.713 31.841 51.399 29.155 54.713 29.155 L 206.713 29.155 C 210.027 29.155 212.713 31.841 212.713 35.155 C 212.713 38.469 210.027 41.155 206.713 41.155 L 183.03 41.155 C 181.383 43.486 176.938 45.155 171.713 45.155 C 167.373 45.155 163.57 44.003 161.463 42.277 C 159.356 44.003 155.553 45.155 151.213 45.155 C 146.873 45.155 143.07 44.003 140.963 42.277 C 138.856 44.003 135.053 45.155 130.713 45.155 C 126.373 45.155 122.57 44.003 120.463 42.277 C 118.356 44.003 114.553 45.155 110.213 45.155 C 105.873 45.155 102.07 44.003 99.963 42.277 C 97.856 44.003 94.053 45.155 89.713 45.155 Z M 53.713 35.155 C 53.713 36.812 55.497 38.155 57.697 38.155 L 73.729 38.155 C 75.929 38.155 77.713 36.812 77.713 35.155 C 77.713 33.498 75.929 32.155 73.729 32.155 L 57.697 32.155 C 55.497 32.155 53.713 33.498 53.713 35.155 Z M 184.713 35.155 C 184.713 36.812 186.497 38.155 188.697 38.155 L 204.729 38.155 C 206.929 38.155 208.713 36.812 208.713 35.155 C 208.713 33.498 206.929 32.155 204.729 32.155 L 188.697 32.155 C 186.497 32.155 184.713 33.498 184.713 35.155 Z' fill='rgb(255, 54, 54)' transform='matrix(0.992546, -0.121869, 0.121869, 1.4, 0, 0)'></path>
                <line fill='rgb(216, 216, 216)' strokeWidth='4px' stroke='rgb(121, 121, 121)' strokeLinecap='round' x1='40' y1='56' x2='72' y2='44'></line>
                <line fill='rgb(216, 216, 216)' strokeWidth='4px' stroke='rgb(121, 121, 121)' strokeLinecap='round' x1='204' y1='24' x2='212' y2='56'></line>
                <text fill='rgb(255, 255, 255)' fontSize='92px' x='28' y='188'>{currentStreak}</text>
            </svg>


        }

        let mavenDisp = '';
        let followDisp = '';
        if (this.props.ArtistProfile.artistStats !== undefined) {
            mavenDisp = `${Math.round(this.props.ArtistProfile.artistStats.mavenScore * 1000)/1000}`;
            followDisp = `${this.props.ArtistProfile.artistStats.totalFollowers}/${this.props.ArtistProfile.artistStats.totalFollowing}`
        }

        return (
            <SmesshyMainButton AppObject={this._app!}
                Display={
                    <div ref={this.rectRef} className='width-100p v-items group-center h-padding-medium v-padding-small'>
                        <div className='artist-tile-grid'>
                            <div style={{justifySelf:'left', gridRowStart:1, gridRowEnd:4, gridColumn:1}}>
                                {artistImage}
                            </div>
                            <div style={{justifySelf:'left', gridRow:1, gridColumnStart:2, gridColumnEnd:7, paddingLeft:4, paddingRight:4}}>
                                <div className='text-boldish text-black text-clip'>
                                    {this.props.ArtistProfile.artistName}
                                </div>
                            </div>
                            <div style={{gridRowStart:2, gridRowEnd:4, gridColumn:2}}>
                                {streakDisplay}
                            </div>
                            <div className='text-small' style={{gridRow:2, gridColumn:3, paddingLeft:4}}>
                                Maven
                            </div>
                            <div className='text-small' style={{gridRow:2, gridColumn:4, paddingLeft:4, overflow:'hidden'}}>
                                {mavenDisp}
                            </div>
                            <div className='text-small' style={{gridRow:3, gridColumn:3, paddingLeft:4}}>
                                Follow
                            </div>
                            <div className='text-small' style={{gridRow:3, gridColumn:4, paddingLeft:4, overflow:'hidden'}}>
                                {followDisp}
                            </div>
                            <div style={{gridRowStart:2, gridRowEnd:4, gridColumn:5}}>
                                {pendingButton}
                            </div>
                            <div style={{gridRowStart:2, gridRowEnd:4, gridColumn:6}}>
                                <div className='h-items h-small-gap'>
                                    {plusButton}
                                    {trashButton}
                                    {checkButton}
                                </div>
                            </div>
            
                        </div>
                    </div>}
                Haptic={this.doButtonHaptic()}
                StartPushedState={false}
                ExtraStyleClass='each-space-around'
                AllowVScroll={this.props.AllowVScroll}
                OnPressFinish={async (): Promise<boolean>=>{
                    return controlThis.props.OnTileClick(bestTop(), controlThis.props.ArtistProfile.artistId, this.props.ArtistProfile.artistName!)}
                }
        />

        );
    }
}


export interface FollowingPageState extends SmesshyCommonState {
    userId: string | null;
    newSearchOn: string | undefined;
    prevSearchOn: string | undefined;
    searchNextStart: number;
    searchAtEnd: boolean;
    searchArtists: Array<[ArtistProfile, string]> | undefined;
    followingAtEnd: boolean;
    followingArtists: Array<[ArtistProfile, string]>;
    followerAtEnd: boolean;
    followerArtists: Array<[ArtistProfile, string]>;
    asyncUpdate: number;
}
export interface FollowingPageProps extends SmesshyCommonProps {
    FetchCount: number;
}

class FollowingPage extends SmesshyCommon(Component<FollowingPageProps, FollowingPageState>) {

    followingRequestNextStart: number;
    followingRequestAtEnd: boolean;
    followingArtistsNextStart: number;
    followingArtistsAtEnd: boolean;
    followerRequestNextStart: number;
    followerRequestAtEnd: boolean;
    followerArtistsNextStart: number;
    followerArtistsAtEnd: boolean;
    searchAbortController: AbortController | undefined;

    constructor(props: FollowingPageProps) {
        super(props);
        this.initCommon(props.AppObject);

        this.followingRequestNextStart =  0;
        this.followingRequestAtEnd =  false;
        this.followingArtistsNextStart =  0;
        this.followingArtistsAtEnd =  false;
        this.followerRequestNextStart =  0;
        this.followerRequestAtEnd =  false;
        this.followerArtistsNextStart =  0;
        this.followerArtistsAtEnd =  false;

        let reqNav = this.requireSetup(()=>{}, true);

        this.state = {
            showWaitSpin: false,
            authenticated: false,
            userId: null,
            newSearchOn: undefined,
            prevSearchOn: undefined,
            searchNextStart: 0,
            searchAtEnd: true,
            searchArtists: undefined,
            followingAtEnd: false,
            followingArtists: new Array<[ArtistProfile, string]>(),
            followerAtEnd: false,
            followerArtists: new Array<[ArtistProfile, string]>(),
            navigateTo: reqNav,
            askQuestion: undefined,
            asyncUpdate: 0
        
        };

    }

    componentDidMount() {
        STrace.addStep('following', 'didMound', '');
    }
    
    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('following', 'populateAuth', authenticated.toString());

        if (authenticated) {
            this.setState({authenticated:true, userId: await this.getUserId()});
            
            let token = await this.getAccessToken();
            if (token !== undefined) {
                await this.fetchMoreFollowingAsync();
                await this.fetchMoreFollowersAsync();
                // clear any notifications about follow requests
                this._app!.clearNotifications('following');
            }
            
        } else {
            this.setState({authenticated:false});
        }
    }

    async completeFollowingProfile(ids: Array<string>) : Promise<Array<ArtistProfile>> {
        let newFollows = new Array<ArtistProfile>();
        let newFollowLU = new Map<string, ArtistProfile>();
        for (const id of ids) {
            const profile: ArtistProfile = {artistId: id, artistName: 'wait...', artistImageUrl: undefined, artistStats: undefined};
            newFollows.push(profile);
            newFollowLU.set(id, profile);
        }
        let controlThis = this;
        STrace.addStep('following', 'getArtistProfilesAsync', '');
        await this.storageManager!.getArtistProfilesAsync(ids, 'basic', (profs: Array<ArtistProfile>)=>{
            for (const prof of profs) {
                if (newFollowLU.has(prof.artistId)) {
                    const oldProf = newFollowLU.get(prof.artistId)!;
                    oldProf.artistImageUrl = prof.artistImageUrl;
                    oldProf.artistName = prof.artistName;
                    oldProf.artistStats = prof.artistStats;
                }
                
            }
            controlThis.setState({asyncUpdate: controlThis.state.asyncUpdate + 1});
        });

        return newFollows;
    }

    async fetchMoreFollowingRequestsAsync() : Promise<Array<ArtistProfile> | undefined> {
        if (this.followingRequestAtEnd) {
            return undefined;
        }
        let token = await this.getAccessToken();
        if (token === undefined) {
            return undefined;
        }           

        STrace.addStep('following', 'getUserFollowingAsync', 'pending');
        const more = await this.storageManager!.getUserFollowingAsync('pending', false);
        const morelist  = new Array<string>(); more.forEach(i=>morelist.push(i));
        STrace.addStep('following', 'completeFollowingProfile', 'more following pending');
        const newFollowing = await this.completeFollowingProfile(morelist);
        this.followingRequestAtEnd = true;
        this.followingRequestNextStart = newFollowing.length;
        return newFollowing;
    }
   
    async fetchMoreFollowingArtistsAsync() : Promise<Array<ArtistProfile> | undefined> {
        if (this.followingArtistsAtEnd) {
            return undefined;
        }
        let token = await this.getAccessToken();
        if (token === undefined) {
            return undefined;
        }           

        STrace.addStep('following', 'getUserFollowingAsync', 'approved');
        const more = await this.storageManager!.getUserFollowingAsync('approved', false);
        const morelist  = new Array<string>(); more.forEach(i=>morelist.push(i));
        STrace.addStep('following', 'completeFollowingProfile', 'more following');
        const newFollowing = await this.completeFollowingProfile(morelist);
        this.followingArtistsAtEnd = true;
        this.followingArtistsNextStart = newFollowing.length;
        return newFollowing
    }

    async fetchMoreFollowerRequestsAsync() : Promise<Array<ArtistProfile> | undefined> {
        if (this.followerRequestAtEnd) {
            return undefined;
        }
        let token = await this.getAccessToken();
        if (token === undefined) {
            return undefined;
        }           

        STrace.addStep('following', 'getUserFollowersAsync', 'pending');
        const more = await this.storageManager!.getUserFollowersAsync(this.followerRequestNextStart, this.followerRequestNextStart + 29, 'pending');
        STrace.addStep('following', 'completeFollowingProfile', 'more follower pending');
        const newFollowing = await this.completeFollowingProfile(more);
        this.followerRequestAtEnd = newFollowing.length < 30;
        this.followerRequestNextStart = this.followerRequestNextStart + newFollowing.length;
        return newFollowing
    }

    async fetchMoreFollowerArtistsAsync() : Promise<Array<ArtistProfile> | undefined> {
        if (this.followerArtistsAtEnd) {
            return undefined;
        }
        let token = await this.getAccessToken();
        if (token === undefined) {
            return undefined;
        }           

        STrace.addStep('following', 'getUserFollowersAsync', '');
        const more = await this.storageManager!.getUserFollowersAsync(this.followerArtistsNextStart, this.followerArtistsNextStart + 29, 'approved');
        STrace.addStep('following', 'completeFollowingProfile', 'more follower');
        const newFollowing = await this.completeFollowingProfile(more);
        this.followerArtistsAtEnd = newFollowing.length < 30;;
        this.followerArtistsNextStart = this.followerArtistsNextStart + newFollowing.length;
        return newFollowing
    }

    async fetchMoreFollowingAsync() {
        
        if (this.state.followingAtEnd) {
            return;
        }
        const following = new Array<[ArtistProfile, string]>();
        if (this.followingArtistsAtEnd === false) {
            const followingArtists = await this.fetchMoreFollowingArtistsAsync();
            if (followingArtists !== undefined) {
                for (const f of followingArtists) {
                    following.push([f, 'approved']);
                }
            }
        }
        if (following.length < 30 && this.followingRequestAtEnd === false) {
            const followingRequests = await this.fetchMoreFollowingRequestsAsync();
            if (followingRequests !== undefined) {
                for (const f of followingRequests) {
                    following.push([f, 'pending']);
                }
            }
        }

        this.setState({followingAtEnd: this.followingArtistsAtEnd, followingArtists: this.state.followingArtists.concat(following)});
    }

    async fetchMoreFollowersAsync() {
        if (this.state.followerAtEnd) {
            return;
        }
        const followers = new Array<[ArtistProfile, string]>();
        if (this.followerRequestAtEnd === false) {
            const followerRequests = await this.fetchMoreFollowerRequestsAsync();
            if (followerRequests !== undefined) {
                for (const f of followerRequests) {
                    followers.push([f, 'pending']);
                }
            }
        }
        if (followers.length < 30 && this.followerArtistsAtEnd === false) {
            const followerArtists = await this.fetchMoreFollowerArtistsAsync();
            if (followerArtists !== undefined) {
                for (const f of followerArtists) {
                    followers.push([f, 'approved']);
                }
            }
        }

        this.setState({followerAtEnd: this.followerArtistsAtEnd, followerArtists: this.state.followerArtists.concat(followers)});
    }


    fetchMoreFollowing(authenticated: boolean) {
        let controlThis = this;
        if (authenticated === false) {
            return;
        }
        this.pushWaitingFrame(this);
        this.executeAsync(async ()=> {
            await controlThis.fetchMoreFollowingAsync();
            controlThis.popWaitingFrame();
        });
    }

    fetchMoreFollowers(authenticated: boolean) {
        let controlThis = this;
        if (authenticated === false) {
            return;
        }
        this.pushWaitingFrame(this);
        this.executeAsync(async ()=> {
            await controlThis.fetchMoreFollowersAsync();
            controlThis.popWaitingFrame();
        });
    }

    fetchMoreSearchResults() {
        let controlThis = this;
        if (this.state.searchAtEnd) {
            return;
        }
        this.executeAsync(async ()=> {
            const token = await controlThis.getAccessToken();
            controlThis.searchAbortController = new AbortController();
            STrace.addStep('following', 'searchArtistProfilesAsync', controlThis.state.newSearchOn!);
            await controlThis.storageManager!.searchArtistProfilesAsync(controlThis.state.newSearchOn!, 
                controlThis.state.searchNextStart, controlThis.state.searchNextStart + 30, 
                controlThis.searchAbortController.signal, (profs: Array<ArtistProfile>)=>{
                    controlThis.searchAbortController = undefined;
                    let oldList = controlThis.state.searchArtists !== undefined ? controlThis.state.searchArtists.slice() : new Array<[ArtistProfile, string]>();
                    for (const p of profs) {
                        oldList.push([p, '']);
                    }
            
                    controlThis.setState({searchArtists: oldList,
                         searchNextStart:controlThis.state.searchNextStart + profs.length, 
                         searchAtEnd: profs.length < 30});
                });
            });

    }


    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`following, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;

        const onOtherNavigate = async (otherId: string, otherName: string) : Promise<boolean> => {
            STrace.addStep('following', 'other nav', otherId);
            const params = createSearchParams({title: otherName, scope: 'others', filter:otherId});
            controlThis.setState({navigateTo: {To:`/other-gallery?${params.toString()}`}});
            return true;
        }
        const onPlusClick = async (top: number, otherId: string, otherName: string) => {
            STrace.addStep('following', 'approveUserFollowerAsync', otherId);
            controlThis.pushWaitingFrame(controlThis);
            await controlThis.storageManager!.approveUserFollowerAsync(otherId);
            const otherPair = controlThis.state.followerArtists.find(prof=>prof[0].artistId === otherId);
            if (otherPair !== undefined) {
                otherPair[1] = 'approved';
                controlThis.setState({asyncUpdate:controlThis.state.asyncUpdate + 1});
            }
            controlThis.popWaitingFrame();
            return true;
        }
        const onAddClick = async (top: number, otherId: string, otherName: string) => {
            STrace.addStep('following', 'postUserFollowingAsync', otherId);
            controlThis.pushWaitingFrame(controlThis);
            await controlThis.storageManager!.postUserFollowingAsync(otherId);
            const otherPair = controlThis.state.searchArtists!.find(prof=>prof[0].artistId === otherId);
            if (otherPair !== undefined) {
                otherPair[1] = 'pending';
                controlThis.state.followingArtists.push(otherPair);
                controlThis.setState({asyncUpdate:controlThis.state.asyncUpdate + 1});
            }
            controlThis.popWaitingFrame();
            return true;
        }

        const onTrashClick = async (top: number, otherId: string, otherName: string, following: boolean, pending: boolean) => {

            let promptText1: string;
            let promptText2: string;
            let yesText: string;
            if (following) {
                if (pending) {
                    promptText1 = `Stop waiting for 'follow' approval`;
                    promptText2 = `from ${otherName}?`;
                    yesText = 'Yes, stop';
                } else {
                    promptText1 = `Stop following`;
                    promptText2 = `${otherName}?`;
                    yesText = 'Yes, stop';
                }
            } else {
                if (pending) {
                    promptText1 = `Quietly remove this request`;
                    promptText2 = `from ${otherName}?`;
                    yesText = `Ghost 'em`;
                } else {
                    promptText1 = `Quietly remove ${otherName}`;
                    promptText2 = `from your followers?`;
                    yesText = `Yes, farewell`;
                }
            }

            controlThis.setState({askQuestion:{
                Top: top,
                Title: <span>Trash?</span>,
                Prompts: [<span key={'l1'}>{promptText1}</span>,
                        <span key={'l2'}>{promptText2}</span>],
                Options: [{Key:'trashFollow', Option:<span>{yesText}</span>, OnOption:async ():Promise<boolean>=>{
                    let newFollowingArtists = controlThis.state.followingArtists;
                    let newFollowerArtists = controlThis.state.followerArtists;

                    controlThis.pushWaitingFrame(controlThis);
                    if (following) {
                        if (pending) {
                            STrace.addStep('following', 'deleteUserFollowingAsync-pending', otherId);
                            await controlThis.storageManager!.deleteUserFollowingAsync(otherId, 'pending');
                        } else {
                            STrace.addStep('following', 'deleteUserFollowingAsync-approved', otherId);
                            await controlThis.storageManager!.deleteUserFollowingAsync(otherId, 'approved');
                        }
                        newFollowingArtists = controlThis.state.followingArtists.filter(prof=>prof[0].artistId !== otherId);
                    } else {
                        if (pending) {
                            STrace.addStep('following', 'deleteUserFollowerAsync-pending', otherId);
                            await controlThis.storageManager!.deleteUserFollowerAsync(otherId, 'pending');
                        } else {
                            STrace.addStep('following', 'deleteUserFollowerAsync-approved', otherId);
                            await controlThis.storageManager!.deleteUserFollowerAsync(otherId, 'approved');
                        }
                        newFollowerArtists = controlThis.state.followerArtists.filter(prof=>prof[0].artistId !== otherId);

                    }
                    controlThis.setState({asyncUpdate:controlThis.state.asyncUpdate + 1, followerArtists: newFollowerArtists, followingArtists: newFollowingArtists});
        
                    controlThis.setState({askQuestion: undefined});
                    controlThis.popWaitingFrame();
                    return true;
                }}],
                OnCancel:()=>{
                    controlThis.setState({askQuestion:undefined});}
                }});
            return true;
        }

        let searchArtistEntry = <SearchInput
            AppObject={this.props.AppObject}
            DefaultPrompt='find someone new...'
            DefaultValue={this.state.newSearchOn}
            Delay={750}
            Haptic={this.doButtonHaptic()}
            Scale={this._app!.GetScaledPxHeight(1)}
            OnValueChange={(newValue: string | undefined)=> {
                // will initiate a search in the search observer
                controlThis.setState({newSearchOn: newValue, prevSearchOn: controlThis.state.prevSearchOn, searchAtEnd: false, searchNextStart:0, searchArtists: undefined});
            }}
        
        />


        const followingList = <div id='following-scroll-host' className='width-100p height-100p v-scroll-container'>
                            <InfiniteScroll
                                dataLength={this.state.followingArtists.length}
                                next={()=>controlThis.fetchMoreFollowing(controlThis.state.authenticated)}
                                hasMore={!this.state.followingAtEnd}
                                loader={<div className='text-bold'>There's more...</div>}
                                scrollableTarget='following-scroll-host'

                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                    <b>Done</b>
                                    </p>
                                }
                                >
                                {this.state.followingArtists.map((pair, index) => (
                                    <FollowingTile
                                        key={index}
                                        AppObject={this.props.AppObject}
                                        AppShape={this._appShape!}
                                        ArtistProfile={pair[0]}
                                        ShowTrash = {true}
                                        ShowPending = {pair[1] === 'pending'}
                                        ShowPlus = {false}
                                        AllowVScroll = {true}
                                        OnTileClick={async (top:number, artistId:string, artistName:string)=>{return await onOtherNavigate(artistId, artistName)}}
                                        OnTrashClick={async (top:number, artistId:string, artistName:string)=>{return await onTrashClick(top, artistId, artistName, true, pair[1] === 'pending')}}
                                        OnPlusClick={async (top:number, artistId:string, artistName:string)=>{return true;}}
                                        />
                                ))}
                            </InfiniteScroll>
                        </div>;

        const followersList = <div id='followers-scroll-host' className='width-100p height-100p v-scroll-container'>
                            <InfiniteScroll
                                dataLength={this.state.followerArtists.length}
                                next={()=>controlThis.fetchMoreFollowers(controlThis.state.authenticated)}
                                hasMore={!this.state.followerAtEnd}
                                loader={<div className='text-bold'>There's more...</div>}
                                scrollableTarget='followers-scroll-host'

                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                    <b>Done</b>
                                    </p>
                                }
                                >
                                {this.state.followerArtists.map((pair, index) => (
                                    <FollowingTile
                                    key={index}
                                    AppObject={this.props.AppObject}
                                    AppShape={this._appShape!}
                                    ArtistProfile={pair[0]}
                                    ShowTrash = {true}
                                    ShowPlus = {pair[1] === 'pending'}
                                    ShowPending = {false}
                                    AllowVScroll = {true}
                                    OnTileClick={async (top:number, artistId:string, artistName:string)=>{return await onOtherNavigate(artistId, artistName)}}
                                    OnTrashClick={async (top:number, artistId:string, artistName:string)=>{return await onTrashClick(top, artistId, artistName, false, pair[1] === 'pending')}}
                                    OnPlusClick={async (top:number, artistId:string, artistName:string)=>{return await onPlusClick(top, artistId, artistName)}}
                                />
                            ))}
                            </InfiniteScroll>
                        </div>;

        const searchArtistList = <div id='search-scroll-host' className='width-100p height-100p v-scroll-container'>
                            <InfiniteScroll
                                dataLength={this.state.searchArtists === undefined ? 0 : this.state.searchArtists.length}
                                next={()=>controlThis.fetchMoreSearchResults()}
                                hasMore={!this.state.searchAtEnd}
                                loader={<div className='text-bold'>Finding them...</div>}
                                scrollableTarget='search-scroll-host'

                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                    <b>That's them</b>
                                    </p>
                                }
                                >
                                {this.state.searchArtists === undefined ? <></> : this.state.searchArtists.map((pair, index) => (
                                    <FollowingTile
                                    key={index}
                                    AppObject={this.props.AppObject}
                                    AppShape={this._appShape!}
                                    ArtistProfile={pair[0]}
                                    ShowTrash = {false}
                                    ShowPlus = {pair[1] !== 'pending'}
                                    ShowPending = {pair[1] === 'pending'}
                                    AllowVScroll = {true}
                                    OnTileClick={async (top:number, artistId:string, artistName:string)=>{return await onOtherNavigate(artistId, artistName)}}
                                    OnTrashClick={async (top:number, artistId:string, artistName:string)=>{return true;}}
                                    OnPlusClick={async (top:number, artistId:string, artistName:string)=>{return await onAddClick(top, artistId, artistName)}}
                                />
                            ))}
                            </InfiniteScroll>
                        </div>;


        const SearchObserver = () => {
            useEffect(()=>{
                if (controlThis.state.newSearchOn !== controlThis.state.prevSearchOn) {
                    // cancel any pending search request
                    if (controlThis.searchAbortController !== undefined) {
                        controlThis.searchAbortController.abort();
                        controlThis.searchAbortController = undefined;
                    }
                    if (controlThis.state.newSearchOn === undefined) {
                        controlThis.setState({searchArtists: undefined})
                    } else {
                        controlThis.fetchMoreSearchResults();
                    }
                }
            });
            return <></>;
        }

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={true}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>Smesshy - Following</span>}
                CloseNav = {-1}
                Body={
                    <>
                        <SearchObserver/>
                        <div className='game-page-mid smesshy-main text-white text-medium h-padding-small v-padding-small' >
                            <div className='h-items h-small-gap width-100p height-100p'>
                                <div className='v-items v-small-gap' style={{width:'50%'}}>
                                    <div className='text-bold text-large h-padding-medium'>
                                        Following:
                                    </div>
                                    {searchArtistEntry}
                                    {this.state.newSearchOn !== undefined ? searchArtistList : followingList}
                                </div>
                                <div className='v-items v-small-gap' style={{width:'50%'}}>
                                    <div className='text-bold text-large h-padding-medium'>
                                        Followers:
                                    </div>
                                    {followersList}
                                </div>

                            </div>
            
                        </div>

                    </>
                }
            />,
            this.state
        );
    }
}

export default FollowingPage;